import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { getPillarIssueSubIssueTreeList } from '../../services/GeneralDisclosureService';
import Snackbar from '../../toaster-message/Snackbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import { useSelector } from 'react-redux';

var facilityRefNo = [];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

const icon = <material.CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <material.CheckBoxIcon fontSize="small" />;

function GlobalFilter(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openFilterDialog, setOpenFilterDialog, getFilterData, callFrom } = props;
    const [issueName, setIssueName] = useState([]);
    const [selectedIssueName, setSelectedIssueName] = useState("");
    const [value, setValue] = React.useState(dayjs(new Date()));
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [facilityName, setFacilityName] = useState([]);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const year = localStorage.getItem("year");
    useEffect(() => {
        if (openFilterDialog.action) {
            if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
                getFacilityByCorporateRefNo();
            }
            setValue(dayjs(year));
        };
    }, [openFilterDialog.action === true]);

    const getFacilityByCorporateRefNo = () => {
        getSitesByCorporateRefNo(openFilterDialog.userData.corporateRefNo)
            .then((resp) => {
                setFacilityName(resp.data)
            })
            .catch((error) => {

            })
    };

    const getPillarIssueSubIssueList = () => {
        getPillarIssueSubIssueTreeList()
            .then((resp) => {
                console.log(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const selectFacility = (e, newValue) => {
        let refNo = newValue.map((ele) => ele.refNo);
        // console.log(newValue)
        // facilityRefNo.push(refNo);
        setSelectedFacilities(refNo)
        // setSelectedFacilities((prevSelected) => {
        //     const isSelected = prevSelected.includes(facilityRefNo);
        //     if (isSelected) {
        //         return prevSelected.filter(id => id !== facilityRefNo);
        //     } else {
        //         return [...prevSelected, facilityRefNo];
        //     }
        // });
    };

    const selectIssueName = () => {

    };

    const handleChangeYear = (newValue) => {
        setValue(newValue);
        // let facilities = facilityRefNo.length ? facilityRefNo : null;
        // let year = newValue.$y;
        // getWasteGenerationReportingData(facilities, year);
        // getWasteRecoveryAndDisposalReportingData(facilities, year);
        // getEnergyConsumptionWithinOrgReportingData(facilities, year);
        // getAirPointSourceReportingData(facilities, year)
        localStorage.setItem("year", newValue.$y);
    };

    const applyFilter = () => {
        let data = {
            facilityRefNo: userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? selectedFacilities : [userDetails.facilityRefNo],
            year: value.$y
        }
        getFilterData(data);
        setOpenFilterDialog({ action: false, userData: null });
        setSelectedFacilities([]);
        facilityRefNo = [];
    };

    const handleClose = () => {
        setOpenFilterDialog({ action: false, userData: null });
        setSelectedFacilities([]);
        facilityRefNo = [];
    };

    return (
        <div>
            <material.Dialog open={openFilterDialog.action} maxWidth={userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? "md" : "sm"} fullWidth TransitionComponent={Transition}>
                <material.DialogTitle>Filter by</material.DialogTitle>
                <material.DialogContent>
                    {userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? (
                        <div className="row">
                            {callFrom !== "issue-dashboard" ? (
                                <div className="col-lg-4">
                                    <material.Autocomplete
                                        id="issue"
                                        options={issueName}
                                        onChange={selectIssueName}
                                        value={selectedIssueName}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Issue"
                                            fullWidth color='success'
                                        />}
                                    />
                                </div>
                            ) : null}
                            <div className={callFrom !== "issue-dashboard" ? "col-lg-4" : "col-lg-6"}>
                                <material.Autocomplete
                                    id="treatment"
                                    margin="dense"
                                    onChange={selectFacility}
                                    options={facilityName}
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(option) => option.assetName}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <material.Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.assetName}
                                        </li>
                                    )}
                                    renderInput={(params) => <material.TextField {...params} required variant="standard" label="Facility Name"
                                    />}
                                />
                            </div>
                            <div className={callFrom !== "issue-dashboard" ? "col-lg-4" : "col-lg-6"}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        openTo="year"
                                        label="Year"
                                        format='YYYY'
                                        views={['year']}
                                        value={value}
                                        onChange={handleChangeYear}
                                        slotProps={{ textField: { variant: 'standard', color: "success" } }}
                                        sx={{ width: "100%" }}
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            {callFrom !== "issue-dashboard" ? (
                                <div className="col-lg-6">
                                    <material.Autocomplete
                                        id="issue"
                                        options={issueName}
                                        onChange={selectIssueName}
                                        value={selectedIssueName}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Issue"
                                            fullWidth color='success'
                                        />}
                                    />
                                </div>
                            ) : null}
                            <div className={callFrom !== "issue-dashboard" ? "col-lg-6" : "col-lg-12"}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        openTo="year"
                                        label="Year"
                                        format='YYYY'
                                        views={['year']}
                                        value={value}
                                        onChange={handleChangeYear}
                                        slotProps={{ textField: { variant: 'standard', color: "success" } }}
                                        sx={{ width: "100%" }}
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button variant="outlined" color='info' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.DoneIcon />} onClick={applyFilter}>Apply</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default GlobalFilter;
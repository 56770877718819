import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateBiodiversityValueOfSiteData = async (BiodiversityValueOfSiteData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/biodiversity-value-of-site/save-or-update`, BiodiversityValueOfSiteData)
};

export const getBiodiversityValueOfSiteData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/biodiversity-value-of-site/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalBiodiversityValueOfSite = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/biodiversity-value-of-site/send-for-approval`, recordId)
};

export const approveBiodiversityValueOfSite = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/biodiversity-value-of-site/approve`, recordId)
};

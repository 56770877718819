import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddGhgScope1 from '../../../sub-issues-dialogs/climate-change-dialog/AddGhgScope1';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import moment from 'moment';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import { approveGhgScope, getGhgScopeData, sendForApprovalGhgScope } from '../../../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';



const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
var usersRole;
var recordRefNo = [];

function GhgScope1(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const [scope1GhgEmissionTabValue, setScope1GhgEmissionTabValue] = useState('Direct GHG Emission');
    const [openAddGhgScope1, setOpenAddGhgScope1] = useState({ action: false, ghgScope1Data: null });
    const [scope1TableData, setScope1TableData] = useState([])
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getGhgScope1AllData();
    }, [scope1GhgEmissionTabValue]);

    const getGhgScope1AllData = async (filterDate) => {
        if (scope1GhgEmissionTabValue === "Direct GHG Emission") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "GHG_SCOPE1"
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate
            }
            await getGhgScopeData(object)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList)
                    setScope1TableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (scope1GhgEmissionTabValue === "Direct GHG Emission History") {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "GHG_SCOPE1"
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate
            }
            await getGhgScopeData(object)
                .then((resp) => {
                    setScope1TableData(resp.data.dataDtoList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        }
    };
    const changeDirectScope1GhgEmissionTab = (event, newValue) => {
        setScope1GhgEmissionTabValue(newValue);
    };
    const addDirectScope1GhgEmission = () => {
        setOpenAddGhgScope1({ action: true, ghgScope1Data: null });
    };
    const editDirectScope1GhgEmission = (rowData) => {
        setOpenAddGhgScope1({ action: true, ghgScope1Data: rowData });
    };
    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };
    const clickCheckBox = (value) => {
        setOpenAddGhgScope1({ action: false, ghgScope1Data: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value);
        }
    };
    const sendForApprovalScope1 = async () => {
        if (recordRefNo.length) {
            await sendForApprovalGhgScope(recordRefNo)
                .then((resp) => {
                    getGhgScope1AllData();
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        }
    };
    const approveScope1 = async () => {
        if (recordRefNo.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo,
                typeOfRecord: "GHG_SCOPE1"
            }
            await approveGhgScope(obj)
                .then((resp) => {
                    getGhgScope1AllData();
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };
    const rejectScope1 = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo,
            typeOfRecord: "GHG_SCOPE1"
        }
        await approveGhgScope(obj)
            .then((resp) => {
                getGhgScope1AllData();
                recordRefNo = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        getGhgScope1AllData(data);
    }

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 2, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6' style={{ display: 'flex', justifyContent: 'end' }}>
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <material.TabContext value={scope1GhgEmissionTabValue}>
                        <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <material.TabList onChange={changeDirectScope1GhgEmissionTab} aria-label="lab API tabs example">
                                <material.Tab className='text-capitalize' label="GHG Scope 1" value="Direct GHG Emission" />
                                <material.Tab className='text-capitalize' label="GHG Scope 1 History" value="Direct GHG Emission History" />
                            </material.TabList>
                        </material.Box>
                        <material.TabPanel value="Direct GHG Emission">
                            <div className="row">
                                <div className="col-6">
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    )}
                                </div>
                                <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                        <span className="float-end">
                                            {scope1TableData.length ? (
                                                <>
                                                    <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveScope1}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectScope1}>Reject</material.Button>
                                                </>
                                            ) : null}
                                        </span>
                                    ) : (
                                        <span className="float-end">
                                            <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalScope1}>Send for Approval</material.Button>
                                            <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addDirectScope1GhgEmission}>Add GHG Scope 1 Data</material.Button>
                                        </span>
                                    )}
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow >
                                                <StyledTableCell>Type of Source</StyledTableCell>
                                                <StyledTableCell>Activity</StyledTableCell>
                                                <StyledTableCell>Amount</StyledTableCell>
                                                <StyledTableCell>Unit CO2-e</StyledTableCell>
                                                <StyledTableCell>From Date</StyledTableCell>
                                                <StyledTableCell>To Date</StyledTableCell>
                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                )}
                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                )}
                                            </material.TableRow>
                                        </material.TableHead>

                                        <material.TableBody>
                                            {scope1TableData?.length ? scope1TableData.map((item, ind) => (
                                                <material.TableRow key={ind}
                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                        () => editDirectScope1GhgEmission(item) : null} >
                                                    <material.TableCell>{item.typeOfSource}</material.TableCell>
                                                    <material.TableCell>{item.activity}</material.TableCell>
                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                    <material.TableCell>{item.unitCo2}</material.TableCell>
                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    )}
                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                        ) : (
                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                        )}
                                                    </material.TableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                        )}
                                                        </material.TableCell>
                                                    )}
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={9}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Paper>
                        </material.TabPanel>

                        <material.TabPanel value="Direct GHG Emission History">
                            <div className="row">
                                <div className="col-8">
                                    <DateFilter
                                        getFormDateToDate={getFormDateToDate}
                                    />
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow >
                                                <StyledTableCell>Type of Source</StyledTableCell>
                                                <StyledTableCell>Activity</StyledTableCell>
                                                <StyledTableCell>Amount</StyledTableCell>
                                                <StyledTableCell>Unit CO2-e</StyledTableCell>
                                                <StyledTableCell>From Date</StyledTableCell>
                                                <StyledTableCell>To Date</StyledTableCell>
                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>

                                        <material.TableBody>
                                            {scope1TableData?.length ? scope1TableData.map((item, ind) => (
                                                <material.TableRow key={ind} >
                                                    <material.TableCell>{item.typeOfSource}</material.TableCell>
                                                    <material.TableCell>{item.activity}</material.TableCell>
                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                    <material.TableCell>{item.unitCo2}</material.TableCell>
                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                    <material.TableCell>
                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                            APPROVED
                                                        </span> : null}
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={9}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Paper>
                        </material.TabPanel>
                    </material.TabContext>
                </material.Box>
            </material.Box>
            <AddGhgScope1
                openAddGhgScope1={openAddGhgScope1}
                setOpenAddGhgScope1={setOpenAddGhgScope1}
                getGhgScope1AllData={getGhgScope1AllData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getGhgScope1AllData={getGhgScope1AllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default GhgScope1;


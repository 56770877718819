import React from 'react';
import { Chart } from "react-google-charts";

export const data = [
    ["Facility Name", "Amount"],
    ["Dabur Coimbatore", 100], // RGB value
    ["Dabur India Narandranpur Unit", 200], // English color name
    ["Facility 3", 250],
    ["Facility 4", 160], // CSS-style declaration
];


function ColumnChart(props) {

    const { columnChartData, fullScreen, options } = props;
    const chartDataSource = columnChartData && columnChartData.length > 1 ? columnChartData : [['Category', 'Value'], ['No Data Found', 0]];
    // const options = {
    //     title: "Material Used",
    //     hAxis: {
    //         title: 'Material Type',
    //     },
    //     vAxis: {
    //         title: 'Amount (MT)',
    //     },
    //     legend: { position: 'top' },
    // };

    // let randomColorArray = []
    // if (columnChartData !== null) {
    //     Object.keys(columnChartData).forEach(x => {
    //         randomColorArray.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
    //     });
    // }

    // const data = {
    //     labels: columnChartData !== null ? Object.keys(columnChartData) : "",
    //     datasets: [
    //         {
    //             label: "Material Used",
    //             data: columnChartData,
    //             backgroundColor: randomColorArray
    //         },
    //     ],
    // };
    // let obj = {};
    // columnChartData?.forEach((ele) => {
    //     obj = {
    //         label: Object.keys(ele),
    //         value: Object.values(ele)
    //     }
    //     return obj;
    // })

    // const data = [
    //     ["Type", "Amount", { "role": "style" }],
    //     ...columnChartData?.map((res) => [res.label, res.value, `color:#${Math.floor(Math.random() * 16777215).toString(16)}`])
    // ];

    return (
        <div>
            <Chart
                chartType="ColumnChart"
                width="100%" height={fullScreen ? "500px" : "400px"}
                data={chartDataSource}
                options={options}
            />
        </div>
    );
}

export default ColumnChart;
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";

export const saveOrUpdateGrievanceRedressalData = async (grievance) => {
    return await axiosInstance.post(`${rootApiUrl}/api/grievance-redressal-status/save-or-update`, grievance)
};

export const getAllGrievanceRedressalData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/grievance-redressal-status/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory ,fromDate: payload.fromDate, toDate: payload.toDate} })
};

export const sendForApprovalGrievanceRedressalRecords = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/grievance-redressal-status/send-for-approval`, recordIds)
};

export const approveGrievanceRedressalRecords = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/grievance-redressal-status/approve`, payloadData)
};
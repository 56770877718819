import React, { useEffect, useState } from "react";
import { material } from "../../../library/Material";
import SwipeDrawer from "../../drawer/SwipeDrawer";
import { useLocation } from "react-router-dom";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import { useSelector } from "react-redux";
import AddEnergy from "../../../sub-issues-dialogs/AddEnergy";
import { getAllEnergyData, sendForApprovalEnergy, approveRecordEnergy } from "../../../services/sub-issue-services/energy/EnergyConsumptionWithinOrgService";
import ApplicableNotApplicableWarning from "../../../dialogs/ApplicableNotApplicableWarning";
import moment from "moment";
import Snackbar from "../../../toaster-message/Snackbar";
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from "../../../shared/Filter";
import RejectionNote from "../../../dialogs/RejectionNote";
import DateFilter from "../../../shared/DateFilter";



var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function EnergyConsumptionWithinOrg(props) {

  const [value, setValue] = useState('Energy consumption within organization');
  const location = useLocation();
  const treeViewData = location.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [openAddOrgEnergy, setOpenAddOrgEnergy] = useState({ action: false, orgElectricityData: null });
  const [energyConsumptionWithinOrgData, setEnergyConsumptionWithinOrgData] = React.useState([]);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [applicable, setApplicable] = useState(true);
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });

  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i];
  };

  useEffect(() => {
    getEnergyData()
  }, [value])

  const getEnergyData = async (filterDate) => {
    if (value === "Energy consumption within organization") {
      let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
      let approveHistory = false;
      let payload = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate
      }
      await getAllEnergyData(payload)
        .then((resp) => {
          let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.energyDtoDataList)
          setEnergyConsumptionWithinOrgData(filterData);
          setApplicable(resp.data.applicable);
          setApplicableRefNo(resp.data.applicableRefNo)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    } else if (value === "Energy consumption within organization History") {
      let forApproval = true;
      let approveHistory = true;
      let payload = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate
      }
      await getAllEnergyData(payload)
        .then((resp) => {
          setEnergyConsumptionWithinOrgData(resp.data.energyDtoDataList);
          setApplicable(resp.data.applicable);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    };
  };

  const addEnergy = () => {
    setOpenAddOrgEnergy({ action: true, orgElectricityDAta: null });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const editEnergyConsumptionWithinOrgData = (rowData) => {
    setOpenAddOrgEnergy({ action: true, orgElectricityData: rowData });
  };
  const clickCheckBox = (value) => {
    setOpenAddOrgEnergy({ action: false, orgElectricityData: null });
    if (recordRefNos.includes(value)) {
      // recordRefNos.pop(value);
      recordRefNos = recordRefNos.filter(item => item !== value);
    }else {
      recordRefNos.push(value)
    }
  };

  const applicableNotApplicable = () => {
    setOpenWarningDialog(true);
  };

  const sendApprovalEnergy = async () => {
    if (recordRefNos.length) {
      await sendForApprovalEnergy(recordRefNos)
        .then((resp) => {
          recordRefNos = [];
          getEnergyData();
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    };
  };

  const approvedEnergy = async () => {
    if (recordRefNos.length) {
      let obj = {
        "rejectionNote": "",
        "corporateRefNo": userDetails.corporateRefNo,
        "facilityRefNo": userDetails.facilityRefNo,
        "subIssueId": treeViewData.id,
        "subIssueName": treeViewData.subIssue,
        "rejectedBy": userDetails.username,
        "status": "APPROVED",
        "recordRefNo": recordRefNos,
      }
      await approveRecordEnergy(obj)
        .then((resp) => {
          recordRefNos = [];
          getEnergyData();
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    };

  };

  const rejectEnergy = async () => {
    if (recordRefNos.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    };
  };

  const getRejectionNoteData = async (noteData) => {
    let obj = {
      "rejectionNote": noteData.rejectionNote,
      "corporateRefNo": userDetails.corporateRefNo,
      "facilityRefNo": userDetails.facilityRefNo,
      "subIssueId": treeViewData.id,
      "subIssueName": treeViewData.subIssue,
      "rejectedBy": userDetails.username,
      "status": "REJECTED",
      "recordRefNo": recordRefNos,
    }
    await approveRecordEnergy(obj)
      .then((resp) => {
        recordRefNos = [];
        getEnergyData();
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        };
      })
  };

  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };

  const getFormDateToDate = (data) => {
    getEnergyData(data);
  }
  return (
    <>
      <material.Box sx={{ display: "flex" }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
          <div className="row">
            <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
            <div className="col-6">
              {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                <span className="float-end">
                  <material.Stack direction="row" spacing={1} alignItems="center">
                    <material.Typography>Not applicable</material.Typography>
                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                    <material.Typography>Applicable</material.Typography>
                  </material.Stack>
                </span>
              )}
            </div>
          </div>
          <div className="mt-3">
            <material.Box sx={{ width: '100%', typography: 'body1' }}>
              <material.TabContext value={value}>
                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                    <material.Tab className="text-capitalize" label="Energy consumption within organization" value="Energy consumption within organization" />
                    <material.Tab className="text-capitalize" label="Energy consumption within organization History" value="Energy consumption within organization History" />
                  </material.TabList>
                </material.Box>
                <material.TabPanel value="Energy consumption within organization">
                  <div className="row align-items-center">
                    <div className="col-6">
                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                        <DateFilter
                          getFormDateToDate={getFormDateToDate}
                        />
                      )}
                    </div>
                    <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                      {usersRole === "ROLE_FACILITY_APPROVER" ? (
                        <span className="float-end">
                          {energyConsumptionWithinOrgData?.length ? (
                            <>
                              <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedEnergy}>Approve</material.Button>
                              <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectEnergy}>Reject</material.Button>
                            </>
                          ) : null}
                        </span>
                      ) : (
                        <span className="float-end">
                          <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalEnergy}>Send for Approval</material.Button>
                          <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addEnergy}>Add Energy</material.Button>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Electricity
                        </material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Type of Source</StyledTableCell>
                                    <StyledTableCell>Source</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>From Date</StyledTableCell>
                                    <StyledTableCell>To Date</StyledTableCell>
                                    <StyledTableCell>Purpose of Consumption</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Rejection Note</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}
                                      sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                      onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                        () => editEnergyConsumptionWithinOrgData(item) : null}>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityTypeOfSource : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricitySource : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityAmount : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityUnit : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityPurposeOfConsumption : "N/A"}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                      <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                          <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                        ) : (
                                          <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                        )}
                                      </material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                          <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                        )}
                                        </material.TableCell>
                                      )}
                                    </material.TableRow>
                                  )) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={10}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Fuel</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Type of Source</StyledTableCell>
                                    <StyledTableCell>Fuel Type </StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{item.fuelTypeOfSource !== "" ? item.fuelTypeOfSource : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.fuelTypeOfSource !== "" ? item.fuelType : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.fuelTypeOfSource !== "" ? item.fuelAmount : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.fuelTypeOfSource !== "" ? item.fuelUnit : "N/A"}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )
                                  }
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Sold</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Purpose</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldPurpose : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldAmount : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldUnit : "N/A"}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{item.electricitySoldPurpose !== "" ? item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : "" : "N/A"}</material.TableCell>
                                      )}
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={5}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )
                                  }
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                </material.TabPanel>
                <material.TabPanel value="Energy consumption within organization History">
                  <div className="row">
                    <div className="col-8">
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    </div>
                  </div>
                  <div className="mt-2 py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Electricity
                        </material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Type of Source</StyledTableCell>
                                    <StyledTableCell>Source</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>From Date</StyledTableCell>
                                    <StyledTableCell>To Date</StyledTableCell>
                                    <StyledTableCell>Purpose of Consumption</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityTypeOfSource : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricitySource : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityAmount : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityUnit : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricityTypeOfSource !== "" ? item.electricityPurposeOfConsumption : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>
                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </material.TableCell>
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={9}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )
                                  }
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Fuel</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Type of Source</StyledTableCell>
                                    <StyledTableCell>Fuel Type </StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{item.fuelTypeOfSource}</material.TableCell>
                                      <material.TableCell>{item.fuelType}</material.TableCell>
                                      <material.TableCell>{item.fuelAmount}</material.TableCell>
                                      <material.TableCell>{item.fuelUnit}</material.TableCell>
                                      <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>
                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </material.TableCell>
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )
                                  }
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="py-2">
                    <material.Accordion>
                      <material.AccordionSummary
                        expandIcon={<material.ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Energy Consumption Within Organization Sold</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Purpose</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {energyConsumptionWithinOrgData?.length ? energyConsumptionWithinOrgData.map((item, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldPurpose : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldAmount : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.electricitySoldUnit : "N/A"}</material.TableCell>
                                      <material.TableCell>{item.electricitySoldPurpose !== "" ? item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : "" : "N/A"}</material.TableCell>
                                      <material.TableCell>
                                        {item.electricitySoldPurpose !== "" ? item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null : "N/A"}
                                      </material.TableCell>
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={5}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )
                                  }
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                </material.TabPanel>
              </material.TabContext>
            </material.Box>
          </div>
        </material.Box>
        <AddEnergy
          openAddOrgEnergy={openAddOrgEnergy}
          setOpenAddOrgEnergy={setOpenAddOrgEnergy}
          getEnergyData={getEnergyData}
          treeViewData={treeViewData}
        />
        <ApplicableNotApplicableWarning
          openWarningDialog={openWarningDialog}
          setOpenWarningDialog={setOpenWarningDialog}
          getEnergyData={getEnergyData}
          applicable={applicable}
          treeViewData={treeViewData}
          applicableRefNo={applicableRefNo}
        />
      </material.Box>
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </>
  );
}
export default EnergyConsumptionWithinOrg;

import { axiosInstance } from "../shared/AxiosConfig";
import { rootApiUrl } from "../environment/Environment";

export const saveFacilityIssueMapping = async (mappingData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/facility-topic-mapping/save`, mappingData)
};

export const getPillarIssueSubIssueFilterData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-pillar-issue-sub-issue-tree-list-filtered`, { params: { ...payload } })
};

export const getEntityData = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/facility-topic-mapping/get-facility-topic-mapping`, payload)
};
import React from 'react';
import { Chart } from "react-google-charts";

function PieChart(props) {

    const { fullScreen, pieChartData, options } = props;

    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             text: 'Material Used Percentage',
    //         },
    //     },
    // };

    // let randomColorArray = []
    // if (pieChartData !== null) {
    //     Object.keys(pieChartData).forEach(x => {
    //         randomColorArray.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
    //     });
    // }

    // const data = {
    //     labels: pieChartData !== null ? Object.keys(pieChartData) : "",
    //     datasets: [
    //         {
    //             data: pieChartData !== null ? Object.values(pieChartData) : "",
    //             backgroundColor: randomColorArray
    //         },
    //     ],
    // };

    // const data = [
    //     ["Type", "Amount", { "role": "style" }],
    //     ...pieChartData?.map((res) => [res.label, Number(res.value), `color:#${Math.floor(Math.random() * 16777215).toString(16)}`])
    // ];

    const chartDataSource = pieChartData && pieChartData.length > 1 ? pieChartData : [['Category', 'Value'], ['No Data Found', 1]];
 
    return (
        <div>
            <Chart
                chartType="PieChart"
                data={chartDataSource}
                options={options}
                width={"100%"}
                height={fullScreen ? "500px" : "400px"}
            />
        </div>
    );
}

export default PieChart;
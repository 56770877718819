import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateInjuriesData } from '../../services/sub-issue-services/InjuriesServices';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddWorkRelatedInjuries(props) {
    const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const { openAddWorkRelatedInjuries, setOpenAddWorkRelatedInjuries, getInjuriesTableData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [workRelatedInjuries, setWorkRelatedInjuries] = useState("");
    const [injureSource, setInjureSource] = useState("");
    const [category, setCategory] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddWorkRelatedInjuries.action && openAddWorkRelatedInjuries.workRelatedInjuriesData) {
            setWorkRelatedInjuries(openAddWorkRelatedInjuries.workRelatedInjuriesData.safetyIncident);
            setCategory(openAddWorkRelatedInjuries.workRelatedInjuriesData.workerCategory);
            setInjureSource(openAddWorkRelatedInjuries.workRelatedInjuriesData.injurySource)
            reset(openAddWorkRelatedInjuries.workRelatedInjuriesData)
        }
    }, [openAddWorkRelatedInjuries.action === true]);

    const selectWorkRelatedInjuries = (event) => {
        setWorkRelatedInjuries(event.target.value);
    };
    const selectCategory = (event) => {
        setCategory(event.target.value);
    };

    const saveWorkRelatedInjuries = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                safetyIncident: data.safetyIncident,
                injurySource: data.injurySource,
                "workerCategory": data.workerCategory,
                "totalNumber": parseInt(data.totalNumber),
                "lostTime": data.lostTime,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            saveOrUpdateInjuriesData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getInjuriesTableData();
                    reset({ totalNumber: "", lostTime: "" });
                    setWorkRelatedInjuries("");
                    setCategory("");
                    setInjureSource("")
                    setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWorkRelatedInjuries = (data) => {
        let object = {
            "refNo": openAddWorkRelatedInjuries.workRelatedInjuriesData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            safetyIncident: data.safetyIncident,
            injurySource: data.injurySource,
            "workerCategory": data.workerCategory,
            "totalNumber": parseInt(data.totalNumber),
            "lostTime": data.lostTime,
            toDate: data.toDate,
            fromDate: data.fromDate,
        }
        saveOrUpdateInjuriesData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getInjuriesTableData();
                reset({ totalNumber: "", lostTime: "" });
                setWorkRelatedInjuries("");
                setCategory("");
                setInjureSource("")
                setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })

    };

    const handleClickClose = () => {
        reset({ totalNumber: "", lostTime: "" });
        setWorkRelatedInjuries("");
        setCategory("");
        setInjureSource("")
        setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
    };

    const selectInjurySource = (event) => {
        setInjureSource(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddWorkRelatedInjuries.workRelatedInjuriesData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getInjuriesTableData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ totalNumber: "", lostTime: "" });
                setWorkRelatedInjuries("");
                setCategory("");
                setInjureSource("")
                setOpenAddWorkRelatedInjuries({ action: false, workRelatedInjuriesData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWorkRelatedInjuries.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddWorkRelatedInjuries.workRelatedInjuriesData ? "Edit Work Related Injuries" : "Add Work Related Injuries"}</material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <div className='my-3'>
                            {openAddWorkRelatedInjuries.workRelatedInjuriesData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                            <div className='row'>
                                <div className='col-4'>
                                    <material.FormControl variant="standard" required fullWidth>
                                        <material.InputLabel id="demo-simple-select-label">Safety Incident</material.InputLabel>
                                        <material.Select
                                            {...register("safetyIncident", { required: true })}
                                            labelId="demo-simple-select-label"
                                            variant='standard'
                                            id="safetyIncident"
                                            value={workRelatedInjuries}
                                            label="Category of worker"
                                            onChange={selectWorkRelatedInjuries}
                                        >
                                            <material.MenuItem value={"Fatalities as a Result of Work Related Injury"}>Fatalities as a Result of Work Related Injury</material.MenuItem>
                                            <material.MenuItem value={"High Consequence Work Related Injuries (Excluding Fatalities)"}>High Consequence Work Related Injuries (Excluding Fatalities)</material.MenuItem>
                                            <material.MenuItem value={"Recordable Work Related Injury"}>Recordable Work Related Injury</material.MenuItem>
                                            <material.MenuItem value={"Near Misses"}>Near Misses</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4'>
                                    <material.FormControl variant="standard" required fullWidth>
                                        <material.InputLabel id="demo-simple-select-label">Injury Source</material.InputLabel>
                                        <material.Select
                                            {...register("injurySource", { required: true })}
                                            labelId="demo-simple-select-label"
                                            variant='standard'
                                            id="injurySource"
                                            value={injureSource}
                                            label="Category of worker"
                                            onChange={selectInjurySource}
                                        >
                                            <material.MenuItem value={"Slip / Trip / Fall"}>Slip / Trip / Fall</material.MenuItem>
                                            <material.MenuItem value={"Struck on"}>Struck on</material.MenuItem>
                                            <material.MenuItem value={"Material Handling"}>Material Handling</material.MenuItem>
                                            <material.MenuItem value={"Machine Safety"}>Machine Safety</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4'>
                                    <material.Box>
                                        <material.FormControl variant="standard" required fullWidth>
                                            <material.InputLabel id="demo-simple-select-label">Category</material.InputLabel>
                                            <material.Select
                                                {...register("workerCategory", { required: true })}
                                                labelId="demo-simple-select-label"
                                                variant='standard'
                                                id="workerCategory"
                                                value={category}
                                                label="Category"
                                                onChange={selectCategory}
                                            >
                                                <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                                <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                                <material.MenuItem value={"Workers Permanent"}>Workers Permanent</material.MenuItem>
                                                <material.MenuItem value={"Workers other than Permanent"}>Workers other than Permanent</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </material.Box>
                                </div>
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("totalNumber", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Number"
                                        id="totalNumber"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.totalNumber && errors.totalNumber.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-4'>
                                    <material.TextField
                                        {...register("lostTime", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                        required
                                        margin="dense"
                                        label="Lost Time (hr)"
                                        id="lostTime"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.lostTime && errors.lostTime.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                            </div>
                        </material.Card>
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions className="m-3">
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWorkRelatedInjuries.workRelatedInjuriesData ? (
                        <>
                            <material.Button color="success" variant="outlined" onClick={handleSubmit(updateWorkRelatedInjuries)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWorkRelatedInjuries.workRelatedInjuriesData.rejectionNote === null) || (openAddWorkRelatedInjuries.workRelatedInjuriesData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button color="success" variant="outlined" onClick={handleSubmit(saveWorkRelatedInjuries)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWorkRelatedInjuries;
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const saveOrUpdateInjuriesData = async (injuriesData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/worker-related-injury/save-or-update`, injuriesData)
};

export const getInjuriesData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/worker-related-injury/get-all`, { params: { corporateRefNo: payloadData.corporateRefNo, facilityRefNo: payloadData.facilityRefNo, forApproval: payloadData.forApproval, approveHistory: payloadData.approveHistory, fromDate: payloadData.fromDate, toDate: payloadData.toDate } })
};

export const sendForApprovalInjuriesRecord = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/worker-related-injury/send-for-approval`, recordIds);
};

export const approveInjuriesRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/worker-related-injury/approve`, recordId)
};
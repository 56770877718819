import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";


export const getScoreData = async (corporateRefNo, facilityRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/score/get-score`, { params: { corporateRefNo: corporateRefNo, facilityRefNo: facilityRefNo } })
};

export const updateScore = async (scoreData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/score/update`, scoreData)
};

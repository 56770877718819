import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getParentalLeaveData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/parental-leave/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateParentalLeaveData = async (ParentalLeaveData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/parental-leave/save-or-update`, ParentalLeaveData)
};

export const sendForApprovalParentalLeaveData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/parental-leave/send-for-approval`, recordIds)
};

export const approveParentalLeaveData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/parental-leave/approve`, recordIds)
};








import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";

export const getSitesByCorporateRefNo = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/facility/get-facility-by-corporate-ref-no`, { params: { corporateRefNo: corporateRefNo } })
};

export const addSiteByCorporateRefNo = async (corporateRefNo, siteData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/facility/add-facility`, siteData, { params: { corporateRefNo: corporateRefNo } })
};

export const getSiteByRefNo = async (facilityRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/facility/get-facility`, { params: { facilityRefNo: facilityRefNo } })
};

export const getNicList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/nic/get-nic-list`)
};

export const addGeographicalLocationData = async (geographicalLocationData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/facility/save-or-update-geographical-location-with-facility`, geographicalLocationData)
};

export const getDistinctNicListForFacility = async (facilityRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/facility/get-distinct-nic-list-for-facility`, { params: { facilityRefNo } })
};


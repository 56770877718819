import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateViolationAndExceedanceData } from '../../services/sub-issue-services/violation-exceedance-service/ViolationExceedanceService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';


function AddViolationExceedance(props) {
    const { addOpenViolationExceedance, setAddOpenViolationExceedance, getAllViolationExceedanceData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [violationAndExceedanceAspect, setViolationAndExceedanceAspect] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const onChangeNoOfDisputes = watch('noOfDisputes');
    // const onChangeDisputePendingCases = watch('disputePendingCases');
    const onChangeDisputeResolutionCase = watch('disputeResolutionCase');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNoOfDisputes, onChangeDisputeResolutionCase]);

    const totalCalculation = () => {
        let noOfDisputesValue = getValues('noOfDisputes');
        let disputeResolutionCaseValue = getValues('disputeResolutionCase');

        if (Number(disputeResolutionCaseValue) > Number(noOfDisputesValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Number of disputes resolved value not greater than number of disputes value",
            })
            setButtonDisable(true)
        } else {
            let val = Number(noOfDisputesValue) - Number(disputeResolutionCaseValue)
            setValue('disputePendingCases', val)
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenViolationExceedance.action && addOpenViolationExceedance.violationExceedanceTableData) {
            setViolationAndExceedanceAspect(addOpenViolationExceedance.violationExceedanceTableData.violationAndExceedanceAspect);
            reset(addOpenViolationExceedance.violationExceedanceTableData);
        }
    }, [addOpenViolationExceedance.action === true]);

    const saveViolationExceedance = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "violationAndExceedanceAspect": data.violationAndExceedanceAspect,
                "noOfDisputes": Number(data.noOfDisputes),
                "describeTheViolationAndExceedanceIssues": data.describeTheViolationAndExceedanceIssues,
                "disputePendingCases": Number(data.disputePendingCases),
                "disputeResolutionCase": Number(data.disputeResolutionCase),
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
                otherSpecify: data.otherSpecify
            }
            await saveOrUpdateViolationAndExceedanceData(object)
                .then((resp) => {
                    getAllViolationExceedanceData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ noOfDisputes: "", describeTheViolationAndExceedanceIssues: "", disputePendingCases: "", disputeResolutionCase: "", otherSpecify: "" });
                    setViolationAndExceedanceAspect("");
                    setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenViolationExceedance.violationExceedanceTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllViolationExceedanceData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ noOfDisputes: "", describeTheViolationAndExceedanceIssues: "", disputePendingCases: "", disputeResolutionCase: "", otherSpecify: "" });
                setViolationAndExceedanceAspect("");
                setAddOpenViolationExceedance({ action: false, materialData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const updateViolationExceedance = async (data) => {
        let object = {
            "refNo": addOpenViolationExceedance.violationExceedanceTableData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "violationAndExceedanceAspect": data.violationAndExceedanceAspect,
            "noOfDisputes": Number(data.noOfDisputes),
            "describeTheViolationAndExceedanceIssues": data.describeTheViolationAndExceedanceIssues,
            "disputePendingCases": Number(data.disputePendingCases),
            "disputeResolutionCase": Number(data.disputeResolutionCase),
            "otherSpecify": violationAndExceedanceAspect === "Other (Specify)" ? data.otherSpecify : "",
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateViolationAndExceedanceData(object)
            .then((resp) => {
                getAllViolationExceedanceData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ noOfDisputes: "", describeTheViolationAndExceedanceIssues: "", disputePendingCases: "", disputeResolutionCase: "", otherSpecify: "", });
                setViolationAndExceedanceAspect("");
                setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ noOfDisputes: "", describeTheViolationAndExceedanceIssues: "", disputePendingCases: "", disputeResolutionCase: "", otherSpecify: "", });
        setViolationAndExceedanceAspect("");
        setAddOpenViolationExceedance({ action: false, violationExceedanceTableData: null });
    };

    const handleViolationExceedanceAspectChange = (event) => {
        setViolationAndExceedanceAspect(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={addOpenViolationExceedance.action} maxWidth="lg" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenViolationExceedance.violationExceedanceTableData ? "Edit Violation & Exceedance" : "Add Violation & Exceedance"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenViolationExceedance.violationExceedanceTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Violation & Exceedance</material.Typography>
                            </div>

                            <div className='col-4'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Violation & Exceedance Aspect</material.InputLabel>
                                    <material.Select
                                        {...register("violationAndExceedanceAspect", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={violationAndExceedanceAspect}
                                        onChange={handleViolationExceedanceAspectChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Regulatory Clearance & Permits"}>Regulatory Clearance & Permits</material.MenuItem>
                                        <material.MenuItem value={"Permit Conditions"}>Permit Conditions</material.MenuItem>
                                        <material.MenuItem value={"Court Cases"}>Court Cases</material.MenuItem>
                                        <material.MenuItem value={"Stakeholder Complaints"}>Stakeholder Complaints</material.MenuItem>
                                        <material.MenuItem value="Other (Specify)">Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <>
                                {violationAndExceedanceAspect === "Other (Specify)" ? (
                                    <div className='col-4'>
                                        <material.TextField
                                            {...register("otherSpecify", { required: true, maxLength: 20 })}
                                            required
                                            margin="dense"
                                            label="Other (Specify)"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors?.otherSpecify?.type === "maxLength" && (
                                            <p className='text-danger'>Maximum length 20 character</p>
                                        )}
                                    </div>
                                ) : null}
                            </>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("noOfDisputes", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Disputes"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfDisputes && errors.noOfDisputes.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("describeTheViolationAndExceedanceIssues", { required: true, minLength: 20, maxLength: 100 })}
                                    required
                                    margin="dense"
                                    label="Describe Violation & Exceedance Issues"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    multiline
                                />
                                {errors.describeTheViolationAndExceedanceIssues && (errors.describeTheViolationAndExceedanceIssues.type === "minLength" || errors.describeTheViolationAndExceedanceIssues.type === "maxLength") && (
                                    <p className="errorMsg text-danger">Describe The Violation & Exceedance Issues should be between 20 to 100 character.</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("disputeResolutionCase", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Disputes Resolved"
                                    id="userName"
                                    defaultValue={0}
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.disputeResolutionCase && errors.disputeResolutionCase.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4'>
                                <material.TextField
                                    {...register("disputePendingCases", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Dispute Pending Cases"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    defaultValue={0}
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                {errors.disputePendingCases && errors.disputePendingCases.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenViolationExceedance.violationExceedanceTableData ? (
                        <>
                            <material.Button color='success' variant="outlined" onClick={handleSubmit(updateViolationExceedance)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenViolationExceedance.violationExceedanceTableData.rejectionNote === null) || (addOpenViolationExceedance.violationExceedanceTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" disabled={buttonDisable} onClick={handleSubmit(saveViolationExceedance)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddViolationExceedance;
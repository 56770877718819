import { rootApiUrl } from "../../../environment/Environment";
import { axiosInstance } from "../../../shared/AxiosConfig";

export const saveOrUpdateBiodiversityImpactsData = async (BiodiversityImpactsData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/biodiversity-service-impacted/save-or-update`, BiodiversityImpactsData)
};

export const getBiodiversityImpactsData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/biodiversity-service-impacted/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalBiodiversityImpacts = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/biodiversity-service-impacted/send-for-approval`, recordRefNo)
};

export const approveBiodiversityImpacts = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/biodiversity-service-impacted/approve`, payload)
};
import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateWorkRelatedIllHealthData } from '../../services/sub-issue-services/WorkRelatedIllHealthService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddWorkRelatedIllHealth(props) {
  const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const { openAddWorkRelatedIllHealth, setOpenAddWorkRelatedIllHealth, getWorkRelatedIllHealthData, treeViewData } = props;
  const [workerRelatedIllHealth, setWorkerRelatedIllHealth] = useState("");
  const [category, setCategory] = useState("");
  const [source, setSource] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

  const selectWorkRelatedIllHealth = (event) => {
    setWorkerRelatedIllHealth(event.target.value)
  };

  const selectCategory = (event) => {
    setCategory(event.target.value)
  };

  useEffect(() => {
    if (openAddWorkRelatedIllHealth.action && openAddWorkRelatedIllHealth.workRelatedIllHealthData) {
      reset(openAddWorkRelatedIllHealth.workRelatedIllHealthData)
      setWorkerRelatedIllHealth(openAddWorkRelatedIllHealth.workRelatedIllHealthData ? openAddWorkRelatedIllHealth.workRelatedIllHealthData.safetyIncident : "")
      setCategory(openAddWorkRelatedIllHealth.workRelatedIllHealthData ? openAddWorkRelatedIllHealth.workRelatedIllHealthData.workerCategory : "");
      setSource(openAddWorkRelatedIllHealth.workRelatedIllHealthData.illHealthSource);
    }
  }, [openAddWorkRelatedIllHealth.action === true]);

  const saveWorkRelatedIllHealth = async (data) => {
    let payLoad = generateFromDateAndToDate(data);
    let newDate = moment().format("YYYY-MM-DD");
    if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
      let obj = {
        "refNo": "",
        "corporateRefNo": userDetails.corporateRefNo,
        "facilityRefNo": userDetails.facilityRefNo,
        "applicable": true,
        safetyIncident: data.safetyIncident,
        illHealthSource: data.illHealthSource,
        "workerCategory": data.workerCategory,
        "totalNumber": parseInt(data.totalNumber),
        "lostTime": data.lostTime,
        toDate: payLoad?.toDate,
        fromDate: payLoad?.fromDate,
      }
      await saveOrUpdateWorkRelatedIllHealthData(obj)
        .then((resp) => {
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
          getWorkRelatedIllHealthData()
          reset({ totalNumber: "", lostTime: "" });
          setWorkerRelatedIllHealth("");
          setCategory("")
          setSource("")
          setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    } else {
      setOpenSnackBar({
        "action": true,
        "type": "warning",
        "message": "You can't add future records",
      })
    };
  };

  const updateWorkRelatedIllHealth = async (data) => {
    let obj = {
      "refNo": openAddWorkRelatedIllHealth.workRelatedIllHealthData.refNo,
      "corporateRefNo": userDetails.corporateRefNo,
      "facilityRefNo": userDetails.facilityRefNo,
      "applicable": true,
      safetyIncident: data.safetyIncident,
      illHealthSource: data.illHealthSource,
      "workerCategory": data.workerCategory,
      "totalNumber": parseInt(data.totalNumber),
      "lostTime": data.lostTime,
      toDate: data.toDate,
      fromDate: data.fromDate,
    }
    await saveOrUpdateWorkRelatedIllHealthData(obj)
      .then((resp) => {
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        getWorkRelatedIllHealthData()
        reset({ totalNumber: "", lostTime: "" });
        setWorkerRelatedIllHealth("");
        setCategory("")
        setSource("")
        setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        };
      })
  };

  const handleClickClose = () => {
    setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null })
    reset({ totalNumber: "", lostTime: "" });
    setWorkerRelatedIllHealth("");
    setCategory("");
    setSource("")
  };

  const selectSource = (event) => {
    setSource(event.target.value)
  };

  const deleteConfirmation = () => {
    setOpenDeleteRecordConfirmationDialog(true);
  };

  const deleteRecordByRefNo = async () => {
    let payload = {
      refNo: openAddWorkRelatedIllHealth.workRelatedIllHealthData.refNo,
      subissueName: treeViewData.subIssue,
    }
    await deleteRecord(payload)
      .then((resp) => {
        getWorkRelatedIllHealthData()
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
        setOpenDeleteRecordConfirmationDialog(false);
        setOpenAddWorkRelatedIllHealth({ action: false, workRelatedIllHealthData: null })
        reset({ totalNumber: "", lostTime: "" });
        setWorkerRelatedIllHealth("");
        setCategory("");
        setSource("");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        }
      })
  };

  return (
    <div>
      <div>
        <material.Dialog open={openAddWorkRelatedIllHealth.action} maxWidth="md" fullWidth >
          <material.DialogTitle className='fw-bold headingText'>{openAddWorkRelatedIllHealth.workRelatedIllHealthData ? "Edit Work Related Ill Health" : "Add Work Related Ill Health"}
          </material.DialogTitle>
          <material.DialogContent>
            <material.DialogContentText id="alert-dialog-description">
              <div className='my-3'>
                {openAddWorkRelatedIllHealth.workRelatedIllHealthData ? null : (
                  <DatePeriod
                    register={register}
                    control={control}
                  />
                )}
              </div>
              <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                <div className='row'>
                  <div className='col-4'>
                    {/* <div className='mt-3'> */}
                    <material.Box>
                      <material.FormControl variant='standard' required fullWidth>
                        <material.InputLabel id="demo-simple-select-label">Ill Health Related Incidents</material.InputLabel>
                        <material.Select
                          {...register("safetyIncident", { required: true })}
                          labelId="demo-simple-select-label"
                          id="workRelatedIllHealth"
                          value={workerRelatedIllHealth}
                          onChange={selectWorkRelatedIllHealth}
                        >
                          <material.MenuItem value={"Fatalities as a Result of Work Related Ill Health"}>Fatalities as a Result of Work Related Ill Health</material.MenuItem>
                          <material.MenuItem value={"High Consequence Work Related Ill Health"}>High Consequence Work Related Ill Health</material.MenuItem>
                          <material.MenuItem value={"Recordable Work Related Ill Health"}>Recordable Work Related Ill Health</material.MenuItem>
                        </material.Select>
                      </material.FormControl>
                    </material.Box>
                  </div>
                  <div className='col-4'>
                    <material.FormControl variant="standard" required fullWidth>
                      <material.InputLabel id="demo-simple-select-label">Ill Health Source</material.InputLabel>
                      <material.Select
                        {...register("illHealthSource", { required: true })}
                        labelId="demo-simple-select-label"
                        variant='standard'
                        id="illHealthSource"
                        value={source}
                        label="Category of worker"
                        onChange={selectSource}
                      >
                        <material.MenuItem value={"Musculoskeletal Disorders"}>Musculoskeletal Disorders</material.MenuItem>
                        <material.MenuItem value={"Skin & Respiratory"}>Skin & Respiratory</material.MenuItem>
                        <material.MenuItem value={"Cancer"}>Cancer</material.MenuItem>
                        <material.MenuItem value={"Mental Illnesses"}>Mental Illnesses</material.MenuItem>
                        <material.MenuItem value={"Hearing Loss"}>Hearing Loss</material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className='col-4'>
                    <material.FormControl variant="standard" required fullWidth>
                      <material.InputLabel id="demo-simple-select-label">Category</material.InputLabel>
                      <material.Select
                        {...register("workerCategory", { required: true })}
                        labelId="demo-simple-select-label"
                        variant='standard'
                        id="workerCategory"
                        value={category}
                        label="Category"
                        onChange={selectCategory}
                      >
                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className='col-4'>
                    <material.TextField
                      {...register("totalNumber", { required: true, pattern: /^[0-9]+$/ })}
                      required
                      margin="dense"
                      label="Total Number"
                      id="totalNumber"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.totalNumber && errors.totalNumber.type === "pattern" && (
                      <p className="errorMsg text-danger">No decimal allowed</p>
                    )}
                  </div>
                  <div className='col-4'>
                    <material.TextField
                      {...register("lostTime", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                      required
                      margin="dense"
                      label="Lost Time (hr)"
                      id="lostTime"
                      variant="standard"
                      type="number"
                      size="small"
                      fullWidth
                    />
                    {errors.lostTime && errors.lostTime.type === "pattern" && (
                      <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                    )}
                  </div>
                </div>
              </material.Card>
            </material.DialogContentText>
          </material.DialogContent>
          <material.DialogActions className="m-3">
            <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
            {openAddWorkRelatedIllHealth.workRelatedIllHealthData ? (
              <>
                <material.Button color="success" variant="outlined" onClick={handleSubmit(updateWorkRelatedIllHealth)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                {(openAddWorkRelatedIllHealth.workRelatedIllHealthData.rejectionNote === null) || (openAddWorkRelatedIllHealth.workRelatedIllHealthData.rejectionNote === "") ? (
                  <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                ) : null}
              </>
            ) : (
              <material.Button color="success" variant="outlined" onClick={handleSubmit(saveWorkRelatedIllHealth)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
            )}
          </material.DialogActions>
        </material.Dialog>
        <Snackbar
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      </div>
      <DeleteRecordConfirmationDialog
        openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
        setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
        treeViewData={treeViewData}
        deleteRecordByRefNo={deleteRecordByRefNo}
      />
    </div>
  )
}

export default AddWorkRelatedIllHealth;
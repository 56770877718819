import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";

export const getWasteDisposalData = async (wasteDisposalData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/waste-disposal/get-all`, { params: { corporateRefNo: wasteDisposalData.corporateRefNo, facilityRefNo: wasteDisposalData.facilityRefNo, forApproval: wasteDisposalData.forApproval, approveHistory: wasteDisposalData.approveHistory,fromDate: wasteDisposalData.fromDate, toDate: wasteDisposalData.toDate  } })
};

export const saveOrUpdateWasteDisposalData = async (saveWasteDisposalData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/waste-disposal/save-or-update`, saveWasteDisposalData)
};

export const sendForApprovalWasteDisposalDataRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-disposal/send-for-approval`, RecordIds);
};

export const approveWasteDisposalDataRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-disposal/approve`, recordId)
};




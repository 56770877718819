import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";

export const getAllWorkRelatedIllHealthData = async (workRelatedIllHealthData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/worker-related-ill-health/get-all`, { params: { corporateRefNo: workRelatedIllHealthData.corporateRefNo, facilityRefNo: workRelatedIllHealthData.facilityRefNo, forApproval: workRelatedIllHealthData.forApproval, approveHistory: workRelatedIllHealthData.approveHistory, fromDate: workRelatedIllHealthData.fromDate, toDate: workRelatedIllHealthData.toDate } })
};

export const saveOrUpdateWorkRelatedIllHealthData = async (workRelatedIllHealthData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/worker-related-ill-health/save-or-update`, workRelatedIllHealthData)
};


export const sendForApprovalWorkRelatedIllHealthRecord = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/worker-related-ill-health/send-for-approval`, recordIds,)
};

export const approveWorkRelatedIllHealthRecord = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/worker-related-ill-health/approve`, recordIds)
};
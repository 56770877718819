import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateEnergyEfficiencyProductsServiceData } from '../../services/sub-issue-services/energy/EnergyEfficiencyProductsServicesService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import DatePeriod from '../../shared/DatePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddEnergyEfficiencyProductService(props) {
    const { openEnergyEfficiencyProductsServices, setOpenEnergyEfficiencyProductsServices, getAllEnergyEfficiencyProductsServiceData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);

    const { register, handleSubmit, control, reset, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [typesOfEnergyIncluded, setTypesOfEnergyIncluded] = useState("");
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openEnergyEfficiencyProductsServices.action && openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData) {
            setTypesOfEnergyIncluded(openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.typesOfEnergyIncluded)
            setUnit(openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.unit);
            reset(openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData);
        }
    }, [openEnergyEfficiencyProductsServices.action === true]);

    const saveEnergyEfficiencyProductsServices = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                refNo: "",
                "reductionInitiatives": data.reductionInitiatives,
                "typesOfEnergyIncluded": data.typesOfEnergyIncluded,
                "amount": Number(data.amount),
                "unit": data.unit,
                otherSpecify: typesOfEnergyIncluded === "Other (Specify)" ? data.otherSpecify : "",
                "achievedOfConservationEfficiencyInitiatives": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateEnergyEfficiencyProductsServiceData(obj)
                .then((resp) => {
                    getAllEnergyEfficiencyProductsServiceData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ amount: "" });
                    setTypesOfEnergyIncluded("");
                    setUnit("");
                    setOpenEnergyEfficiencyProductsServices({ action: false, energyEfficiencyProductsServicesTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEnergyEfficiencyProductsServices = async (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            refNo: openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.refNo,
            "reductionInitiatives": data.reductionInitiatives,
            "typesOfEnergyIncluded": data.typesOfEnergyIncluded,
            "amount": Number(data.amount),
            "unit": data.unit,
            otherSpecify: typesOfEnergyIncluded === "Other (Specify)" ? data.otherSpecify : "",
            "achievedOfConservationEfficiencyInitiatives": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateEnergyEfficiencyProductsServiceData(obj)
            .then((resp) => {
                getAllEnergyEfficiencyProductsServiceData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ amount: "" });
                setTypesOfEnergyIncluded("");
                setUnit("");
                setOpenEnergyEfficiencyProductsServices({ action: false, energyEfficiencyProductsServicesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ amount: "" });
        setTypesOfEnergyIncluded("");
        setUnit("");
        setOpenEnergyEfficiencyProductsServices({ action: false, energyEfficiencyProductsServicesTableData: null });
    };
    const changeTypesOfEnergyIncluded = (event) => {
        setTypesOfEnergyIncluded(event.target.value);
    };
    const changeUnit = (event) => {
        setUnit(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllEnergyEfficiencyProductsServiceData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setTypesOfEnergyIncluded("");
                setUnit("");
                setOpenEnergyEfficiencyProductsServices({ action: false, energyEfficiencyProductsServicesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openEnergyEfficiencyProductsServices.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData ? "Edit Energy Efficiency Products Services" : "Add Energy Efficiency Products Services"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Energy Efficiency Products Service Data</material.Typography>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Types of Energy Included</material.InputLabel>
                                    <material.Select
                                        {...register("typesOfEnergyIncluded", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typesOfEnergyIncluded}
                                        onChange={changeTypesOfEnergyIncluded}
                                        label="Types of Energy Included"
                                    >
                                        <material.MenuItem value={"Transport of Sold Products & Services"}>Transport of Sold Products & Services</material.MenuItem>
                                        <material.MenuItem value={"Energy For Operation of Computer"}>Energy for Operation of Computer</material.MenuItem>
                                        <material.MenuItem value={"Improvement in Energy Transmission"}>Improvement in Energy Transmission</material.MenuItem>
                                        <material.MenuItem value={"Other (Specify)"}>Other (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>

                            {typesOfEnergyIncluded === "Other (Specify)" ? (
                                <div className='col-md-4 py-2'>
                                    <material.TextField
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Specify Energy Included"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}

                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Amount"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={changeUnit}
                                        label="Unit"
                                    >
                                        <material.MenuItem value={"J"}>J</material.MenuItem>
                                        <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        <material.MenuItem value={"TJ"}>TJ</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateEnergyEfficiencyProductsServices)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.rejectionNote === null) || (openEnergyEfficiencyProductsServices.energyEfficiencyProductsServicesTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveEnergyEfficiencyProductsServices)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEnergyEfficiencyProductService
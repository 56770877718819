import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { getCpcbList } from '../../services/materiality-services/MaterialityService';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { addSiteByCorporateRefNo } from '../../services/SiteService';


function AddCpcb(props) {

    const { openCpcb, setOpenCpcb, getSiteBySiteRefNo } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedIndustryType, setSelectedIndustryType] = useState(null);
    const [filteredIndustryTypes, setFilteredIndustryTypes] = useState([]);

    const [cpcbData, setCpcbData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openCpcb.action && openCpcb.editData === null) {
            getAllCpcbList();
        } else if (openCpcb.action && openCpcb.editData) {
            getAllCpcbList();
            setSelectedCategory(openCpcb.editData.category)
            setSelectedIndustryType(openCpcb.editData.industryType)
        };
    }, [openCpcb.action === true]);

    const getAllCpcbList = () => {
        getCpcbList()
            .then((resp) => {
                setCpcbData(resp.data);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const handleCategoryChange = (event, value) => {
        setSelectedCategory(value);
        setFilteredIndustryTypes(cpcbData.filter(item => item.category === value).map(item => item.industryType));
        setSelectedIndustryType(null);
    };

    const handleIndustryTypeChange = (event, value) => {
        setSelectedIndustryType(value);
    };

    const handleClose = () => {
        setOpenCpcb({ action: false, editData: null })
        setSelectedCategory(null);
        setSelectedIndustryType(null);
        setFilteredIndustryTypes([]);
    };

    const SaveCpcb = () => {
        if (selectedCategory && selectedIndustryType) {
            const payload = [{
                assetName: userDetails.facilityName,
                refNo: userDetails.facilityRefNo,
                cpcbDtos: [{ category: selectedCategory, industryType: selectedIndustryType }]
            }]
            addSiteByCorporateRefNo(userDetails.corporateRefNo, payload)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Saved",
                    })
                    getSiteBySiteRefNo();
                    setOpenCpcb({ action: false, editData: null })
                    setSelectedCategory(null);
                    setSelectedIndustryType(null);
                    setFilteredIndustryTypes([]);
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    return (
        <div>
            <material.Dialog open={openCpcb.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openCpcb.editData ? "Edit CPCB" : "Add CPCB"}</material.DialogTitle>
                <material.DialogContent>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-6 mt-2'>
                                <material.Autocomplete
                                    options={[...new Set(cpcbData.map(item => item.category))]}
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    renderInput={(params) => <material.TextField variant="standard" {...params} label="Category" />}
                                />
                            </div>
                            <div className='col-6 mt-2'>
                                {/* {filteredIndustryTypes.length > 0 && ( */}
                                    <material.Autocomplete
                                        options={filteredIndustryTypes}
                                        value={selectedIndustryType}
                                        onChange={handleIndustryTypeChange}
                                        renderInput={(params) => <material.TextField variant="standard" {...params} label="Industry Type" />}
                                    />
                                {/* )} */}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(SaveCpcb)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddCpcb
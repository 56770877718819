import React from 'react';
import { Controller } from 'react-hook-form';
import { dataPeriod, quarterData, halfYearPeriod, months } from './TimePeriod';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { material } from '../library/Material';

function DatePeriod(props) {

    const { register, control } = props;
    const [periodType, setPeriodType] = React.useState('');
    const [selectedMonth, setSelectedMonth] = React.useState('');
    const [quarterType, setQuarterType] = React.useState('');
    const [halfYearType, setHalfYearType] = React.useState('');
    const [selectedYear, setSelectedYear] = React.useState('');

    const onchangePeriodType = (event) => {
        setPeriodType(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    };

    const handleQuarterChange = (event) => {
        setQuarterType(event.target.value);
    };

    const onchangeHalfYearlyType = (event) => {
        setHalfYearType(event.target.value);
    };

    const changeLabel = () => {
        if (periodType === 'Monthly') {
            return 'Month';
        } else if (periodType === 'Quarterly') {
            return 'Quarter';
        } else if (periodType === 'Half Yearly') {
            return 'One half';
        }
    };
    const changeSourceArray = () => {
        if (periodType === 'Monthly') {
            return months;
        } else if (periodType === 'Quarterly') {
            return quarterData;
        } else if (periodType === 'Half Yearly') {
            return halfYearPeriod;
        } else {
            return [];
        }
    };

    const changeSelectionChangeFunction = (event) => {
        if (periodType === 'Monthly') {
            handleMonthChange(event);
        } else if (periodType === 'Quarterly') {
            handleQuarterChange(event);
        } else if (periodType === 'Half Yearly') {
            onchangeHalfYearlyType(event);
        }
    };
    const changeValueState = () => {
        if (periodType === 'Monthly') {
            return selectedMonth;
        } else if (periodType === 'Quarterly') {
            return quarterType;
        } else if (periodType === 'Half Yearly') {
            return halfYearType;
        }
    };

    return (
        <div>
            <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                <div className='row'>
                    <div className='py-1'>
                        {/* <material.Typography className='fw-bold'>Date Period</material.Typography> */}
                    </div>
                    <div className='col-lg-4'>
                        <material.FormControl variant="standard" fullWidth >
                            <material.InputLabel id="demo-simple-select-standard-label" required>Data Period</material.InputLabel>
                            <material.Select
                                {...register('periodType', { required: true, })}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={periodType}
                                onChange={onchangePeriodType}
                                label="Data Period"
                            >
                                {
                                    dataPeriod.map((period, pInd) => (
                                        <material.MenuItem value={period} key={pInd}>
                                            {period}
                                        </material.MenuItem>
                                    )
                                    )
                                }
                            </material.Select>
                        </material.FormControl>
                    </div>
                    {(periodType == '') || (periodType == 'Yearly') ? null : (
                        <div className='col-lg-4'>
                            <material.FormControl variant="standard" fullWidth >
                                <material.InputLabel id="demo-simple-select-standard-label" required>{changeLabel()}</material.InputLabel>
                                <material.Select
                                    {...register(periodType === 'Monthly' ? 'selectedMonth' : periodType === 'Quarterly' ? 'selectedQuarter' : periodType === 'Half Yearly' ? 'halfYearType' : null, { required: true, })}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="Month"
                                    value={changeValueState()}
                                    onChange={changeSelectionChangeFunction}
                                >
                                    {
                                        changeSourceArray().map((item, ind) => (
                                            <material.MenuItem value={item.value} key={ind}>
                                                {item.name}
                                            </material.MenuItem>
                                        )
                                        )
                                    }
                                </material.Select>
                            </material.FormControl>
                        </div>
                    )}
                    {periodType !== '' ? (
                        <div className='col-lg-4'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="selectedYear"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            openTo="year"
                                            views={['year']}
                                            label="Year*"
                                            format='YYYY'
                                            defaultValue={selectedYear}
                                            onChange={(newValue) => {
                                                setSelectedYear(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                    {...field}
                                                />
                                            )}
                                            slotProps={{ textField: { variant: 'standard', color: 'success' } }}
                                            sx={{ width: '100%' }}
                                            disableFuture
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    ) : null}
                </div>
            </material.Card>
        </div >
    );
}

export default DatePeriod;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddGrievancesRedressalSystem from '../../../sub-issues-dialogs/grievances-redressal-dialog/AddGrievancesRedressalSystem';
import { approveGrievanceRedressalRecords, getAllGrievanceRedressalData, sendForApprovalGrievanceRedressalRecords } from '../../../services/sub-issue-services/GrievanceService';
import moment from 'moment';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
var usersRole;
var recordRefNo = [];

function GrievancesRedressalSystem(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const [grievancesRedressalSystemTabValue, setGrievancesRedressalSystemTabValue] = useState('Grievances Redressal System');
    const [openAddGrievancesRedressalSystem, setOpenAddGrievancesRedressalSystem] = useState({ action: false, grievancesData: null });
    const [grievancesData, SetGrievancesData] = useState([]);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);

    useEffect(() => {
        getGrievanceRedressalData()
    }, [grievancesRedressalSystemTabValue]);

    const getFormDateToDate = (data) => {
        getGrievanceRedressalData(data)
    };

    const getGrievanceRedressalData = async (filterDate) => {
        if (grievancesRedressalSystemTabValue === "Grievances Redressal System") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllGrievanceRedressalData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dtoList)
                    SetGrievancesData(filterData)
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (grievancesRedressalSystemTabValue === "Grievances Redressal System History") {
            let forApproval = true;
            let approveHistory = true;
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllGrievanceRedressalData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    SetGrievancesData(resp.data.dtoList)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const switchButtonClick = (data) => {
        setOpenWarningDialog(true)
    };
    const changeGrievancesRedressalSystemTab = (event, newValue) => {
        setGrievancesRedressalSystemTabValue(newValue);
    };

    const addGrievancesRedressalSystem = () => {
        setOpenAddGrievancesRedressalSystem({ action: true, grievancesData: null });
    };

    const editGrievanceData = (rowData) => {
        setOpenAddGrievancesRedressalSystem({ action: true, grievancesData: rowData });
    };

    const handleCheckedRecords = (ids) => {
        setOpenAddGrievancesRedressalSystem({ action: false, grievancesData: null });
        if (recordRefNo.includes(ids)) {
            // recordRefNo.pop(ids)
            recordRefNo = recordRefNo.filter(item => item !== ids);
        } else {
            recordRefNo.push(ids)
        };
    };

    const sendForApproval = async () => {
        if (recordRefNo.length) {
            await sendForApprovalGrievanceRedressalRecords(recordRefNo)
                .then((resp) => {
                    getGrievanceRedressalData()
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const approveRecords = async () => {
        if (recordRefNo.length) {
            let object = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo
            }
            await approveGrievanceRedressalRecords(object)
                .then((resp) => {
                    getGrievanceRedressalData()
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const rejectRecords = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };
    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveGrievanceRedressalRecords(obj)
            .then((resp) => {
                recordRefNo = [];
                getGrievanceRedressalData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7,width:100 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6' style={{ display: 'flex', justifyContent: 'end' }} hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                            <material.Stack direction="row" spacing={1} alignItems="center">
                                <material.Typography>Not applicable</material.Typography>
                                <material.Switch {...label} checked={applicable} color="secondary" onClick={switchButtonClick} />
                                <material.Typography>Applicable</material.Typography>
                            </material.Stack>
                        </div>
                    </div>
                    <material.TabContext value={grievancesRedressalSystemTabValue}>
                        <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <material.TabList onChange={changeGrievancesRedressalSystemTab} aria-label="lab API tabs example">
                                <material.Tab className='text-capitalize' label="Grievances Redressal System" value="Grievances Redressal System" />
                                <material.Tab className='text-capitalize' label="Grievances Redressal System History" value="Grievances Redressal System History" />
                                <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                            </material.TabList>
                        </material.Box>
                        <material.TabPanel value="Grievances Redressal System">
                            <div className='row mt-2 align-items-center'>
                                <div className="col-lg-6 pb-2">
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    )}
                                </div>
                                <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ?
                                        (
                                            <>
                                                {grievancesData?.length ? (
                                                    <span className='float-end'>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                                                    </span>
                                                ) : null}
                                            </>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" disabled={!applicable} hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none", mr: 2 }} onClick={sendForApproval} startIcon={<material.SendIcon />}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none" }}
                                                    startIcon={<material.AddIcon />} disabled={!applicable} onClick={addGrievancesRedressalSystem}>Add Grievances Redressal System</material.Button>
                                            </span>
                                        )}
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <div className='row mt-2'>
                                    <div className="col-12">
                                        <material.TableContainer >
                                            <material.Table>
                                                <material.TableHead>
                                                    <material.TableRow>
                                                        <StyledTableCell>Nature</StyledTableCell>
                                                        <StyledTableCell>Category</StyledTableCell>
                                                        <StyledTableCell>Type</StyledTableCell>
                                                        <StyledTableCell>Other Specify</StyledTableCell>
                                                        <StyledTableCell>Grievance Received Total</StyledTableCell>
                                                        <StyledTableCell>Grievance Resolved </StyledTableCell>
                                                        <StyledTableCell>Grievance Pending </StyledTableCell>
                                                        <StyledTableCell>From Date</StyledTableCell>
                                                        <StyledTableCell>To Date</StyledTableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                        )}
                                                        <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <StyledTableCell>Rejection Note</StyledTableCell>
                                                        )}
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    {grievancesData?.length ? grievancesData.map((rowData, i) => (
                                                        <material.TableRow key={i}
                                                            sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                            onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ? () => editGrievanceData(rowData) : null}
                                                        >
                                                            <material.TableCell>{rowData.nature}</material.TableCell>
                                                            <material.TableCell>{rowData.category}</material.TableCell>
                                                            <material.TableCell>{rowData.type}</material.TableCell>
                                                            <material.TableCell>{(rowData.otherSpecify === "" || rowData.otherSpecify === null) ? "N/A" : rowData.otherSpecify}</material.TableCell>
                                                            <material.TableCell>{rowData.grievanceReceivedTotal}</material.TableCell>
                                                            <material.TableCell>{rowData.grievancesResolved}</material.TableCell>
                                                            <material.TableCell>{rowData.grievancesPending}</material.TableCell>
                                                            <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            )}
                                                            <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                    <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                                                ) : (
                                                                    <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                                                )}
                                                            </material.TableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                                                )}
                                                                </material.TableCell>
                                                            )}
                                                        </material.TableRow>
                                                    )) : (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={12}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )}
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </div>
                                </div>
                            </material.Paper>
                        </material.TabPanel>
                        <material.TabPanel value="Grievances Redressal System History">
                            <div className="row">
                                <div className="col-lg-8">
                                    <DateFilter
                                        getFormDateToDate={getFormDateToDate}
                                    />
                                </div>
                            </div>
                            <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                <div className='row mt-2'>
                                    <div className="col-12">
                                        <material.TableContainer >
                                            <material.Table>
                                                <material.TableHead>
                                                    <material.TableRow >
                                                        <StyledTableCell>Nature</StyledTableCell>
                                                        <StyledTableCell>Category</StyledTableCell>
                                                        <StyledTableCell>Type</StyledTableCell>
                                                        <StyledTableCell>Other Specify</StyledTableCell>
                                                        <StyledTableCell>Grievance Received Total</StyledTableCell>
                                                        <StyledTableCell>Grievance Resolved</StyledTableCell>
                                                        <StyledTableCell>Grievance Pending</StyledTableCell>
                                                        <StyledTableCell>From Date</StyledTableCell>
                                                        <StyledTableCell>To Date</StyledTableCell>
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                        <StyledTableCell>Status</StyledTableCell>
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    {grievancesData?.length ? grievancesData.map((rowData, i) => (
                                                        <material.TableRow key={i}>
                                                            <material.TableCell>{rowData.nature}</material.TableCell>
                                                            <material.TableCell>{rowData.category}</material.TableCell>
                                                            <material.TableCell>{rowData.type}</material.TableCell>
                                                            <material.TableCell>{(rowData.otherSpecify === "" || rowData.otherSpecify === null) ? "N/A" : rowData.otherSpecify}</material.TableCell>
                                                            <material.TableCell>{rowData.grievanceReceivedTotal}</material.TableCell>
                                                            <material.TableCell>{rowData.grievancesResolved}</material.TableCell>
                                                            <material.TableCell>{rowData.grievancesPending}</material.TableCell>
                                                            <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>
                                                                {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                    APPROVED
                                                                </span> : null}
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )) : (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={12}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )}
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </div>
                                </div>
                            </material.Paper>
                        </material.TabPanel>
                        <material.TabPanel value="Specific Disclosure">
                            <SpecificDisclosure
                                treeViewData={treeViewData}
                            />
                        </material.TabPanel>
                        <material.TabPanel value="Specific Disclosure History">
                            <SpecificDisclosureHistory
                                treeViewData={treeViewData}
                            />
                        </material.TabPanel>
                    </material.TabContext>
                </material.Box>
            </material.Box>
            <AddGrievancesRedressalSystem
                openAddGrievancesRedressalSystem={openAddGrievancesRedressalSystem}
                setOpenAddGrievancesRedressalSystem={setOpenAddGrievancesRedressalSystem}
                getGrievanceRedressalData={getGrievanceRedressalData}
                treeViewData={treeViewData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getGrievanceRedressalData={getGrievanceRedressalData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default GrievancesRedressalSystem;
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getAllMaterialUsedData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/materials-used/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, typeOfRecord: payload.typeOfRecord, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveMaterialUsedData = async (materialUsedData, typeOfRecord) => {
    return await axiosInstance.post(`${rootApiUrl}/api/materials-used/save-or-update`, materialUsedData, { params: { typeOfRecord: typeOfRecord } })
};

export const sendForApproval = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/materials-used/send-for-approval`, recordId)
};

export const approveRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/materials-used/approve`, recordId)
};
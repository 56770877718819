import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateWasteRecoveryData } from '../../services/sub-issue-services/WasteRecoveryService';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddWasteRecovery(props) {
    const { register, handleSubmit, reset, control, watch, formState: { errors }, } = useForm();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddWasteRecovery, setOpenAddWasteRecovery, getAllWasteRecoveryData, treeViewData } = props;
    const [selectCategoryOfWaste, setSelectCategoryOfWaste] = useState("");
    const [selectWasteRecoveryOption, setSelectWasteRecoveryOption] = useState("");
    const [selectUnit, setSelectUnit] = useState("");
    const [reuseOrRecoverPartDataChange, setReuseOrRecoverPartDataChange] = useState("No");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);


    useEffect(() => {
        if (openAddWasteRecovery.action && openAddWasteRecovery.wasteRecoveryData) {
            setSelectCategoryOfWaste(openAddWasteRecovery.wasteRecoveryData.wasteCategory);
            setSelectWasteRecoveryOption(openAddWasteRecovery.wasteRecoveryData.wasteRecoveryOption);
            setSelectUnit(openAddWasteRecovery.wasteRecoveryData.unit);
            setReuseOrRecoverPartDataChange(openAddWasteRecovery.wasteRecoveryData.doesThePlantRecoverPartOfItsWaste ? "Yes" : "No");
            reset(openAddWasteRecovery.wasteRecoveryData);
        }
    }, [openAddWasteRecovery.action === true]);

    const reuseOrRecoverPartData = (event) => {
        setReuseOrRecoverPartDataChange(event.target.value);
    };

    const saveWasteRecovery = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "wasteCategory": data.wasteCategory,
                "wasteRecoveryOption": data.wasteRecoveryOption,
                "wasteDivertedFromDisposal": data.wasteDivertedFromDisposal,
                "doesThePlantRecoverPartOfItsWaste": reuseOrRecoverPartDataChange === "Yes" ? true : false,
                "onsite": Number(data.onsite),
                "offsite": Number(data.offsite),
                "unit": data.unit,
                otherSpecify: data.otherSpecify,
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateWasteRecoveryData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteRecoveryData()
                    setOpenAddWasteRecovery({ action: false, wasteRecoveryData: null });
                    reset({ wasteDivertedFromDisposal: "", onsite: "", offsite: "", otherSpecify: "" });
                    setSelectCategoryOfWaste("")
                    setSelectWasteRecoveryOption("")
                    setSelectUnit("")
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWasteRecovery = async (data) => {
        let obj = {
            "refNo": openAddWasteRecovery.wasteRecoveryData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "wasteCategory": data.wasteCategory,
            "wasteRecoveryOption": data.wasteRecoveryOption,
            "wasteDivertedFromDisposal": data.wasteDivertedFromDisposal,
            "doesThePlantRecoverPartOfItsWaste": reuseOrRecoverPartDataChange === "Yes" ? true : false,
            "onsite": Number(data.onsite),
            "offsite": Number(data.offsite),
            "unit": data.unit,
            otherSpecify: selectCategoryOfWaste === "Other Waste (Specify)" ? data.otherSpecify : "",
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateWasteRecoveryData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWasteRecoveryData();
                setOpenAddWasteRecovery({ action: false, wasteRecoveryData: null });
                reset({ wasteDivertedFromDisposal: "", onsite: "", offsite: "", otherSpecify: "" });
                setSelectCategoryOfWaste("");
                setSelectWasteRecoveryOption("");
                setSelectUnit("");
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ wasteDivertedFromDisposal: "", onsite: "", offsite: "", });
        setSelectCategoryOfWaste("")
        setSelectWasteRecoveryOption("")
        setSelectUnit("")
        setOpenAddWasteRecovery({ action: false, wasteRecoveryData: null });
    };

    const handleSelectCategoryOfWaste = (event) => {
        setSelectCategoryOfWaste(event.target.value)
    };

    const handleSelectWasteRecoveryOption = (event) => {
        setSelectWasteRecoveryOption(event.target.value)
    };

    const handleSelectUnit = (event) => {
        setSelectUnit(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddWasteRecovery.wasteRecoveryData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllWasteRecoveryData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setOpenAddWasteRecovery({ action: false, wasteRecoveryData: null });
                reset({ wasteDivertedFromDisposal: "", onsite: "", offsite: "", otherSpecify: "" });
                setSelectCategoryOfWaste("");
                setSelectWasteRecoveryOption("");
                setSelectUnit("");
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWasteRecovery.action} maxWidth="md" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>{openAddWasteRecovery.wasteRecoveryData ? "Edit Waste Recovery" : "Add Waste Recovery"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddWasteRecovery.wasteRecoveryData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Waste Recovery</material.Typography>
                            </div>
                            <div className='col-12 d-flex flex-row align-items-center'>
                                <div className='me-2 fw-bold text-dark'>Does the plant reuse or recover part of its waste? :</div>
                                <material.FormControl>
                                    <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={reuseOrRecoverPartDataChange}
                                        onChange={reuseOrRecoverPartData}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </div>

                            <div className='col-lg-4'>
                                <material.Box>
                                    <material.FormControl required fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Category of Waste</material.InputLabel>
                                        <material.Select
                                            {...register("wasteCategory", { required: true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='pt-2'
                                            margin="dense"
                                            value={selectCategoryOfWaste}
                                            onChange={handleSelectCategoryOfWaste}
                                        >
                                            <material.MenuItem value="Construction and Demolition">Construction and Demolition</material.MenuItem>
                                            <material.MenuItem value="Municipal Solid Waste">Municipal Solid Waste</material.MenuItem>
                                            <material.MenuItem value="Packaging Waste (Other than Plastic)">Packaging Waste (Other than Plastic)</material.MenuItem>
                                            <material.MenuItem value="Plastic Waste">Plastic Waste</material.MenuItem>
                                            <material.MenuItem value="Other Non Hazardous Waste">Other Non Hazardous Waste</material.MenuItem>
                                            <material.MenuItem value="Non Oily (Chemical/ Acid/ Alkali/ Ary Batteries/ Sludge)">Non Oily (Chemical/ Acid/ Alkali/ Ary Batteries/ Sludge)</material.MenuItem>
                                            <material.MenuItem value="Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)">Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)</material.MenuItem>
                                            <material.MenuItem value="Other Hazardous Waste">Other Hazardous Waste</material.MenuItem>
                                            <material.MenuItem value="Battery Waste">Battery Waste</material.MenuItem>
                                            <material.MenuItem value="Bio Medical Waste">Bio Medical Waste</material.MenuItem>
                                            <material.MenuItem value="Radioactive Waste">Radioactive Waste</material.MenuItem>
                                            <material.MenuItem value="E waste">E Waste</material.MenuItem>
                                            <material.MenuItem value="Other Waste (Specify)">Other Waste (Specify)</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            {selectCategoryOfWaste === "Other Waste (Specify)" ? (
                                <div className='col-lg-4'>
                                    <material.TextField
                                        fullWidth
                                        required
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="Other Waste Specify"
                                        margin="dense"
                                        type="text"
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4'>
                                <material.Box>
                                    <material.FormControl required fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Waste Recovery Option</material.InputLabel>
                                        <material.Select
                                            {...register("wasteRecoveryOption", { required: true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            className='pt-2'
                                            value={selectWasteRecoveryOption}
                                            onChange={handleSelectWasteRecoveryOption}
                                        >
                                            <material.MenuItem value="Preparation for Reuse">Preparation for Reuse</material.MenuItem>
                                            <material.MenuItem value="Recycling">Recycling</material.MenuItem>
                                            <material.MenuItem value="Other Recovery Operations">Other Recovery Operations</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            {reuseOrRecoverPartDataChange === "Yes" ? (
                                <div className='col-lg-4 '>
                                    <material.TextField
                                        fullWidth
                                        className='pt-2'
                                        required
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="Waste Diverted From Disposal"
                                        margin="dense"
                                        type="number"
                                        {...register("wasteDivertedFromDisposal", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    />
                                    {errors.wasteDivertedFromDisposal && errors.wasteDivertedFromDisposal.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4'>
                                <material.TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="On Site"
                                    margin="dense"
                                    type="number"
                                    {...register("onsite", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                />
                                {errors.onsite && errors.onsite.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <material.TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Off Site"
                                    margin="dense"
                                    type="number"
                                    {...register("offsite", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                />
                                {errors.offsite && errors.offsite.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <material.Box>
                                    <material.FormControl required fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Unit</material.InputLabel>
                                        <material.Select
                                            {...register("unit", { required: true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectUnit}
                                            className='pt-2'
                                            onChange={handleSelectUnit}
                                        >
                                            <material.MenuItem value="Kg">Kg</material.MenuItem>
                                            <material.MenuItem value="MT">MT</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWasteRecovery.wasteRecoveryData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateWasteRecovery)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWasteRecovery.wasteRecoveryData.rejectionNote === null) || (openAddWasteRecovery.wasteRecoveryData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveWasteRecovery)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
             <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWasteRecovery;
import React, { useEffect, useState } from 'react';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { saveOrUpdateFreedomOfAssociationData } from '../../services/sub-issue-services/freedom-of-association-services/FreedomOfAssociationService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import DatePeriod from '../../shared/DatePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddFreedomOfAssociation(props) {

    const { openFreedomAssociation, setOpenFreedomAssociation, getFreedomOfAssociationAllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [gender, setGender] = useState("");
    const [categoryOfEmployeeAndWorker, setCategoryOfEmployeeAndWorker] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openFreedomAssociation.action && openFreedomAssociation.freedomAssociationData) {
            setGender(openFreedomAssociation.freedomAssociationData.gender);
            setCategoryOfEmployeeAndWorker(openFreedomAssociation.freedomAssociationData.categoryOfEmployeeWorker);
            reset(openFreedomAssociation.freedomAssociationData);
        }
    }, [openFreedomAssociation.action === true]);

    const handleClose = () => {
        reset({ number: "" })
        setGender("");
        setCategoryOfEmployeeAndWorker("");
        setOpenFreedomAssociation({ action: false, freedomAssociationData: null });
    };
    const selectGender = (event) => {
        setGender(event.target.value);
    };
    const selectCategoryOfEmployeeAndWorker = (event) => {
        setCategoryOfEmployeeAndWorker(event.target.value);
    };
    const saveFreedomAssociation = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "categoryOfEmployeeWorker": data.categoryOfEmployeeWorker,
                "gender": data.gender,
                "number": data.number,
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            saveOrUpdateFreedomOfAssociationData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getFreedomOfAssociationAllData();
                    reset({ number: "" });
                    setGender("");
                    setCategoryOfEmployeeAndWorker("");
                    setOpenFreedomAssociation({ action: false, freedomAssociationData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateFreedomAssociation = (data) => {
        let obj = {
            ...data,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
        }
        saveOrUpdateFreedomOfAssociationData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getFreedomOfAssociationAllData();
                reset({ number: "" });
                setGender("");
                setCategoryOfEmployeeAndWorker("");
                setOpenFreedomAssociation({ action: false, freedomAssociationData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openFreedomAssociation.freedomAssociationData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getFreedomOfAssociationAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ number: "" })
                setGender("");
                setCategoryOfEmployeeAndWorker("");
                setOpenFreedomAssociation({ action: false, freedomAssociationData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openFreedomAssociation.action} maxWidth="lg"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openFreedomAssociation.freedomAssociationData ? "Edit Freedom of Association" : "Add Freedom of Association"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openFreedomAssociation.freedomAssociationData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("categoryOfEmployeeWorker", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfEmployeeAndWorker}
                                        onChange={selectCategoryOfEmployeeAndWorker}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={selectGender}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("number", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number && errors.number.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none" }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openFreedomAssociation.freedomAssociationData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateFreedomAssociation)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openFreedomAssociation.freedomAssociationData.rejectionNote === null) || (openFreedomAssociation.freedomAssociationData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveFreedomAssociation)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddFreedomOfAssociation;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateNoticesAndFinesData } from '../../services/sub-issue-services/notices-fines-service/NoticesFinesService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';


function AddNoticesFines(props) {
    const { addOpenNoticesAndFines, setAddOpenNoticesAndFines, getAllNoticesFinesData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [nonComplianceAspect, setNonComplianceAspect] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeNoOfNonCompliance = watch('noOfNonCompliance');
    const onChangeDisputeResolutionCase = watch('disputeResolutionCase');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNoOfNonCompliance, onChangeDisputeResolutionCase]);

    const totalCalculation = () => {
        let onChangeNoOfNonComplianceValue = getValues('noOfNonCompliance');
        let disputeResolutionCaseValue = getValues('disputeResolutionCase');

        if (Number(disputeResolutionCaseValue) > Number(onChangeNoOfNonComplianceValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Dispute resolution case value not greater than total No. of non-compliance value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenNoticesAndFines.action && addOpenNoticesAndFines.noticesFinesTableData) {
            setNonComplianceAspect(addOpenNoticesAndFines.noticesFinesTableData.nonComplianceAspect);
            reset(addOpenNoticesAndFines.noticesFinesTableData);
        }
    }, [addOpenNoticesAndFines.action === true]);


    const saveNoticesFines = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "nonComplianceAspect": data.nonComplianceAspect,
                "noOfNonCompliance": Number(data.noOfNonCompliance),
                "monetaryValueFine": Number(data.monetaryValueFine),
                "nonMonetarySanctions": Number(data.nonMonetarySanctions),
                "disputeResolutionCase": Number(data.disputeResolutionCase),
                "totalMonetaryValueFine": Number(data.totalMonetaryValueFine),
                otherSpecify: nonComplianceAspect === "Others (Specify)" ? data.otherSpecify : "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateNoticesAndFinesData(object)
                .then((resp) => {
                    getAllNoticesFinesData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ noOfNonCompliance: "", monetaryValueFine: "", nonMonetarySanctions: "", disputeResolutionCase: "", totalMonetaryValueFine: "", });
                    setNonComplianceAspect("");
                    setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenNoticesAndFines.noticesFinesTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllNoticesFinesData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ noOfNonCompliance: "", monetaryValueFine: "", nonMonetarySanctions: "", disputeResolutionCase: "", totalMonetaryValueFine: "", });
                setNonComplianceAspect("");
                setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const updateNoticesFines = async (data) => {
        let object = {
            "refNo": addOpenNoticesAndFines.noticesFinesTableData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "nonComplianceAspect": data.nonComplianceAspect,
            "noOfNonCompliance": Number(data.noOfNonCompliance),
            "monetaryValueFine": Number(data.monetaryValueFine),
            "nonMonetarySanctions": Number(data.nonMonetarySanctions),
            "disputeResolutionCase": Number(data.disputeResolutionCase),
            "totalMonetaryValueFine": Number(data.totalMonetaryValueFine),
            otherSpecify: nonComplianceAspect === "Others (Specify)" ? data.otherSpecify : "",
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateNoticesAndFinesData(object)
            .then((resp) => {
                getAllNoticesFinesData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ noOfNonCompliance: "", monetaryValueFine: "", nonMonetarySanctions: "", disputeResolutionCase: "", totalMonetaryValueFine: "", });
                setNonComplianceAspect("");
                setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ noOfNonCompliance: "", monetaryValueFine: "", nonMonetarySanctions: "", disputeResolutionCase: "", totalMonetaryValueFine: "", });
        setNonComplianceAspect("");
        setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
    };

    const handleNonComplianceAspectChange = (event) => {
        setNonComplianceAspect(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={addOpenNoticesAndFines.action} maxWidth="lg" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenNoticesAndFines.noticesFinesTableData ? "Edit Notices & Fines" : "Add Notices & Fines"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenNoticesAndFines.noticesFinesTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Notices & Fines</material.Typography>
                            </div>
                            <div className='col-4 py-1'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Non Compliance Aspect</material.InputLabel>
                                    <material.Select
                                        {...register("nonComplianceAspect", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={nonComplianceAspect}
                                        onChange={handleNonComplianceAspectChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Water Withdrawal"}>Water Withdrawal</material.MenuItem>
                                        <material.MenuItem value={"Water Discharge"}>Water Discharge</material.MenuItem>
                                        <material.MenuItem value={"Air Emission"}>Air Emission</material.MenuItem>
                                        <material.MenuItem value={"Waste Disposal"}>Waste Disposal</material.MenuItem>
                                        <material.MenuItem value={"Others (Specify)"}>Others (specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {nonComplianceAspect === "Others (Specify)" ? (
                                <div className='col-4 py-1'>
                                    <material.TextField
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Non Compliance Aspect Specify"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("noOfNonCompliance", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Non Compliance"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfNonCompliance && errors.noOfNonCompliance.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("monetaryValueFine", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monetary Value Fine (INR Lakh)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monetaryValueFine && errors.monetaryValueFine.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("nonMonetarySanctions", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Non Monetary Sanctions"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.nonMonetarySanctions && errors.nonMonetarySanctions.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("disputeResolutionCase", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Dispute Resolution Case"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.disputeResolutionCase && errors.disputeResolutionCase.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            {/* <div className='col-4 py-1'>
                                <material.TextField
                                    {...register("totalMonetaryValueFine", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="Total Monetary Value Fine (INR lakh)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalMonetaryValueFine && errors.totalMonetaryValueFine.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenNoticesAndFines.noticesFinesTableData ? (
                        <>
                            <material.Button color='success' variant="outlined" onClick={handleSubmit(updateNoticesFines)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenNoticesAndFines.noticesFinesTableData.rejectionNote === null) || (addOpenNoticesAndFines.noticesFinesTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" disabled={buttonDisable} onClick={handleSubmit(saveNoticesFines)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddNoticesFines;
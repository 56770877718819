import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    data: {}
}

const loginAuthSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: true,
                data: action.payload,
            };
            return loginAuthState;
        }
    },
});

export const { loginAction } = loginAuthSlice.actions;
export default loginAuthSlice.reducer;
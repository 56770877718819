import React, { useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../specific-disclosure/SpecificDisclosureHistory';


function LifecycleAssessment(props) {

    const location = useLocation();
    const treeViewData = location?.state;
    const [specificDisclosureTabs, setSpecificDisclosureTabs] = useState('Specific Disclosure');

    const handleChangeTabs = (event, newValue) => {
        setSpecificDisclosureTabs(newValue);
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <span><material.Typography variant="h5">{treeViewData?.subIssue}</material.Typography></span>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={specificDisclosureTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
        </>
    );
}

export default LifecycleAssessment;
import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { saveCorporateProductDetails } from '../services/CorporateService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';
import { getDistinctCpcbListForCorporate, getDistinctNseListForCorporate, getDistinctSebiListForCorporate } from '../services/materiality-services/MaterialityService';

function ProductOrServicesDialog(props) {

    const { openProductOrServiceDialog, setOpenProductOrServicesDialog, getProductServiceDetails } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [sebiCategoryData, setSebiCategoryData] = useState([]);
    const [nseCategoryData, setNseCategoryData] = useState([]);
    const [cpcbCategoryData, setCpcbCategoryData] = useState([]);
    const [selectSebiCategoryData, setSelectSebiCategoryData] = useState("");
    const [selectNseCategoryData, setSelectNseCategoryData] = useState("");
    const [selectCpcbCategoryData, setSelectCpcbCategoryData] = useState("");

    useEffect(() => {
        if (openProductOrServiceDialog.action) {
            getAllProductServiceList();
            reset(openProductOrServiceDialog.data);
            setSelectSebiCategoryData(openProductOrServiceDialog.data.sebiCategory);
            setSelectNseCategoryData(openProductOrServiceDialog.data.nseCategory);
            setSelectCpcbCategoryData(openProductOrServiceDialog.data.cpcbCategory);
        };
    }, [openProductOrServiceDialog.action === true]);

    const getAllProductServiceList = () => {
        Promise.all([getDistinctNseListForCorporate(userDetails.corporateRefNo), getDistinctCpcbListForCorporate(userDetails.corporateRefNo), getDistinctSebiListForCorporate(userDetails.corporateRefNo)])
            .then((resp) => {
                const nseCategoryData = resp[0]?.data.map((ele) => ele.basicIndustry);
                const cpcbCategoryData = resp[1]?.data.map((ele) => ele.category);
                const sebiCategoryData = resp[2]?.data.map((ele) => ele.basicIndustry);
                setSebiCategoryData(sebiCategoryData);
                setNseCategoryData(nseCategoryData);
                setCpcbCategoryData(cpcbCategoryData);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    }

    // const getNseList = () => {
    //     getDistinctNseListForCorporate()
    //         .then((resp) => {

    //         })
    //         .catch((error) => {

    //         })
    // };

    // const getCpcbList = () => {
    //     getDistinctCpcbListForCorporate()
    //         .then((resp) => {

    //         })
    //         .catch((error) => {

    //         })
    // };

    // const getSebiList = () => {
    //     getDistinctSebiListForCorporate()
    //         .then((resp) => {

    //         })
    //         .catch((error) => {

    //         })
    // };

    const update = async (data) => {
        if (parseFloat(data.turnOverContribution) <= 100) {
            await saveCorporateProductDetails({ ...data, corporateRefNo: userDetails.corporateRefNo })
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Updated successfully",
                    })
                    setOpenProductOrServicesDialog({ action: false, data: null })
                    getProductServiceDetails()
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": "Total exceeding 100",
            })
        }
    };
    const close = () => {
        setOpenProductOrServicesDialog({ action: false, data: null })
    };

    const sebiCategoryChange = (event) => {
        setSelectSebiCategoryData(event.target.value);
    };

    const nseCategoryChange = (event) => {
        setSelectNseCategoryData(event.target.value);
    };

    const cpcbCategoryChange = (event) => {
        setSelectCpcbCategoryData(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={openProductOrServiceDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle className='headingText'>Edit product/services</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div>
                            <material.TextField
                                label="Project activity"
                                id="projectActivity"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                color='success'
                                InputProps={{ readOnly: true }}
                                {...register("projectActivity")}
                            />
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                label="NIC code"
                                id="nicCode"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                color='success'
                                InputProps={{ readOnly: true }}
                                {...register("nicCode")}
                            />
                        </div>

                        <div className='mt-3'>
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">SEBI Category</material.InputLabel>
                                <material.Select
                                    {...register("sebiCategory")}
                                    // required
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectSebiCategoryData}
                                    onChange={sebiCategoryChange}
                                    color='success'
                                    label="SEBI Category"
                                >
                                    {sebiCategoryData?.map((sebi, k) => (
                                        <material.MenuItem value={sebi} key={k}>{sebi}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='mt-3'>
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">NSE Category</material.InputLabel>
                                <material.Select
                                    {...register("nseCategory")}
                                    required
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectNseCategoryData}
                                    onChange={nseCategoryChange}
                                    color='success'
                                    label="NSE Category"
                                >
                                    {nseCategoryData?.map((nse, k) => (
                                        <material.MenuItem value={nse} key={k}>{nse}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>

                        {/* <div className='mt-3'>
                            <material.TextField
                                label="CDP category"
                                id="cdpCategory"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                color='success'
                                InputProps={{ readOnly: true }}
                                {...register("cdpCategory")}
                            />
                        </div> */}
                        <div className='mt-3'>
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">CPCB category</material.InputLabel>
                                <material.Select
                                    {...register("cpcbCategory")}
                                    required
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectCpcbCategoryData}
                                    onChange={cpcbCategoryChange}
                                    color='success'
                                    label="NSE Category"
                                >
                                    {cpcbCategoryData?.map((cpcb, k) => (
                                        <material.MenuItem value={cpcb} key={k}>{cpcb}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className='mt-3'>
                            <material.TextField
                                error={errors.turnOverContribution?.type === "required"}
                                required
                                label="Total turnover contribution (%)"
                                id="turnOverContribution"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                color='success'
                                {...register("turnOverContribution", { required: true, pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/i })}
                            />
                            {(errors?.turnOverContribution?.type === "pattern") && (
                                <p className='text-danger'>Invalid input, please input only number</p>
                            )}
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={close}>Close</material.Button>
                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(update)}>Update</material.Button>
                </material.DialogActions>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ProductOrServicesDialog;
import React from 'react';
import { material } from '../../library/Material';
import ColumnChart from '../../shared/reporting/ColumnChart';
import PieChart from '../../shared/reporting/PieChart';
import LineChart from '../../shared/reporting/LineChart';
import DonutChart from '../../shared/reporting/DonutChart';
import ComboChart from '../../shared/reporting/ComboChart';
import SpeedometerChart from '../../shared/reporting/SpeedometerChart';
import HalfDonutChart from '../../shared/reporting/HalfDonutChart';
import WaterReportingChart from '../../shared/reporting/WaterReportingChart';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

function FullScreenChartDialog(props) {

    const { openFullScreenChartDialog, setOpenFullScreenChartDialog, columnChartData, pieChartData, options, lineChartData } = props;

    const handleClose = () => {
        setOpenFullScreenChartDialog({ action: false, chartData: null, chartName: null });
    };

    return (
        <div>
            <material.Dialog open={openFullScreenChartDialog.action} maxWidth="xl" fullWidth TransitionComponent={Transition}>
                <material.DialogTitle>
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {openFullScreenChartDialog.chartName === "column-chart" ? (
                        <ColumnChart fullScreen={true} columnChartData={columnChartData} options={options} />
                    ) : openFullScreenChartDialog.chartName === "pie-chart" ? (
                        <PieChart fullScreen={true} pieChartData={pieChartData} />
                    ) : openFullScreenChartDialog.chartName === "line-chart" ? (
                        <LineChart fullScreen={true} lineChartData={lineChartData} options={options} />
                    ) : openFullScreenChartDialog.chartName === "donut-chart" ? (
                        <DonutChart
                            fullScreen={true}
                            chartData={openFullScreenChartDialog.chartData}
                            options={options}
                        />
                    ) : openFullScreenChartDialog.chartName === "gauge-chart" ? (
                        <SpeedometerChart
                            fullScreen={true}
                            chartData={openFullScreenChartDialog.chartData}
                        />
                    ) : openFullScreenChartDialog.chartName === "half-donut-chart" ? (
                        <WaterReportingChart
                            fullScreen={true}
                            chartData={openFullScreenChartDialog.chartData}
                        />
                    ) : (
                        <ComboChart
                            fullScreen={true}
                            chartData={openFullScreenChartDialog.chartData}
                            options={options}
                        />
                    )}
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default FullScreenChartDialog;
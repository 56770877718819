import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";



export const getSensitiveSpeciesAffectedData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/sensitive-species-affected/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateSensitiveSpeciesAffectedData = async (SensitiveSpeciesAffectedData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/sensitive-species-affected/save-or-update`, SensitiveSpeciesAffectedData)
};

export const sendForApprovalSensitiveSpeciesAffected = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/sensitive-species-affected/send-for-approval`, recordRefNos)
};

export const approveSensitiveSpeciesAffected = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/sensitive-species-affected/approve`, recordRefNos)
};









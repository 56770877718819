import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const saveOrUpdateWaterDischargeData = async (waterDischargeData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/water/water-discharge/save-or-update`, waterDischargeData)
};

export const getWaterDischargeData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/water/water-discharge/get-all-data`, { params: payloadData})
};

export const sendForApprovalWaterDischargeRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-discharge/send-for-approval`, RecordIds);
};

export const approveWaterDischargeRecord = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-discharge/approve`, payloadData)
};
import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { saveWaterWithdrawalData } from '../services/sub-issue-services/WaterWithdralService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';
import DatePeriod from '../shared/DatePeriod';
import { generateFromDateAndToDate } from '../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../services/sub-issue-services/DeleteRecordService';

function AddWaterWithdrawal(props) {

    const { openAddWaterWithDrawal, setOpenAddWaterWithDrawal, getWaterWithdrawalData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, formState: { errors }, } = useForm();

    const [selectTypeOfSource, setSelectTypeOfSource] = useState("");
    const [selectUnit, setSelectUnit] = useState("");
    const [selectTypeOfWater, setSelectTypeOfWater] = useState("");
    const [selectWaterStressed, setSelectWaterStressed] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddWaterWithDrawal.action && openAddWaterWithDrawal.waterWithDrawalData) {
            reset(openAddWaterWithDrawal.waterWithDrawalData)
            setSelectTypeOfSource(openAddWaterWithDrawal.waterWithDrawalData.typeOfSource)
            setSelectUnit(openAddWaterWithDrawal.waterWithDrawalData.unit)
            setSelectTypeOfWater(openAddWaterWithDrawal.waterWithDrawalData.typeOfWater);
            setSelectWaterStressed(openAddWaterWithDrawal.waterWithDrawalData.waterStressed ? "Yes" : "No")
        }
    }, [openAddWaterWithDrawal.action === true]);

    const handleChangeUnit = (event) => {
        setSelectUnit(event.target.value)
    };
    const handleChangeTypeOfSource = (event) => {
        setSelectTypeOfSource(event.target.value)
    };
    const handleChangeTypeOfWater = (event) => {
        setSelectTypeOfWater(event.target.value)
    };
    const saveWaterWithdrawal = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "typeOfSource": data.typeOfSource,
                "typeOfWater": data.typeOfWater,
                "amount": parseFloat(data.amount),
                "unit": "",
                "applicable": true,
                "facilityRefNo": userDetails.facilityRefNo,
                waterStressed: selectWaterStressed === "Yes" ? true : false,
                "refNo": "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveWaterWithdrawalData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getWaterWithdrawalData();
                    reset({ amount: "" });
                    setSelectTypeOfSource("")
                    setSelectUnit("")
                    setSelectTypeOfWater("")
                    setSelectWaterStressed("")
                    setOpenAddWaterWithDrawal({ action: false, waterWithDrawalData: null })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };

    };

    const updateWaterWithdrawal = (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "typeOfSource": data.typeOfSource,
            "typeOfWater": data.typeOfWater,
            "amount": parseFloat(data.amount),
            "unit": data.unit,
            "applicable": true,
            "facilityRefNo": userDetails.facilityRefNo,
            waterStressed: selectWaterStressed === "Yes" ? true : false,
            "refNo": openAddWaterWithDrawal.waterWithDrawalData.refNo,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveWaterWithdrawalData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getWaterWithdrawalData();
                reset({ amount: "" });
                setSelectTypeOfSource("")
                setSelectUnit("")
                setSelectTypeOfWater("")
                setSelectWaterStressed("")
                setOpenAddWaterWithDrawal({ action: false, waterWithDrawalData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ amount: "" });
        setSelectTypeOfSource("")
        setSelectUnit("")
        setSelectTypeOfWater("")
        setSelectWaterStressed("")
        setOpenAddWaterWithDrawal({ action: false, waterWithDrawalData: null })
    };

    const handleChangeWaterStressed = (event) => {
        setSelectWaterStressed(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddWaterWithDrawal.waterWithDrawalData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getWaterWithdrawalData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setSelectTypeOfSource("")
                setSelectUnit("")
                setSelectTypeOfWater("")
                setSelectWaterStressed("")
                setOpenAddWaterWithDrawal({ action: false, waterWithDrawalData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWaterWithDrawal.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddWaterWithDrawal.waterWithDrawalData ? "Edit Water Withdrawal" : "Add Water Withdrawal"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddWaterWithDrawal.waterWithDrawalData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Water Withdrawal KLD</material.Typography>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.Box>
                                    <material.FormControl fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Type of Source</material.InputLabel>
                                        <material.Select
                                            {...register("typeOfSource", { required: true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            // inputProps={openAddWaterWithDrawal.waterWithDrawalData ? { readOnly: true } : null}
                                            value={selectTypeOfSource}
                                            onChange={handleChangeTypeOfSource}
                                        >
                                            <material.MenuItem value="Surface Water">Surface Water</material.MenuItem>
                                            <material.MenuItem value="Ground Water">Ground Water</material.MenuItem>
                                            <material.MenuItem value="Sea Water / Desalinated Water">Sea Water / Desalinated Water</material.MenuItem>
                                            <material.MenuItem value="Produced Water">Produced Water</material.MenuItem>
                                            <material.MenuItem value="3rd Party Water">3rd Party Water</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.Box>
                                    <material.FormControl fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Type of Water</material.InputLabel>
                                        <material.Select
                                            {...register("typeOfWater", { required: true })}
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // inputProps={openAddWaterWithDrawal.waterWithDrawalData ? { readOnly: true } : null}
                                            value={selectTypeOfWater}
                                            onChange={handleChangeTypeOfWater}
                                        >
                                            <material.MenuItem value="Fresh Water - Amount (≤1,000 mg/L TDS)">Fresh Water Amount (≤1,000 mg/L TDS)</material.MenuItem>
                                            <material.MenuItem value="Other Water Amount (>1,000 mg/L TDS)">Other Water Amount ({">"}1,000 mg/L TDS)</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.FormControl fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Water Stressed</material.InputLabel>
                                    <material.Select
                                        {...register("waterStressed", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectWaterStressed}
                                        onChange={handleChangeWaterStressed}
                                    >
                                        <material.MenuItem value="Yes">Yes</material.MenuItem>
                                        <material.MenuItem value="No">No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Amount"
                                    margin="dense"
                                    type="number"
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            {/* <div className='col-md-4 py-2'>
                                <material.Box >
                                    <material.FormControl fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard">Unit</material.InputLabel>
                                        <material.Select
                                            {...register("unit", { required: true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectUnit}
                                            onChange={handleChangeUnit}
                                        >
                                            <material.MenuItem value="KL">KL</material.MenuItem>
                                            <material.MenuItem value="ML">ML</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-2'>

                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWaterWithDrawal.waterWithDrawalData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateWaterWithdrawal)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWaterWithDrawal.waterWithDrawalData.rejectionNote === null) || (openAddWaterWithDrawal.waterWithDrawalData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveWaterWithdrawal)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    );
}

export default AddWaterWithdrawal;
import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getEHSApprovalStatusData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/ehs-approval-status/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateEHSApprovalStatusData = async (EHSApprovalStatusData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/ehs-approval-status/save-or-update`, EHSApprovalStatusData)
};

export const sendForApprovalEHSApprovalStatusData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/ehs-approval-status/send-for-approval`, recordIds)
};

export const approveEHSApprovalStatusData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/ehs-approval-status/approve`, recordIds)
};














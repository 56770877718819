import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getEnergyEfficiencyProductsServiceData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/efficiency-products-services/get-all`, { params: payloadData })
};

export const saveOrUpdateEnergyEfficiencyProductsServiceData = async (energyEfficiencyProductsServiceData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/efficiency-products-services/save-or-update`, energyEfficiencyProductsServiceData)
};

export const sendForApprovalEnergyEfficiencyProductsService = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/efficiency-products-services/send-for-approval`, recordRefNos)
};

export const approveEnergyEfficiencyProductsService = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/efficiency-products-services/approve`, payload)
};
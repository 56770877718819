import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getEmployeesWorkersWellBeingData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employees-workers-well-being/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateEmployeesWorkersWellBeingData = async (EmployeesWorkersWellBeingData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/employees-workers-well-being/save-or-update`, EmployeesWorkersWellBeingData)
};

export const sendForApprovalEmployeesWorkersWellBeingData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employees-workers-well-being/send-for-approval`, recordIds)
};

export const approveEmployeesWorkersWellBeingData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employees-workers-well-being/approve`, recordIds)
};















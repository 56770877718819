import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveMaterialUsedData } from '../../services/sub-issue-services/MaterialUsedService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';

function AddRecycledInput(props) {

    const { openAddRecycledInput, setOpenAddRecycledInput, getRecycledInputData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [usedMaterial, setUsedMaterial] = useState("");
    const [typeOfMaterial, setTypeOfMaterial] = useState("");
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {

        if (openAddRecycledInput.action && openAddRecycledInput.recycledInputData) {
            reset(openAddRecycledInput.recycledInputData);
            setUsedMaterial(openAddRecycledInput.recycledInputData ? openAddRecycledInput.recycledInputData.material : "");
            setTypeOfMaterial(openAddRecycledInput.recycledInputData ? openAddRecycledInput.recycledInputData.typeOfMaterial : "");
            setUnit(openAddRecycledInput.recycledInputData ? openAddRecycledInput.recycledInputData.unit : "");
        }
    }, [openAddRecycledInput.action === true]);

    const submitRecycledInputData = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "material": data.material,
                "typeOfMaterial": data.typeOfMaterial,
                "amount": Number(data.amount),
                otherSpecify: usedMaterial === "Other Waste (Specify)" ? data.otherSpecify : "",
                "unit": data.unit,
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveMaterialUsedData(obj, "RECYCLED_INPUT")
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getRecycledInputData();
                    reset({ amount: "" });
                    setUsedMaterial("");
                    setTypeOfMaterial("");
                    setUnit("");
                    setOpenAddRecycledInput({ action: false, recycledInputData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddRecycledInput.recycledInputData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getRecycledInputData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setUsedMaterial("");
                setTypeOfMaterial("");
                setUnit("");
                setOpenAddRecycledInput({ action: false, recycledInputData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const updateRecycledInputData = async (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openAddRecycledInput.recycledInputData.refNo,
            "material": data.material,
            "typeOfMaterial": data.typeOfMaterial,
            "amount": Number(data.amount),
            otherSpecify: usedMaterial === "Other Waste (Specify)" ? data.otherSpecify : "",
            "unit": data.unit,
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveMaterialUsedData(obj, "RECYCLED_INPUT")
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getRecycledInputData();
                reset({ amount: "" });
                setUsedMaterial("");
                setTypeOfMaterial("");
                setUnit("");
                setOpenAddRecycledInput({ action: false, recycledInputData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClickClose = () => {
        setOpenAddRecycledInput({ action: false, recycledInputData: null });
        reset({ amount: "" });
        setUsedMaterial("");
        setTypeOfMaterial("");
        setUnit("");
    };

    const selectMaterial = (event) => {
        setUsedMaterial(event.target.value);
    };

    const selectTypeofMaterial = (event) => {
        setTypeOfMaterial(event.target.value);
    };

    const selectUnit = (event) => {
        setUnit(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={openAddRecycledInput.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddRecycledInput.recycledInputData ? "Edit Recycled Input" : "Add Recycled Input"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddRecycledInput.recycledInputData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Recycle Data</material.Typography>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Recycled Material</material.InputLabel>
                                    <material.Select
                                        {...register("material", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={usedMaterial}
                                        onChange={selectMaterial}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Plastics (including packaging)"}>Plastics (Including Packaging)</material.MenuItem>
                                        <material.MenuItem value={"E Waste"}>E Waste</material.MenuItem>
                                        <material.MenuItem value={"Hazardous Waste"}>Hazardous Waste</material.MenuItem>
                                        <material.MenuItem value={"Other Waste (Specify)"}>Other Waste (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {usedMaterial === "Other Waste (Specify)" ? (
                                <div className='col-md-3 py-2'>
                                    <material.TextField
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Waste Specify"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Material</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfMaterial", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfMaterial}
                                        onChange={selectTypeofMaterial}
                                        fullWidth
                                        label="Type of material"
                                    >
                                        <material.MenuItem value={"Reused"}>Reused</material.MenuItem>
                                        <material.MenuItem value={"Recycled"}>Recycled</material.MenuItem>
                                        <material.MenuItem value={"Safely Disposed"}>Safely Disposed</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Amount"
                                    id="amount"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        label="Unit"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        fullWidth
                                        value={unit}
                                        onChange={selectUnit}
                                    >
                                        <material.MenuItem value={"Tons"}>Tons</material.MenuItem>
                                        <material.MenuItem value={"Kl"}>Kl</material.MenuItem>
                                        <material.MenuItem value={"Thousand m3"}>Thousand m3</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddRecycledInput.recycledInputData ? (
                        <>
                            <material.Button color='success' variant="outlined" onClick={handleSubmit(updateRecycledInputData)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddRecycledInput.recycledInputData.rejectionNote === null) || (openAddRecycledInput.recycledInputData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button color='success' variant="outlined" onClick={handleSubmit(submitRecycledInputData)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>

            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddRecycledInput;
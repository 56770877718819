import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddEnergyEfficiencyProduction from '../../../sub-issues-dialogs/energy-dialogs/AddEnergyEfficiencyProduction';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import { approveProductionEfficiency, getProductionEfficiencyData, sendForApprovalProductionEfficiency } from '../../../services/sub-issue-services/energy/EnergyEfficiencyProductionService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function EnergyEfficiencyProduction(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [tabValue, setTabValue] = useState('Energy Efficiency Production');
    const [energyEfficiencyProductionTableData, setEnergyEfficiencyProductionTableData] = useState([]);
    const [openEnergyEfficiencyProduction, setOpenEnergyEfficiencyProduction] = useState({ action: false, energyEfficiencyProductionData: null })
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getEfficiencyProductionData()
    }, [tabValue]);

    const getEfficiencyProductionData = async (filterDate) => {
        if (tabValue === "Energy Efficiency Production") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate
            }
            await getProductionEfficiencyData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList);
                    setEnergyEfficiencyProductionTableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (tabValue === "Energy Efficiency Production History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate
            }
            await getProductionEfficiencyData(payloadData)
                .then((resp) => {
                    setEnergyEfficiencyProductionTableData(resp.data.dataDtoList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const clickCheckBox = (value) => {
        setOpenEnergyEfficiencyProduction({ action: false, energyEfficiencyProductionData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        }
        else {
            recordRefNos.push(value)
        };
    };

    const addEnergyEfficiencyProduction = () => {
        setOpenEnergyEfficiencyProduction({ action: true, energyEfficiencyProductionData: null });
    };

    const editEnergyEfficiencyProduction = (tableData) => {
        setOpenEnergyEfficiencyProduction({ action: true, energyEfficiencyProductionData: tableData });
    };

    const sendApprovalEnergyEfficiencyProduction = async () => {
        if (recordRefNos.length) {
            await sendForApprovalProductionEfficiency(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getEfficiencyProductionData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const approvedEnergyEfficiencyProduction = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos,
            }
            await approveProductionEfficiency(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getEfficiencyProductionData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const rejectEnergyEfficiencyProduction = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos,
        }
        await approveProductionEfficiency(obj)
            .then((resp) => {
                recordRefNos = [];
                getEfficiencyProductionData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        getEfficiencyProductionData(data);
    }

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>

                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={tabValue}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Energy Efficiency Production" value="Energy Efficiency Production" />
                                        <material.Tab className="text-capitalize" label="Energy Efficiency Production History" value="Energy Efficiency Production History" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                        <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Energy Efficiency Production">
                                    <div className="row">
                                        <div className="col-6">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                                <span className="float-end">
                                                    {energyEfficiencyProductionTableData.length ? (
                                                        <>
                                                            <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedEnergyEfficiencyProduction}>Approve</material.Button>
                                                            <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectEnergyEfficiencyProduction}>Reject</material.Button>
                                                        </>
                                                    ) : null}
                                                </span>
                                            ) : (
                                                <span className="float-end">
                                                    <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalEnergyEfficiencyProduction}>Send for Approval</material.Button>
                                                    <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addEnergyEfficiencyProduction}>Add Energy Efficiency Production</material.Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Reduction Initiatives</StyledTableCell>
                                                                <StyledTableCell>Types of Energy Included</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {energyEfficiencyProductionTableData?.length ? energyEfficiencyProductionTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                        () => editEnergyEfficiencyProduction(item) : null}>
                                                                    <material.TableCell>{item.reductionInitiatives}</material.TableCell>
                                                                    <material.TableCell>{item.typesOfEnergyIncluded}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={9}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Energy Efficiency Production History">
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row">
                                            <div className="col-8">
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Reduction Initiatives</StyledTableCell>
                                                                <StyledTableCell>Types of Energy Included</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {energyEfficiencyProductionTableData?.length ? energyEfficiencyProductionTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}>
                                                                    <material.TableCell>{item.reductionInitiatives}</material.TableCell>
                                                                    <material.TableCell>{item.typesOfEnergyIncluded}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={8}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )
                                                            }
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure">
                                    <SpecificDisclosure
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Specific Disclosure History">
                                    <SpecificDisclosureHistory
                                        treeViewData={treeViewData}
                                    />
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddEnergyEfficiencyProduction
                getEfficiencyProductionData={getEfficiencyProductionData}
                openEnergyEfficiencyProduction={openEnergyEfficiencyProduction}
                setOpenEnergyEfficiencyProduction={setOpenEnergyEfficiencyProduction}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getEfficiencyProductionData={getEfficiencyProductionData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default EnergyEfficiencyProduction;
import React from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateEnergyOutsideData } from '../../services/sub-issue-services/energy/EnergyConsumptionOutsideService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


const upstream = ["Purchased Goods and Services", "Capital Goods", "Fuel and Energy Related Activities", "Upstream Transportation and Distribution", "Waste Generated in Operations", "Business Travel Explore", "Employee Commuting", "Upstream Leased Assets", "Other Upstream"];
const downstream = ["Downstream Transportation and Distribution", "Processing of Sold Products", "Use of Sold Products", "End of Life Treatment of Sold Products", "Downstream Leased Assets", "Franchises", "Investments", "Other Downstream"];


function AddEnergyConsumptionOutside(props) {

    const { openEnergyConsumptionOutside, setOpenEnergyConsumptionOutside, energyConsumptionOutsideData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [parameter, setParameter] = useState("");
    const [category, setCategory] = useState("");
    const [unit, setUnit] = useState("");
    const [changeCategory, setChangeCategory] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openEnergyConsumptionOutside.action && openEnergyConsumptionOutside.energyConsumptionOutsideData) {
            setParameter(openEnergyConsumptionOutside.energyConsumptionOutsideData.parameter);
            setCategory(openEnergyConsumptionOutside.energyConsumptionOutsideData.category);
            setUnit(openEnergyConsumptionOutside.energyConsumptionOutsideData.unit);
            if (openEnergyConsumptionOutside.energyConsumptionOutsideData.parameter === "Renewable Energy Consumption in Upstream Categories" || openEnergyConsumptionOutside.energyConsumptionOutsideData.parameter === "Non Renewable Energy Consumption in Upstream Categories") {
                setChangeCategory(upstream);
            } else if (openEnergyConsumptionOutside.energyConsumptionOutsideData.parameter === "Energy Consumption in Downstream Categories" || openEnergyConsumptionOutside.energyConsumptionOutsideData.parameter === "Non Renewable Energy Consumption in Downstream Categories") {
                setChangeCategory(downstream);
            };
            reset(openEnergyConsumptionOutside.energyConsumptionOutsideData);
        };
    }, [openEnergyConsumptionOutside.action === true]);

    const handleClose = () => {
        reset({ amount: "" });
        setParameter("");
        setCategory("");
        setChangeCategory([]);
        setUnit("");
        setOpenEnergyConsumptionOutside({ action: false, energyConsumptionOutsideData: null });
    };

    const changeParameter = (event) => {
        const parameterChangeType = event.target.value;
        setParameter(parameterChangeType);
        if (parameterChangeType === "Renewable Energy Consumption in Upstream Categories" || parameterChangeType === "Non Renewable Energy Consumption in Upstream Categories") {
            setChangeCategory(upstream);
        } else if (parameterChangeType === "Renewable Energy Consumption in Downstream Categories" || parameterChangeType === "Non Renewable Energy Consumption in Downstream Categories") {
            setChangeCategory(downstream);
        };
    };
    const changeCategoryType = (event) => {
        setCategory(event.target.value)
    };
    const changeUnit = (event) => {
        setUnit(event.target.value);
    };

    const saveEnergyConsumptionOutside = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "refNo": "",
                "parameter": data.parameter,
                "category": data.category,
                "amount": data.amount,
                "unit": data.unit,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateEnergyOutsideData(obj)
                .then((resp) => {
                    energyConsumptionOutsideData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ amount: "" });
                    setParameter("");
                    setCategory("");
                    setChangeCategory([]);
                    setUnit("");
                    setOpenEnergyConsumptionOutside({ action: false, energyConsumptionOutsideData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEnergyConsumptionOutside = (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "refNo": openEnergyConsumptionOutside.energyConsumptionOutsideData.refNo,
            "parameter": data.parameter,
            "category": data.category,
            "amount": data.amount,
            "unit": data.unit,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateEnergyOutsideData(obj)
            .then((resp) => {
                energyConsumptionOutsideData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ amount: "" });
                setParameter("");
                setChangeCategory([]);
                setCategory("");
                setUnit("");
                setOpenEnergyConsumptionOutside({ action: false, energyConsumptionOutsideData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openEnergyConsumptionOutside.energyConsumptionOutsideData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                energyConsumptionOutsideData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setParameter("");
                setChangeCategory([]);
                setCategory("");
                setUnit("");
                setOpenEnergyConsumptionOutside({ action: false, energyConsumptionOutsideData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openEnergyConsumptionOutside.action} maxWidth="lg"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openEnergyConsumptionOutside.energyConsumptionOutsideData ? "Edit Energy Consumption Outside Organization" : "Add Energy Consumption Outside Organization"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openEnergyConsumptionOutside.energyConsumptionOutsideData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Energy Consumption Outside Data</material.Typography>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Parameter</material.InputLabel>
                                    <material.Select
                                        {...register("parameter", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={parameter}
                                        onChange={changeParameter}
                                        label="Parameter"
                                    >
                                        <material.MenuItem value={"Renewable Energy Consumption in Upstream Categories"}>Renewable Energy Consumption in Upstream Categories</material.MenuItem>
                                        <material.MenuItem value={"Non Renewable Energy Consumption in Upstream Categories"}>Non Renewable Energy Consumption in Upstream Categories</material.MenuItem>
                                        <material.MenuItem value={"Renewable Energy Consumption in Downstream Categories"}>Renewable Energy Consumption in Downstream Categories</material.MenuItem>
                                        <material.MenuItem value={"Non Renewable Energy Consumption in Downstream Categories"}>Non Renewable Energy Consumption in Downstream Categories</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={category}
                                        onChange={changeCategoryType}
                                        label="Category"
                                    >
                                        {changeCategory.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Amount"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-3 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Unit</material.InputLabel>
                                    <material.Select
                                        {...register("unit", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={changeUnit}
                                        label="Unit"
                                    >
                                        <material.MenuItem value={"J"}>J</material.MenuItem>
                                        <material.MenuItem value={"GJ"}>GJ</material.MenuItem>
                                        <material.MenuItem value={"TJ"}>TJ</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergyConsumptionOutside.energyConsumptionOutsideData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateEnergyConsumptionOutside)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergyConsumptionOutside.energyConsumptionOutsideData.rejectionNote === null) || (openEnergyConsumptionOutside.energyConsumptionOutsideData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveEnergyConsumptionOutside)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEnergyConsumptionOutside
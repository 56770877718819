import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import AddRetirementBenefit from '../../../sub-issues-dialogs/employee/AddRetirementBenefit';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveRetirementBenefit, getRetirementBenefitData, sendForApprovalRetirementBenefit } from '../../../services/sub-issue-services/employee-services/RetirementBenefitService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';



var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function RetirementBenefit(props) {

    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [value, setValue] = useState('Retirement Benefit');
    const [retirementBenefitTableData, setRetirementBenefitTableData] = useState([]);
    const [openRetirementBenefit, setOpenRetirementBenefit] = useState({ action: false, retirementBenefitData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getRetirementBenefitAllData()
    }, [value]);

    const getFormDateToDate = (data) => {
        getRetirementBenefitAllData(data)
    };

    const getRetirementBenefitAllData = async (data) => {
        if (value === "Retirement Benefit") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getRetirementBenefitData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.retirementBenefitDataList);
                    setRetirementBenefitTableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (value === "Retirement Benefit History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getRetirementBenefitData(payloadData)
                .then((resp) => {
                    setRetirementBenefitTableData(resp.data.retirementBenefitDataList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const clickCheckBox = (value) => {
        setOpenRetirementBenefit({ action: false, retirementBenefitData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value)
        }
    }
    const addRetirementBenefit = () => {
        setOpenRetirementBenefit({ action: true, retirementBenefitData: null });
    };
    const editRetirementBenefit = (tableData) => {
        setOpenRetirementBenefit({ action: true, retirementBenefitData: tableData });
    };
    const sendApprovalRetirementBenefitData = async () => {
        if (recordRefNo.length) {
            await sendForApprovalRetirementBenefit(recordRefNo)
                .then((resp) => {
                    recordRefNo = [];
                    getRetirementBenefitAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };
    const approvedRetirementBenefitData = async () => {
        // if (recordRefNo.length) {
        //     await approveRetirementBenefit("APPROVED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNo)
        //         .then((resp) => {
        //             recordRefNo = [];
        //             getRetirementBenefitAllData();
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "success",
        //                 "message": resp.data,
        //             })
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
        //             })
        //         })
        // }
        if (recordRefNo.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo
            }
            await approveRetirementBenefit(obj)
                .then((resp) => {
                    recordRefNo = [];
                    getRetirementBenefitAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const rejectRetirementBenefitData = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordRefNo.length) {
            //     await approveRetirementBenefit("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNo)
            //         .then((resp) => {
            //             recordRefNo = [];
            //             getRetirementBenefitAllData();
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveRetirementBenefit(obj)
            .then((resp) => {
                recordRefNo = [];
                getRetirementBenefitAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={value}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Retirement Benefit" value="Retirement Benefit" />
                                        <material.Tab className="text-capitalize" label="Retirement Benefit History" value="Retirement Benefit History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Retirement Benefit">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-7">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {retirementBenefitTableData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedRetirementBenefitData}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRetirementBenefitData}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalRetirementBenefitData}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addRetirementBenefit}>Add Retirement Benefit</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Category</StyledTableCell>
                                                                <StyledTableCell>Deducted & Deposited with the Authority</StyledTableCell>
                                                                <StyledTableCell>PF</StyledTableCell>
                                                                <StyledTableCell>Gratuity</StyledTableCell>
                                                                <StyledTableCell>ESI</StyledTableCell>
                                                                <StyledTableCell>Others Please Specify</StyledTableCell>
                                                                {/* <StyledTableCell>Total</StyledTableCell> */}
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {retirementBenefitTableData?.length ? retirementBenefitTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                        () => editRetirementBenefit(item) : null}>
                                                                    <material.TableCell>{item.category}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.providentFund}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.gratuity}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.esi}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.othersPleaseSpecify}</material.TableCell>
                                                                    {/* <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.total}</material.TableCell> */}
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Retirement Benefit History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Category</StyledTableCell>
                                                                <StyledTableCell>Deducted & Deposited with the Authority</StyledTableCell>
                                                                <StyledTableCell>PF</StyledTableCell>
                                                                <StyledTableCell>Gratuity</StyledTableCell>
                                                                <StyledTableCell>ESI</StyledTableCell>
                                                                <StyledTableCell>Others Please Specify</StyledTableCell>
                                                                {/* <StyledTableCell>Total</StyledTableCell> */}
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {retirementBenefitTableData?.length ? retirementBenefitTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}>
                                                                    <material.TableCell>{item.category}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.pfgratuity}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.gratuity}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.esi}</material.TableCell>
                                                                    <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.othersPleaseSpecify}</material.TableCell>
                                                                    {/* <material.TableCell>{item.deductedAndDepositedWithTheAuthority === "N.A" ? "N/A" : item.total}</material.TableCell> */}
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )
                                                            }
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddRetirementBenefit
                getRetirementBenefitAllData={getRetirementBenefitAllData}
                openRetirementBenefit={openRetirementBenefit}
                setOpenRetirementBenefit={setOpenRetirementBenefit}
                treeViewData={treeViewData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getRetirementBenefitAllData={getRetirementBenefitAllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default RetirementBenefit;
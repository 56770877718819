import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddNoticesFines from '../../../sub-issues-dialogs/add-notices-fines/AddNoticesFines';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveNoticesAndFinesData, getNoticesAndFinesData, sendForApprovalNoticesAndFinesData } from '../../../services/sub-issue-services/notices-fines-service/NoticesFinesService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function NoticesAndFines(props) {

    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [noticesFinesTabs, setNoticesFinesTabs] = useState('Notices & Fines');
    const [addOpenNoticesAndFines, setAddOpenNoticesAndFines] = useState({ action: false, noticesFinesTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [noticesFinesTableData, setNoticesFinesTableData] = useState([]);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });


    useEffect(() => {
        getAllNoticesFinesData()
    }, [noticesFinesTabs]);

    const getFormDateToDate = (data) => {
        getAllNoticesFinesData(data)
    };

    const getAllNoticesFinesData = async (DateFilterData) => {
        if (noticesFinesTabs === "Notices & Fines") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: DateFilterData?.fromDate,
                toDate: DateFilterData?.toDate,
            }
            await getNoticesAndFinesData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList);
                    setNoticesFinesTableData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
        else if (noticesFinesTabs === "Notices & Fines History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: DateFilterData?.fromDate,
                toDate: DateFilterData?.toDate,
            }
            await getNoticesAndFinesData(payloadData)
                .then((resp) => {
                    setNoticesFinesTableData(resp.data.dataDtoList)
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const addNoticesAndFines = () => {
        setAddOpenNoticesAndFines({ action: true, noticesFinesTableData: null });
    };

    const editNoticesAndFines = (rowData) => {
        setAddOpenNoticesAndFines({ action: true, noticesFinesTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenNoticesAndFines({ action: false, noticesFinesTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendForApproval = async () => {
        if (recordRefNos.length) {
            await sendForApprovalNoticesAndFinesData(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getAllNoticesFinesData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const approve = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveNoticesAndFinesData(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getAllNoticesFinesData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const reject = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveNoticesAndFinesData(obj)
            .then((resp) => {
                recordRefNos = [];
                getAllNoticesFinesData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleChange = (event, newValue) => {
        setNoticesFinesTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            <span className='float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={noticesFinesTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Notices & Fines" value="Notices & Fines" />
                                    <material.Tab className='text-capitalize' label="Notices & Fines History" value="Notices & Fines History" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Notices & Fines">
                                <div className='row mt-2 align-items-center'>
                                    <div className="col-7 pb-2">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-5 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <span className='float-end'>
                                                {noticesFinesTableData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 1 }} onClick={approve}
                                                            disabled={!applicable || !recordRefNos.length} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} startIcon={<material.DoneIcon />}>Approve</material.Button>
                                                        <material.Button variant="contained" color='error' disabled={!applicable || !recordRefNos.length} startIcon={<material.PriorityHighIcon />}
                                                            hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} sx={{ textTransform: "none" }} onClick={reject}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                            </span>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none", mr: 1 }}
                                                    disabled={!applicable} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none" }}
                                                    startIcon={<material.AddIcon />} disabled={!applicable} onClick={addNoticesAndFines}>Add Notices & Fines</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Non Compliance Aspect</StyledTableCell>
                                                    <StyledTableCell>Other (Specify)</StyledTableCell>
                                                    <StyledTableCell>Number of Non Compliance</StyledTableCell>
                                                    <StyledTableCell>Monetary Value Fine (INR Lakh)</StyledTableCell>
                                                    <StyledTableCell>Non Monetary Sanctions</StyledTableCell>
                                                    <StyledTableCell>Dispute Resolution Case</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                    )}
                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {noticesFinesTableData?.length ? noticesFinesTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                            () => editNoticesAndFines(item) : null}>
                                                        <material.TableCell>{item.nonComplianceAspect}</material.TableCell>
                                                        <material.TableCell>{(item.otherSpecify === "" || item.otherSpecify === null) ? "N/A" : item.otherSpecify}</material.TableCell>
                                                        <material.TableCell>{item.noOfNonCompliance}</material.TableCell>
                                                        <material.TableCell>{item.monetaryValueFine}</material.TableCell>
                                                        <material.TableCell>{item.nonMonetarySanctions}</material.TableCell>
                                                        <material.TableCell>{item.disputeResolutionCase}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )}
                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={7}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Notices & Fines History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <StyledTableCell>Non Compliance Aspect</StyledTableCell>
                                                    <StyledTableCell>Other (Specify)</StyledTableCell>
                                                    <StyledTableCell>Number of Non Compliance</StyledTableCell>
                                                    <StyledTableCell>Monetary Value Fine (INR Lakh)</StyledTableCell>
                                                    <StyledTableCell>Non Monetary Sanctions</StyledTableCell>
                                                    <StyledTableCell>Dispute Resolution Case</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {noticesFinesTableData?.length ? noticesFinesTableData.map((item, ind) => (
                                                    <material.TableRow key={ind} >
                                                        <material.TableCell>{item.nonComplianceAspect}</material.TableCell>
                                                        <material.TableCell>{(item.otherSpecify === "" || item.otherSpecify === null) ? "N/A" : item.otherSpecify}</material.TableCell>
                                                        <material.TableCell>{item.noOfNonCompliance}</material.TableCell>
                                                        <material.TableCell>{item.monetaryValueFine}</material.TableCell>
                                                        <material.TableCell>{item.nonMonetarySanctions}</material.TableCell>
                                                        <material.TableCell>{item.disputeResolutionCase}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={7}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddNoticesFines
                addOpenNoticesAndFines={addOpenNoticesAndFines}
                setAddOpenNoticesAndFines={setAddOpenNoticesAndFines}
                getAllNoticesFinesData={getAllNoticesFinesData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getAllNoticesFinesData={getAllNoticesFinesData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default NoticesAndFines;
import React, { useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import Snackbar from '../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { saveNewPasswordByAdmin } from '../services/UsermanagementService';

function ResetPassword(props) {

    const { setOpenResetPasswordUserListDialog, openResetPasswordDialog, setOpenResetPasswordDialog } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [type, setType] = useState("password");
    const [isVisible, setIsVisible] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const changePassword = async (data) => {
        let payloadData = {
            userName: openResetPasswordDialog.resetPasswordData.userName,
            password: data.password,
            otp: openResetPasswordDialog.resetPasswordData.otp,
            updatedBy: userDetails.username
        }
        await saveNewPasswordByAdmin(payloadData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenResetPasswordUserListDialog({ action: false, verifyOtp: null });
                setOpenResetPasswordDialog({ action: false, resetPasswordData: null });
                reset({password:""});
                setIsVisible(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const close = () => {
        setOpenResetPasswordDialog({ action: false, resetPasswordData: null });
        reset({password:""});
        setIsVisible(false)
    };

    return (
        <div>
            <material.Dialog open={openResetPasswordDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle className='fw-bold'>Reset Password
                    <span className='float-end'>
                        <material.IconButton color='error' title='Close' onClick={close}>
                            <material.CloseIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            <material.TextField
                                {...register("password", { required: true, })}
                                required
                                label="Enter New Password"
                                id="password"
                                variant="standard"
                                type={type}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <material.InputAdornment position="end">
                                            {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                                        </material.InputAdornment>
                                    ),
                                }}
                            />
                            <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                        </div>
                        <span className='d-flex justify-content-center mt-3'>
                            <material.Button onClick={handleSubmit(changePassword)} color='success' variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Change Password</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ResetPassword;
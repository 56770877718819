import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { saveOrUpdateGhgScopeData } from '../../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


const Upstream = ["Purchased Goods and Services", "Capital Goods", "Upstream Transportation and Distribution", "Waste Generated in Operations", "Business Travel", "Employee Commuting", "Upstream Leased Assets", "Other Leased Assets"];
const Downstream = ["Downstream Transportation and Distribution", "Processing of Sold Products", "Use of Sold Products", "End of Life Treatment of Sold Products", "Downstream Leased Assets", "Franchises", "Investments", "Other Downstream"];

function AddGhgScope3(props) {

    const { addOpenClimateChangeScope3Ghg, setAddOpenClimateChangeScope3Ghg, getGhgScope3AllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [typeOfSource, setTypeOfSource] = useState("");
    const [activity, setActivity] = useState("");
    const [upstream, setUpstream] = useState([]);
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (addOpenClimateChangeScope3Ghg.action && addOpenClimateChangeScope3Ghg.scope3TableData) {
            reset(addOpenClimateChangeScope3Ghg.scope3TableData);
            setTypeOfSource(addOpenClimateChangeScope3Ghg.scope3TableData.typeOfSource);
            setActivity(addOpenClimateChangeScope3Ghg.scope3TableData.activity);
            setUnit(addOpenClimateChangeScope3Ghg.scope3TableData.unitCo2);
            if (addOpenClimateChangeScope3Ghg.scope3TableData.typeOfSource === 'Energy Consumption in Upstream Categories') {
                setUpstream(Upstream)
            } else {
                setUpstream(Downstream)
            }
        }
    }, [addOpenClimateChangeScope3Ghg.action === true]);

    const saveClimateChangeGhgScope3 = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "applicable": true,
                "typeOfSource": data.typeOfSource,
                "activity": data.activity,
                "amount": parseFloat(data.amount),
                "unitCo2": data.unitCo2,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateGhgScopeData(object, "GHG_SCOPE3")
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope3AllData();
                    reset({ amount: "" });
                    setTypeOfSource("");
                    setActivity("");
                    setUpstream([]);
                    setUnit("");
                    setAddOpenClimateChangeScope3Ghg({ action: false, scope3TableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateClimateChangeGhgScope3 = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": addOpenClimateChangeScope3Ghg.scope3TableData.refNo,
            "applicable": true,
            "typeOfSource": data.typeOfSource,
            "activity": data.activity,
            "amount": parseFloat(data.amount),
            "unitCo2": data.unitCo2,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateGhgScopeData(object, "GHG_SCOPE3")
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getGhgScope3AllData();
                reset({ amount: "" });
                setTypeOfSource("");
                setActivity("");
                setUpstream([]);
                setUnit("");
                setAddOpenClimateChangeScope3Ghg({ action: false, scope3TableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    }

    const handleClose = () => {
        reset({ amount: "" });
        setTypeOfSource("");
        setActivity("");
        setUpstream([]);
        setUnit("");
        setAddOpenClimateChangeScope3Ghg({ action: false, scope3TableData: null });
    };

    const selectTypeOfSource = (event) => {
        let selectedTypeOfSource = event.target.value;
        setTypeOfSource(selectedTypeOfSource)
        if (selectedTypeOfSource === 'Energy Consumption in Upstream Categories') {
            setUpstream(Upstream)
        } else {
            setUpstream(Downstream)
        }
    };

    const selectActivity = (event) => {
        setActivity(event.target.value)
    };

    const selectUnit = (event) => {
        setUnit(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenClimateChangeScope3Ghg.scope3TableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getGhgScope3AllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setTypeOfSource("");
                setActivity("");
                setUpstream([]);
                setUnit("");
                setAddOpenClimateChangeScope3Ghg({ action: false, scope3TableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenClimateChangeScope3Ghg.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenClimateChangeScope3Ghg.scope3TableData ? "Edit GHG Scope 3" : "Add GHG Scope 3"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenClimateChangeScope3Ghg.scope3TableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>GHG Scope3 Data</material.Typography>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfSource", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfSource}
                                        onChange={selectTypeOfSource}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Energy Consumption in Upstream Categories"}>Energy Consumption in Upstream Categories</material.MenuItem>
                                        <material.MenuItem value={"Energy Consumption in Downstream Categories"}>Energy Consumption in Downstream Categories</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Activity</material.InputLabel>
                                    <material.Select
                                        {...register("activity", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={activity}
                                        onChange={selectActivity}
                                        fullWidth
                                        label="Material"
                                    >
                                        {upstream.map((ele, i) => (
                                            <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.TextField
                                    required
                                    margin="dense"
                                    label="Amount"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>UnitCO2</material.InputLabel>
                                    <material.Select
                                        {...register("unitCo2", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={selectUnit}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"metric tons"}>metric tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenClimateChangeScope3Ghg.scope3TableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateClimateChangeGhgScope3)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenClimateChangeScope3Ghg.scope3TableData.rejectionNote === null) || (addOpenClimateChangeScope3Ghg.scope3TableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveClimateChangeGhgScope3)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddGhgScope3;
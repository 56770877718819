import React, { useEffect } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

function TableWidgetDialog(props) {

    const { setOpenTableWidgetDialog, openTableWidgetDialog, year, columnName, tableHeading } = props;

    const handleClose = () => {
        setOpenTableWidgetDialog({ action: false, tableData: [] });
    };

    // console.log(openTableWidgetDialog)

    return (
        <div>
            <material.Dialog open={openTableWidgetDialog.action} maxWidth="xl" fullWidth TransitionComponent={Transition}>
                <material.DialogTitle>{tableHeading},  Reporting Period : {year} - {Number(year) + 1}
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <material.Paper elevation={3} sx={{ p: 1, boxShadow: "0px 0px 15px 5px rgba(72, 198, 223, 0.3)" }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead>
                                    {columnName?.map((column, cInd) =>
                                        <StyledTableCell size='small' key={cInd}>{column.headerName}</StyledTableCell>
                                    )}
                                </material.TableHead>
                                <material.TableBody>
                                    {openTableWidgetDialog?.tableData.length ? openTableWidgetDialog?.tableData.map((item, rowIndex) => (
                                        <material.TableRow key={rowIndex}>
                                            {columnName.map((column, colIndex) => (

                                                <material.TableCell key={colIndex}>
                                                    {item[column.key]}</material.TableCell>
                                            ))}
                                        </material.TableRow>
                                    )) : (
                                        <material.TableRow >
                                            <material.TableCell colSpan={12}>
                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                            </material.TableCell>
                                        </material.TableRow>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </material.DialogContent>
                {/* <material.DialogContent>
                    <material.Paper elevation={3} sx={{ p: 1, boxShadow: "0px 0px 15px 5px rgba(72, 198, 223, 0.3)" }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead>
                                    <material.TableRow>
                                        <StyledTableCell>Facility Name</StyledTableCell>
                                        <StyledTableCell>Source Id</StyledTableCell>
                                        <StyledTableCell>Stack Height (m)</StyledTableCell>
                                        <StyledTableCell>Stack Diameter (m)</StyledTableCell>
                                        <StyledTableCell>Stack Gas Velocity (m/sec)</StyledTableCell>
                                        <StyledTableCell>Pollutant Name</StyledTableCell>
                                        <StyledTableCell>Concentration (mg/m³)</StyledTableCell>
                                        <StyledTableCell>Rate (gm/sec)</StyledTableCell>
                                        <StyledTableCell>Load (MT/Day)</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {openTableWidgetDialog?.tableData ? openTableWidgetDialog.tableData.facilityDetails.map((facility) => (
                                        facility.pollutantData.map((source) => (
                                            source.pollutantInfo.map((pollutant) => (
                                                <material.TableRow
                                                    key={`${facility.facilityRefNo}-${source.sourceId}-${pollutant.pollutantName}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <material.TableCell component="th" scope="row">{facility.facilityName}</material.TableCell>
                                                    <material.TableCell>{source.sourceId}</material.TableCell>
                                                    <material.TableCell>{pollutant.stackHight}</material.TableCell>
                                                    <material.TableCell>{pollutant.stackDiameter}</material.TableCell>
                                                    <material.TableCell>{pollutant.stackVelocity}</material.TableCell>
                                                    <material.TableCell>{pollutant.pollutantName}</material.TableCell>
                                                    <material.TableCell>{pollutant.concentration.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{pollutant.rate.toFixed(2)}</material.TableCell>
                                                    <material.TableCell>{pollutant.load.toFixed(2)}</material.TableCell>
                                                </material.TableRow>
                                            ))
                                        ))
                                    )) : null}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </material.DialogContent> */}
            </material.Dialog>
        </div>
    );
}

export default TableWidgetDialog;
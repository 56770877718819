import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    data: ""
};

const sidebarSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        sidebarAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: true,
                data: action.payload,
            };
            return loginAuthState;
        },
        sidebarEsgDataAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: true,
                data: action.payload,
            };
            return loginAuthState;
        }
    },
});

export const { sidebarAction, sidebarEsgDataAction } = sidebarSlice.actions;
export default sidebarSlice.reducer;
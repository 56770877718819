import { rootApiUrl } from "../../environment/Environment";
import { axiosInstance } from "../../shared/AxiosConfig";

export const getSebiList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-sebi-list`,)
};

export const getNseList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-nse-list`,)
};

export const getNicList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-nic-list`,)
};

export const getCpcbList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-cpcb-list`,)
};

export const deleteNic = async (facilityRefNo, nicData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/facility/delete-nic`, nicData, { params: { facilityRefNo } })
};

export const deleteCpcb = async (facilityRefNo, nicData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/facility/delete-cpcb`, nicData, { params: { facilityRefNo } })
};

export const deleteNse = async (facilityRefNo, nicData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/facility/delete-nse`, nicData, { params: { facilityRefNo } })
};

export const deleteSebi = async (facilityRefNo, nicData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/facility/delete-sebi`, nicData, { params: { facilityRefNo } })
};

export const getDistinctSebiListForCorporate = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/corporate/get-distinct-sebi-list-for-corporate`, { params: { corporateRefNo } })
};

export const getDistinctNseListForCorporate = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/corporate/get-distinct-nse-list-for-corporate`, { params: { corporateRefNo } })
};

export const getDistinctCpcbListForCorporate = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/corporate/get-distinct-cpcb-list-for-corporate`, { params: { corporateRefNo } })
};
import React, { useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import ProductOrServicesDialog from '../../dialogs/ProductOrServicesDialog';

function ProductOrServices(props) {

    const { columns, fields, getProductServiceDetails } = props;
    const [openProductOrServiceDialog, setOpenProductOrServicesDialog] = useState({ action: false, data: null })
    const editProductOrServices = (productData) => {
        setOpenProductOrServicesDialog({ action: true, data: productData })
    };

    return (
        <div>
            <material.TableContainer >
                <material.Table>
                    <material.TableHead>
                        <material.TableRow >
                            {columns.map((column, cInd) =>
                                <StyledTableCell key={cInd} sx={{ width: 400 }}>{column.header}</StyledTableCell>
                            )}
                            <StyledTableCell className=''>Action</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {fields?.map((item, rowIndex) => (
                            <material.TableRow key={rowIndex}>
                                {columns.map((column, colIndex) => (

                                    <material.TableCell component="th" scope="row" key={colIndex}>
                                        {column.key === "nicDto" ? item[column.key].projectActivity : item[column.key]}</material.TableCell>
                                ))}
                                <material.TableCell>
                                    <material.IconButton title='Edit' aria-label="create" size="large" onClick={() => editProductOrServices(item)}>
                                        <material.CreateIcon color='primary' />
                                    </material.IconButton>
                                </material.TableCell>
                            </material.TableRow>
                        ))}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
            <ProductOrServicesDialog
                openProductOrServiceDialog={openProductOrServiceDialog}
                setOpenProductOrServicesDialog={setOpenProductOrServicesDialog}
                getProductServiceDetails={getProductServiceDetails}
            />
        </div>
    );
}

export default ProductOrServices;
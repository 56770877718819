import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateProductionEfficiencyData = async (efficiencyProductionData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/production-efficiency/save-or-update`, efficiencyProductionData)
};

export const getProductionEfficiencyData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/production-efficiency/get-all`, { params:payload })
};

export const sendForApprovalProductionEfficiency = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/production-efficiency/send-for-approval`, recordRefNos)
};

export const approveProductionEfficiency = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/production-efficiency/approve`, payload)
};
import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateHabitatsProtectedOrRestoredData = async (HabitatsProtectedOrRestoredData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/habitats-protected/save-or-update`, HabitatsProtectedOrRestoredData)
};

export const getHabitatsProtectedOrRestoredData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/habitats-protected/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalHabitatsProtectedOrRestored = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/habitats-protected/send-for-approval`, recordRefNos)
};

export const approveHabitatsProtectedOrRestored = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/habitats-protected/approve`, recordRefNos)
};

import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateWasteDisposalData } from '../../services/sub-issue-services/WasteDisposalService';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddWasteDisposal(props) {
    const { register, handleSubmit, reset, control, watch, formState: { errors }, } = useForm();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddWasteDisposal, setOpenAddWasteDisposal, getAllWasteDisposalData, treeViewData } = props;
    const [selectCategoryOfWaste, setSelectCategoryOfWaste] = useState("");
    const [selectWasteDisposalOption, setSelectWasteDisposalOption] = useState("");
    const [selectUnit, setSelectUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddWasteDisposal.action && openAddWasteDisposal.wasteDisposalData) {
            setSelectCategoryOfWaste(openAddWasteDisposal.wasteDisposalData.wasteCategory);
            setSelectWasteDisposalOption(openAddWasteDisposal.wasteDisposalData.wasteDisposalOption);
            setSelectUnit(openAddWasteDisposal.wasteDisposalData.unit);
            reset(openAddWasteDisposal.wasteDisposalData);
        }
    }, [openAddWasteDisposal.action === true])


    const saveWasteDisposal = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "wasteCategory": data.wasteCategory,
                "wasteDisposalOption": data.wasteDisposalOption,
                "onsite": Number(data.onsite),
                "offsite": Number(data.offsite),
                "unit": data.unit,
                "applicable": true,
                otherSpecify: data.otherSpecify,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateWasteDisposalData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllWasteDisposalData()
                    reset({ onsite: "", offsite: "", otherSpecify: "" });
                    setSelectCategoryOfWaste("")
                    setSelectWasteDisposalOption("")
                    setSelectUnit("")
                    setOpenAddWasteDisposal({ action: false, wasteDisposalData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateWasteDisposal = async (data) => {
        let object = {
            "refNo": openAddWasteDisposal.wasteDisposalData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "wasteCategory": data.wasteCategory,
            "wasteDisposalOption": data.wasteDisposalOption,
            "onsite": Number(data.onsite),
            "offsite": Number(data.offsite),
            "unit": data.unit,
            "applicable": true,
            otherSpecify: selectCategoryOfWaste === "Other Waste (Specify)" ? data.otherSpecify : "",
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateWasteDisposalData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllWasteDisposalData()
                reset({ onsite: "", offsite: "", otherSpecify: "" });
                setSelectCategoryOfWaste("")
                setSelectWasteDisposalOption("")
                setSelectUnit("")
                setOpenAddWasteDisposal({ action: false, wasteDisposalData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ onsite: "", offsite: "", });
        setSelectCategoryOfWaste("")
        setSelectWasteDisposalOption("")
        setSelectUnit("")
        setOpenAddWasteDisposal({ action: false, wasteDisposalData: null });
    };

    const handleSelectCategoryOfWaste = (event) => {
        setSelectCategoryOfWaste(event.target.value);
    };

    const handleSelectWasteDisposalOption = (event) => {
        setSelectWasteDisposalOption(event.target.value);
    };

    const handleSelectUnit = (event) => {
        setSelectUnit(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddWasteDisposal.wasteDisposalData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllWasteDisposalData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ onsite: "", offsite: "", otherSpecify: "" });
                setSelectCategoryOfWaste("")
                setSelectWasteDisposalOption("")
                setSelectUnit("")
                setOpenAddWasteDisposal({ action: false, wasteDisposalData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddWasteDisposal.action} maxWidth="md" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>{openAddWasteDisposal.wasteDisposalData ? "Edit Waste Disposal" : "Add Waste Disposal"}</material.DialogTitle>
                <material.DialogContent>
                    <form>
                        {openAddWasteDisposal.wasteDisposalData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                        <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'>
                                    <material.Typography className='fw-bold'>Waste Disposal</material.Typography>
                                </div>
                                <div className='col-lg-4 py-2'>
                                    <material.Box>
                                        <material.FormControl required fullWidth variant="standard">
                                            <material.InputLabel id="demo-simple-select-standard" variant="standard">Category of Waste</material.InputLabel>
                                            <material.Select
                                                {...register("wasteCategory", { required: true })}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                margin="dense"
                                                className='pt-2'
                                                value={selectCategoryOfWaste}
                                                onChange={handleSelectCategoryOfWaste}
                                            >
                                                <material.MenuItem value="Construction and Demolition">Construction and Demolition</material.MenuItem>
                                                <material.MenuItem value="Municipal Solid Waste">Municipal Solid Waste</material.MenuItem>
                                                <material.MenuItem value="Packaging Waste (Other than Plastic)">Packaging Waste (Other than Plastic)</material.MenuItem>
                                                <material.MenuItem value="Plastic Waste">Plastic Waste</material.MenuItem>
                                                <material.MenuItem value="Other Non Hazardous Waste">Other Non Hazardous Waste</material.MenuItem>
                                                <material.MenuItem value="Non Oily (Chemical/ Acid/ Alkali/ Dry Batteries/ Sludge)">Non Oily (Chemical/ Acid/ Alkali/ Dry Batteries/ Sludge)</material.MenuItem>
                                                <material.MenuItem value="Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)">Oily Substances (Absorbent Materials, Impacted Soil, Waste Oil & Lubricant)</material.MenuItem>
                                                <material.MenuItem value="Other Hazardous Waste">Other Hazardous Waste</material.MenuItem>
                                                <material.MenuItem value="Battery Waste">Battery Waste</material.MenuItem>
                                                <material.MenuItem value="Bio Medical Waste">Bio Medical Waste</material.MenuItem>
                                                <material.MenuItem value="Radioactive Waste">Radioactive Waste</material.MenuItem>
                                                <material.MenuItem value="E Waste">E Waste</material.MenuItem>
                                                <material.MenuItem value="Other Waste (Specify)">Other Waste (Specify)</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </material.Box>
                                </div>
                                {selectCategoryOfWaste === "Other Waste (Specify)" ? (
                                    <div className='col-lg-4 py-2'>
                                        <material.TextField
                                            fullWidth
                                            required
                                            variant="standard"
                                            id="demo-simple-select-standard"
                                            label="Other Waste Specify"
                                            margin="dense"
                                            type="text"
                                            {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        />
                                        {errors?.otherSpecify?.type === "maxLength" && (
                                            <p className='text-danger'>Maximum length 20 character</p>
                                        )}
                                    </div>
                                ) : null}
                                <div className='col-lg-4 py-2'>
                                    <material.Box>
                                        <material.FormControl required fullWidth variant="standard">
                                            <material.InputLabel id="demo-simple-select-standard" variant="standard">Waste Disposal Option</material.InputLabel>
                                            <material.Select
                                                {...register("wasteDisposalOption", { required: true })}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                margin="dense"
                                                className='pt-2'
                                                value={selectWasteDisposalOption}
                                                onChange={handleSelectWasteDisposalOption}
                                            >
                                                <material.MenuItem value="Incineration (With Energy Recovery)">Incineration (With Energy Recovery)</material.MenuItem>
                                                <material.MenuItem value="Incineration (Without Energy Recovery)">Incineration (Without Energy Recovery)</material.MenuItem>
                                                <material.MenuItem value="Landfilling">Landfilling</material.MenuItem>
                                                <material.MenuItem value="Other Disposal Operations">Other Disposal Operations</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </material.Box>
                                </div>
                                <div className='col-lg-4 py-2'>
                                    <material.TextField
                                        fullWidth
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="On Site"
                                        margin="dense"
                                        type="number"
                                        {...register("onsite", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    />
                                    {errors.onsite && errors.onsite.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 py-2'>
                                    <material.TextField
                                        fullWidth
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="Off Site"
                                        margin="dense"
                                        type="number"
                                        {...register("offsite", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    />
                                    {errors.offsite && errors.offsite.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 py-2'>
                                    <material.Box>
                                        <material.FormControl required fullWidth variant="standard">
                                            <material.InputLabel id="demo-simple-select-standard" variant="standard">Unit</material.InputLabel>
                                            <material.Select
                                                {...register("unit", { required: true })}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                margin="dense"
                                                className='pt-2'
                                                value={selectUnit}
                                                onChange={handleSelectUnit}
                                            >
                                                <material.MenuItem value="Kg">Kg</material.MenuItem>
                                                <material.MenuItem value="MT">MT</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </material.Box>
                                </div>
                            </div>
                        </material.Card>
                    </form>
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddWasteDisposal.wasteDisposalData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateWasteDisposal)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddWasteDisposal.wasteDisposalData.rejectionNote === null) || (openAddWasteDisposal.wasteDisposalData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveWasteDisposal)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddWasteDisposal
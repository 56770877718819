import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LoginSlice from "./slice/LoginSlice";
import LogoutSlice from "./slice/LogoutSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import DrawerSlice from "./slice/DrawerSlice";
import SidebarSlice from "./slice/SidebarSlice";
import TreeViewPillarSlice from "./slice/TreeViewPillarSlice";
import TreeViewIssueSlice from "./slice/TreeViewIssueSlice";
import DatePeriodSlice from "./slice/DatePeriodSlice";

const persistConfig = {
    key: 'main-root',
    storage: storage,
};

const reducer = combineReducers({
    LoginSlice,
    LogoutSlice,
    DrawerSlice,
    SidebarSlice,
    TreeViewPillarSlice,
    TreeViewIssueSlice,
    DatePeriodSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export const persistor = persistStore(store);
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateBiodiversityImpactsData } from '../../services/sub-issue-services/biodiversity-services/BiodiversityImpactsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';

const envInputOutputData = ["Land Use", "Water Use", "GHG Emission", "Air Emission", "Waste Water", "Solid Waste", "Hazardous Waste"]
const envPressureData = ["Terrestrial Ecotoxicity", "Terrestrial Acidification", "Photochemical Ozone", "Land Occupation", "Land Transformation", "Water Withdrawal", "Climate Change", "Freshwater Eutrophication", "Marine Eutrophication", "Freshwater Ecotoxicity", "Marine Ecotoxicity", "Reduction of Species", "Introduction on Invasive Species"]
const provisionalData = [" Clean Water", "Food, Fodder, Fuel, Fibre, etc.", "Physical Support"]
const regulativeData = ["Flood and Storm Protection", "Climate Regulation", "Animal & Plant Population", "Erosion Control", "Translocation of Nutrients", "Water Flow Maintenance", "Water Quality", "Dilution by Atmosphere & Ecosystems"];
const supportingData = ["Nutrient Cycle", "Soil Biological Activity", "Soil Formation", "Water Cycling"];
const culturalData = ["Recreation", "Aesthetics", "Spirituality"];


function AddBiodiversityImpacts(props) {

    const { openBiodiversityImpacts, setOpenBiodiversityImpacts, getBiodiversityImpactsAllData, treeViewData } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [envInputOutput, setEnvInputOutput] = useState("");
    const [envPressureType, setEnvPressureType] = useState("");
    const [areaImpactedType, setAreaImpactedType] = useState("");
    const [durationType, setDurationType] = useState("");
    const [reversibilityOrIrreversibilityType, setReversibilityOrIrreversibilityType] = useState("");
    const [impactedEcoServicesGroupType, setImpactedEcoServicesGroupType] = useState("");
    const [impactedEcoServicesType, setImpactedEcoServicesType] = useState("");
    const [impactedEcoServicesData, setImpactedEcoServicesData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openBiodiversityImpacts.action && openBiodiversityImpacts.biodiversityImpactsData) {
            setEnvInputOutput(openBiodiversityImpacts.biodiversityImpactsData.envInputAndOutput);
            setEnvPressureType(openBiodiversityImpacts.biodiversityImpactsData.envPressure);
            setAreaImpactedType(openBiodiversityImpacts.biodiversityImpactsData.areaImpacted);
            setDurationType(openBiodiversityImpacts.biodiversityImpactsData.duration);
            setReversibilityOrIrreversibilityType(openBiodiversityImpacts.biodiversityImpactsData.reversibilityOrIrreversibility);
            setImpactedEcoServicesGroupType(openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServicesGroup);
            setImpactedEcoServicesType(openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServices);

            if (openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServicesGroup === "Provisional") {
                setImpactedEcoServicesData(provisionalData);
            } else if (openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServicesGroup === "Regulative") {
                setImpactedEcoServicesData(regulativeData);
            } else if (openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServicesGroup === "Supporting") {
                setImpactedEcoServicesData(supportingData);
            } else if (openBiodiversityImpacts.biodiversityImpactsData.impactedEcoServicesGroup === "Cultural") {
                setImpactedEcoServicesData(culturalData);
            };
            reset(openBiodiversityImpacts.biodiversityImpactsData);
        };
    }, [openBiodiversityImpacts.action === true]);

    const handleClose = () => {
        setOpenBiodiversityImpacts({ action: false, biodiversityImpactsData: null });
        setEnvInputOutput("");
        setEnvPressureType("");
        setAreaImpactedType("");
        setImpactedEcoServicesData([]);
        setDurationType("");
        setReversibilityOrIrreversibilityType("");
        setImpactedEcoServicesGroupType("");
        setImpactedEcoServicesType("");
    };
    const changeEnvInputOutput = (event) => {
        setEnvInputOutput(event.target.value);
    };
    const changeEnvPressure = (event) => {
        setEnvPressureType(event.target.value);
    };
    const changeAreaImpacted = (event) => {
        setAreaImpactedType(event.target.value);
    };
    const changeDuration = (event) => {
        setDurationType(event.target.value);
    };
    const changeReversibilityIrreversibility = (event) => {
        setReversibilityOrIrreversibilityType(event.target.value);
    };
    const changeImpactedEcoServicesGroup = (event) => {
        let impactedEcoServicesGroupData = event.target.value;
        setImpactedEcoServicesGroupType(impactedEcoServicesGroupData);

        if (impactedEcoServicesGroupData === "Provisional") {
            setImpactedEcoServicesData(provisionalData);
        } else if (impactedEcoServicesGroupData === "Regulative") {
            setImpactedEcoServicesData(regulativeData);
        } else if (impactedEcoServicesGroupData === "Supporting") {
            setImpactedEcoServicesData(supportingData);
        } else if (impactedEcoServicesGroupData === "Cultural") {
            setImpactedEcoServicesData(culturalData);
        }
    };
    const changeImpactedEcoServices = (event) => {
        setImpactedEcoServicesType(event.target.value);
    };
    const saveBiodiversityImpacts = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "envInputAndOutput": data.envInputAndOutput,
                "envPressure": data.envPressure,
                "impactedEcoServicesGroup": data.impactedEcoServicesGroup,
                "impactedEcoServices": data.impactedEcoServices,
                "areaImpacted": data.areaImpacted,
                "duration": data.duration,
                "reversibilityOrIrreversibility": data.reversibilityOrIrreversibility,
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateBiodiversityImpactsData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getBiodiversityImpactsAllData();
                    setEnvInputOutput("");
                    setEnvPressureType("");
                    setAreaImpactedType("");
                    setImpactedEcoServicesData([]);
                    setDurationType("");
                    setReversibilityOrIrreversibilityType("");
                    setImpactedEcoServicesGroupType("");
                    setImpactedEcoServicesType("");
                    setOpenBiodiversityImpacts({ action: false, biodiversityImpactsData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateBiodiversityImpacts = (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openBiodiversityImpacts.biodiversityImpactsData.refNo,
            "envInputAndOutput": data.envInputAndOutput,
            "envPressure": data.envPressure,
            "impactedEcoServicesGroup": data.impactedEcoServicesGroup,
            "impactedEcoServices": data.impactedEcoServices,
            "areaImpacted": data.areaImpacted,
            "duration": data.duration,
            "reversibilityOrIrreversibility": data.reversibilityOrIrreversibility,
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateBiodiversityImpactsData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getBiodiversityImpactsAllData();
                setEnvInputOutput("");
                setEnvPressureType("");
                setAreaImpactedType("");
                setImpactedEcoServicesData([]);
                setDurationType("");
                setReversibilityOrIrreversibilityType("");
                setImpactedEcoServicesGroupType("");
                setImpactedEcoServicesType("");
                setOpenBiodiversityImpacts({ action: false, biodiversityImpactsData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openBiodiversityImpacts.biodiversityImpactsData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getBiodiversityImpactsAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setEnvInputOutput("");
                setEnvPressureType("");
                setAreaImpactedType("");
                setImpactedEcoServicesData([]);
                setDurationType("");
                setReversibilityOrIrreversibilityType("");
                setImpactedEcoServicesGroupType("");
                setImpactedEcoServicesType("");
                setOpenBiodiversityImpacts({ action: false, biodiversityImpactsData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openBiodiversityImpacts.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openBiodiversityImpacts.biodiversityImpactsData ? "Edit Biodiversity Impacts" : "Add Biodiversity Impacts"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openBiodiversityImpacts.biodiversityImpactsData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Env Input & Output</material.InputLabel>
                                    <material.Select
                                        {...register("envInputAndOutput", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={envInputOutput}
                                        onChange={changeEnvInputOutput}
                                        label="Env Input & Output"
                                    >
                                        {envInputOutputData?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Env Pressure</material.InputLabel>
                                    <material.Select
                                        {...register("envPressure", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={envPressureType}
                                        onChange={changeEnvPressure}
                                        label="Env Pressure"
                                    >
                                        {envPressureData?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Impacted Eco Services Group</material.InputLabel>
                                    <material.Select
                                        {...register("impactedEcoServicesGroup", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={impactedEcoServicesGroupType}
                                        onChange={changeImpactedEcoServicesGroup}
                                        label="Impacted Eco Services Group"
                                    >
                                        <material.MenuItem value={"Provisional"}>Provisional</material.MenuItem>
                                        <material.MenuItem value={"Regulative"}>Regulative</material.MenuItem>
                                        <material.MenuItem value={"Supporting"}>Supporting</material.MenuItem>
                                        <material.MenuItem value={"Cultural"}>Cultural</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Impacted Eco Services</material.InputLabel>
                                    <material.Select
                                        {...register("impactedEcoServices", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={impactedEcoServicesType}
                                        onChange={changeImpactedEcoServices}
                                        label="Impacted Eco Services"
                                    >
                                        {impactedEcoServicesData?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Area Impacted</material.InputLabel>
                                    <material.Select
                                        {...register("areaImpacted", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={areaImpactedType}
                                        onChange={changeAreaImpacted}
                                        label="Area Impacted"
                                    >
                                        <material.MenuItem value={"Project Site"}>Project Site</material.MenuItem>
                                        <material.MenuItem value={"1 KM Radius Area"}>1 KM Radius Area</material.MenuItem>
                                        <material.MenuItem value={"5 KM Radius Area"}>5 KM Radius Area</material.MenuItem>
                                        <material.MenuItem value={"> 5 KM Radius Area"}>{"> 5 KM Radius Area"}</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Duration</material.InputLabel>
                                    <material.Select
                                        {...register("duration", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={durationType}
                                        onChange={changeDuration}
                                        label="Duration"
                                    >
                                        <material.MenuItem value={"Short Term"}>Short Term</material.MenuItem>
                                        <material.MenuItem value={"Medium Term"}>Medium Term</material.MenuItem>
                                        <material.MenuItem value={"Long Term"}>Long Term</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Reversibility or Irreversibility</material.InputLabel>
                                    <material.Select
                                        {...register("reversibilityOrIrreversibility", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={reversibilityOrIrreversibilityType}
                                        onChange={changeReversibilityIrreversibility}
                                        label="Reversibility or Irreversibility"
                                    >
                                        <material.MenuItem value={"Reversible"}>Reversible</material.MenuItem>
                                        <material.MenuItem value={"Irreversible"}>Irreversible</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openBiodiversityImpacts.biodiversityImpactsData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateBiodiversityImpacts)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openBiodiversityImpacts.biodiversityImpactsData.rejectionNote === null) || (openBiodiversityImpacts.biodiversityImpactsData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveBiodiversityImpacts)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddBiodiversityImpacts;
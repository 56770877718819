import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: ""
};

const treeViewIssueSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        treeViewIssueAction: (state = initialState, action) => {
            const treeViewState = {
                data: action.payload,
            };
            return treeViewState;
        },
    },
});

export const { treeViewIssueAction } = treeViewIssueSlice.actions;
export default treeViewIssueSlice.reducer;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateEmployeeHiringData } from '../../services/sub-issue-services/EmployeeHiringService';
import { checkCriteriaForYesTypeAnswer } from '../../shared/Shared'
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddEmployeeHiring(props) {
    const { openAddEmployeeHiring, setOpenAddEmployeeHiring, getAllEmployeeHiringData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [ageBasedDataChange, setAgeBasedDataChange] = useState("No");

    const [categoryOfWorker, setCategoryOfWorker] = useState('');
    const [selectCategory, setSelectCategory] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeMale = watch('male');
    const onChangeFemale = watch('female');
    const onChangeOther = watch('other');

    useEffect(() => {
        totalCalculation();
    }, [onChangeMale, onChangeFemale, onChangeOther]);

    const totalCalculation = () => {
        let val1 = getValues('male');
        let val2 = getValues('female');
        let val3 = getValues('other');
        if (val1 && val2 && val3) {
            let totalValue = Number(val1) + Number(val2) + Number(val3);
            setValue("total", totalValue);
        };
    };

    useEffect(() => {
        if (openAddEmployeeHiring.action && openAddEmployeeHiring.employeeHiringData) {
            setCategoryOfWorker(openAddEmployeeHiring.employeeHiringData.categoryOfWorker);
            setAgeBasedDataChange(openAddEmployeeHiring.employeeHiringData.isAgeBasedDataPresent ? "Yes" : "No");
            setSelectCategory(openAddEmployeeHiring.employeeHiringData.category)
            reset(openAddEmployeeHiring.employeeHiringData);
        }
    }, [openAddEmployeeHiring.action === true]);

    const categoryOfWorkerChange = (event) => {
        setCategoryOfWorker(event.target.value);
    }
    const handleClose = () => {
        reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
        setCategoryOfWorker("");
        setOpenAddEmployeeHiring({ action: false, employeeHiringData: null })
    };
    const handleChangeCategory = (event) => {
        setSelectCategory(event.target.value)
    }
    const postSaveUpdateEmployeeHiringData = (obj) => {
        saveOrUpdateEmployeeHiringData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllEmployeeHiringData();
                reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
                setCategoryOfWorker("");
                setOpenAddEmployeeHiring({ action: false, employeeHiringData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const saveEmployeeHiring = (data) => {
        let typeOfRecord = "New_Hiring_Turnover"
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "refNo": openAddEmployeeHiring.employeeHiringData ? openAddEmployeeHiring.employeeHiringData.refNo : "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "categoryOfWorker": data.categoryOfWorker,
                category: data.category,
                "male": Number(data.male),
                "female": Number(data.female),
                "other": Number(data.other),
                "total": Number(data.total),
                "maleUnder30": Number(data.maleUnder30),
                "male30To50": Number(data.male30To50),
                "maleAbove50": Number(data.maleAbove50),
                "femaleUnder30": Number(data.femaleUnder30),
                "female30To50": Number(data.female30To50),
                "femaleAbove50": Number(data.femaleAbove50),
                "otherUnder30": Number(data.otherUnder30),
                "other30To50": Number(data.other30To50),
                "otherAbove50": Number(data.otherAbove50),
                "vulnerable": Number(data.vulnerable),
                "isAgeBasedDataPresent": ageBasedDataChange === "Yes" ? true : false,
                typeOfRecord,
                toDate: payLoad?.toDate ? payLoad.toDate : data.toDate,
                fromDate: payLoad?.fromDate ? payLoad.fromDate : data.fromDate,
            };
            if (ageBasedDataChange === "No") {
                if (Number(data.total) >= Number(data.vulnerable)) {
                    postSaveUpdateEmployeeHiringData(obj);
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            } else if (ageBasedDataChange === "Yes") {
                if (checkCriteriaForYesTypeAnswer(data)) {
                    if (Number(data.total) >= Number(data.vulnerable)) {
                        postSaveUpdateEmployeeHiringData(obj);
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                        })
                    }
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Sum of age based count not matching with total count",
                    })
                };
            };
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEmployeeHiring = (data) => {
        let typeOfRecord = "New_Hiring_Turnover"
        let obj = {
            "refNo": openAddEmployeeHiring.employeeHiringData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "categoryOfWorker": data.categoryOfWorker,
            category: data.category,
            "male": Number(data.male),
            "female": Number(data.female),
            "other": Number(data.other),
            "total": Number(data.total),
            "maleUnder30": Number(data.maleUnder30),
            "male30To50": Number(data.male30To50),
            "maleAbove50": Number(data.maleAbove50),
            "femaleUnder30": Number(data.femaleUnder30),
            "female30To50": Number(data.female30To50),
            "femaleAbove50": Number(data.femaleAbove50),
            "otherUnder30": Number(data.otherUnder30),
            "other30To50": Number(data.other30To50),
            "otherAbove50": Number(data.otherAbove50),
            "vulnerable": Number(data.vulnerable),
            "isAgeBasedDataPresent": ageBasedDataChange === "Yes" ? true : false,
            typeOfRecord,
            toDate: data.toDate,
            fromDate: data.fromDate,
        };
        if (ageBasedDataChange === "No") {
            if (Number(data.total) >= Number(data.vulnerable)) {
                postSaveUpdateEmployeeHiringData(obj);
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                })
            }
        } else if (ageBasedDataChange === "Yes") {
            if (checkCriteriaForYesTypeAnswer(data)) {
                if (Number(data.total) >= Number(data.vulnerable)) {
                    postSaveUpdateEmployeeHiringData(obj);
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Sum of age based count not matching with total count",
                })
            };
        };
    };

    const ageBasedData = (event) => {
        setAgeBasedDataChange(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddEmployeeHiring.employeeHiringData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllEmployeeHiringData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
                setCategoryOfWorker("");
                setOpenAddEmployeeHiring({ action: false, employeeHiringData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openAddEmployeeHiring.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {openAddEmployeeHiring.employeeHiringData ? "Edit Employees Hiring & Turnover" : "Add Employees Hiring & Turnover"}
                </material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <div className='row'>
                            <div className='col-12 d-flex flex-row align-items-center'>
                                <div className='me-2 fw-bold text-dark'>Is employee age based data present? :</div>
                                <material.FormControl>
                                    <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={ageBasedDataChange}
                                        onChange={ageBasedData}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </div>
                        </div>
                        <div className='my-3'>
                            {openAddEmployeeHiring.employeeHiringData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'>
                                    <material.Typography><h5 className='fw-bold headingText'>Employee & worker</h5></material.Typography>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.FormControl variant="standard" className='mt-3' fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label" >Employee & worker</material.InputLabel>
                                        <material.Select
                                            {...register("categoryOfWorker", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={categoryOfWorker}
                                            onChange={categoryOfWorkerChange}
                                        >
                                            <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                            <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                            <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                            <material.MenuItem value={"Worker other than permanent"}>Worker other than Permanent</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.FormControl variant="standard" className='mt-3' fullWidth required>
                                        <material.InputLabel id="demo-simple-select-standard-label" >Category</material.InputLabel>
                                        <material.Select
                                            {...register("category", { required: true, })}
                                            id="demo-simple-select-standard"
                                            value={selectCategory}
                                            onChange={handleChangeCategory}
                                        >
                                            <material.MenuItem value={"Turnover"}>Turnover</material.MenuItem>
                                            <material.MenuItem value={"New Hiring"}>New Hiring</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("male", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Male"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.male && errors.male.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("female", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Female"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.female && errors.female.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("other", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Other"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.other && errors.other.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("total", { required: true, })}
                                        required
                                        className='mt-3'
                                        label="Total"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("vulnerable", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        className='mt-3'
                                        label="Vulnerable"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.vulnerable && errors.vulnerable.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed.</p>
                                    )}
                                </div>
                            </div>
                        </material.Card>
                        {ageBasedDataChange === "Yes" ? (
                            <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                                <div className='row'>
                                    <div className='py-1'>
                                        <material.Typography><h5 className='fw-bold headingText'>Employee age based data</h5></material.Typography>
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("maleUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male under 30"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.maleUnder30 && errors.maleUnder30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("male30To50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male 30 to 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.male30To50 && errors.male30To50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("maleAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Male above 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.maleAbove50 && errors.maleAbove50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("femaleUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female under 30"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.femaleUnder30 && errors.femaleUnder30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("female30To50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female 30 to 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.female30To50 && errors.female30To50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("femaleAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            className='mt-3'
                                            label="Female above 50"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.femaleAbove50 && errors.femaleAbove50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed.</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("otherUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other Under 30"
                                            id="femaleUnder30"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.other30 && errors.other30.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("other30To50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other 30 to 50"
                                            id="other30To50"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.other30To50 && errors.other30To50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                        <material.TextField
                                            {...register("otherAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                            required
                                            margin="dense"
                                            label="Other Above 50"
                                            id="otherAbove50"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.otherAbove50 && errors.otherAbove50.type === "pattern" && (
                                            <p className="errorMsg text-danger">No decimal allowed</p>
                                        )}
                                    </div>
                                </div>
                            </material.Card>
                        ) : null}
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddEmployeeHiring.employeeHiringData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateEmployeeHiring)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddEmployeeHiring.employeeHiringData.rejectionNote === null) || (openAddEmployeeHiring.employeeHiringData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveEmployeeHiring)}
                            startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddEmployeeHiring;
import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { getUsersByAdmin } from '../services/UsermanagementService';
import { getUsersByCorporate } from '../services/UsermanagementService';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../shared/TableHeaderStyle';
import Snackbar from '../toaster-message/Snackbar';
import ResetPassword from './ResetPassword';

var userRole;

function ResetPasswordUserList(props) {

    const { openResetPasswordUserListDialog, setOpenResetPasswordUserListDialog } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [usersData, setUsersData] = useState([]);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState({ action: false, resetPasswordData: null })
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        userRole = userDetails.roles[i]
    };

    useEffect(() => {
        if (openResetPasswordUserListDialog.action) {
            getUserList()
        }
    }, [openResetPasswordUserListDialog.action === true]);

    const getUserList = async () => {
        if (userRole === "ROLE_ADMIN") {
            await getUsersByAdmin()
                .then((resp) => {
                    setUsersData(resp.data)
                    console.log(resp.data)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                })
        } else if (userRole === "ROLE_CORPORATE_EDITOR") {
            await getUsersByCorporate(userDetails.corporateRefNo)
                .then((resp) => {
                    setUsersData(resp.data)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                })
        };
    };

    const userResetPassword = (rowData) => {
        let resetPasswordData = {
            userName: rowData.username,
            otp: openResetPasswordUserListDialog.verifyOtp
        }
        setOpenResetPasswordDialog({ action: true, resetPasswordData: resetPasswordData })
    };

    const close = () => {
        setOpenResetPasswordUserListDialog({ action: false, verifyOtp: null })
    };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth open={openResetPasswordUserListDialog.action}>
                <material.DialogTitle className='d-flex justify-content-between fw-bold headingText'>
                    User List
                    {/* {openViewUsers.callFrom === "corporateList" ? (
                        <span>Corporate users for {openViewUsers.corporateData?.corporateName}</span>
                    ) : openViewUsers.callFrom === "siteList" ? (
                        <span>Facility users for {openViewUsers.siteData?.assetName}</span>
                    ) : null} */}
                    <span>
                        <material.Button variant="outlined" color='error' onClick={close}><material.CloseIcon titleAccess='Close' /></material.Button>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12'>
                            <material.TableContainer sx={{ maxHeight: 560 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>User Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Role</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {usersData.length ? usersData.map((row, i) => (
                                            <material.TableRow key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                onClick={() => userResetPassword(row)}
                                            >
                                                <material.TableCell>{row.name}</material.TableCell>
                                                <material.TableCell>{row.username}</material.TableCell>
                                                <material.TableCell>{row.email}</material.TableCell>
                                                {/* {userDetails.roles.includes("ROLE_ADMIN") ? (
                                                    <material.TableCell>{row.role.map((role, i) => (
                                                        <span key={i}>{role.name.split("_").slice(1).join(" ")}</span>
                                                    ))}</material.TableCell>
                                                ) : (
                                                    <material.TableCell>{row.role}</material.TableCell> 
                                                )} */}
                                                <material.TableCell>{row.role}</material.TableCell> 
                                            </material.TableRow>
                                        )) : null}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ResetPassword
                openResetPasswordDialog={openResetPasswordDialog}
                setOpenResetPasswordDialog={setOpenResetPasswordDialog}
                setOpenResetPasswordUserListDialog={setOpenResetPasswordUserListDialog}
            />
        </div >
    );
}

export default ResetPasswordUserList;
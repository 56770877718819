import React from 'react';
import { Chart } from "react-google-charts";

export const consumptionOptions = {
    title: "Water Consumption",
    titleTextStyle: {
        fontName: 'Arial', // Font family of the title
        fontSize: 18,      // Size of the title text
        bold: true,        // Make the title text bold
        italic: false      // Title text style is not italic
    },
    pieHole: 0.6,
    is3D: false,
    chartArea: { width: "75%" },
    legend: { position: "top" },
    colors: ["#87CEEB", "#F5F5F5"],
};

export const dischargeOptions = {
    title: "Water Discharge",
    titleTextStyle: {
        fontName: 'Arial', // Font family of the title
        fontSize: 18,      // Size of the title text
        bold: true,        // Make the title text bold
        italic: false      // Title text style is not italic
    },
    pieHole: 0.6,
    is3D: false,
    chartArea: { width: "75%" },
    legend: { position: "top" },
    colors: ["#77ef5e", "#F5F5F5"],
};

export const recycleOptions = {
    title: "Water Recycle",
    titleTextStyle: {
        fontName: 'Arial', // Font family of the title
        fontSize: 18,      // Size of the title text
        bold: true,        // Make the title text bold
        italic: false      // Title text style is not italic
    },
    pieHole: 0.6,
    is3D: false,
    chartArea: { width: "75%" },
    legend: { position: "top" },
    colors: ["#efc44d", "#F5F5F5"],
};

function WaterReportingChart(props) {

    const { chartData, fullScreen } = props;

    const recycleReuseValue = chartData?.corporateTotalRecycle ? (Number(chartData?.corporateTotalRecycle / chartData?.corporateTotalWithdrawal) * 100).toFixed(2) : 0;
    const recycleReuseMax = 100;
    const recycleChartData = [
        ["Water", "Percentage"],
        ["Water Recycle", Number(recycleReuseValue)],
        ["", recycleReuseMax - recycleReuseValue]
    ];

    const dischargeValue = chartData?.corporateTotalDischarge ? (Number(chartData?.corporateTotalDischarge / chartData?.corporateTotalWithdrawal) * 100).toFixed(2) : 0;
    const dischargeMax = 100;
    const dischargeChartData = [
        ["Water", "Percentage"],
        ["Water Discharge", Number(dischargeValue)],
        ["", dischargeMax - dischargeValue]
    ];

    const consumptionValue = chartData?.corporateTotalConsumption ? (Number(chartData?.corporateTotalConsumption / chartData?.corporateTotalWithdrawal) * 100).toFixed(2) : 0;
    const consumptionMax = 100;
    const consumptionChartData = [
        ["Water", "Percentage"],
        ["Water Consumption", Number(consumptionValue)],
        ["", consumptionMax - consumptionValue]
    ];

    return (
        <div className='row'>
            <span style={{ display: "flex", justifyContent: "center", fontName: "arial", fontSize: 18, fontWeight: "bold" }}>Water Consumption, Water Discharge & Water Recycle/Reuse</span>
            <div className="col-lg-4" style={{ position: 'relative' }}>
                <Chart
                    chartType="PieChart"
                    data={consumptionChartData}
                    options={consumptionOptions}
                    width={"100%"}
                    height={fullScreen ? "500px" : "350px"}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    {consumptionValue}% of <br /> Water <br /> Withdrawal
                </div>
            </div>
            <div className="col-lg-4" style={{ position: 'relative' }}>
                <Chart
                    chartType="PieChart"
                    data={dischargeChartData}
                    options={dischargeOptions}
                    width={"100%"}
                    height={fullScreen ? "500px" : "350px"}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    {dischargeValue}% of <br /> Water <br /> Withdrawal
                </div>
            </div>
            <div className="col-lg-4" style={{ position: 'relative' }}>
                <Chart
                    chartType="PieChart"
                    data={recycleChartData}
                    options={recycleOptions}
                    width={"100%"}
                    height={fullScreen ? "500px" : "350px"}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    {recycleReuseValue}% of <br /> Water <br /> Withdrawal
                </div>
            </div>
        </div>
    );
}

export default WaterReportingChart;
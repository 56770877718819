import React, { useState, useEffect } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import AddWaterConsumption from '../../../sub-issues-dialogs/AddWaterConsumption';
import { approveWaterConsumptionSupplierRecord, getWaterConsumptionSupplierData, sendForApprovalWaterConsumptionSupplierRecord } from '../../../services/sub-issue-services/WaterConsumptionSupplierService';
import moment from 'moment';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';



const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var usersRole;
var recordRefNo = [];

function WaterConsumption(props) {

    const location = useLocation();
    const treeViewData = location.state;

    const [openAddWaterConsumption, setOpenAddWaterConsumption] = useState({ action: false, waterConsumptionData: null });
    const [allWaterConsumptionData, setAllWaterConsumptionData] = useState([]);
    const [changeWaterConsumptionTab, setChangeWaterConsumptionTab] = useState("Water Consumption")
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        waterConsumptionSupplierData()
    }, [changeWaterConsumptionTab]);

    const waterConsumptionSupplierData = async () => {
        if (changeWaterConsumptionTab === "Water Consumption") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
            }
            await getWaterConsumptionSupplierData(object)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.waterConsumptionSupplierDataDtoList)
                    setAllWaterConsumptionData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (changeWaterConsumptionTab === "Water Consumption History") {
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
            }
            await getWaterConsumptionSupplierData(object)
                .then((resp) => {
                    setAllWaterConsumptionData(resp.data.waterConsumptionSupplierDataDtoList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };
    const addWaterConsumption = () => {
        setOpenAddWaterConsumption({ action: true, waterConsumptionData: null });
    };
    const editWaterConsumption = (rowData) => {
        setOpenAddWaterConsumption({ action: true, waterConsumptionData: rowData });
    };
    const handleChangeWaterConsumptionTab = (event, newValue) => {
        setChangeWaterConsumptionTab(newValue)
    };

    const sendApprovalWaterConsumption = async () => {
        if (recordRefNo.length) {
            await sendForApprovalWaterConsumptionSupplierRecord(recordRefNo)
                .then((resp) => {
                    waterConsumptionSupplierData();
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const approveWaterConsumption = async () => {
        if (recordRefNo.length) {
            let object = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo
            }
            await approveWaterConsumptionSupplierRecord(object)
                .then((resp) => {
                    waterConsumptionSupplierData();
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const rejectWaterConsumption = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };
    const handleCheckRecords = (value) => {
        setOpenAddWaterConsumption({ action: false, waterConsumptionData: null });
        if (recordRefNo.includes(value)) {
            recordRefNo.pop(value);
        }
        else {
            recordRefNo.push(value);
        }
    };
    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveWaterConsumptionSupplierRecord(obj)
            .then((resp) => {
                recordRefNo = [];
                waterConsumptionSupplierData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className='float-end'>
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='mt-3'>
                        <material.TabContext value={changeWaterConsumptionTab}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeWaterConsumptionTab} aria-label="lab API tabs example">
                                    <material.Tab className="text-capitalize" label="Water Consumption" value="Water Consumption" />
                                    <material.Tab className="text-capitalize" label="Water Consumption History" value="Water Consumption History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Water Consumption">
                                <div className="row mt-2">
                                    <div className="col-12 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <>
                                                {allWaterConsumptionData.length ? (
                                                    <span className='float-end'>
                                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} disabled={!applicable} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"}
                                                            startIcon={<material.DoneIcon />} onClick={approveWaterConsumption} >Approve</material.Button>
                                                        <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} disabled={!applicable} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"}
                                                            onClick={rejectWaterConsumption} startIcon={<material.PriorityHighIcon />}>Reject</material.Button>
                                                    </span>
                                                ) : null}
                                            </>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.SendIcon />} disabled={!applicable} onClick={sendApprovalWaterConsumption}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none" }} disabled={!applicable} startIcon={<material.AddIcon />} onClick={addWaterConsumption} >Add Water Consumption</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Withdrawal or Discharge</StyledTableCell>
                                                            <StyledTableCell>Source </StyledTableCell>
                                                            <StyledTableCell>Amount</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>{usersRole === "ROLE_FACILITY_APPROVER" ? null : "Approval Date"}</StyledTableCell>
                                                            <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <StyledTableCell>Rejection Note</StyledTableCell>
                                                            )}
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {allWaterConsumptionData?.length ? allWaterConsumptionData.map((item, ind) => (
                                                            <material.TableRow key={ind}
                                                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false)
                                                                    && applicable === true
                                                                ) ? () => editWaterConsumption(item) : null}>
                                                                <material.TableCell>{item.type}</material.TableCell>
                                                                <material.TableCell>{item.source}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell> {item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""} </material.TableCell>
                                                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => handleCheckRecords(item.refNo)} color="success" />
                                                                    ) : (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => handleCheckRecords(item.refNo)} color="success" />
                                                                    )}
                                                                </material.TableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                        <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                    )}
                                                                    </material.TableCell>
                                                                )}
                                                            </material.TableRow>
                                                        )) :
                                                            (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={7}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Water Consumption History">
                                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Withdrawal or Discharge</StyledTableCell>
                                                            <StyledTableCell>Source </StyledTableCell>
                                                            <StyledTableCell>Amount</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {allWaterConsumptionData?.length ? allWaterConsumptionData.map((item, ind) => (
                                                            <material.TableRow key={ind}>
                                                                <material.TableCell>{item.type}</material.TableCell>
                                                                <material.TableCell>{item.source}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell> {item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""} </material.TableCell>
                                                                <material.TableCell>
                                                                    {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) :
                                                            (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={6}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </div>
                </material.Box>
            </material.Box>
            <AddWaterConsumption
                openAddWaterConsumption={openAddWaterConsumption}
                setOpenAddWaterConsumption={setOpenAddWaterConsumption}
                waterConsumptionSupplierData={waterConsumptionSupplierData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                waterConsumptionSupplierData={waterConsumptionSupplierData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default WaterConsumption;
import React from 'react';
import { Bars } from 'react-loader-spinner';

function BarsLoader(props) {
    return (
        <div style={{ display: "flex", width: "100%", height: "400px", justifyContent: "center", alignItems: "center" }}>
            <Bars
                height="80"
                width="80"
                color="rgb(42, 32, 186)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
}

export default BarsLoader;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import AddBiodiversityValueOfSite from '../../../sub-issues-dialogs/biodiversity/AddBiodiversityValueOfSite';
import { approveBiodiversityValueOfSite, getBiodiversityValueOfSiteData, sendForApprovalBiodiversityValueOfSite } from '../../../services/sub-issue-services/biodiversity-services/BiodiversityValueOfSiteService';
import { useSelector } from 'react-redux';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };


function BiodiversityValueOfSite(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [biodiversityValueOfSite, setBiodiversityValueOfSite] = useState('Biodiversity Value of Site');
    const [biodiversityValueOfSiteTableData, setBiodiversityValueOfSiteTableData] = useState([]);
    const [addOpenBiodiversityValueOfSite, setAddOpenBiodiversityValueOfSite] = useState({ action: false, biodiversityValueOfSiteTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getBiodiversityValueOfSiteTableData()
    }, [biodiversityValueOfSite]);

    const getFormDateToDate = (data) => {
        getBiodiversityValueOfSiteTableData(data)
    };

    const getBiodiversityValueOfSiteTableData = async (filterDate) => {
        if (biodiversityValueOfSite === "Biodiversity Value of Site") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getBiodiversityValueOfSiteData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList);
                    setBiodiversityValueOfSiteTableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (biodiversityValueOfSite === "Biodiversity Value of Site History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getBiodiversityValueOfSiteData(payloadData)
                .then((resp) => {
                    setBiodiversityValueOfSiteTableData(resp.data.dataDtoList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const addBiodiversityValueOfSite = () => {
        setAddOpenBiodiversityValueOfSite({ action: true, biodiversityValueOfSiteTableData: null });
    };

    const editBiodiversityValueOfSite = (rowData) => {
        setAddOpenBiodiversityValueOfSite({ action: true, biodiversityValueOfSiteTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        }
        else {
            recordRefNos.push(value)
        }
    };

    const sendForApprovalBiodiversityValueOfSiteData = async () => {
        if (recordRefNos.length) {
            await sendForApprovalBiodiversityValueOfSite(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getBiodiversityValueOfSiteTableData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        }
    };

    const approveBiodiversityValueOfSiteData = async () => {
        // if (recordIds.length) {
        //     await approveBiodiversityValueOfSite("APPROVED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordIds)
        //         .then((resp) => {
        //             recordIds = [];
        //             getBiodiversityValueOfSiteTableData();
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "success",
        //                 "message": resp.data,
        //             })
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
        //             })
        //         })
        // }
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveBiodiversityValueOfSite(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getBiodiversityValueOfSiteTableData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const rejectBiodiversityValueOfSiteData = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordIds.length) {
            //     await approveBiodiversityValueOfSite("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordIds)
            //         .then((resp) => {
            //             recordIds = [];
            //             getBiodiversityValueOfSiteTableData();
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveBiodiversityValueOfSite(obj)
            .then((resp) => {
                recordRefNos = [];
                getBiodiversityValueOfSiteTableData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const handleChangeTabs = (event, newValue) => {
        setBiodiversityValueOfSite(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5">{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary"
                                        checked={applicable} onChange={applicableNotApplicable}
                                    />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={biodiversityValueOfSite}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab sx={{ textTransform: "none" }} label="Biodiversity Value of Site" value="Biodiversity Value of Site" />
                                    <material.Tab sx={{ textTransform: "none" }} label="Biodiversity Value of Site History" value="Biodiversity Value of Site History" />
                                    <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Biodiversity Value of Site">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-lg-7">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <span className="float-end">
                                                {biodiversityValueOfSiteTableData.length ? (
                                                    <>
                                                        <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveBiodiversityValueOfSiteData}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectBiodiversityValueOfSiteData}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                            </span>
                                        ) : (
                                            <span className="float-end">
                                                <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalBiodiversityValueOfSiteData}>Send for Approval</material.Button>
                                                <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addBiodiversityValueOfSite}>Add Biodiversity Value of Site</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Land Ownership</StyledTableCell>
                                                    <StyledTableCell>Sensitive Biodiversity Area</StyledTableCell>
                                                    <StyledTableCell>Category of PA/Conservation Importance Site</StyledTableCell>
                                                    <StyledTableCell>Position in Relation</StyledTableCell>
                                                    <StyledTableCell>Ecosystem Characterization</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                    )}
                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {biodiversityValueOfSiteTableData?.length ? biodiversityValueOfSiteTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                            () => editBiodiversityValueOfSite(item) : null}>
                                                        <material.TableCell>{item.landOwnership}</material.TableCell>
                                                        <material.TableCell>{item.sensitiveBiodiversityArea}</material.TableCell>
                                                        <material.TableCell>{item.categoryOfConservationImportanceSite}</material.TableCell>
                                                        <material.TableCell>{item.positionInRelation}</material.TableCell>
                                                        <material.TableCell>{item.ecosystemCharacterization}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )}
                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Biodiversity Value of Site History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Land Ownership</StyledTableCell>
                                                    <StyledTableCell>Sensitive Biodiversity Area</StyledTableCell>
                                                    <StyledTableCell>Category of PA/Conservation Importance Site</StyledTableCell>
                                                    <StyledTableCell>Position in Relation</StyledTableCell>
                                                    <StyledTableCell>Ecosystem Characterization</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {biodiversityValueOfSiteTableData?.length ? biodiversityValueOfSiteTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}>
                                                        <material.TableCell>{item.landOwnership}</material.TableCell>
                                                        <material.TableCell>{item.sensitiveBiodiversityArea}</material.TableCell>
                                                        <material.TableCell>{item.categoryOfConservationImportanceSite}</material.TableCell>
                                                        <material.TableCell>{item.positionInRelation}</material.TableCell>
                                                        <material.TableCell>{item.ecosystemCharacterization}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) :
                                                    (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={10}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )
                                                }
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure">
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History">
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddBiodiversityValueOfSite
                addOpenBiodiversityValueOfSite={addOpenBiodiversityValueOfSite}
                setAddOpenBiodiversityValueOfSite={setAddOpenBiodiversityValueOfSite}
                getBiodiversityValueOfSiteTableData={getBiodiversityValueOfSiteTableData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getBiodiversityValueOfSiteTableData={getBiodiversityValueOfSiteTableData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default BiodiversityValueOfSite;
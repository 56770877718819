import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateGhgScopeData } from '../../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddGhgScope1(props) {

    const { openAddGhgScope1, setOpenAddGhgScope1, getGhgScope1AllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [activity, setActivity] = React.useState('');
    const [unit, setUnit] = React.useState('');
    const [sourceType, setSourceType] = React.useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddGhgScope1.action && openAddGhgScope1.ghgScope1Data) {
            setActivity(openAddGhgScope1.ghgScope1Data.activity);
            setUnit(openAddGhgScope1.ghgScope1Data.unitCo2);
            setSourceType(openAddGhgScope1.ghgScope1Data.typeOfSource);
            reset(openAddGhgScope1.ghgScope1Data)
        }
    }, [openAddGhgScope1.action === true]);


    const handleChange = (event) => {
        setActivity(event.target.value);
    };
    const handleUnitChange = (event) => {
        setUnit(event.target.value);
    };
    const handleSourceChange = (event) => {
        setSourceType(event.target.value);
    }
    const handleClose = () => {
        reset({ amount: "" });
        setActivity("");
        setUnit("");
        setSourceType("");
        setOpenAddGhgScope1({ action: false, ghgScope1Data: null });
    }
    const saveGhgScope1 = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "typeOfSource": data.typeOfSource,
                "activity": data.activity,
                "amount": parseFloat(data.amount),
                "unitCo2": data.unitCo2,
                "applicable": true,
                "refNo": "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateGhgScopeData(object, "GHG_SCOPE1")
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope1AllData();
                    reset({ amount: "" });
                    setActivity("");
                    setUnit("");
                    setSourceType("");
                    setOpenAddGhgScope1({ action: false, ghgScope1Data: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateGhgScope1 = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "typeOfSource": data.typeOfSource,
            "activity": data.activity,
            "amount": parseFloat(data.amount),
            "unitCo2": data.unitCo2,
            "applicable": true,
            "refNo": openAddGhgScope1.ghgScope1Data.refNo,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateGhgScopeData(object, "GHG_SCOPE1")
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getGhgScope1AllData();
                reset({ amount: "" });
                setActivity("");
                setUnit("");
                setSourceType("");
                setOpenAddGhgScope1({ action: false, ghgScope1Data: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddGhgScope1.ghgScope1Data.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getGhgScope1AllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setActivity("");
                setUnit("");
                setSourceType("");
                setOpenAddGhgScope1({ action: false, ghgScope1Data: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddGhgScope1.action} maxWidth="md" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {openAddGhgScope1.ghgScope1Data ? "Edit GHG Scope 1" : "Add GHG Scope 1"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openAddGhgScope1.ghgScope1Data ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>GHG Scope1 Data</material.Typography>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" fullWidth className='mt-3'>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfSource", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={sourceType}
                                        onChange={handleSourceChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Stationary Combustion"}>Stationary Combustion</material.MenuItem>
                                        <material.MenuItem value={"Physical or Chemical Processing"}>Physical or Chemical Processing</material.MenuItem>
                                        <material.MenuItem value={"Transportation of Materials,Products,Waste,Workers,and Passengers"}>Transportation of Materials,Products,Waste,Workers,and Passengers</material.MenuItem>
                                        <material.MenuItem value={"Fugitive"}>Fugitive</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" className='mt-3' fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Activity</material.InputLabel>
                                    <material.Select
                                        {...register("activity", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={activity}
                                        onChange={handleChange}
                                        label="Activity type"
                                    >
                                        <material.MenuItem value={"Electricity"}>Electricity</material.MenuItem>
                                        <material.MenuItem value={"Heating"}>Heating</material.MenuItem>
                                        <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                        <material.MenuItem value={"Steam"}>Steam</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    className='mt-3'
                                    label="Amount"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Amount field allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" className='mt-3' fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit CO2-e</material.InputLabel>
                                    <material.Select
                                        {...register("unitCo2", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={handleUnitChange}
                                        label="Unit type"
                                    >
                                        <material.MenuItem value={"kg"}>kg</material.MenuItem>
                                        <material.MenuItem value={"metric tons"}>metric tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddGhgScope1.ghgScope1Data ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateGhgScope1)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddGhgScope1.ghgScope1Data.rejectionNote === null) || (openAddGhgScope1.ghgScope1Data.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveGhgScope1)}
                            startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddGhgScope1;
import React from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <material.Slide direction="up" ref={ref} {...props} />;
});

function MultipleTable(props) {

    const { openTableWidgetDialog, setOpenTableWidgetDialog, year } = props;

    const handleClose = () => {
        setOpenTableWidgetDialog({ action: false, tableData: [] });
    };

    return (
        <div>
            <material.Dialog open={openTableWidgetDialog.action} maxWidth="xl" fullWidth TransitionComponent={Transition}>
                <material.DialogTitle>
                    Reporting Period : {year} - {Number(year) + 1}
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {openTableWidgetDialog.tableData?.map((table, i) => (
                        <material.Paper elevation={3} sx={{ p: 1, mt: 1, boxShadow: "0px 0px 15px 5px rgba(72, 198, 223, 0.3)" }} key={i}>
                            <material.TableContainer sx={{ maxHeight: "70vh" }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead>
                                        <material.TableRow>
                                            <material.TableCell sx={{ mb: 3, fontSize: 18 }} colSpan="6">{table.tableName}</material.TableCell></material.TableRow>
                                        {table.columnName?.map((column, cInd) =>
                                            <StyledTableCell size='small' key={cInd}>{column.headerName}</StyledTableCell>
                                        )}
                                    </material.TableHead>
                                    <material.TableBody>
                                        {table.tableData?.length ? table.tableData.map((item, rowIndex) => (
                                            <material.TableRow key={rowIndex}>
                                                {table.columnName.map((column, colIndex) => (

                                                    <material.TableCell key={colIndex}>
                                                        {item[column.key]}</material.TableCell>
                                                ))}
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={12}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </material.Paper>
                    ))}
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default MultipleTable;
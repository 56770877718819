import React, { useEffect, useState } from "react";
import { material } from "../../../library/Material";
import SwipeDrawer from "../../drawer/SwipeDrawer";
import { useLocation } from "react-router-dom";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import { useSelector } from "react-redux";
import moment from "moment";
import ApplicableNotApplicableWarning from "../../../dialogs/ApplicableNotApplicableWarning";
import Snackbar from "../../../toaster-message/Snackbar";
import AddWaterDischarge from "../../../sub-issues-dialogs/water-sub-issue-dilog/AddWaterDischarge";
import { approveWaterDischargeRecord, getWaterDischargeData, sendForApprovalWaterDischargeRecord } from "../../../services/sub-issue-services/WaterDischargeService";
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from "../../../shared/Filter";
import SpecificDisclosureHistory from "../../specific-disclosure/SpecificDisclosureHistory";
import SpecificDisclosure from "../../specific-disclosure/SpecificDisclosure";
import RejectionNote from "../../../dialogs/RejectionNote";
import DateFilter from "../../../shared/DateFilter";

var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function WaterDischarge(props) {
  const location = useLocation();
  const treeViewData = location?.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i];
  };

  const [waterDischargeTab, setWaterDischargeTab] = useState("Water Discharge");
  const [openWaterDischarge, setOpenWaterDischarge] = useState({ action: false, waterDischargeAllData: null });
  const [waterDischargeAllTableData, setWaterDischargeAllTableData] = useState([]);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [applicable, setApplicable] = useState(true);
  useEffect(() => {
    getWaterDischargeAllData();
  }, [waterDischargeTab]);

  const getWaterDischargeAllData = async (filterDate) => {
    if (waterDischargeTab === "Water Discharge") {
      let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
      let approveHistory = false;
      let object = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate
      }
      await getWaterDischargeData(object)
        .then((resp) => {
          let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dischargeDataList)
          setWaterDischargeAllTableData(filterData);
          setApplicable(resp.data.applicable);
          setApplicableRefNo(resp.data.applicableRefNo);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    } else if (waterDischargeTab === "Water Discharge History") {
      let forApproval = true;
      let approveHistory = true;
      let object = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: filterDate?.fromDate,
        toDate: filterDate?.toDate
      }
      await getWaterDischargeData(object)
        .then((resp) => {
          setWaterDischargeAllTableData(resp.data.dischargeDataList);
          setApplicable(resp.data.applicable);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    }
  };
  const handleChangeWaterDischargeTab = (event, newValue) => {
    setWaterDischargeTab(newValue);
  };
  const applicableWaterDischarge = () => {
    setOpenWarningDialog(true);
  };
  const addWaterDischarge = () => {
    setOpenWaterDischarge({ action: true, waterDischargeAllData: null, });
  };
  const editWaterDischarge = (rowData) => {
    setOpenWaterDischarge({ action: true, waterDischargeAllData: rowData, });
  };
  const handleCheckWaterDischargeRecords = (value) => {
    setOpenWaterDischarge({ action: false, waterDischargeAllData: null, });
    if (recordRefNo.includes(value)) {
      // recordRefNo.pop(value);
      recordRefNo = recordRefNo.filter(item => item !== value);
    }
    else {
      recordRefNo.push(value);
    }
  };
  const sendApprovalWaterDischarge = async () => {
    if (recordRefNo.length) {
      await sendForApprovalWaterDischargeRecord(recordRefNo)
        .then((resp) => {
          getWaterDischargeAllData();
          recordRefNo = [];
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    }
  };
  const approveWaterDischarge = async () => {
    if (recordRefNo.length) {
      let object = {
        "rejectionNote": "",
        "corporateRefNo": userDetails.corporateRefNo,
        "facilityRefNo": userDetails.facilityRefNo,
        "subIssueId": treeViewData.id,
        "subIssueName": treeViewData.subIssue,
        "rejectedBy": userDetails.username,
        "status": "APPROVED",
        "recordRefNo": recordRefNo
      }
      await approveWaterDischargeRecord(object)
        .then((resp) => {
          getWaterDischargeAllData();
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    }
  };
  const rejectWaterDischarge = async () => {
    if (recordRefNo.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    }
  };
  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };

  const getRejectionNoteData = async (noteData) => {
    let obj = {
      "rejectionNote": noteData.rejectionNote,
      "corporateRefNo": userDetails.corporateRefNo,
      "facilityRefNo": userDetails.facilityRefNo,
      "subIssueId": treeViewData.id,
      "subIssueName": treeViewData.subIssue,
      "rejectedBy": userDetails.username,
      "status": "REJECTED",
      "recordRefNo": recordRefNo
    }
    await approveWaterDischargeRecord(obj)
      .then((resp) => {
        recordRefNo = [];
        getWaterDischargeAllData();
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        };
      })
  };

  const getFormDateToDate = (data) => {
    getWaterDischargeAllData(data);
  }
  return (
    <>
      <material.Box sx={{ display: "flex" }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
          <div className="row">
            <span className="col-6"> <material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue} </material.Typography> </span>
            <div className="col-6">
              {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                <span className="float-end">
                  <material.Stack direction="row" spacing={1} alignItems="center">
                    <material.Typography>Not applicable</material.Typography>
                    <material.Switch {...label} checked={applicable} color="secondary" onClick={applicableWaterDischarge} />
                    <material.Typography>Applicable</material.Typography>
                  </material.Stack>
                </span>
              )}
            </div>
          </div>
          <div>
            <material.Box sx={{ width: "100%", typography: "body1" }}>
              <material.TabContext value={waterDischargeTab}>
                <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <material.TabList onChange={handleChangeWaterDischargeTab} aria-label="lab API tabs example">
                    <material.Tab className="text-capitalize" label="Water Discharge" value="Water Discharge" />
                    <material.Tab className="text-capitalize" label="Water Discharge History" value="Water Discharge History" />
                    <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                    <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                  </material.TabList>
                </material.Box>
                <material.TabPanel value="Water Discharge">
                  <div className="row mt-2">
                    <div className="col-6">
                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                        <DateFilter
                          getFormDateToDate={getFormDateToDate}
                        />
                      )}
                    </div>
                    <div className="col-6 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                      {usersRole === "ROLE_FACILITY_APPROVER" ? (
                        <>
                          {waterDischargeAllTableData?.length ? (
                            <span className='float-end'>
                              <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} disabled={!applicable || !recordRefNo.length} startIcon={<material.DoneIcon />} onClick={approveWaterDischarge} >Approve</material.Button>
                              <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNo.length} onClick={rejectWaterDischarge} startIcon={<material.PriorityHighIcon />}>Reject</material.Button>
                            </span>
                          ) : null}
                        </>
                      ) : (
                        <span className='float-end'>
                          <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.SendIcon />} disabled={!applicable} onClick={sendApprovalWaterDischarge}>Send for Approval</material.Button>
                          <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} disabled={!applicable} startIcon={<material.AddIcon />} onClick={addWaterDischarge} >Add Water Discharge</material.Button>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-3 py-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Recycle/Reuse KLD</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Output Source</StyledTableCell>
                                    <StyledTableCell>Level of Treatment</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Quantity Reused / Recycled</StyledTableCell>
                                    <StyledTableCell>Purpose of Reuse / Recycle</StyledTableCell>
                                    <StyledTableCell>Final Discharge Quantity</StyledTableCell>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>From Date</StyledTableCell>
                                    <StyledTableCell>To Date</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Rejection Note</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((rowData, i) => (
                                    <material.TableRow key={i}
                                      sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                      onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true
                                      ) ? () => editWaterDischarge(rowData) : null}>
                                      <material.TableCell>{rowData.outputSource}</material.TableCell>
                                      <material.TableCell>{rowData.levelOfTreatment}</material.TableCell>
                                      <material.TableCell>{(rowData.category === null || rowData.category === "") ? "N/A" : rowData.category}</material.TableCell>
                                      <material.TableCell>{(rowData.quantityReusedOrRecycled === null || rowData.quantityReusedOrRecycled === "") ? "N/A" : rowData.quantityReusedOrRecycled}</material.TableCell>
                                      <material.TableCell>{(rowData.purposeOfReuseOrRecycle === null || rowData.purposeOfReuseOrRecycle === "") ? "N/A" : rowData.purposeOfReuseOrRecycle}</material.TableCell>
                                      <material.TableCell>{rowData.finalDischarge}</material.TableCell>
                                      <material.TableCell>{rowData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                      <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                          <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckWaterDischargeRecords(rowData.refNo)} color="success" />
                                        ) : (
                                          <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckWaterDischargeRecords(rowData.refNo)} color="success" />
                                        )}
                                      </material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                          <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                        )}
                                        </material.TableCell>
                                      )}
                                    </material.TableRow>
                                  )) :
                                    (
                                      <material.TableRow >
                                        <material.TableCell colSpan={12}>
                                          <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                      </material.TableRow>
                                    )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Effluent Destination</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>Discharge Location (Latitude)</StyledTableCell>
                                    <StyledTableCell>Discharge Location (Longitude)</StyledTableCell>
                                    <StyledTableCell>Discharge Destination Type</StyledTableCell>
                                    <StyledTableCell>Discharge Destination</StyledTableCell>
                                    <StyledTableCell>Destination Category</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((pollutionData, e) =>
                                    <material.TableRow key={e}>
                                      <material.TableCell>{pollutionData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeLocationLat}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeLocationLong}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeDestinationType}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeDestination}</material.TableCell>
                                      <material.TableCell>{pollutionData.destinationCategory} </material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{pollutionData.approvedDate ? moment(pollutionData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                    </material.TableRow>
                                  ) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={8}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Quality</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>Pollutant Suite</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter Other (Specify)</StyledTableCell>
                                    <StyledTableCell>Pollutant Value</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((pollutionData, e) =>
                                    <material.TableRow key={e}>
                                      <material.TableCell>{pollutionData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantSuit === null || pollutionData.qualityPollutantSuit === "") ? "N/A" : pollutionData.qualityPollutantSuit}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantParameter === null || pollutionData.qualityPollutantParameter === "") ? "N/A" : pollutionData.qualityPollutantParameter}</material.TableCell>
                                      <material.TableCell>{(pollutionData.otherSpecifyDischargePollutant === null || pollutionData.otherSpecifyDischargePollutant === "") ? "N/A" : pollutionData.otherSpecifyDischargePollutant}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantValue === null || pollutionData.qualityPollutantValue === "") ? "N/A" : pollutionData.qualityPollutantValue}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityUnit === null || pollutionData.qualityUnit === "") ? "N/A" : pollutionData.qualityUnit}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{pollutionData.approvedDate ? moment(pollutionData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                    </material.TableRow>
                                  ) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={7}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Receptor Quality</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Destination</StyledTableCell>
                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                    <StyledTableCell>Pollutant Suite</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter Other (Specify)</StyledTableCell>
                                    <StyledTableCell>Pollutant Value</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                      <StyledTableCell>Approval Date</StyledTableCell>
                                    )}
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((rowData, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{rowData.dischargeDestination}</material.TableCell>
                                      <material.TableCell>{(rowData.monitoringLocationLat === null || rowData.monitoringLocationLat === "") ? "N/A" : rowData.monitoringLocationLat}</material.TableCell>
                                      <material.TableCell>{(rowData.monitoringLocationLong === null || rowData.monitoringLocationLong === "") ? "N/A" : rowData.monitoringLocationLong}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantSuit === null || rowData.receptorQualityPollutantSuit === "") ? "N/A" : rowData.receptorQualityPollutantSuit}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantParameter === null || rowData.receptorQualityPollutantParameter === "") ? "N/A" : rowData.receptorQualityPollutantParameter}</material.TableCell>
                                      <material.TableCell>{(rowData.otherSpecifyReceptorPollutant === null || rowData.otherSpecifyReceptorPollutant === "") ? "N/A" : rowData.otherSpecifyReceptorPollutant}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantValue === null || rowData.receptorQualityPollutantValue === "") ? "N/A" : rowData.receptorQualityPollutantValue}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityUnit === null || rowData.receptorQualityUnit === "") ? "N/A" : rowData.receptorQualityUnit}</material.TableCell>
                                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                        <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      )}
                                    </material.TableRow>
                                  )) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={9}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                </material.TabPanel>
                <material.TabPanel value="Water Discharge History">
                  <div className="row">
                    <div className="col-8">
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    </div>
                  </div>
                  <div className="mt-2 py-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Recycle/Reuse KLD</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Output Source</StyledTableCell>
                                    <StyledTableCell>Level of Treatment</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Quantity Reused / Recycled</StyledTableCell>
                                    <StyledTableCell>Purpose of Reuse / Recycle</StyledTableCell>
                                    <StyledTableCell>Final Discharge Quantity</StyledTableCell>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>From Date</StyledTableCell>
                                    <StyledTableCell>To Date</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((rowData, i) => (
                                    <material.TableRow key={i}>
                                      <material.TableCell>{rowData.outputSource}</material.TableCell>
                                      <material.TableCell>{rowData.levelOfTreatment}</material.TableCell>
                                      <material.TableCell>{(rowData.category === null || rowData.category === "") ? "N/A" : rowData.category}</material.TableCell>
                                      <material.TableCell>{(rowData.quantityReusedOrRecycled === null || rowData.quantityReusedOrRecycled === "") ? "N/A" : rowData.quantityReusedOrRecycled}</material.TableCell>
                                      <material.TableCell>{(rowData.purposeOfReuseOrRecycle === null || rowData.purposeOfReuseOrRecycle === "") ? "N/A" : rowData.purposeOfReuseOrRecycle}</material.TableCell>
                                      <material.TableCell>{rowData.finalDischarge}</material.TableCell>
                                      <material.TableCell>{rowData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>
                                        {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </material.TableCell>
                                    </material.TableRow>
                                  )) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={11}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Effluent Destination</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>Discharge Location (Latitude)</StyledTableCell>
                                    <StyledTableCell>Discharge Location (Longitude)</StyledTableCell>
                                    <StyledTableCell>Discharge Destination Type</StyledTableCell>
                                    <StyledTableCell>Discharge Destination</StyledTableCell>
                                    <StyledTableCell>Destination Category</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((pollutionData, e) =>
                                    <material.TableRow key={e}>
                                      <material.TableCell>{pollutionData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeLocationLat}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeLocationLong}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeDestinationType}</material.TableCell>
                                      <material.TableCell>{pollutionData.dischargeDestination}</material.TableCell>
                                      <material.TableCell>{pollutionData.destinationCategory} </material.TableCell>
                                      <material.TableCell>{pollutionData.approvedDate ? moment(pollutionData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <StyledTableCell>
                                        {pollutionData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </StyledTableCell>
                                    </material.TableRow>
                                  ) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={8}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Quality</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Point</StyledTableCell>
                                    <StyledTableCell>Pollutant Suite</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter Other (Specify)</StyledTableCell>
                                    <StyledTableCell>Pollutant Value</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((pollutionData, e) =>
                                    <material.TableRow key={e}>
                                      <material.TableCell>{pollutionData.dischargePoint}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantSuit === null || pollutionData.qualityPollutantSuit === "") ? "N/A" : pollutionData.qualityPollutantSuit}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantParameter === null || pollutionData.qualityPollutantParameter === "") ? "N/A" : pollutionData.qualityPollutantParameter}</material.TableCell>
                                      <material.TableCell>{(pollutionData.otherSpecifyDischargePollutant === null || pollutionData.otherSpecifyDischargePollutant === "") ? "N/A" : pollutionData.otherSpecifyDischargePollutant}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityPollutantValue === null || pollutionData.qualityPollutantValue === "") ? "N/A" : pollutionData.qualityPollutantValue}</material.TableCell>
                                      <material.TableCell>{(pollutionData.qualityUnit === null || pollutionData.qualityUnit === "") ? "N/A" : pollutionData.qualityUnit}</material.TableCell>
                                      <material.TableCell>{pollutionData.approvedDate ? moment(pollutionData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <StyledTableCell>
                                        {pollutionData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </StyledTableCell>
                                    </material.TableRow>
                                  ) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={7}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                  <div className="mt-2">
                    <material.Accordion>
                      <material.AccordionSummary expandIcon={<material.ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <material.Typography sx={{ fontSize: 15, fontWeight: "bold" }} className='headingText'>Water Discharge Receptor Quality</material.Typography>
                      </material.AccordionSummary>
                      <material.AccordionDetails>
                        <div className="row mt-2">
                          <div className="col-12">
                            <material.TableContainer>
                              <material.Table>
                                <material.TableHead>
                                  <material.TableRow>
                                    <StyledTableCell>Discharge Destination</StyledTableCell>
                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                    <StyledTableCell>Pollutant Suite</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                    <StyledTableCell>Pollutant Parameter Other (Specify)</StyledTableCell>
                                    <StyledTableCell>Pollutant Value</StyledTableCell>
                                    <StyledTableCell>Unit</StyledTableCell>
                                    <StyledTableCell>Approval Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                  </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                  {waterDischargeAllTableData?.length ? waterDischargeAllTableData.map((rowData, ind) => (
                                    <material.TableRow key={ind}>
                                      <material.TableCell>{rowData.dischargeDestination}</material.TableCell>
                                      <material.TableCell>{(rowData.monitoringLocationLat === null || rowData.monitoringLocationLat === "") ? "N/A" : rowData.monitoringLocationLat}</material.TableCell>
                                      <material.TableCell>{(rowData.monitoringLocationLong === null || rowData.monitoringLocationLong === "") ? "N/A" : rowData.monitoringLocationLong}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantSuit === null || rowData.receptorQualityPollutantSuit === "") ? "N/A" : rowData.receptorQualityPollutantSuit}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantParameter === null || rowData.receptorQualityPollutantParameter === "") ? "N/A" : rowData.receptorQualityPollutantParameter}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantParameter === null || rowData.receptorQualityPollutantParameter === "") ? "N/A" : rowData.receptorQualityPollutantParameter}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityPollutantValue === null || rowData.receptorQualityPollutantValue === "") ? "N/A" : rowData.receptorQualityPollutantValue}</material.TableCell>
                                      <material.TableCell>{(rowData.receptorQualityUnit === null || rowData.receptorQualityUnit === "") ? "N/A" : rowData.receptorQualityUnit}</material.TableCell>
                                      <material.TableCell> {rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                      <material.TableCell>
                                        {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                          APPROVED
                                        </span> : null}
                                      </material.TableCell>
                                    </material.TableRow>
                                  )) : (
                                    <material.TableRow >
                                      <material.TableCell colSpan={9}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                      </material.TableCell>
                                    </material.TableRow>
                                  )}
                                </material.TableBody>
                              </material.Table>
                            </material.TableContainer>
                          </div>
                        </div>
                      </material.AccordionDetails>
                    </material.Accordion>
                  </div>
                </material.TabPanel>
                <material.TabPanel value="Specific Disclosure">
                  <SpecificDisclosure
                    treeViewData={treeViewData}
                  />
                </material.TabPanel>
                <material.TabPanel value="Specific Disclosure History">
                  <SpecificDisclosureHistory
                    treeViewData={treeViewData}
                  />
                </material.TabPanel>
              </material.TabContext>
            </material.Box>
          </div>
        </material.Box>
      </material.Box>
      <AddWaterDischarge
        openWaterDischarge={openWaterDischarge}
        setOpenWaterDischarge={setOpenWaterDischarge}
        getWaterDischargeAllData={getWaterDischargeAllData}
        treeViewData={treeViewData}
      />
      <ApplicableNotApplicableWarning
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getWaterDischargeAllData={getWaterDischargeAllData}
        applicable={applicable}
        treeViewData={treeViewData}
        applicableRefNo={applicableRefNo}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </>
  );
}

export default WaterDischarge;

import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateChildAndForcedLabourData = async (ChildAndForcedLabourData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/child-and-forced-labour/save-or-update`, ChildAndForcedLabourData)
};

export const getChildAndForcedLabourData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/child-and-forced-labour/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalChildAndForcedLabour = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/child-and-forced-labour/send-for-approval`, recordRefNo)
};

export const approveChildAndForcedLabour = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/child-and-forced-labour/approve`, payloadData)
};
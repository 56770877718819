import React, { useState, useEffect } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import ColumnChart from '../../../shared/reporting/ColumnChart';
import { MoreOptionMenu } from '../Dashboard';
import LineChart from '../../../shared/reporting/LineChart';
import PieChart from '../../../shared/reporting/PieChart';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import RecyclingPlant from '../../../assets/recycle.png';
import StockDown from '../../../assets/trending.png';
import FilterImage from '../../../assets/filter.png';
import GlobalFilter from '../GlobalFilter';
import { getMaterialReclaimedData, getMaterialRecycledData, getMaterialReportData } from '../../../services/reporting-services/MaterialService';
import { tableHeaderContent } from '../../../shared/reporting/TableHeader';
import BarsLoader from '../../../shared/loader/BarsLoader';
import Snackbar from '../../../toaster-message/Snackbar';
import { percentageCalculation, sum } from '../../../shared/Shared';


const yearData = dayjs(new Date());
var reclaimedPercentage = 0;
var recyclePercentage = 0;

function Material(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [isBarsLoader, setIsBarsLoader] = useState(true);
    const [value, setValue] = React.useState(dayjs(new Date()));
    const [openFilterDialog, setOpenFilterDialog] = useState({ action: false, userData: null });
    const [materialChartData, setMaterialChartData] = useState([]);

    const year = localStorage.getItem("year");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pieChartDataSource, setPieChartDataSource] = useState(null);
    const [columnChartData, setColumnChartData] = useState(null);
    const [materialTableData, setMaterialTableData] = useState([]);
    const [recycleAndReclaimedPercentage, setRecycleAndReclaimedPercentage] = useState(null);
    // const [reclaimedPercentage, setReclaimedPercentage] = useState(0);
    // const [recyclePercentage, setRecyclePercentage] = useState(0);

    const options = {
        title: "Material Used",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#00551F", "#541F00"],
        hAxis: {
            title: 'Types of Source',
        },
        vAxis: {
            title: 'Amount (MT)',
        },
        legend: {
            position: "top",
            alignment: 'end'
        },
        chartArea: { width: "80%" },
    };

    const pieChartOptions = {
        title: "Material Used Percentage",
        titleTextStyle: {
            fontName: 'Arial', // Font family of the title
            fontSize: 18,      // Size of the title text
            bold: true,        // Make the title text bold
            italic: false      // Title text style is not italic
        },
        colors: ["#35A682", "#A66F35"],
        legend: {
            position: "bottom",
        },
        chartArea: { width: "80%" },
    };

    useEffect(() => {
        localStorage.setItem("year", yearData.$y);
        let facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getMaterialReportingData(facilities, year);
        getAllMaterialUsedData(facilities, year)
    }, []);

    const getFilterData = (filterData) => {
        getMaterialReportingData(filterData.facilityRefNo, filterData.year);
        getAllMaterialUsedData(filterData.facilityRefNo, filterData.year);
    };

    const getMaterialReportingData = async (facilities, year) => {
        const array = [];
        setIsBarsLoader(true)
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        await getMaterialReportData(payload)
            .then((resp) => {
                const columnChartDataSource = [
                    ["Material Type", "Amount", { role: "style" }],
                    ["Renewable", resp.data.corporateTotalRenewableMaterialsUsed, "#00551F"],
                    ["Non-renewable", resp.data.corporateTotalNonRenewableMaterialsUsed, "#541F00"],
                ];
                setColumnChartData(columnChartDataSource)
                const pieChartDataSource = [
                    ["Material Type", "Amount", { role: "style" }],
                    ["Renewable", resp.data.corporateTotalRenewableMaterialUsedPercentage, "#35A682"],
                    ["Non-renewable", resp.data.corporateTotalNonRenewableMaterialUsedPercentage, "#A66F35"],
                ];
                setPieChartDataSource(pieChartDataSource)

                if (resp.data.facilityDetails) {
                    const percentage = {
                        materialName: "Percentage",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialUsedPercentage.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialUsedPercentage.toFixed(2),
                        totalMaterialsUsed: 100
                    };
                    const total = {
                        materialName: "Total",
                        nonRenewable: resp.data.corporateTotalNonRenewableMaterialsUsed.toFixed(2),
                        renewable: resp.data.corporateTotalRenewableMaterialsUsed.toFixed(2),
                        totalMaterialsUsed: resp.data.corporateTotalMaterialsUsed.toFixed(2)
                    };
                    resp.data.facilityDetails.forEach(facility => {
                        const facilityName = facility.facilityName;

                        facility.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                nonRenewable: material.nonRenewable.toFixed(2),
                                renewable: material.renewable.toFixed(2),
                                totalMaterialsUsed: material.totalMaterialsUsed.toFixed(2),
                            };
                            array.push(row);
                        });
                    });
                    array.push(total);
                    array.push(percentage);
                    setMaterialChartData(array);
                } else {
                    setMaterialChartData([]);
                    setColumnChartData(null);
                    setPieChartDataSource(null);
                }
                // setTimeout(() => {
                setIsBarsLoader(false);
                // }, [2000]);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const getAllMaterialUsedData = async (facilities, year) => {
        setIsBarsLoader(true);
        let payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityList: facilities?.length > 0 ? facilities.join(',') : null,
            YearList: year
        }
        try {
            await Promise.all([
                getMaterialReportData(payload),
                getMaterialReclaimedData(payload),
                getMaterialRecycledData(payload)
            ])
                .then((resp) => {
                    const materialUsedData = resp[0].data;
                    const reclaimedData = resp[1].data;
                    const recycleData = resp[2].data;
                    const materialTableData = [];
                    const reclaimedTableData = [];
                    const recycleTableData = [];
                    if (!(reclaimedData.corporateName && recycleData.corporateName)) {
                        reclaimedPercentage = 0;
                        recyclePercentage = 0;
                    }
                    const totalMaterialUsed = {
                        facilityName: "Total",
                        nonRenewable: materialUsedData.corporateTotalNonRenewableMaterialsUsed?.toFixed(2),
                        renewable: materialUsedData.corporateTotalRenewableMaterialsUsed?.toFixed(2),
                        totalMaterialsUsed: materialUsedData.corporateTotalMaterialsUsed?.toFixed(2)
                    };

                    const percentageMaterialUsed = {
                        facilityName: "Percentage",
                        nonRenewable: materialUsedData.corporateTotalNonRenewableMaterialUsedPercentage?.toFixed(2),
                        renewable: materialUsedData.corporateTotalRenewableMaterialUsedPercentage?.toFixed(2),
                        totalMaterialsUsed: 100
                    };

                    materialUsedData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                "materialName": material.materialName,
                                "renewable": material.renewable ? material.renewable?.toFixed(2) : 0,
                                "nonRenewable": material.nonRenewable ? material.nonRenewable?.toFixed(2) : 0,
                                "totalMaterialsUsed": material.totalMaterialsUsed ? material.totalMaterialsUsed?.toFixed(2) : 0
                            };
                            materialTableData.push(row);
                        })
                    })
                    materialTableData.push(totalMaterialUsed);
                    materialTableData.push(percentageMaterialUsed);

                    reclaimedData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        const totalTypes = facilities.typeTotals;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                "safelyDisposed": material.typeDetails["Safely Disposed"] ? material.typeDetails["Safely Disposed"]?.toFixed(2) : 0,
                                "reUsed": material.typeDetails["Reused"] ? material.typeDetails["Reused"]?.toFixed(2) : 0,
                                recycled: material.typeDetails.Recycled ? material.typeDetails.Recycled?.toFixed(2) : 0,
                                totalMaterialsUsed: material.totalMaterialsUsed?.toFixed(2)
                            };
                            reclaimedTableData.push(row);
                        })
                        const totalReclaimed = {
                            facilityName: "Total",
                            "safelyDisposed": totalTypes["Safely Disposed"] ? totalTypes["Safely Disposed"]?.toFixed(2) : 0,
                            "reUsed": totalTypes["Reused"] ? totalTypes["Reused"]?.toFixed(2) : 0,
                            recycled: totalTypes.Recycled ? totalTypes.Recycled?.toFixed(2) : 0,
                            totalMaterialsUsed: reclaimedData.corporateTotalMaterialsUsed?.toFixed(2)
                        };
                        // const percentageReclaimed = {
                        //     facilityName: "Percentage",
                        //     "safelyDisposed": totalTypes["Safely Disposed"] ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes["Safely Disposed"])) : 0,
                        //     "reUsed": totalTypes["Reused"] ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes["Reused"])) : 0,
                        //     recycled: totalTypes.Recycled ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes.Recycled)) : 0,
                        //     totalMaterialsUsed: percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(reclaimedData.corporateTotalMaterialsUsed)),
                        // };
                        reclaimedPercentage = percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(reclaimedData.corporateTotalMaterialsUsed));
                        reclaimedTableData.push(totalReclaimed);
                        // reclaimedTableData.push(percentageReclaimed);
                    });

                    const newReclaimedData = reclaimedTableData.filter(obj => obj.facilityName !== "Total");
                    const totalReclaimedValues = {
                        safelyDisposed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.safelyDisposed || 0), 0),
                        reUsed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.reUsed || 0), 0),
                        recycled: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.recycled || 0), 0),
                        totalMaterialsUsed: newReclaimedData.reduce((acc, obj) => acc + parseFloat(obj.totalMaterialsUsed || 0), 0)
                    };

                    newReclaimedData.push({ facilityName: "Total", ...totalReclaimedValues });

                    // const totalTypes = []
                    recycleData?.facilityDetails?.forEach(facilities => {
                        const facilityName = facilities.facilityName;
                        const totalTypes = facilities.typeTotals;
                        facilities.materialUsedDetails.forEach(material => {
                            const row = {
                                facilityName,
                                materialName: material.materialName,
                                "safelyDisposed": material.typeDetails["Safely Disposed"] ? material.typeDetails["Safely Disposed"]?.toFixed(2) : 0,
                                "reUsed": material.typeDetails["Reused"] ? material.typeDetails["Reused"]?.toFixed(2) : 0,
                                recycled: material.typeDetails.Recycled ? material.typeDetails.Recycled?.toFixed(2) : 0,
                                totalMaterialsUsed: material.totalMaterialsUsed?.toFixed(2)
                            };
                            recycleTableData.push(row);
                        })
                        const totalRecycle = {
                            facilityName: "Total",
                            "safelyDisposed": totalTypes["Safely Disposed"] ? totalTypes["Safely Disposed"]?.toFixed(2) : 0,
                            "reUsed": totalTypes["Reused"] ? totalTypes["Reused"]?.toFixed(2) : 0,
                            recycled: totalTypes.Recycled ? totalTypes.Recycled?.toFixed(2) : 0,
                            // totalMaterialsUsed: recycleData.corporateTotalMaterialsUsed?.toFixed(2)
                        };
                        // const percentageRecycle = {
                        //     facilityName: "Percentage",
                        //     "safelyDisposed": totalTypes["Safely Disposed"] ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes["Safely Disposed"])) : 0,
                        //     "reUsed": totalTypes["Reused"] ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes["Reused"])) : 0,
                        //     recycled: totalTypes.Recycled ? percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(totalTypes.Recycled)) : 0,
                        //     // totalMaterialsUsed: percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(recycleData.corporateTotalMaterialsUsed)),
                        // };
                        recyclePercentage = percentageCalculation(Number(materialUsedData.corporateTotalMaterialsUsed), Number(recycleData.corporateTotalMaterialsUsed));
                        recycleTableData.push(totalRecycle);
                        // recycleTableData.push(percentageRecycle);
                    });

                    const newData = recycleTableData.filter(obj => obj.facilityName !== "Total");
                    const totalValues = {
                        safelyDisposed: newData.reduce((acc, obj) => acc + parseFloat(obj.safelyDisposed || 0), 0),
                        reUsed: newData.reduce((acc, obj) => acc + parseFloat(obj.reUsed || 0), 0),
                        recycled: newData.reduce((acc, obj) => acc + parseFloat(obj.recycled || 0), 0),
                        totalMaterialsUsed: newData.reduce((acc, obj) => acc + parseFloat(obj.totalMaterialsUsed || 0), 0)
                    };

                    newData.push({ facilityName: "Total", ...totalValues });
                    setRecycleAndReclaimedPercentage(Number(reclaimedPercentage) + Number(recyclePercentage));

                    const deriveData = [
                        {
                            totalMaterialUsed: materialUsedData.corporateTotalMaterialsUsed?.toFixed(2),
                            totalReclaimed: reclaimedData.corporateTotalMaterialsUsed?.toFixed(2),
                            totalRecycle: recycleData.corporateTotalMaterialsUsed?.toFixed(2)
                        },
                        {
                            totalMaterialUsed: "Percentage",
                            totalReclaimed: reclaimedPercentage,
                            totalRecycle: recyclePercentage
                        },
                    ];

                    const materialUsedTableData = [
                        { columnName: tableHeaderContent[15].materialUsedTableColumns, tableData: materialTableData, tableName: "Material Use in Metric Tons" },
                        { columnName: tableHeaderContent[16].recycleInputColumns, tableData: newReclaimedData, tableName: "Reclaimed Product in Metric Tons" },
                        { columnName: tableHeaderContent[16].recycleInputColumns, tableData: newData, tableName: "Recycle Input in Metric Tons" },
                        { columnName: tableHeaderContent[17].derivedColumns, tableData: deriveData, tableName: "Derived Data" },
                    ];
                    setMaterialTableData(materialUsedTableData);
                    // setTimeout(() => {
                    setIsBarsLoader(false);
                    // }, [2000])
                })
        } catch (error) {
            if (error.response?.status === 401) {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "You are not authorized to perform this action. Redirected to login",
                })
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            };
        }
    };

    const handleChangeYear = (newValue) => {
        setValue(newValue);
    };

    const refreshData = () => {
        const facilities = userDetails.roles.includes("ROLE_CORPORATE_EDITOR") ? null : [userDetails.facilityRefNo];
        getMaterialReportingData(facilities, yearData.$y);
        getAllMaterialUsedData(facilities, yearData.$y);
        localStorage.setItem("year", yearData.$y);
        reclaimedPercentage = 0;
        recyclePercentage = 0;
    };

    const handleMenu = () => {
        setOpenFilterDialog({ action: true, userData: userDetails });
    };


    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3 }} className='body-content'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 col-md-5">
                                <span><material.Typography variant="h5" className='text-capitalize'>Material</material.Typography></span>
                            </div>
                            <div className="col-lg-4 col-md-3 mt-3">
                                <div className='float-end'>
                                    <span>
                                        <material.IconButton
                                            aria-label="more"
                                            id="long-button"
                                            onClick={handleMenu}
                                            title='Filter'
                                            color='info'
                                        >
                                            <img
                                                src={FilterImage}
                                                height={25}
                                                width={25}
                                            />
                                        </material.IconButton>
                                    </span>
                                    <span>
                                        <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                            <material.RefreshIcon />
                                        </material.IconButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="column-chart"
                                                options={options}
                                                columnChartData={columnChartData}
                                                tableData={materialChartData}
                                                year={year}
                                                columnName={tableHeaderContent[4].materialTableColumns}
                                                tableHeading="Material Use in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    {/* {isBarsLoader ? (
                                        <BarsLoader />
                                    ) : ( */}
                                    <ColumnChart
                                        options={options}
                                        columnChartData={columnChartData}
                                    />
                                    {/* )} */}
                                </material.Card>
                            </div>
                            <div className="col-lg-6">
                                <material.Card elevation={3} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="line-chart"
                                                options={options}
                                                lineChartData={columnChartData}
                                                tableData={materialChartData}
                                                year={year}
                                                columnName={tableHeaderContent[4].materialTableColumns}
                                                tableHeading="Material Use in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    {/* {isBarsLoader ? (
                                        <BarsLoader />
                                    ) : ( */}
                                    <LineChart
                                        options={options}
                                        lineChartData={columnChartData}
                                    />
                                    {/* )} */}
                                </material.Card>
                            </div>
                            <div className="col-lg-6">
                                <material.Card elevation={3} sx={{ mt: 2 }} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -10 }}>
                                        <span>
                                            <MoreOptionMenu
                                                chartName="pie-chart"
                                                options={pieChartOptions}
                                                pieChartData={pieChartDataSource}
                                                tableData={materialChartData}
                                                year={year}
                                                columnName={tableHeaderContent[4].materialTableColumns}
                                                tableHeading="Material Use in Metric Tons"
                                            />
                                        </span>
                                    </div>
                                    {/* {isBarsLoader ? (
                                        <BarsLoader />
                                    ) : ( */}
                                    <PieChart
                                        options={pieChartOptions}
                                        pieChartData={pieChartDataSource}
                                    />
                                    {/* )} */}
                                </material.Card>
                            </div>
                            <div className="col-lg-6">
                                <material.Card elevation={3} sx={{ mt: 2, height: 430 }} className='column-chart'>
                                    <div className='d-flex flex-row-reverse' style={{ marginBottom: -15 }}>
                                        <span>
                                            <MoreOptionMenu
                                                tableData={materialTableData}
                                                year={year}
                                                callFrom="multiple-table"
                                                hideFullScreen={true}
                                            />
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px" }}>
                                        {isBarsLoader ? (
                                            <BarsLoader />
                                        ) : (
                                            <span className='recycle-reclaimed-chart'>
                                                <span className='text-center'>
                                                    <material.Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }} className='text-capitalize'>Recycle & Reclaimed Input</material.Typography>
                                                </span>
                                                <div className='text-center d-flex flex-column mt-5'>
                                                    <material.Typography variant="h5" sx={{ mt: 1, color: "brown", fontWeight: "bold" }} className='text-capitalize'>{recycleAndReclaimedPercentage?.toFixed(2)}</material.Typography>
                                                </div>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "60px" }}>
                                                    <img
                                                        width="15%"
                                                        height="80px"
                                                        src={RecyclingPlant}
                                                        alt='RecyclingPlant'
                                                    />
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </material.Card>
                            </div>
                        </div>
                    </div>
                </material.Box>
            </material.Box>
            <GlobalFilter
                openFilterDialog={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
                getFilterData={getFilterData}
                callFrom="issue-dashboard"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default Material;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateAirEmissionPointSourceData } from '../../services/sub-issue-services/air-service/AirEmissionPointSourceService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddPollutantRatePointSource from './AddPollutantRatePointSource';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';



var pollutantRateAreaSourceTableData = []
function AddAirEmissionPointSource(props) {
    const { openAirEmissionsPointSourceDialog, setOpenAirEmissionsPointSourceDialog, allAirEmissionQuantityData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [selectSourcePollutantName, setSelectSourcePollutantName] = useState("");
    const [selectAreaPollutantName, setSelectAreaPollutantName] = useState("");
    const [sourceId, setSourceId] = useState("");
    const [openAddPollutantRateAreaSource, setOpenAddPollutantRateAreaSource] = useState({ action: false, pollutantRateAreaSourceTableData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pollutantRateAndAreaSourceData, setPollutantRateAndAreaSource] = useState([]);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const changeSourceId = watch("sourceIds");
    useEffect(() => {
        let value = getValues("sourceIds")
        setSourceId(value)
    }, [changeSourceId]);

    useEffect(() => {
        if (openAirEmissionsPointSourceDialog.action && openAirEmissionsPointSourceDialog.airEmissionsQuantityData) {
            reset(openAirEmissionsPointSourceDialog.airEmissionsQuantityData)
            setSelectAreaPollutantName(openAirEmissionsPointSourceDialog.airEmissionsQuantityData.areaNameOfPollutant)
            setSelectSourcePollutantName(openAirEmissionsPointSourceDialog.airEmissionsQuantityData.nameOfPollutant);
        }
    }, [openAirEmissionsPointSourceDialog.action === true]);

    const getPollutantRateAreaSource = (data) => {
        pollutantRateAreaSourceTableData.push(data);
        setPollutantRateAndAreaSource(pollutantRateAreaSourceTableData);
    };

    const saveAirEmissionsQuantity = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                refNo: "",
                applicable: true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
                ...data,
                airEmissionPointSourcePollutantDto: pollutantRateAndAreaSourceData
            }
            await saveOrUpdateAirEmissionPointSourceData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({
                        sourceIds: "", coordinateEasting: "", stackIn: "", stackDia: "", stackVe: "", temp: "", coordinateNorthing: ""
                    });
                    allAirEmissionQuantityData();
                    setSelectAreaPollutantName("");
                    setSelectSourcePollutantName("");
                    pollutantRateAreaSourceTableData = [];
                    setPollutantRateAndAreaSource([])
                    setOpenAirEmissionsPointSourceDialog({ action: false, airEmissionsQuantityData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAirEmissionsQuantity = async (fromData) => {
        let payload = {
            ...fromData,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            otherSpecifyPollutant: selectSourcePollutantName === "Other Pollutant (Specify)" ? fromData.otherSpecifyPollutant : "",
        }
        await saveOrUpdateAirEmissionPointSourceData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                allAirEmissionQuantityData();
                reset({
                    sourceIds: "", coordinateEasting: "", stackIn: "", stackDia: "", stackVe: "", temp: "", coordinateNorthing: ""
                });
                setSelectAreaPollutantName("")
                setSelectSourcePollutantName("");
                setOpenAirEmissionsPointSourceDialog({ action: false, airEmissionsQuantityData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({
            sourceIds: "", coordinateEasting: "", stackIn: "", stackDia: "", stackVe: "", temp: "", coordinateNorthing: ""
        });
        setSelectAreaPollutantName("");
        setSelectSourcePollutantName("");
        pollutantRateAreaSourceTableData = [];
        setPollutantRateAndAreaSource([])
        setOpenAirEmissionsPointSourceDialog({ action: false, airEmissionsQuantityData: null });
    };

    const addPollutantRateAreaSource = () => {
        setOpenAddPollutantRateAreaSource({ action: true, pollutantRateAreaSourceTableData: null });
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAirEmissionsPointSourceDialog.airEmissionsQuantityData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                allAirEmissionQuantityData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({
                    sourceIds: "", coordinateEasting: "", stackIn: "", stackDia: "", stackVe: "", temp: "", coordinateNorthing: ""
                });
                setSelectAreaPollutantName("")
                setSelectSourcePollutantName("");
                setOpenAirEmissionsPointSourceDialog({ action: false, airEmissionsQuantityData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAirEmissionsPointSourceDialog.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>
                    {openAirEmissionsPointSourceDialog.airEmissionsQuantityData ? "Edit Air Emission" : "Add Air Emission"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openAirEmissionsPointSourceDialog.airEmissionsQuantityData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Source Details (Point Source)</material.Typography>
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("sourceIds", { required: true, })}
                                    required
                                    label="Source Id"
                                    id="Source id"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("coordinateEasting", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Stack Coordinate (Latitude)"
                                    id="stackCoordinateEasting"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.coordinateEasting && errors.coordinateEasting.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("coordinateNorthing", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Stack Coordinate (Longitude)"
                                    id="stackCoordinateNorthing"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.coordinateNorthing && errors.coordinateNorthing.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("stackIn", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Stack Height (m)"
                                    id="stack Height(m)"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.stackIn && errors.stackIn.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("stackDia", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Stack Diameter (m)"
                                    id="stack dia (m)"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.stackDia && errors.stackDia.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("stackVe", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Stack Gas Velocity (m/s)"
                                    id="stack ve(m/s)"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.stackVe && errors.stackVe.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("temp", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Temperature (°C )"
                                    id="temperature (°C )"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.temp && errors.temp.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                    {openAirEmissionsPointSourceDialog.airEmissionsQuantityData ? null : (
                        <div>
                            <div className='row mt-3'>
                                <div className='col-6 py-1'>
                                    <material.Typography className='fw-bold'>Air Emissions Pollutant & Rate (Point Source)</material.Typography>
                                </div>
                                <div className='col-6'>
                                    <span className='float-end'>
                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addPollutantRateAreaSource}>Add Pollutant & Rate</material.Button>
                                    </span>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-12">
                                    <material.TableContainer >
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Source Id</StyledTableCell>
                                                    <StyledTableCell>Pollutant Name</StyledTableCell>
                                                    <StyledTableCell>Other Pollutant Name (Specify)</StyledTableCell>
                                                    <StyledTableCell>Concentration (mg/nm3)</StyledTableCell>
                                                    <StyledTableCell>Rate (gm/sec)</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {pollutantRateAndAreaSourceData?.map((item, ind) => (
                                                    <material.TableRow key={ind}>
                                                        <material.TableCell>{item.sourceIds}</material.TableCell>
                                                        <material.TableCell>{item.pollutant}</material.TableCell>
                                                        <material.TableCell>{(item.otherSpecifyPollutant === "" || item.otherSpecifyPollutant === null) ? "N/A" : item.otherSpecifyPollutant}</material.TableCell>
                                                        <material.TableCell>{item.concentration}</material.TableCell>
                                                        <material.TableCell>{item.pollutantRate}</material.TableCell>
                                                    </material.TableRow>
                                                ))}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </div>
                            </div>
                        </div>
                    )}

                </material.DialogContent>
                <material.DialogActions className='sticky' sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAirEmissionsPointSourceDialog.airEmissionsQuantityData === null ? (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAirEmissionsQuantity)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    ) : (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateAirEmissionsQuantity)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAirEmissionsPointSourceDialog.airEmissionsQuantityData.rejectionNote === null) || (openAirEmissionsPointSourceDialog.airEmissionsQuantityData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <AddPollutantRatePointSource
                openAddPollutantRateAreaSource={openAddPollutantRateAreaSource}
                setOpenAddPollutantRateAreaSource={setOpenAddPollutantRateAreaSource}
                getPollutantRateAreaSource={getPollutantRateAreaSource}
                sourceId={sourceId}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddAirEmissionPointSource;

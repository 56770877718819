import React from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import SiteList from '../site/SiteList';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import CorporateGenInfo from '../../tabs/CorporateGenInfo';
import { useSelector } from 'react-redux';
import FacilityGenInfo from '../../tabs/FacilityGenInfo';
import BusinessAndOperationsInfo from '../../tabs/BusinessAndOperationsInfo';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <material.Box sx={{ p: 3 }}>
                    <material.Typography>{children}</material.Typography>
                </material.Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

var usersRole;

function CorporateDetails(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [corporateOpen, setCorporateOpen] = React.useState(true);
    const [facilityOpen, setFacilityOpen] = React.useState(false);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const corporate = () => {
        setCorporateOpen(true);
        setFacilityOpen(false);
    };
    const facility = () => {
        setFacilityOpen(true);
        setCorporateOpen(false);
    };


    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, width:"80%" }} className='body-content'>
                <div className='row'>
                    <span><material.Typography variant="h5" className='headingText'>{usersRole === "ROLE_CORPORATE_EDITOR" ? "Corporate " + userDetails.corporateName : usersRole === "ROLE_FACILITY_EDITOR" || usersRole === "ROLE_FACILITY_ESG_DATA" ? "Facility under " + userDetails.corporateName : ""}</material.Typography></span>
                    {/* <div className='col-1'></div> */}
                    <div className='mt-3'>
                        <material.Box sx={{ bgcolor: 'background.paper' }}>
                            <material.AppBar position="static" sx={{ backgroundColor: "rgb(4, 153, 79)" }}>
                                {usersRole === "ROLE_CORPORATE_EDITOR" ? (
                                    <material.Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <material.Tab sx={{ textTransform: "none" }} label="General Information" {...a11yProps(0)} />
                                        <material.Tab sx={{ textTransform: "none" }} label="Business and Operations Information" {...a11yProps(1)} />
                                        {/* <material.Tab sx={{ textTransform: "none" }} label="HR Info" {...a11yProps(2)} /> */}
                                    </material.Tabs>
                                ) : usersRole === "ROLE_FACILITY_EDITOR" || usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                    <material.Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <material.Tab sx={{ textTransform: "none" }} label="General Information" {...a11yProps(0)} />
                                        {/* <material.Tab sx={{ textTransform: "none" }} label="HR Info" {...a11yProps(1)} />
                                        <material.Tab sx={{ textTransform: "none" }} label="Location Info" {...a11yProps(2)} /> */}
                                    </material.Tabs>
                                ) : null}
                            </material.AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    {usersRole === "ROLE_CORPORATE_EDITOR" ? (
                                        <CorporateGenInfo />
                                    ) : usersRole === "ROLE_FACILITY_EDITOR" || usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                        <FacilityGenInfo />
                                    ) : ""}
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    {usersRole === "ROLE_CORPORATE_EDITOR" ? (
                                        <BusinessAndOperationsInfo />
                                    ) : null}
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    {/* {usersRole === "ROLE_CORPORATE_EDITOR" ? (
                                        <HrInfo />
                                    ) : null} */}
                                </TabPanel>
                            </SwipeableViews>
                        </material.Box>
                    </div>
                    {/* <div className='col-1'></div> */}
                </div>
            </material.Box>
        </material.Box>
    );
}

export default CorporateDetails;
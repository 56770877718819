import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateEnvironmentalCriteriaData } from '../../services/sub-issue-services/supplier-chain-environment-service/EnvironmentalCriteriaService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddEnvironmentalCriteria(props) {
    const { addOpenEnvironmentalCriteria, setAddOpenEnvironmentalCriteria, getAllEnvironmentalCriteriaData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [auditDoneForSupplierEsgPerformance, setAuditDoneForSupplierEsgPerformance] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeTotalNoOfSuppliers = watch('totalNoOfSuppliers');
    const onChangeSuppliersScreenedUsingEnvCriteria = watch('suppliersScreenedUsingEnvCriteria');
    const onChangeSuppliersAssessedForEnvImpact = watch('suppliersAssessedForEnvImpact');
    const onChangeSuppliersHavingSignificantNegativeImpact = watch('suppliersHavingSignificantNegativeImpact');
    const onChangeCorrectiveMeasureGivenToSupplier = watch('correctiveMeasureGivenToSupplier');
    const onChangeRelationshipTerminatedForNegativeEnvImpact = watch('relationshipTerminatedForNegativeEnvImpact');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNoOfSuppliers, onChangeSuppliersScreenedUsingEnvCriteria, onChangeSuppliersAssessedForEnvImpact, onChangeSuppliersHavingSignificantNegativeImpact, onChangeCorrectiveMeasureGivenToSupplier, onChangeRelationshipTerminatedForNegativeEnvImpact]);

    const totalCalculation = () => {
        let totalNoOfSuppliersValue = getValues('totalNoOfSuppliers');
        let suppliersScreenedUsingEnvCriteriaValue = getValues('suppliersScreenedUsingEnvCriteria');
        let suppliersAssessedForEnvImpactValue = getValues('suppliersAssessedForEnvImpact');
        let suppliersHavingSignificantNegativeImpactValue = getValues('suppliersHavingSignificantNegativeImpact');
        let correctiveMeasureGivenToSupplierValue = getValues('correctiveMeasureGivenToSupplier');
        let relationshipTerminatedForNegativeEnvImpactValue = getValues('relationshipTerminatedForNegativeEnvImpact');

        if (Number(suppliersScreenedUsingEnvCriteriaValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers screened using env criteria value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersAssessedForEnvImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers assessed for env impact value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersHavingSignificantNegativeImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers having significant negative impact not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(correctiveMeasureGivenToSupplierValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Corrective measure given to supplier value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(relationshipTerminatedForNegativeEnvImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Relationships terminated for negative env impact value not greater than total No. of suppliers value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenEnvironmentalCriteria.action && addOpenEnvironmentalCriteria.environmentalCriteriaTableData) {
            setAuditDoneForSupplierEsgPerformance(addOpenEnvironmentalCriteria.environmentalCriteriaTableData.auditDoneForSupplierEsgPerformance);
            reset(addOpenEnvironmentalCriteria.environmentalCriteriaTableData);
        }
    }, [addOpenEnvironmentalCriteria.action === true]);

    const saveEnvironmentalCriteria = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": "",
                "applicable": true,
                "totalNoOfSuppliers": Number(data.totalNoOfSuppliers),
                "suppliersScreenedUsingEnvCriteria": Number(data.suppliersScreenedUsingEnvCriteria),
                "suppliersAssessedForEnvImpact": Number(data.suppliersAssessedForEnvImpact),
                "suppliersHavingSignificantNegativeImpact": Number(data.suppliersHavingSignificantNegativeImpact),
                "correctiveMeasureGivenToSupplier": Number(data.correctiveMeasureGivenToSupplier),
                "relationshipTerminatedForNegativeEnvImpact": Number(data.relationshipTerminatedForNegativeEnvImpact),
                "auditDoneForSupplierEsgPerformance": data.auditDoneForSupplierEsgPerformance,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateEnvironmentalCriteriaData(object)
                .then((resp) => {
                    getAllEnvironmentalCriteriaData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    setAuditDoneForSupplierEsgPerformance("");
                    reset({ totalNoOfSuppliers: "", suppliersScreenedUsingEnvCriteria: "", suppliersAssessedForEnvImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeEnvImpact: "", });
                    setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEnvironmentalCriteria = async (data) => {
        let object = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            "refNo": addOpenEnvironmentalCriteria.environmentalCriteriaTableData.refNo,
            "applicable": true,
            "totalNoOfSuppliers": Number(data.totalNoOfSuppliers),
            "suppliersScreenedUsingEnvCriteria": Number(data.suppliersScreenedUsingEnvCriteria),
            "suppliersAssessedForEnvImpact": Number(data.suppliersAssessedForEnvImpact),
            "suppliersHavingSignificantNegativeImpact": Number(data.suppliersHavingSignificantNegativeImpact),
            "correctiveMeasureGivenToSupplier": Number(data.correctiveMeasureGivenToSupplier),
            "relationshipTerminatedForNegativeEnvImpact": Number(data.relationshipTerminatedForNegativeEnvImpact),
            "auditDoneForSupplierEsgPerformance": data.auditDoneForSupplierEsgPerformance,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateEnvironmentalCriteriaData(object)
            .then((resp) => {
                getAllEnvironmentalCriteriaData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setAuditDoneForSupplierEsgPerformance("");
                reset({ totalNoOfSuppliers: "", suppliersScreenedUsingEnvCriteria: "", suppliersAssessedForEnvImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeEnvImpact: "", });
                setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        setAuditDoneForSupplierEsgPerformance("");
        reset({ totalNoOfSuppliers: "", suppliersScreenedUsingEnvCriteria: "", suppliersAssessedForEnvImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeEnvImpact: "", });
        setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
    };

    const selectAuditDoneForSupplierESGPerformance = (event) => {
        setAuditDoneForSupplierEsgPerformance(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenEnvironmentalCriteria.environmentalCriteriaTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllEnvironmentalCriteriaData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setAuditDoneForSupplierEsgPerformance("");
                reset({ totalNoOfSuppliers: "", suppliersScreenedUsingEnvCriteria: "", suppliersAssessedForEnvImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeEnvImpact: "", });
                setAddOpenEnvironmentalCriteria({ action: false, environmentalCriteriaTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenEnvironmentalCriteria.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? "Edit Environmental Criteria" : "Add Environmental Criteria"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Environmental Criteria</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("totalNoOfSuppliers", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Suppliers"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalNoOfSuppliers?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersScreenedUsingEnvCriteria", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Screened Using Env Criteria"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersScreenedUsingEnvCriteria?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersAssessedForEnvImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Assessed for Env Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersAssessedForEnvImpact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersHavingSignificantNegativeImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Suppliers Having Significant Negative Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersHavingSignificantNegativeImpact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("correctiveMeasureGivenToSupplier", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Corrective Measure Given to Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.correctiveMeasureGivenToSupplier?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("relationshipTerminatedForNegativeEnvImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Relationships Terminated for Negative Env Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.relationshipTerminatedForNegativeEnvImpact?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Audit Done for Supplier ESG Performance</material.InputLabel>
                                    <material.Select
                                        {...register("auditDoneForSupplierEsgPerformance", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={auditDoneForSupplierEsgPerformance}
                                        onChange={selectAuditDoneForSupplierESGPerformance}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenEnvironmentalCriteria.environmentalCriteriaTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} disabled={buttonDisable} onClick={handleSubmit(updateEnvironmentalCriteria)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenEnvironmentalCriteria.environmentalCriteriaTableData.rejectionNote === null) || (addOpenEnvironmentalCriteria.environmentalCriteriaTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} disabled={buttonDisable} onClick={handleSubmit(saveEnvironmentalCriteria)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEnvironmentalCriteria;
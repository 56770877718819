import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import AddIndigenousPeoplesRights from '../../../sub-issues-dialogs/indigenous-peoples-rights-dialogs/AddIndigenousPeoplesRights';
import { approveIndigenousPeoplesRights, getIndigenousPeoplesRightsData, sendForApprovalIndigenousPeoplesRights } from '../../../services/sub-issue-services/indigenous-peoples-services/IndigenousPeoplesRightsService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function IndigenousPeoplesRights(props) {

    const location = useLocation();
    const treeViewData = location?.state;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [value, setValue] = useState('Indigenous Peoples Rights');
    const [indigenousPeoplesRightsTableData, setIndigenousPeoplesRightsTableData] = useState([]);
    const [openIndigenousPeoplesRights, setOpenIndigenousPeoplesRights] = useState({ action: false, indigenousPeoplesRightsData: null })
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getIndigenousPeoplesRightsAllData();
    }, [value]);

    const getIndigenousPeoplesRightsAllData = async (filterDate) => {
        if (value === "Indigenous Peoples Rights") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getIndigenousPeoplesRightsData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.indigenousPeopleDataList);
                    setIndigenousPeoplesRightsTableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (value === "Indigenous Peoples Rights History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getIndigenousPeoplesRightsData(payloadData)
                .then((resp) => {
                    setIndigenousPeoplesRightsTableData(resp.data.indigenousPeopleDataList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const clickCheckBox = (value) => {
        setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value)
        };
    };
    const addIndigenousPeoplesRights = () => {
        setOpenIndigenousPeoplesRights({ action: true, indigenousPeoplesRightsData: null });
    };
    const editIndigenousPeoplesRights = (tableData) => {
        setOpenIndigenousPeoplesRights({ action: true, indigenousPeoplesRightsData: tableData });
    };
    const sendApprovalIndigenousPeoplesRightsData = async () => {
        if (recordRefNo.length) {
            await sendForApprovalIndigenousPeoplesRights(recordRefNo)
                .then((resp) => {
                    recordRefNo = [];
                    getIndigenousPeoplesRightsAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };
    const approvedIndigenousPeoplesRightsData = async () => {
        if (recordRefNo.length) {
            let object = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo
            }
            await approveIndigenousPeoplesRights(object)
                .then((resp) => {
                    recordRefNo = [];
                    getIndigenousPeoplesRightsAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };
    const rejectIndigenousPeoplesRightsData = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };
    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveIndigenousPeoplesRights(obj)
            .then((resp) => {
                recordRefNo = [];
                getIndigenousPeoplesRightsAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const getFormDateToDate = (data) => {
        getIndigenousPeoplesRightsAllData(data);
    }

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="mt-3">
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={value}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Indigenous Peoples Rights" value="Indigenous Peoples Rights" />
                                        <material.Tab className="text-capitalize" label="Indigenous Peoples Rights History" value="Indigenous Peoples Rights History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Indigenous Peoples Rights">
                                    <div className="row">
                                        <div className="col-6">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                                <span className="float-end">
                                                    {indigenousPeoplesRightsTableData.length ? (
                                                        <>
                                                            <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedIndigenousPeoplesRightsData}>Approve</material.Button>
                                                            <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectIndigenousPeoplesRightsData}>Reject</material.Button>
                                                        </>
                                                    ) : null}
                                                </span>
                                            ) : (
                                                <span className="float-end">
                                                    <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalIndigenousPeoplesRightsData}>Send for Approval</material.Button>
                                                    <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addIndigenousPeoplesRights}>Add Indigenous Peoples Rights</material.Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Number of Violations Incidents</StyledTableCell>
                                                                <StyledTableCell>Incident Reviewed</StyledTableCell>
                                                                <StyledTableCell>Remediation Plan Implemented</StyledTableCell>
                                                                <StyledTableCell>Remediation Plans Reviewed</StyledTableCell>
                                                                <StyledTableCell>Incident no Longer Subject to Action</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {indigenousPeoplesRightsTableData?.length ? indigenousPeoplesRightsTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                        () => editIndigenousPeoplesRights(item) : null}>
                                                                    <material.TableCell>{item.numberOfViolationsIncidents}</material.TableCell>
                                                                    <material.TableCell>{item.incidentReviewed}</material.TableCell>
                                                                    <material.TableCell>{item.remediationPlanImplemented}</material.TableCell>
                                                                    <material.TableCell>{item.remediationPlansReviewed}</material.TableCell>
                                                                    <material.TableCell>{item.incidentNoLongerSubjectToAction}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={11}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Indigenous Peoples Rights History">
                                    <div className="mb-2">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Number  of Violations Incidents</StyledTableCell>
                                                                <StyledTableCell> Incident Reviewed</StyledTableCell>
                                                                <StyledTableCell>Remediation Plan Implemented</StyledTableCell>
                                                                <StyledTableCell>Remediation Plans Reviewed</StyledTableCell>
                                                                <StyledTableCell>Incident no Longer Subject to Action</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {indigenousPeoplesRightsTableData?.length ? indigenousPeoplesRightsTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}>
                                                                    <material.TableCell>{item.numberOfViolationsIncidents}</material.TableCell>
                                                                    <material.TableCell>{item.incidentReviewed}</material.TableCell>
                                                                    <material.TableCell>{item.remediationPlanImplemented}</material.TableCell>
                                                                    <material.TableCell>{item.remediationPlansReviewed}</material.TableCell>
                                                                    <material.TableCell>{item.incidentNoLongerSubjectToAction}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={11}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )
                                                            }
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getIndigenousPeoplesRightsAllData={getIndigenousPeoplesRightsAllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddIndigenousPeoplesRights
                getIndigenousPeoplesRightsAllData={getIndigenousPeoplesRightsAllData}
                openIndigenousPeoplesRights={openIndigenousPeoplesRights}
                setOpenIndigenousPeoplesRights={setOpenIndigenousPeoplesRights}
                treeViewData={treeViewData}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default IndigenousPeoplesRights;
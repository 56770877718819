import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";

export const saveOrUpdateIndigenousPeoplesRightsData = async (IndigenousPeoplesRightsData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/indigenous-people-rights/save-or-update`, IndigenousPeoplesRightsData)
};

export const getIndigenousPeoplesRightsData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/indigenous-people-rights/get-all`, { params:payload})
};

export const sendForApprovalIndigenousPeoplesRights = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/indigenous-people-rights/send-for-approval`, recordRefNo)
};

export const approveIndigenousPeoplesRights = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/indigenous-people-rights/approve`, payloadData)
};
import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import { addCorporate } from '../services/CorporateService';
import Snackbar from '../toaster-message/Snackbar';


function AddCorporate(props) {

    const { openAddCorporate, setOpenAddCorporate, getCorporateList } = props;
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    const [value, setValue] = React.useState(dayjs());
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddCorporate.corporateData) {
            reset(openAddCorporate.corporateData)
        };
    }, [openAddCorporate.action === true])

    const saveCorporate = async (formData) => {
        await addCorporate({...formData, active: true})
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Added successfully",
                })
                getCorporateList();
                reset({
                    "corporateName": ""
                })
                setOpenAddCorporate({ action: false, corporateData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                };
            })
    };
    const close = () => {
        reset({
            "corporateName": ""
        })
        setOpenAddCorporate({ action: false, corporateData: null })
    };
    const update = async (formData) => {
        await addCorporate(formData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Updated successfully",
                })
                getCorporateList();
                reset({
                    "corporateName": ""
                })
                setOpenAddCorporate({ action: false, corporateData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth open={openAddCorporate.action}>
                <material.DialogTitle><span><material.Typography variant="h5" className="fw-bold headingText">{openAddCorporate.corporateData ? "Edit Corporate" : "Add Corporate"}</material.Typography></span></material.DialogTitle>
                <material.DialogContent>
                    {/* <material.Paper elevation={3} sx={{ p: 3 }}> */}
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <material.TextField
                                required
                                label="Corporate Name"
                                id="Name"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: openAddCorporate.readOnly }}
                                color='success'
                                {...register("corporateName", { required: true, maxLength: 100 })}
                            />
                            {(errors?.corporateName?.type === "maxLength") && (
                                <p className='text-danger'>Can not exceed 100 character</p>
                            )}
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <material.TextField
                                label="Corporate Identity No"
                                id="corporateIdentityNo"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                color='success'
                                {...register("corporateIdentityNo", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="Year Of Incorporation"
                                    format='DD-MM-YYYY'
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    slotProps={{ textField: { variant: 'standard', color: "success" } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <material.TextField
                                label="Email"
                                id="email"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                color='success'
                                {...register("email", { required: true })}
                            />
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Paid Up Capital"
                            id="paidUpCapital"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("paidUpCapital")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Name Of Stock Exchanges"
                            id="nameOfStockExchanges"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("nameOfStockExchanges")}
                        />
                    </div>
                    <div className="col-lg-12 col-md-12 mt-3">
                        <material.TextField
                            label="Registered Address"
                            id="registeredAddress"
                            variant="standard"
                            type="text"
                            size="small"
                            multiline
                            fullWidth
                            color='success'
                            {...register("registeredAddress")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="City"
                            id="city"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("city")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="State"
                            id="state"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("state")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Country"
                            id="country"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("country")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Pin Code"
                            id="pin"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("pin")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Phone"
                            id="phone"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("phone")}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mt-3">
                        <material.TextField
                            label="Fax"
                            id="fax"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("fax")}
                        />
                    </div> */}
                    </div>
                    {/* </material.Paper> */}
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, pr: 3 }}>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 2 }} onClick={close} startIcon={<material.CloseIcon />}>Close</material.Button>
                    {openAddCorporate.corporateData ? (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(update)} startIcon={<material.DoneIcon />}>Update</material.Button>
                    ) : (
                        <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(saveCorporate)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddCorporate;
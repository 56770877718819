import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getPerformanceAppraisalData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/performance-appraisal/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdatePerformanceAppraisalData = async (PerformanceAppraisalData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/performance-appraisal/save-or-update`, PerformanceAppraisalData)
};

export const sendForApprovalPerformanceAppraisalData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/performance-appraisal/send-for-approval`, recordIds)
};

export const approvePerformanceAppraisalData = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/performance-appraisal/approve`, payload)
};

export const getTotalEmployeeLinkData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employee-and-worker-number/get-latest-employee-data`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, typeOfRecord: payload.typeOfRecord, } })
};




import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { createMultipleGeneralDisclosures, generalDisclosuresStatusChange, getDisclosuresQuestion, getGeneralDisclosuresByQuestion, sendDisclosuresForApprove } from '../../services/GeneralDisclosureService';
import RejectionNote from '../../dialogs/RejectionNote';
import DynamicQuestionForm from '../dynamic-question-form/DynamicQuestionForm';


var usersRole;
function SpecificDisclosure(props) {

    const { treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [openAddSpecificDisclosure, setOpenAddSpecificDisclosure] = useState(false);
    const [allowNewDisclosure, setAllowNewDisclosure] = useState(true);
    const [specificDisclosureQuestions, setSpecificDisclosureQuestions] = useState([]);
    const [specificDisclosureAns, setSpecificDisclosureAns] = useState([]);
    const [hideButton, setHideButton] = useState(true);
    const [allQuestionsAnswers, setAllQuestionsAnswers] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [questions, setQuestions] = useState([]);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [isRefresh, setIsRefresh] = useState(true);

    useEffect(() => {
        getSpecificDisclosures();
        setIsRefresh(false)
    }, [treeViewData?.subIssue, isRefresh]);

    const getSpecificDisclosures = () => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData?.pillar,
            "issue": treeViewData?.subIssue,
            typeOfDisclosure: "SPECIFIC_DISCLOSURE",
            sendForApproval: usersRole === "ROLE_FACILITY_APPROVER" ? true : false,
            isApprover: usersRole === "ROLE_FACILITY_APPROVER" ? true : false,
        };
        Promise.all([getDisclosuresQuestion(treeViewData?.subIssue, "SPECIFIC_DISCLOSURE"), getGeneralDisclosuresByQuestion(obj)]).then((responses) => {
            // Handle the responses for each API request
            const allQuestions = responses[0].data;
            let questionStructure = convertToNestedStructure(allQuestions);
            setQuestions(questionStructure);
            const allAnswers = responses[1].data;
            if (allAnswers.length > 0) {
                setSpecificDisclosureAns(allAnswers)
                allAnswers[0].answers?.forEach(element => {
                    if (element.approvalStatus === "APPROVED") {
                        setAllowNewDisclosure(true);
                    } else {
                        setAllowNewDisclosure(false);
                    }
                    setHideButton(element.sentForApproval)
                });
            } else {
                setSpecificDisclosureAns(allAnswers)
                setAllowNewDisclosure(true);
            };

            // Create a data structure to associate questions with answers using question ID
            // Create a map to store answers based on question IDs
            const answersMap = new Map();
            // Populate the answers map
            allAnswers.forEach(answer => {
                const questionId = answer.questionId;
                answersMap.set(questionId, answer);
            });
            // Create a new array by adding the 'answer' property to each question
            const questionsWithAnswers = allQuestions.map(question => {
                const questionId = question.questionid;
                const answer = answersMap.get(questionId);
                // Add an 'answer' property to the question object
                question.answer = answer ? answer : null;
                return question;
            });
            const questionnaireStructure = [];
            questionsWithAnswers.forEach((question, index) => {
                const { questionid, parent_question_no } = question;
                const isChild = parent_question_no !== null;
                if (!isChild) {
                    const childQuestions = questionsWithAnswers.filter((child) => child.parent_question_no === questionid);
                    questionnaireStructure.push({
                        ...question,
                        child_questions: childQuestions.length > 0 ? childQuestions : null,
                    });
                }
            });
            // Log the resulting array
            setSpecificDisclosureQuestions(questionnaireStructure)
            setAllQuestionsAnswers(answersMap)
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            });
    };

    const convertToNestedStructure = (data) => {
        const nestedStructure = [];
        const parentMap = {};
        data.forEach((item) => {
            const newItem = { ...item, child_questions: [] };
            if (item.parent_question_no === null) {
                // Top-level question
                nestedStructure.push(newItem);
            } else {
                // Child question
                const parentItem = parentMap[item.parent_question_no];
                if (parentItem) {
                    parentItem.child_questions.push(newItem);
                }
            }
            parentMap[item.questionid] = newItem;
        });
        return nestedStructure;
    }

    // const addSpecificDisclosure = () => {
    //     setOpenAddSpecificDisclosure(true)
    // };
    // const editSpecificDisclosure = (disclosureData) => {
    //     setOpenEditSpecificDisclosure({ action: true, disclosureData: [disclosureData], allAnswers: allQuestionsAnswers });
    // };

    const sendApprovalSpecificDisclosure = async () => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData?.pillar,
            "issue": treeViewData?.subIssue,
            typeOfDisclosure: "SPECIFIC_DISCLOSURE",
        };
        if (hideButton === false || hideButton === null) {
            await sendDisclosuresForApprove(obj)
                .then((resp) => {
                    getSpecificDisclosures()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                })
        };
    };
    const approveSpecificDisclosure = async () => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData?.pillar,
            "issue": treeViewData?.subIssue,
            typeOfDisclosure: "SPECIFIC_DISCLOSURE",
            status: "APPROVED",
        };
        await generalDisclosuresStatusChange(obj)
            .then((res) => {
                getSpecificDisclosures()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };
    const rejectSpecificDisclosure = async () => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData?.pillar,
            "issue": treeViewData?.subIssue,
            typeOfDisclosure: "SPECIFIC_DISCLOSURE",
            status: "REJECTED",
            rejectionNote: noteData.rejectionNote
        }
        await generalDisclosuresStatusChange(obj)
            .then((res) => {
                getSpecificDisclosures()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const save = async (data) => {
        let questionAnsList = Object.entries(data).map((ele) => {
            let answerData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                pillar: treeViewData?.pillar,
                issue: treeViewData?.subIssue,
                questionId: ele[0],
                "typeOfDisclosure": "SPECIFIC_DISCLOSURE",
                answer: ele[1],
                refNo: allQuestionsAnswers.get(ele[0])?.answers[0]?.refNo
                // answers: {
                //     date: moment().format("YYYY-MM-DDTHH:mm:ss"),
                //     answer: ele[1],
                //     approvalStatus: "",
                //     approvalDate: ""
                // }
            };
            return answerData;
        })
        console.log(questionAnsList)
        await createMultipleGeneralDisclosures(questionAnsList)
            .then((resp) => {
                getSpecificDisclosures()
                setOpenAddSpecificDisclosure(false)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setIsRefresh(true);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const cancel = () => {

    }


    return (
        <material.Card className='px-2'>
            <div className='row'>
                <div hidden={usersRole === "ROLE_FACILITY_EDITOR"} className='col-12'>
                    <span className='float-end my-2' hidden={usersRole === "ROLE_FACILITY_APPROVER"}>
                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalSpecificDisclosure}>Send for Approval</material.Button>
                        <material.Button variant="contained" color='success' hidden={specificDisclosureAns.length == 0 || specificDisclosureAns[0]?.answers[0]?.rejectionNote == "" || specificDisclosureAns[0]?.answers[0]?.rejectionNote == null} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.VisibilityIcon />} onClick={() => viewRejectionNote(specificDisclosureAns[0]?.answers[0])}>View Rejection Note</material.Button>
                        {/* <material.Button disabled={!allowNewDisclosure} sx={{ textTransform: "none" }} variant="contained" color="success" onClick={addSpecificDisclosure} startIcon={<material.AddIcon />}>Add Specific Disclosure</material.Button> */}
                    </span>
                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? null : (
                        <span className='float-end my-2' hidden={allowNewDisclosure}>
                            <material.Button sx={{ textTransform: "none", mr: 1 }} variant="contained" color='success' startIcon={<material.DoneIcon />} onClick={approveSpecificDisclosure}>Approve</material.Button>
                            <material.Button sx={{ textTransform: "none" }} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectSpecificDisclosure}>Reject</material.Button>
                        </span>
                    )}
                </div>
                <div className='col-12'>
                    {usersRole === "ROLE_FACILITY_ESG_DATA" || usersRole === "ROLE_FACILITY_EDITOR" ? (
                        specificDisclosureAns.length > 0 ?
                            <DynamicQuestionForm questions={specificDisclosureQuestions} save={save} cancel={cancel} /> :
                            <DynamicQuestionForm questions={questions} save={save} cancel={cancel} />)
                        : null
                    }
                    {usersRole === "ROLE_FACILITY_APPROVER" ? (
                        specificDisclosureAns.length > 0 ?
                            <DynamicQuestionForm questions={specificDisclosureQuestions} save={save} cancel={cancel} /> :
                            <div className='d-flex align-items-center justify-content-center text-danger fw-bold py-5 fs-4'>
                                No data found
                            </div>
                    )
                        : null
                    }
                </div>
                {/* <AddSpecificDisclosure
                getSpecificDisclosures={getSpecificDisclosures}
                treeViewData={treeViewData}
                openAddSpecificDisclosure={openAddSpecificDisclosure}
                setOpenAddSpecificDisclosure={setOpenAddSpecificDisclosure}
            />
            <EditSpecificDisclosure
                getSpecificDisclosures={getSpecificDisclosures}
                treeViewData={treeViewData}
                openEditSpecificDisclosure={openEditSpecificDisclosure}
                setOpenEditSpecificDisclosure={setOpenEditSpecificDisclosure}
            /> */}
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <RejectionNote
                    openRejectionNoteDialog={openRejectionNoteDialog}
                    setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                    getRejectionNoteData={getRejectionNoteData}
                />
            </div>
        </material.Card>
    )
}

export default SpecificDisclosure;
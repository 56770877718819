import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector, useDispatch } from 'react-redux';
import { getPillarIssueSubIssueTreeList } from '../../services/GeneralDisclosureService';
import { treeViewPillarAction } from '../../store/slice/TreeViewPillarSlice';
import { treeViewIssueAction } from '../../store/slice/TreeViewIssueSlice';
import { NavLink } from 'react-router-dom';
import Snackbar from '../../toaster-message/Snackbar';
import { getPillarIssueSubIssueFilterData } from '../../services/ManageEntityService';

function SidebarTreeView(props) {

    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const treeViewPillarData = useSelector((state) => state.TreeViewPillarSlice.data);
    const treeViewIssueData = useSelector((state) => state.TreeViewIssueSlice.data);
    const [pillarIssueSubIssueData, setPillarIssueSubIssueData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });


    useEffect(() => {
        pillarIssueSubIssueTreeList()
    }, []);
    const pillarIssueSubIssueTreeList = async () => {
        const payload = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo
        }
        await getPillarIssueSubIssueFilterData(payload)
            .then((resp) => {
                setPillarIssueSubIssueData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };
    const handleClickOpenPillar = (pillar) => {
        dispatch(treeViewPillarAction(pillar))
    };
    const handleClickClosePillar = (pillar) => {
        dispatch(treeViewPillarAction(""))
    };
    const handleClickOpenIssue = (issue) => {
        dispatch(treeViewIssueAction(issue))
    };
    const handleClickCloseIssue = (issue) => {
        dispatch(treeViewIssueAction(""))
    };

    return (
        <div>
            <material.Box sx={{ flexGrow: 1, overflowY: "auto", width: 240, height: userDetails.roles.includes("ROLE_FACILITY_APPROVER") ? "78vh" : "60vh" }}>
                <material.List
                    sx={{ mt: -3, ml: -1 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {pillarIssueSubIssueData?.map((pillar, i) => (
                        <div key={i}>
                            <material.ListItemButton key={i} title={pillar.attributeName} sx={{ cursor: "default" }}>
                                {treeViewPillarData === pillar.attributeName ? <material.ExpandLess onClick={() => handleClickClosePillar(pillar.attributeName)} sx={{ cursor: "pointer" }} /> : <material.ExpandMore onClick={() => handleClickOpenPillar(pillar.attributeName)} sx={{ cursor: "pointer" }} />}
                                <material.ListItemText primary={pillar.attributeName} />
                            </material.ListItemButton>
                            {pillar?.children?.map((issue, j) => (
                                <material.Collapse in={treeViewPillarData === pillar.attributeName} timeout="auto" unmountOnExit key={j}>
                                    <material.List component="div" disablePadding key={j}>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to={{ pathname: `/${pillar.attributeName}/${issue.attributeName.split(" ").join("_")}` }} state={{ issue: issue?.attributeName, pillar: pillar?.attributeName }}>
                                            <material.ListItemButton sx={{ pl: 6, cursor: "default" }} title={issue.attributeName}>
                                                {treeViewIssueData === issue.attributeName ? <material.ExpandLess onClick={() => handleClickCloseIssue(issue.attributeName)} sx={{ cursor: "pointer" }} /> : <material.ExpandMore onClick={() => handleClickOpenIssue(issue.attributeName)} sx={{ cursor: "pointer" }} />}
                                                <material.ListItemText primary={issue.attributeName} />
                                            </material.ListItemButton>
                                        </NavLink>
                                        {issue?.children?.map((subIssue, k) => (
                                            <material.Collapse in={treeViewIssueData === issue.attributeName} timeout="auto" unmountOnExit key={k}>
                                                <material.List component="div" disablePadding key={k}>
                                                    <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to={{ pathname: `/${pillar.attributeName}/${issue.attributeName.split(" ").join("_")}/${subIssue.attributeName.split(" ").join("_")}` }} state={{ issue: issue?.attributeName, pillar: pillar?.attributeName, subIssue: subIssue?.attributeName, id: subIssue?.id }}>
                                                        <material.ListItemButton sx={{ pl: 10 }} title={subIssue.attributeName}>
                                                            <material.ListItemText primary={subIssue.attributeName} />
                                                        </material.ListItemButton>
                                                    </NavLink>
                                                </material.List>
                                            </material.Collapse>
                                        ))}
                                    </material.List>
                                </material.Collapse>
                            ))}
                        </div>
                    ))}
                </material.List>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default SidebarTreeView;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { updateAreaSourcePollutantData } from '../../services/sub-issue-services/air-service/AirEmissionAreaSourceService';
import Snackbar from '../../toaster-message/Snackbar';

function AddAreaSourcePollutantDetails(props) {

    const { openPollutantDetailsDialog, setOpenPollutantDetailsDialog, sourceId, getPollutantDetails, getAllAreaSourceData, treeViewData } = props;
    const { register, handleSubmit, reset, watch, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [selectAreaPollutantName, setSelectAreaPollutantName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openPollutantDetailsDialog.action) {
            setValue("sourceIds", sourceId);
        }
        if (openPollutantDetailsDialog.action && openPollutantDetailsDialog.pollutantData) {
            reset(openPollutantDetailsDialog.pollutantData);
            setSelectAreaPollutantName(openPollutantDetailsDialog.pollutantData.pollutant);
            console.log(openPollutantDetailsDialog.pollutantData)
        };
    }, [openPollutantDetailsDialog.action === true]);

    const handleChangeAreaPollutantName = (event) => {
        setSelectAreaPollutantName(event.target.value)
    };

    const handleClose = () => {
        setOpenPollutantDetailsDialog({ action: false, pollutantData: null });
        reset({ sourceIds: "", otherSpecifyArea: "", areaEmissionFactor: "" });
        setSelectAreaPollutantName("");
    };

    const saveAreaSourceData = (formData) => {
        if (openPollutantDetailsDialog.pollutantData) {
            let obj = {
                ...formData,
                otherSpecifyPollutant: selectAreaPollutantName === "Other Pollutant (Specify)" ? formData.otherSpecifyPollutant : "",
            }
            updateAreaSourcePollutantData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllAreaSourceData();
                    setOpenPollutantDetailsDialog({ action: false, pollutantData: null });
                    reset({ sourceIds: "", otherSpecifyPollutant: "", areaEmissionFactor: "" });
                    setSelectAreaPollutantName("");
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            let obj = {
                sourceIds: formData.sourceIds,
                otherSpecifyPollutant: selectAreaPollutantName === "Other Pollutant (Specify)" ? formData.otherSpecifyPollutant : "",
                areaEmissionFactor: formData.areaEmissionFactor,
                pollutant: formData.pollutant,
                sourceRefNo: "",
                refNo: ""
            }
            getPollutantDetails(obj);
            setOpenPollutantDetailsDialog({ action: false, pollutantData: null });
            reset({ sourceIds: "", otherSpecifyPollutant: "", areaEmissionFactor: "" });
            setSelectAreaPollutantName("");
        }
    };

    return (
        <div>
            <material.Dialog open={openPollutantDetailsDialog.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold'>{openPollutantDetailsDialog.pollutantData ? "Edit Pollutant Details" : "Add Pollutant Details"}</material.DialogTitle>
                <material.DialogContent>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className="row">
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Pollutant Details (Area Source)</material.Typography>
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("sourceIds", { required: true, })}
                                    required
                                    label="Source Id"
                                    id="Source id"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                />
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Pollutant Name</material.InputLabel>
                                    <material.Select
                                        {...register("pollutant", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectAreaPollutantName}
                                        onChange={handleChangeAreaPollutantName}
                                        label="Pollutants name"
                                    >
                                        <material.MenuItem value={"PM10"}>PM10</material.MenuItem>
                                        <material.MenuItem value={"NOx"}>NOx</material.MenuItem>
                                        <material.MenuItem value={"SOx"}>SOx</material.MenuItem>
                                        <material.MenuItem value={"CO"}>CO</material.MenuItem>
                                        <material.MenuItem value={"VOC"}>VOC</material.MenuItem>
                                        <material.MenuItem value={"CH4"}>CH4</material.MenuItem>
                                        <material.MenuItem value={"POP"}>POP</material.MenuItem>
                                        <material.MenuItem value={"HAP"}>HAP</material.MenuItem>
                                        <material.MenuItem value={"Other Pollutant (Specify)"}>Other Pollutant (Specify)</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {selectAreaPollutantName === "Other Pollutant (Specify)" ? (
                                <div className='col-4 col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("otherSpecifyPollutant", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Area Pollutant Specify"
                                        id="Other(Specify)"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.othersSpecifyArea?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("areaEmissionFactor", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Emission Factor (gm/sec/unit area)"
                                    id="areaEmissionFactor"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.areaEmissionFactor && errors.areaEmissionFactor.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAreaSourceData)} startIcon={<material.DoneIcon />}>{openPollutantDetailsDialog.pollutantData ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddAreaSourcePollutantDetails;
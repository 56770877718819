import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    datePeriod: {}
};

const datePeriodSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        datePeriodAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: true,
                datePeriod: action.payload,
            };
            return loginAuthState;
        }
    },
})

export const { datePeriodAction } = datePeriodSlice.actions;
export default datePeriodSlice.reducer;
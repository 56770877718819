
export const getDataFilterByCurrentApprovedDataAndNullApprovedDate = (sourceData) => {
    let totalData = [];
    let latestApprovedData = null;
    let nullData = sourceData.filter((ele) => ele.approvedDate === null || ele.approvedDate === "");
    let filterApprovedData = sourceData.filter((ele) => ele.approvedStatus === "APPROVED")
    if (filterApprovedData.length) {
        latestApprovedData = filterApprovedData.reduce((prev, current) =>
            new Date(current.approvedDate) > new Date(prev.approvedDate) ? current : prev
        );
    };
    if (nullData.length && latestApprovedData !== null) {
        totalData = [latestApprovedData, ...nullData];
    } else if (nullData.length === 0 && latestApprovedData !== null) {
        totalData = [latestApprovedData]
    } else {
        totalData = nullData
    }
    return totalData;
};

export const getDataFilterByCurrentApprovedDataAndNotApprovedDate = (sourceData) => {
    let totalData = [];
    let latestApprovedData = null;
    let nullData = sourceData.filter((ele) => ele.approved_date === null || ele.approved_date === "");
    let filterApprovedData = sourceData.filter((ele) => ele.approved_status === "APPROVED")
    if (filterApprovedData.length) {
        latestApprovedData = filterApprovedData.reduce((prev, current) =>
            new Date(current.approved_date) > new Date(prev.approved_date) ? current : prev
        );
    };
    if (nullData.length && latestApprovedData !== null) {
        totalData = [latestApprovedData, ...nullData];
    } else if (nullData.length === 0 && latestApprovedData !== null) {
        totalData = [latestApprovedData]
    } else {
        totalData = nullData
    }
    return totalData;
};
import React, { useState, useEffect } from 'react';
import { material } from '../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { addUsers } from '../services/UsermanagementService';
import Snackbar from '../toaster-message/Snackbar';


var usersRole;
var corporateRefNo = [];
var corporateData;

function EditOrViewUser(props) {

    const { openEditOrViewUser, setOpenEditOrViewUser, getUserList, usersData, corporateData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [role, setRole] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        if (openEditOrViewUser.usersData) {
            reset(openEditOrViewUser.usersData)
            setRole(openEditOrViewUser.usersData.role)
        };
    }, [openEditOrViewUser.action === true])

    const handleClose = () => {
        reset({ 'userName': "", "name": "", 'email': "", 'role': "", 'password': "", 'corporateName': "" })
        setOpenEditOrViewUser({ "action": false, usersData: null })
    };

    const editSaveUser = async (data) => {
        let obj = {
            "username": data.username,
            "name": data.name,
            "email": data.email,
            "role": [
                data.role
            ],
            // "corporateRefNo": data.corporateRefNo,
            // "siteRefNo": data.facilityRefNo,
            "updateUser": true,
            "active": data.isActive
        }
        await addUsers(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.message,
                })
                getUserList();
                reset({ 'userName': "", "name": "", 'email': "", 'role': "", 'password': "", 'corporateName': "" });
                setOpenEditOrViewUser({ "action": false, usersData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const selectRole = (event) => {
        setRole(event.target.value)
    };

    return (
        <div>

            <material.Dialog maxWidth="sm" fullWidth open={openEditOrViewUser.action}>
                <material.DialogTitle className='fw-bold'>Edit user</material.DialogTitle>
                <material.DialogContent>
                    <form>
                        <div>
                            <material.TextField
                                {...register("name", { required: true, })}
                                error={errors.name?.type === "required"}
                                margin="dense"
                                required
                                label="Name"
                                id="userName"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.name?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div>
                            <material.TextField
                                {...register("username", { required: true, })}
                                error={errors.username?.type === "required"}
                                margin="dense"
                                required
                                label="User Name"
                                id="userName"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <p className='form-text text-danger'>{errors.username?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div>
                            <material.TextField
                                {...register("email", { required: true, })}
                                error={errors.email?.type === "required"}
                                margin="dense"
                                required
                                label="Email"
                                id="email"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                        </div>
                        {openEditOrViewUser.usersData?.role === "ROLE_CORPORATE_EDITOR" || openEditOrViewUser.usersData?.role === "ROLE_CORPORATE_APPROVER" ? (
                            <div>
                                <material.TextField
                                    {...register("corporateName", { required: true, })}
                                    error={errors.corporateName?.type === "required"}
                                    margin="dense"
                                    required
                                    label="Corporate Name"
                                    id="corporateName"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                />
                                <p className='form-text text-danger'>{errors.corporateName?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : openEditOrViewUser.usersData?.role === "ROLE_FACILITY_EDITOR" || openEditOrViewUser.usersData?.role === "ROLE_FACILITY_APPROVER" || openEditOrViewUser.usersData?.role === "ROLE_FACILITY_ESG_DATA" ? (
                            <div>
                                <div>
                                    <material.TextField
                                        {...register("corporateName", { required: true, })}
                                        error={errors.corporateName?.type === "required"}
                                        margin="dense"
                                        required
                                        label="Corporate Name"
                                        id="corporateName"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                    />
                                    <p className='form-text text-danger'>{errors.corporateName?.type === "required" && 'This field is required'}</p>
                                </div>
                                <div>
                                    <material.TextField
                                        {...register("facilityName", { required: true, })}
                                        error={errors.facilityName?.type === "required"}
                                        margin="dense"
                                        required
                                        label="Facility Name"
                                        id="facilityName"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                    />
                                    <p className='form-text text-danger'>{errors.facilityName?.type === "required" && 'This field is required'}</p>
                                </div>
                            </div>
                        ) : null}
                        {openEditOrViewUser.usersData?.role === "ROLE_FACILITY_EDITOR" || openEditOrViewUser.usersData?.role === "ROLE_FACILITY_APPROVER" || openEditOrViewUser.usersData?.role === "ROLE_FACILITY_ESG_DATA" ? (
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel required id="role">Role</material.InputLabel>
                                    <material.Select
                                        {...register("role", { required: true })}
                                        error={errors.role?.type === "required"}
                                        margin="dense"
                                        id="role"
                                        value={role}
                                        onChange={selectRole}
                                    >
                                        <material.MenuItem value="ROLE_FACILITY_EDITOR">FACILITY EDITOR</material.MenuItem>
                                        <material.MenuItem value="ROLE_FACILITY_APPROVER">FACILITY APPROVER</material.MenuItem>
                                        <material.MenuItem value="ROLE_FACILITY_ESG_DATA">FACILITY ESG DATA</material.MenuItem>
                                    </material.Select>
                                    <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                </material.FormControl>
                            </div>
                        ) : openEditOrViewUser.usersData?.role === "ROLE_CORPORATE_EDITOR" || openEditOrViewUser.usersData?.role === "ROLE_CORPORATE_APPROVER" ? (
                            <div>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel required id="role">Role</material.InputLabel>
                                    <material.Select
                                        {...register("role", { required: true })}
                                        error={errors.role?.type === "required"}
                                        margin="dense"
                                        id="role"
                                        value={role}
                                        onChange={selectRole}
                                    >
                                        <material.MenuItem value="ROLE_CORPORATE_EDITOR">CORPORATE EDITOR</material.MenuItem>
                                        <material.MenuItem value="ROLE_CORPORATE_APPROVER">CORPORATE APPROVER</material.MenuItem>
                                    </material.Select>
                                    <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                </material.FormControl>
                            </div>
                        ) : openEditOrViewUser.usersData?.role === "ROLE_ADMIN" ? (
                            <material.FormControl variant="standard" fullWidth>
                                <material.InputLabel required id="role">Role</material.InputLabel>
                                <material.Select
                                    {...register("role", { required: true })}
                                    error={errors.role?.type === "required"}
                                    margin="dense"
                                    id="role"
                                    value={role}
                                    onChange={selectRole}
                                >
                                    <material.MenuItem value="ROLE_ADMIN">ADMIN</material.MenuItem>
                                </material.Select>
                                <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                            </material.FormControl>
                        ) : null}
                    </form>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button color='success' variant="outlined" onClick={handleSubmit(editSaveUser)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default EditOrViewUser;
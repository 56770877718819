import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getEnvironmentalCriteriaData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/environmental-criteria/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateEnvironmentalCriteriaData = async (EnvironmentalCriteriaData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/environmental-criteria/save-or-update`, EnvironmentalCriteriaData)
};

export const sendForApprovalEnvironmentalCriteriaData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/environmental-criteria/send-for-approval`, recordIds)
};

export const approveEnvironmentalCriteriaData = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/environmental-criteria/approve`, payload)
};










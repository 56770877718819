import React, { useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddNse from './AddNse';
import { useSelector } from 'react-redux';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import Snackbar from '../../toaster-message/Snackbar';
import { deleteNse } from '../../services/materiality-services/MaterialityService';

function NseMateriality(props) {

    const { getSiteBySiteRefNo, facilityData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openNse, setOpenNse] = useState({ action: false, editData: null });
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const addNseDialog = () => {
        setOpenNse({ action: true, editData: null });
    };

    const editNseData = (rowData) => {
        setOpenNse({ action: true, editData: rowData });
    };

    const deleteConfirmation = (rowData) => {
        setOpenDeleteRecordConfirmationDialog(true);
        setDeleteRowData(rowData);
    };

    const deleteProductServiceData = (rowData) => {
        deleteNse(userDetails.facilityRefNo, rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSiteBySiteRefNo();
                setOpenDeleteRecordConfirmationDialog(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };


    return (
        <>
            <div className='row'>
                {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                    <div className='col-12'>
                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", float: 'inline-end' }} startIcon={<material.AddIcon />} onClick={addNseDialog} >Add NSE</material.Button>
                    </div>
                ) : null}
                <div className='col-12 mt-3'>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Code</StyledTableCell>
                                    <StyledTableCell>Sector</StyledTableCell>
                                    <StyledTableCell>Industry Code</StyledTableCell>
                                    <StyledTableCell>Industry</StyledTableCell>
                                    <StyledTableCell>Basic Industry Code</StyledTableCell>
                                    <StyledTableCell>Basic Industry</StyledTableCell>
                                    <StyledTableCell>Definition</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {facilityData?.nseDtos?.length ? facilityData.nseDtos.map((nse, k) => (
                                    <material.TableRow key={k}
                                    // sx={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                    // onClick={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? () => editNseData(nse) : null}
                                    >
                                        <material.TableCell>{nse.sectCode}</material.TableCell>
                                        <material.TableCell>{nse.sector}</material.TableCell>
                                        <material.TableCell>{nse.indCode}</material.TableCell>
                                        <material.TableCell>{nse.industry}</material.TableCell>
                                        <material.TableCell>{nse.basicIndCode}</material.TableCell>
                                        <material.TableCell>{nse.basicIndustry}</material.TableCell>
                                        <material.TableCell className='text-break' size='small' style={{ width: "20rem" }}>{nse.definition}</material.TableCell>
                                        <material.TableCell>
                                            <material.IconButton color='error' onClick={() => deleteConfirmation(nse)}>
                                                <material.DeleteIcon />
                                            </material.IconButton>
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={8}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddNse
                openNse={openNse}
                setOpenNse={setOpenNse}
                getSiteBySiteRefNo={getSiteBySiteRefNo}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                deleteProductServiceData={deleteProductServiceData}
                callFrom="NSE"
                deleteRowData={deleteRowData}
            />
        </>
    )
}

export default NseMateriality
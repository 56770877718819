import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateIndigenousPeoplesRightsData } from '../../services/sub-issue-services/indigenous-peoples-services/IndigenousPeoplesRightsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddIndigenousPeoplesRights(props) {

    const { openIndigenousPeoplesRights, setOpenIndigenousPeoplesRights, getIndigenousPeoplesRightsAllData, treeViewData } = props;
    const { register, handleSubmit, reset, watch, getValues, control, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeNumberOfViolationsIncidents = watch('numberOfViolationsIncidents');
    const onChangeIncidentReviewed = watch('incidentReviewed');
    const onChangeRemediationPlanImplemented = watch('remediationPlanImplemented');
    const onChangeRemediationPlansReviewed = watch('remediationPlansReviewed');
    const onChangeIncidentNoLongerSubjectToAction = watch('incidentNoLongerSubjectToAction');

    useEffect(() => {
        totalCalculation();
    }, [onChangeNumberOfViolationsIncidents, onChangeIncidentReviewed, onChangeRemediationPlanImplemented, onChangeRemediationPlansReviewed, onChangeIncidentNoLongerSubjectToAction]);

    const totalCalculation = () => {
        let numberOfViolationsIncidentsValue = getValues('numberOfViolationsIncidents');
        let incidentReviewedValue = getValues('incidentReviewed');
        let remediationPlanImplementedValue = getValues('remediationPlanImplemented');
        let remediationPlansReviewedValue = getValues('remediationPlansReviewed');
        let incidentNoLongerSubjectToActionValue = getValues('incidentNoLongerSubjectToAction');
        if (Number(incidentReviewedValue) > Number(numberOfViolationsIncidentsValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Incident reviewed value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(remediationPlanImplementedValue) > Number(numberOfViolationsIncidentsValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Remediation plan implemented value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(remediationPlansReviewedValue) > Number(numberOfViolationsIncidentsValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Remediation plans reviewed value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else if (Number(incidentNoLongerSubjectToActionValue) > Number(numberOfViolationsIncidentsValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Incident no longer subject to action value not greater than Number of violations incidents value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (openIndigenousPeoplesRights.action && openIndigenousPeoplesRights.indigenousPeoplesRightsData) {
            reset(openIndigenousPeoplesRights.indigenousPeoplesRightsData)
        }
    }, [openIndigenousPeoplesRights.action === true])

    const handleClose = () => {
        reset({ numberOfViolationsIncidents: "", incidentReviewed: "", remediationPlanImplemented: "", remediationPlansReviewed: "", incidentNoLongerSubjectToAction: "" });
        setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsData: null });
    };

    const saveChildAndForcedLabour = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "numberOfViolationsIncidents": Number(data.numberOfViolationsIncidents),
                "incidentReviewed": Number(data.incidentReviewed),
                "remediationPlanImplemented": Number(data.remediationPlanImplemented),
                "remediationPlansReviewed": Number(data.remediationPlansReviewed),
                "incidentNoLongerSubjectToAction": Number(data.incidentNoLongerSubjectToAction),
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateIndigenousPeoplesRightsData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getIndigenousPeoplesRightsAllData();
                    reset({ numberOfViolationsIncidents: "", incidentReviewed: "", remediationPlanImplemented: "", remediationPlansReviewed: "", incidentNoLongerSubjectToAction: "" });
                    setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateChildAndForcedLabour = (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openIndigenousPeoplesRights.indigenousPeoplesRightsData.refNo,
            "numberOfViolationsIncidents": Number(data.numberOfViolationsIncidents),
            "incidentReviewed": Number(data.incidentReviewed),
            "remediationPlanImplemented": Number(data.remediationPlanImplemented),
            "remediationPlansReviewed": Number(data.remediationPlansReviewed),
            "incidentNoLongerSubjectToAction": Number(data.incidentNoLongerSubjectToAction),
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateIndigenousPeoplesRightsData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getIndigenousPeoplesRightsAllData();
                reset({ numberOfViolationsIncidents: "", incidentReviewed: "", remediationPlanImplemented: "", remediationPlansReviewed: "", incidentNoLongerSubjectToAction: "" });
                setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openIndigenousPeoplesRights.indigenousPeoplesRightsData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getIndigenousPeoplesRightsAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ numberOfViolationsIncidents: "", incidentReviewed: "", remediationPlanImplemented: "", remediationPlansReviewed: "", incidentNoLongerSubjectToAction: "" });
                setOpenIndigenousPeoplesRights({ action: false, indigenousPeoplesRightsData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openIndigenousPeoplesRights.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsData ? "Edit Indigenous Peoples Rights" : "Add Indigenous Peoples Rights"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Indigenous Peoples Rights Data</material.Typography>
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("numberOfViolationsIncidents", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Number of Violations Incidents"
                                    id="numberOfViolationsIncidents"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.numberOfViolationsIncidents && errors.numberOfViolationsIncidents.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("incidentReviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Incident Reviewed"
                                    id="incidentReviewed"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.incidentReviewed && errors.incidentReviewed.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("remediationPlanImplemented", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Remediation Plan Implemented"
                                    id="remediationPlanImplemented"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.remediationPlanImplemented && errors.remediationPlanImplemented.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("remediationPlansReviewed", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Remediation Plans Reviewed"
                                    id="remediationPlansReviewed"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.remediationPlansReviewed && errors.remediationPlansReviewed.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-md-4 py-2'>
                                <material.TextField
                                    {...register("incidentNoLongerSubjectToAction", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Incident no Longer Subject to Action"
                                    id="incidentNoLongerSubjectToAction"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.incidentNoLongerSubjectToAction && errors.incidentNoLongerSubjectToAction.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openIndigenousPeoplesRights.indigenousPeoplesRightsData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} disabled={buttonDisable} onClick={handleSubmit(updateChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openIndigenousPeoplesRights.indigenousPeoplesRightsData.rejectionNote === null) || (openIndigenousPeoplesRights.indigenousPeoplesRightsData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" disabled={buttonDisable} sx={{ textTransform: "none" }} onClick={handleSubmit(saveChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddIndigenousPeoplesRights;
import React from 'react';
import { material } from '../library/Material';
import { useNavigate } from 'react-router-dom';

function NoMatch(props) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1)
    };

    return (
        <div className='row mt-5'>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
                <span className='text-center'>
                    <material.Typography>Not Yet Implemented</material.Typography>
                </span>
            </div>
            <div className="col-lg-4">
                <span className="float-end">
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.ReplyIcon />} onClick={goBack}>Go Back</material.Button>
                </span>
            </div>
        </div>
    );
}

export default NoMatch;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddAirEmissionsInteractions from '../../../sub-issues-dialogs/air-emissions-interactions/AddAirEmissionsInteractions';
import { approveAirEmissionsInteractionsData, getAirEmissionsInteractionsData, sendForApprovalAirEmissionsInteractionsData } from '../../../services/sub-issue-services/air-service/AirEmissionsInteractionsService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';



var recordRefNos = [];
const label = { inputProps: { 'aria-label': 'Color switch demo', 'aria-label2': 'Checkbox demo' } };
var usersRole;

function AirEmissionsInteractions(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [airEmissionsInteractionsTabs, setAirEmissionsInteractionsTabs] = useState('Air Emissions & Interactions');
    const [airEmissionsInteractionsTableData, setAirEmissionsInteractionsTableData] = useState([]);
    const [addOpenAirEmissionsInteractions, setAddOpenAirEmissionsInteractions] = useState({ action: false, airEmissionsInteractionsTableData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getAllAirEmissionsInteractionsData()
    }, [airEmissionsInteractionsTabs]);

    const getFormDateToDate = (data) => {
        getAllAirEmissionsInteractionsData(data)
    };

    const getAllAirEmissionsInteractionsData = async (filterDate) => {
        if (airEmissionsInteractionsTabs === "Air Emissions & Interactions") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAirEmissionsInteractionsData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList);
                    setAirEmissionsInteractionsTableData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (airEmissionsInteractionsTabs === "Air Emissions & Interactions History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAirEmissionsInteractionsData(payloadData)
                .then((resp) => {
                    setAirEmissionsInteractionsTableData(resp.data.dataDtoList)
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const addAirEmissionsInteractions = () => {
        setAddOpenAirEmissionsInteractions({ action: true, airEmissionsInteractionsTableData: null });
    };

    const editAirEmissionsInteractions = (rowData) => {
        setAddOpenAirEmissionsInteractions({ action: true, airEmissionsInteractionsTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendForApproval = async () => {
        if (recordRefNos.length) {
            await sendForApprovalAirEmissionsInteractionsData(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getAllAirEmissionsInteractionsData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const approve = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveAirEmissionsInteractionsData(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getAllAirEmissionsInteractionsData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const reject = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // await approveAirEmissionsInteractionsData("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
            //     .then((resp) => {
            //         recordRefNos = [];
            //         getAllAirEmissionsInteractionsData();
            //         setOpenSnackBar({
            //             "action": true,
            //             "type": "success",
            //             "message": resp.data,
            //         })
            //     })
            //     .catch((error) => {
            //         setOpenSnackBar({
            //             "action": true,
            //             "type": "error",
            //             "message":  error.response?.data?.message ? error.response.data.message : error.response.data,
            //         })
            //     })
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveAirEmissionsInteractionsData(obj)
            .then((resp) => {
                recordRefNos = [];
                getAllAirEmissionsInteractionsData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const handleChangeTabs = (event, newValue) => {
        setAirEmissionsInteractionsTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            <span className='float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={airEmissionsInteractionsTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Air Emissions & Interactions" value="Air Emissions & Interactions" />
                                    <material.Tab className='text-capitalize' label="Air Emissions & Interactions History" value="Air Emissions & Interactions History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Air Emissions & Interactions">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-6 pb-2">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <span className="float-end">
                                                {airEmissionsInteractionsTableData.length ? (
                                                    <>
                                                        <material.Button variant="contained" color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                            </span>
                                        ) : (
                                            <span className="float-end">
                                                <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addAirEmissionsInteractions}>Add Air Emissions & Interactions</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Monitoring Location ID</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                                    <StyledTableCell>Measured Value</StyledTableCell>
                                                    <StyledTableCell>National Standard</StyledTableCell>
                                                    <StyledTableCell>Unit</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                    )}
                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {airEmissionsInteractionsTableData?.length ? airEmissionsInteractionsTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                            () => editAirEmissionsInteractions(item) : null}>
                                                        <material.TableCell >{item.monitoringLocationIds}</material.TableCell>
                                                        <material.TableCell >{item.monitoringLocationEasting}</material.TableCell>
                                                        <material.TableCell >{item.monitoringLocationNorthing}</material.TableCell>
                                                        <material.TableCell >{item.measuredValue}</material.TableCell>
                                                        <material.TableCell >{item.nationalStandard}</material.TableCell>
                                                        <material.TableCell >{item.unit}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell >{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )}
                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={13}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Air Emissions & Interactions History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Monitoring Location ID</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                                    <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                                    <StyledTableCell>Measured Value</StyledTableCell>
                                                    <StyledTableCell>National Standard</StyledTableCell>
                                                    <StyledTableCell>Unit</StyledTableCell>
                                                    <StyledTableCell>From Date</StyledTableCell>
                                                    <StyledTableCell>To Date</StyledTableCell>
                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                    <StyledTableCell>Status</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>

                                            <material.TableBody>
                                                {airEmissionsInteractionsTableData?.length ? airEmissionsInteractionsTableData.map((item, ind) => (
                                                    <material.TableRow key={ind} >
                                                        <material.TableCell>{item.monitoringLocationIds}</material.TableCell>
                                                        <material.TableCell>{item.monitoringLocationEasting}</material.TableCell>
                                                        <material.TableCell>{item.monitoringLocationNorthing}</material.TableCell>
                                                        <material.TableCell>{item.measuredValue}</material.TableCell>
                                                        <material.TableCell>{item.nationalStandard}</material.TableCell>
                                                        <material.TableCell>{item.unit}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddAirEmissionsInteractions
                addOpenAirEmissionsInteractions={addOpenAirEmissionsInteractions}
                setAddOpenAirEmissionsInteractions={setAddOpenAirEmissionsInteractions}
                getAllAirEmissionsInteractionsData={getAllAirEmissionsInteractionsData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getAllAirEmissionsInteractionsData={getAllAirEmissionsInteractionsData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default AirEmissionsInteractions;
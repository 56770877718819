import React, { useEffect, useState } from 'react';
import Snackbar from '../../toaster-message/Snackbar';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateRetirementBenefitData } from '../../services/sub-issue-services/employee-services/RetirementBenefitService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DatePeriod from '../../shared/DatePeriod';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddRetirementBenefit(props) {

    const { openRetirementBenefit, setOpenRetirementBenefit, getRetirementBenefitAllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [Category, setCategory] = useState("");
    const [deductedDepositedAuthority, setDeductedDepositedAuthority] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openRetirementBenefit.action && openRetirementBenefit.retirementBenefitData) {
            setCategory(openRetirementBenefit.retirementBenefitData.category);
            setDeductedDepositedAuthority(openRetirementBenefit.retirementBenefitData.deductedAndDepositedWithTheAuthority);
            reset(openRetirementBenefit.retirementBenefitData);
        }
    }, [openRetirementBenefit.action === true])

    const handleClose = () => {
        reset({ providentFund: "", esi: "", othersPleaseSpecify: "", gratuity: "" });
        setCategory("");
        setDeductedDepositedAuthority("");
        setOpenRetirementBenefit({ action: false, retirementBenefitData: null });

    };
    const changeCategory = (event) => {
        setCategory(event.target.value);
    };
    const changeDeductedDepositedAuthority = (event) => {
        setDeductedDepositedAuthority(event.target.value);
    };
    const saveRetirementBenefit = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "category": data.category,
                "deductedAndDepositedWithTheAuthority": data.deductedAndDepositedWithTheAuthority,
                "othersPleaseSpecify": parseFloat(data.othersPleaseSpecify),
                "applicable": true,
                "providentFund": parseFloat(data.providentFund),
                "gratuity": parseFloat(data.gratuity),
                "esi": parseFloat(data.esi),
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            saveOrUpdateRetirementBenefitData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getRetirementBenefitAllData()
                    reset({ providentFund: "", esi: "", othersPleaseSpecify: "", gratuity: "" });
                    setCategory("");
                    setDeductedDepositedAuthority("");
                    setOpenRetirementBenefit({ action: false, retirementBenefitData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateRetirementBenefit = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openRetirementBenefit.retirementBenefitData.refNo,
            "category": data.category,
            "deductedAndDepositedWithTheAuthority": data.deductedAndDepositedWithTheAuthority,
            "othersPleaseSpecify": parseFloat(data.othersPleaseSpecify),
            "applicable": true,
            "providentFund": parseFloat(data.providentFund),
            "gratuity": parseFloat(data.gratuity),
            "esi": parseFloat(data.esi),
            toDate: data.toDate,
            fromDate: data.fromDate,
        }
        saveOrUpdateRetirementBenefitData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getRetirementBenefitAllData()
                reset({ providentFund: "", esi: "", othersPleaseSpecify: "", gratuity: "" });
                setCategory("");
                setDeductedDepositedAuthority("");
                setOpenRetirementBenefit({ action: false, retirementBenefitData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openRetirementBenefit.retirementBenefitData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getRetirementBenefitAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ providentFund: "", esi: "", othersPleaseSpecify: "", gratuity: "" });
                setCategory("");
                setDeductedDepositedAuthority("");
                setOpenRetirementBenefit({ action: false, retirementBenefitData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openRetirementBenefit.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openRetirementBenefit.retirementBenefitData ? "Edit Retirement Benefit" : "Add Retirement Benefit"}
                </material.DialogTitle>
                <material.DialogContent>
                    <form>
                        <div className='my-3'>
                            {openRetirementBenefit.retirementBenefitData ? null : (
                                <DatePeriod
                                    register={register}
                                    control={control}
                                />
                            )}
                        </div>
                        <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-2 col-4'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Category</material.InputLabel>
                                        <material.Select
                                            {...register("category", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={Category}
                                            onChange={changeCategory}
                                            label="Category"
                                        >
                                            <material.MenuItem value={"Employee Parmanent"}>Employee Parmanent</material.MenuItem>
                                            <material.MenuItem value={"Employee other than Parmanent"}>Employee other than Parmanent</material.MenuItem>
                                            <material.MenuItem value={"Worker Parmanent"}>Worker Parmanent</material.MenuItem>
                                            <material.MenuItem value={"Worker other than Parmanent"}>Worker other than Parmanent</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='py-2 col-4'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Deducted & Deposited with the Authority</material.InputLabel>
                                        <material.Select
                                            {...register("deductedAndDepositedWithTheAuthority", { required: true, })}
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={deductedDepositedAuthority}
                                            onChange={changeDeductedDepositedAuthority}
                                            label="Deducted & Deposited With the Authority"
                                        >
                                            <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                            <material.MenuItem value={"No"}>No</material.MenuItem>
                                            <material.MenuItem value={"N.A"}>N.A</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                {deductedDepositedAuthority === "N.A" ? null : (
                                    <>
                                        <div className='py-2 col-4'>
                                            <material.TextField
                                                {...register("providentFund", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="PF"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.providentFund && errors.providentFund.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                        <div className='py-2 col-4'>
                                            <material.TextField
                                                {...register("gratuity", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="Gratuity"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.gratuity && errors.gratuity.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                        <div className='py-2 col-4'>
                                            <material.TextField
                                                {...register("esi", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="ESI"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.esi && errors.esi.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                        <div className='py-2 col-4'>
                                            <material.TextField
                                                {...register("othersPleaseSpecify", { required: true, pattern: /^[0-9]+$/ })}
                                                required
                                                label="Others Please Specify"
                                                id="userName"
                                                variant="standard"
                                                type='number'
                                                size="small"
                                                fullWidth
                                            />
                                            {errors.othersPleaseSpecify && errors.othersPleaseSpecify.type === "pattern" && (
                                                <p className="errorMsg text-danger">Allowed only number.</p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </material.Card>
                    </form>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openRetirementBenefit.retirementBenefitData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateRetirementBenefit)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openRetirementBenefit.retirementBenefitData.rejectionNote === null) || (openRetirementBenefit.retirementBenefitData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveRetirementBenefit)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddRetirementBenefit
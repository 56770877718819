import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getNoticesAndFinesData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/notice-and-fines/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory ,fromDate: payload.fromDate, toDate: payload.toDate} })
};

export const saveOrUpdateNoticesAndFinesData = async (NoticesAndFinesData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/notice-and-fines/save-or-update`, NoticesAndFinesData)
};

export const sendForApprovalNoticesAndFinesData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/notice-and-fines/send-for-approval`, recordIds)
};

export const approveNoticesAndFinesData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/notice-and-fines/approve`, recordIds)
};







import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getViolationAndExceedanceData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/violation-and-exceedance/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateViolationAndExceedanceData = async (ViolationAndExceedanceData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/violation-and-exceedance/save-or-update`, ViolationAndExceedanceData)
};

export const sendForApprovalViolationAndExceedanceData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/violation-and-exceedance/send-for-approval`, recordIds)
};

export const approveViolationAndExceedanceData = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/violation-and-exceedance/approve`, payloadData)
};











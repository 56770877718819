import React, { useState, useEffect } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { addGeographicalLocationData } from '../services/SiteService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';


function AddGeographicalLocation(props) {
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: "onTouched", });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddGeographicalLocation, setOpenAddGeographicalLocation, getSiteBySiteRefNo } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddGeographicalLocation.action && openAddGeographicalLocation.facilityData) {
            reset(openAddGeographicalLocation.facilityData)
        }
    }, [openAddGeographicalLocation.action === true]);



    const saveGeographicalLocation = async (data) => {
        await addGeographicalLocationData([{ ...data, "corporateRefNo": userDetails.corporateRefNo, "facilityRefNo": userDetails.facilityRefNo, }])
            .then((resp) => {
                getSiteBySiteRefNo()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Save successfully",
                })
                reset({ boundaryPoints: "", latitude: "", longitude: "" })
                setOpenAddGeographicalLocation({ action: false, facilityData: null });
            }).catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message":error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const cancel = () => {
        reset({ boundaryPoints: "", latitude: "", longitude: "" })
        setOpenAddGeographicalLocation({ action: false, facilityData: null });
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth open={openAddGeographicalLocation.action}>
                <material.DialogTitle><span><material.Typography variant="h5" className="headingText">{openAddGeographicalLocation.facilityData ? "Edit geographical location" : "Add geographical location"}</material.Typography></span></material.DialogTitle>
                <material.DialogContent>
                    <div className="">
                        <material.TextField
                            required
                            label="Boundary Points"
                            id="Name"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("boundaryPoints", { required: true })}
                        />
                    </div>
                    <div className="mt-3">
                        <material.TextField
                            required
                            label="Easting"
                            id="Name"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("latitude", { required: true, pattern: /^[0-9]+(?:.[0-9]{1,6}$)/ })}
                        />
                        {errors.latitude && errors.latitude.type === "pattern" && (
                            <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                        )}
                    </div>
                    <div className="mt-3">
                        <material.TextField
                            required
                            label="Northing"
                            id="Name"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            color='success'
                            {...register("longitude", { required: true, pattern: /^[0-9]+(?:.[0-9]{1,6}$)/ })}
                        />
                        {errors.longitude && errors.longitude.type === "pattern" && (
                            <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                        )}
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, pr: 3 }}>
                    <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 2 }} onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }} onClick={handleSubmit(saveGeographicalLocation)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddGeographicalLocation;
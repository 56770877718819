import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const saveOrUpdateEmployeeHiringData = async (employeeHiringData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/employee-and-worker-number/save-or-update`, employeeHiringData, { params: { typeOfRecord: employeeHiringData.typeOfRecord } })
};

export const getEmployeeHiringData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employee-and-worker-number/get-all`, { params: { corporateRefNo: payloadData.corporateRefNo, facilityRefNo: payloadData.facilityRefNo, forApproval: payloadData.forApproval, approveHistory: payloadData.approveHistory, typeOfRecord: payloadData.typeOfRecord, fromDate: payloadData.fromDate, toDate: payloadData.toDate } })
};

export const sendForApprovalEmployeeHiringRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/send-for-approval`, RecordIds);
};

export const approveEmployeeHiringRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/approve`, recordId)
};
import { rootApiUrl } from "../../../environment/Environment";
import { axiosInstance } from "../../../shared/AxiosConfig";


export const saveOrUpdateAirEmissionAreaSourceData = async (airEmissionQuantityData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/air-emissions-area-source/save-or-update`, airEmissionQuantityData)
};

export const getAirEmissionAreaSourceData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/air-emissions-area-source/get-all`, { params: payload })
};

export const sendForApprovalAirEmissionAreSourceRecords = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/air-emissions-area-source/send-for-approval`, recordId)
};

export const approveOrRejectAirEmissionAreSourceRecords = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/air-emissions-area-source/approve`, payload)
};

export const updateAreaSourcePollutantData = async (airEmissionQuantityData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/air-emissions-area-source/update-pollutant-data`, airEmissionQuantityData)
};
import React, { useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { generateOtpByUserName, saveNewPassword, verifyOtpByUserName } from '../services/UsermanagementService';
import Snackbar from '../toaster-message/Snackbar';

function ForgetPassword(props) {

    const { openForgetPasswordDialog, setOpenForgetPasswordDialog } = props;
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [getOtp, setGetOtp] = useState(false);
    const [otpVerify, setOtpVerify] = useState(false);
    const [type, setType] = useState("password");
    const [isVisible, setIsVisible] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(false);

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const generateOtp = async (formData) => {
        setIsLoading(true);
        await generateOtpByUserName(formData.userName)
            .then((resp) => {
                setIsLoading(false)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setGetOtp(true)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const verifyOtp = async (formData) => {
        await verifyOtpByUserName(formData.userName, formData.otp)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOtpVerify(true)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const changePassword = async (formData) => {
        await saveNewPassword(formData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ userName: "", otp: "", password: "" });
                setOpenForgetPasswordDialog(false);
                setGetOtp(false);
                setOtpVerify(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const close = () => {
        setOpenForgetPasswordDialog(false)
        reset({ userName: "", otp: "", password: "" })
        setGetOtp(false);
        setOtpVerify(false);
        setIsLoading(false)
    };

    return (
        <div>
            <material.Dialog open={openForgetPasswordDialog} maxWidth="sm" fullWidth>
                <material.DialogTitle className='fw-bold'>Forgot Password
                    <span className='float-end'>
                        <material.IconButton color='error' title='Close' onClick={close}>
                            <material.CloseIcon />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {isLoading ? (
                        <material.Stack spacing={1}>
                            <material.Skeleton variant="rounded" width="100%" height="20vh" />
                        </material.Stack>
                    ) : (
                        <div className='row'>
                            <div>
                                <material.TextField
                                    error={errors.userName?.type === "required"}
                                    {...register("userName", { required: true })}
                                    required
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Enter User Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    disabled={getOtp}
                                />
                                {(errors?.userName?.type) && (
                                    <p className='text-danger'>This field is required.</p>
                                )}
                            </div>
                            {getOtp ? (
                                <div>
                                    <material.TextField
                                        error={errors.otp?.type === "required"}
                                        {...register("otp", { required: true, minLength: 6, maxLength: 6 })}
                                        required
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Enter OTP"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        disabled={otpVerify}
                                    />
                                    {(errors?.otp?.type === "minLength" || errors?.otp?.type === "maxLength") && (
                                        <p className='text-danger'>Enter 6 digit otp</p>
                                    )}
                                </div>
                            ) : null}
                            {otpVerify ? (
                                <div>
                                    <material.TextField
                                        {...register("password", { required: true, })}
                                        required
                                        label="Enter New Password"
                                        id="password"
                                        variant="standard"
                                        type={type}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <material.InputAdornment position="end">
                                                    {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                                                </material.InputAdornment>
                                            ),
                                        }}
                                    />
                                    <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                                </div>
                            ) : null}
                            <span className='d-flex justify-content-center mt-3'>
                                {!getOtp ? (
                                    <material.Button onClick={handleSubmit(generateOtp)} color='success' variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Get-OTP</material.Button>
                                ) : null}
                                {!otpVerify && getOtp ? (
                                    <material.Button onClick={handleSubmit(verifyOtp)} color='success' variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Verify-OTP</material.Button>
                                ) : null}
                                {getOtp && otpVerify ? (
                                    <material.Button onClick={handleSubmit(changePassword)} color='success' variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} disabled={!isValid}>Change Password</material.Button>
                                ) : null}
                            </span>
                        </div>
                    )}
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ForgetPassword;
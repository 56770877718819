import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import { saveOrUpdateGrievanceRedressalData } from '../../services/sub-issue-services/GrievanceService';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';


const internalCategory = [
    "Employee Permanent", "Employee other than Permanent", "Worker Permanent", "Worker other than Permanent"
];
const externalCategory = [
    "Affected Community", "Vulnerable Group", "Women Group", "Government Agencies", "Customers", "Value Chain Partners", "Shareholders", "Investors other than Shareholders"
];
const internalType = [
    "Working Conditions", "Health & Safety", "POSH", "HR Issue", "Wages", "Discrimination at Workplace", "Child Labour", "Forced Labour", "Other Type (Specify)"
];
const externalType = [
    "Environmental Pollution", "Socio Economic Issues", "Biodiversity", "Community Health & Safety", "Natural Resource Sharing", "Quality of Products or Services", "Other Type (Specify)"
];

function AddGrievancesRedressalSystem(props) {

    const { openAddGrievancesRedressalSystem, setOpenAddGrievancesRedressalSystem, getGrievanceRedressalData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);

    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [selectNature, setSelectNature] = useState('');
    const [type, setType] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectType, setSelectType] = useState("");
    const [selectCategory, setSelectCategory] = useState("");
    const [dataMatched, setDataMatched] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeValue1 = watch('grievanceReceivedTotal');
    const onChangeValue2 = watch('grievancesResolved');

    useEffect(() => {
        totalCalculation();
    }, [onChangeValue1, onChangeValue2]);

    const totalCalculation = () => {
        let val1 = getValues('grievanceReceivedTotal');
        let val2 = getValues('grievancesResolved');
        if (Number(val1) < Number(val2)) {
            setDataMatched(false)
            setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": "The total number of grievances received cannot be less than the total number of grievances resolved.",
            })
        } else {
            setDataMatched(true)
        }
        if (val1 && val2) {
            let totalValue = Number(val1) - Number(val2);
            setValue("grievancesPending", totalValue);
        } else {
            setValue("grievancesPending", val1);
        }
    };

    useEffect(() => {
        if (openAddGrievancesRedressalSystem.action && openAddGrievancesRedressalSystem.grievancesData) {
            reset(openAddGrievancesRedressalSystem.grievancesData)
            setSelectNature(openAddGrievancesRedressalSystem.grievancesData ? openAddGrievancesRedressalSystem.grievancesData.nature : "")
            if (openAddGrievancesRedressalSystem.grievancesData.nature === "Internal") {
                setCategory(internalCategory)
                setType(internalType)
            } else if (openAddGrievancesRedressalSystem.grievancesData.nature === "External") {
                setType(externalType)
                setCategory(externalCategory)
            };
            setSelectType(openAddGrievancesRedressalSystem.grievancesData ? openAddGrievancesRedressalSystem.grievancesData.type : "")
            setSelectCategory(openAddGrievancesRedressalSystem.grievancesData.category)
        }
    }, [openAddGrievancesRedressalSystem.action === true]);

    const handleChangeNature = (event) => {
        setSelectNature(event.target.value);
        if (event.target.value === "Internal") {
            setCategory(internalCategory)
            setType(internalType)
        } else if (event.target.value === "External") {
            setType(externalType)
            setCategory(externalCategory)
        }
    };

    const handleCategoryChange = (event) => {
        setSelectCategory(event.target.value);
    };
    const handleTypeChange = (event) => {
        setSelectType(event.target.value);
    };
    const handleClose = () => {
        setSelectNature("")
        setSelectType("")
        setCategory([])
        setSelectCategory("")
        reset({ grievanceReceivedTotal: "", grievancesResolved: "", grievancesPending: "", otherSpecify: "" })
        setOpenAddGrievancesRedressalSystem({ action: false, grievancesData: null });
    };
    const saveGrievanceRedressalSystem = async (formData) => {
        let payLoad = generateFromDateAndToDate(formData);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                ...formData,
                "refNo": "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateGrievanceRedressalData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGrievanceRedressalData()
                    setSelectNature("")
                    setSelectType("")
                    setCategory([])
                    setSelectCategory("")
                    reset({ grievanceReceivedTotal: "", grievancesResolved: "", grievancesPending: "", otherSpecify: "" })
                    setOpenAddGrievancesRedressalSystem({ action: false, grievancesData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddGrievancesRedressalSystem.grievancesData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getGrievanceRedressalData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                getGrievanceRedressalData()
                setSelectNature("")
                setSelectType("")
                setCategory([])
                setSelectCategory("")
                reset({ grievanceReceivedTotal: "", grievancesResolved: "", grievancesPending: "", otherSpecify: "" })
                setOpenAddGrievancesRedressalSystem({ action: false, grievancesData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const updateGrievanceRedressalSystem = async (formData) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            ...formData,
            "refNo": openAddGrievancesRedressalSystem.grievancesData.refNo,
            toDate: formData.toDate,
            fromDate: formData.fromDate,
            otherSpecify: selectType === "Other Type (Specify)" ? formData.otherSpecify : ""
        }
        await saveOrUpdateGrievanceRedressalData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getGrievanceRedressalData()
                setSelectNature("")
                setSelectType("")
                setCategory([])
                setSelectCategory("")
                reset({ grievanceReceivedTotal: "", grievancesResolved: "", grievancesPending: "" })
                setOpenAddGrievancesRedressalSystem({ action: false, grievancesData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openAddGrievancesRedressalSystem.action} maxWidth="lg"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openAddGrievancesRedressalSystem.grievancesData ? "Edit Grievances Redressal System" : "Add Grievances Redressal System"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openAddGrievancesRedressalSystem.grievancesData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row mt-2'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Grievances Redressal System</material.Typography>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Nature</material.InputLabel>
                                    <material.Select
                                        {...register("nature", { required: true, })}
                                        id="nature"
                                        value={selectNature}
                                        onChange={handleChangeNature}
                                        label="Internal category"
                                    >
                                        <material.MenuItem value={"Internal"}>Internal</material.MenuItem>
                                        <material.MenuItem value={"External"}>External</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Category</material.InputLabel>
                                    <material.Select
                                        {...register("category", { required: true, })}
                                        id="internalCategory"
                                        value={selectCategory}
                                        onChange={handleCategoryChange}
                                        label="Internal category"
                                    >
                                        {category.map((ele, i) => (
                                            <material.MenuItem key={i} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="demo-simple-select-standard-label">Type</material.InputLabel>
                                    <material.Select
                                        {...register("type", { required: true, })}
                                        required
                                        id="type"
                                        value={selectType}
                                        onChange={handleTypeChange}
                                        label="Internal type"
                                    >
                                        {type.map((ele, i) => (
                                            <material.MenuItem key={i} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {selectType === "Other Type (Specify)" ? (
                                <div className='col-lg-4 col-md-6 mt-3'>
                                    <material.TextField
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Type (Specify)"
                                        id="otherSpecify"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4 col-md-6 mt-3'>
                                <material.TextField
                                    {...register("grievanceReceivedTotal", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Grievance Received Total"
                                    id="grievanceReceivedTotal"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.grievanceReceivedTotal && errors.grievanceReceivedTotal.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-6 mt-3'>
                                <material.TextField
                                    {...register("grievancesResolved", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Grievance Resolved"
                                    id="grievancesResolved"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.grievancesResolved && errors.grievancesResolved.type === "pattern" && (
                                    <p className="errorMsg text-danger">No negative value allowed & no decimal value allowed</p>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-6 mt-3'>
                                <material.TextField
                                    {...register("grievancesPending", { required: true, pattern: /^\+?(0|[1-9]\d*)$/ })}
                                    required
                                    label="Grievance Pending"
                                    id="grievancesPending"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                                {errors.grievancesPending && errors.grievancesPending.type === "pattern" && (
                                    <p className="errorMsg text-danger">No negative value allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddGrievancesRedressalSystem.grievancesData ? (
                        <>
                            <material.Button color='success' variant="outlined" onClick={handleSubmit(updateGrievanceRedressalSystem)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddGrievancesRedressalSystem.grievancesData.rejectionNote === null) || (openAddGrievancesRedressalSystem.grievancesData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" onClick={handleSubmit(saveGrievanceRedressalSystem)} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} disabled={!dataMatched}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddGrievancesRedressalSystem; 
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveFacilityIssueMapping } from '../../services/ManageEntityService';

function EditEntity(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const navigate = useNavigate();
    const location = useLocation();
    const entityData = location.state?.editData;

    const goBack = () => {
        navigate("/manage-entity");
    };

    const updateEntity = (data) => {
        const transformedData = Object.keys(data).map(key => {
            return {
                topicId: parseInt(key),
                allowed: data[key]
            };
        });
        const payload = {
            corporateRefNo: userDetails.roles.includes("ROLE_ADMIN") ? entityData[0]?.children[0]?.corporateRefNo : userDetails.corporateRefNo,
            facilityRefNo: [entityData[0]?.children[0]?.facilityRefNo],
            data: transformedData
        }
        saveFacilityIssueMapping(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                navigate("/manage-entity");
                reset();
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className="col-6">
                            <span>
                                {userDetails.roles.includes("ROLE_ADMIN") ?
                                    (<material.Typography variant="h5">Edit Entity for {entityData[0]?.children[0]?.facilityName} under {entityData[0]?.children[0]?.corporateName}</material.Typography>)
                                    :
                                    (<material.Typography variant="h5">Edit Entity for {entityData[0]?.children[0]?.facilityName}</material.Typography>)
                                }
                            </span>
                        </div>
                        <div className="col-6">
                            <span className='float-end'>
                                <material.Button variant="contained" color='info' sx={{ textTransform: "none" }}
                                    onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                            </span>
                        </div>
                    </div>
                    <material.Paper elevation={3} sx={{ p: 3, mt: 2, pb: 7 }} className='column-chart'>
                        <form>
                            {entityData ? entityData.map((pillar, i) => (
                                <material.Accordion sx={{ mt: 2 }} key={i}>
                                    <material.AccordionSummary
                                        expandIcon={<material.ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>{pillar.attributeName}</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <div className='row mt-2'>
                                            {pillar.children.map((issue, k) => (
                                                <div className="col-lg-12 col-md-12 col-sm-12" key={k}>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <material.Typography>{issue.attributeName}</material.Typography>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <material.Stack direction="row" spacing={1} alignItems="center">
                                                                <material.FormGroup>
                                                                    <material.FormControlLabel labelPlacement="start"
                                                                        control={<material.Switch color="success" defaultChecked={issue.allowed} />}
                                                                        {...register(`${issue.id}`)}
                                                                    />
                                                                </material.FormGroup>
                                                            </material.Stack>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </material.AccordionDetails>
                                </material.Accordion>

                            )) : null}
                            <div className="col-12 mt-3">
                                <span className='float-end'>
                                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }}
                                        onClick={handleSubmit(updateEntity)} startIcon={<material.DoneIcon />}>Update</material.Button>
                                </span>
                            </div>
                        </form>
                    </material.Paper>
                </material.Box>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default EditEntity;
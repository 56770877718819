import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';

function DeleteRecordConfirmationDialog(props) {

    const { openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog, treeViewData, deleteRecordByRefNo, deleteProductServiceData, callFrom, deleteRowData } = props;
    const [selectValue, setSelectValue] = useState("Yes");

    useEffect(() => {
        if (openDeleteRecordConfirmationDialog) {
            setSelectValue("");
        }
    }, [openDeleteRecordConfirmationDialog === true]);

    const handleChange = (event) => {
        let value = event.target.value;
        setSelectValue(value);
        if (value === "Yes" && treeViewData?.subIssue) {
            deleteRecordByRefNo();
        } else if (value === "Yes" && callFrom) {
            deleteProductServiceData(deleteRowData)
        }
        else {
            setOpenDeleteRecordConfirmationDialog(false);
        };
    };

    return (
        <div>
            <material.Dialog open={openDeleteRecordConfirmationDialog} maxWidth="auto">
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>You are about to delete a record from {callFrom} {treeViewData?.subIssue} !</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectValue}
                                    onChange={handleChange}
                                >
                                    <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
                                    <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default DeleteRecordConfirmationDialog;
import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";


export const getAllCorporate = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/corporate/get-all-corporates-with-facilities`)
};

export const addCorporate = async (corporateData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/corporate/add-corporate`, corporateData)
};

export const getCorporateDetailsByRefNo = async (corporateRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/corporate/get-corporate`, { params: { corporateRefNo: corporateRefNo } })
};

export const addUpdateCorporateOrFacilityPointOfContact = async (contactDetails) => {
    return await axiosInstance.post(`${rootApiUrl}/api/corporate/add-update-corporate-or-facility-point-of-contact`, contactDetails)
};

export const getCorporateWithFacilitiesByCorporateRefNo = async (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate/get-corporate-with-facilities`, { params: { corporateRefNo: corporateRefNo } })
};

export const getCorporateNicDetails = async (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate/get-corporate-nic-details`, { params: { corporateRefNo: corporateRefNo } })
};

export const getFacilityCountByNationalInterNational = async (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate/get-facility-count-by-national-international`, { params: { corporateRefNo: corporateRefNo } })
};

export const getFacilityCountByStateCountry = async (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate/get-facility-count-by-state-country`, { params: { corporateRefNo: corporateRefNo } })
};

// export const saveNicDetails = async (nicData) => {
//     return axiosInstance.post(`${rootApiUrl}/api/corporate/save-nic-details-corporate`, nicData)
// };

export const saveCorporateProductDetails = async (details) => {
    return axiosInstance.post(`${rootApiUrl}/api/corporate-product-service-details/save`, details)
};

export const getMarketServedEntity = (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate/get-market-serve-stats`, { params: { corporateRefNo: corporateRefNo } })
};

export const getProductServiceDetailsByCorporateRefNo = (corporateRefNo) => {
    return axiosInstance.get(`${rootApiUrl}/api/corporate-product-service-details/get-by-corporate-ref-no`, { params: { corporateRefNo } })
};
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import moment from 'moment';
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddDifferentlyAbledEmployeesWorkers from '../../../sub-issues-dialogs/differently-abled-employees-workers-subissue/AddDifferentlyAbledEmployeesWorkers';
import { approveDifferentlyAbledEmployeesWorkersRecord, getAllDifferentlyAbledEmployeesWorkersData, sendForApprovalDifferentlyAbledEmployeesWorkersRecord } from '../../../services/sub-issue-services/DifferentlyAbledEmployeesWorkersService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../../toaster-message/Snackbar';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import EmployeeAgeBasedDataDialog from '../../../sub-issues-dialogs/employee/EmployeeAgeBasedDataDialog';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import DatePeriod from '../../../shared/DatePeriod';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function DifferentlyAbledEmployeesAndWorkers(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const [differentlyAbledEmployeesWorkers, setDifferentlyAbledEmployeesWorkers] = useState("Differently Abled Employees & Workers");
    const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("No");
    const [openAddDifferentlyAbledEmployeesWorkers, setOpenAddDifferentlyAbledEmployeesWorkers] = useState({ action: false, differentlyAbledEmployeesWorkersData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [differentlyAbledEmployeesWorkersData, setDifferentlyAbledEmployeesWorkersData] = useState([]);
    const [openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable] = useState({ action: false, employeesAgeBasedData: null, collForm: "DIFFERENTLY_ABLED_EMPLOYEE_WORKERS" });
    const [accordionIsDisable, setAccordionIsDisable] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(null);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    useEffect(() => {
        getDifferentlyAbledEmployeesWorkersData()
    }, [differentlyAbledEmployeesWorkers]);

    const getFormDateToDate = (data) => {
        getDifferentlyAbledEmployeesWorkersData(data)
    };

    const getDifferentlyAbledEmployeesWorkersData = async (data) => {
        if (differentlyAbledEmployeesWorkers === "Differently Abled Employees & Workers") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS"
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                typeOfRecord,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getAllDifferentlyAbledEmployeesWorkersData(object)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dtoList)
                    setDifferentlyAbledEmployeesWorkersData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                    if (resp.data.dtoList.length) {
                        resp.data?.dtoList.forEach(ele => {
                            setIsEmployeeAgeBasedDataPresent(ele.isAgeBasedDataPresent ? "Yes" : "No")
                            setAccordionIsDisable(ele.isAgeBasedDataPresent)
                        })
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                });
        } else if (differentlyAbledEmployeesWorkers === "Differently Abled Employees & Workers History") {
            let typeOfRecord = "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS"
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                typeOfRecord,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getAllDifferentlyAbledEmployeesWorkersData(object)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    setDifferentlyAbledEmployeesWorkersData(resp.data.dtoList)
                    if (resp.data.dtoList.length) {
                        resp.data?.dtoList.forEach(ele => {
                            setIsEmployeeAgeBasedDataPresent(ele.isAgeBasedDataPresent ? "Yes" : "No")
                            setAccordionIsDisable(ele.isAgeBasedDataPresent)
                        })
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        }
    };

    const applicableOrNotAbledEmployeesWorkers = () => {
        setOpenWarningDialog(true);
    };

    const differentlyAbledEmployeesWorkersTabs = (event, newValue) => {
        setDifferentlyAbledEmployeesWorkers(newValue)
    };

    const handleCheckedRecords = (value) => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: false, differentlyAbledEmployeesWorkersData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value);
        }
    };

    const sendForApprovalRecords = async () => {
        if (recordRefNo.length) {
            await sendForApprovalDifferentlyAbledEmployeesWorkersRecord(recordRefNo)
                .then((resp) => {
                    getDifferentlyAbledEmployeesWorkersData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const approveRecords = async () => {
        if (recordRefNo.length) {
            let typeOfRecord = "DIFFERENTLY_ABLED_EMPLOYEES_WORKERS";
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo,
                typeOfRecord
            }
            await approveDifferentlyAbledEmployeesWorkersRecord(obj)
                .then((resp) => {
                    recordRefNo = [];
                    getDifferentlyAbledEmployeesWorkersData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const rejectRecords = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveDifferentlyAbledEmployeesWorkersRecord(obj)
            .then((resp) => {
                recordRefNo = [];
                getDifferentlyAbledEmployeesWorkersData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const addDifferentlyAbledEmployeesWorkers = () => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: true, differentlyAbledEmployeesWorkersData: null });
    };

    const editDifferentlyAbledEmployeesWorkers = (rowData) => {
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: true, differentlyAbledEmployeesWorkersData: rowData });
    };
    const employeeAgeBasedData = (rowData) => {
        setOpenEmployeeAgeBasedDataTable({ action: true, employeesAgeBasedData: rowData, collForm: "DIFFERENTLY_ABLED_EMPLOYEE_WORKERS" });
        setOpenAddDifferentlyAbledEmployeesWorkers({ action: false, differentlyAbledEmployeesWorkersData: null });
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>

                        </div>
                        <div className='col-6'>
                            <span className="float-end"
                                hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}
                            >
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary" checked={applicable}
                                        onChange={applicableOrNotAbledEmployeesWorkers}
                                    />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext
                                value={differentlyAbledEmployeesWorkers}
                            >
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList
                                        onChange={differentlyAbledEmployeesWorkersTabs}
                                        aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Differently Abled Employees & Workers" value="Differently Abled Employees & Workers" />
                                        <material.Tab className='text-capitalize' label="Differently Abled Employees & Workers History" value="Differently Abled Employees & Workers History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Differently Abled Employees & Workers">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-6">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {differentlyAbledEmployeesWorkersData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable||!recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable||!recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalRecords}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addDifferentlyAbledEmployeesWorkers}>Add Differently Employee And Worker</material.Button>
                                            </span>
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <material.TableContainer>
                                                        <material.Table>
                                                            <material.TableHead>
                                                                <material.TableRow>
                                                                    <StyledTableCell>Category of Worker</StyledTableCell>
                                                                    <StyledTableCell>Male</StyledTableCell>
                                                                    <StyledTableCell>Female</StyledTableCell>
                                                                    <StyledTableCell>Other</StyledTableCell>
                                                                    <StyledTableCell>Total Derived</StyledTableCell>
                                                                    <StyledTableCell>From Date</StyledTableCell>
                                                                    <StyledTableCell>To Date</StyledTableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                                    )}
                                                                    <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                    <StyledTableCell>Age Based Data</StyledTableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <StyledTableCell>Rejection Note</StyledTableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            </material.TableHead>
                                                            <material.TableBody>
                                                                {differentlyAbledEmployeesWorkersData.length ? differentlyAbledEmployeesWorkersData?.map((rowData, ind) => (
                                                                    <material.TableRow key={ind}
                                                                        sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                        onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ? () => editDifferentlyAbledEmployeesWorkers(rowData) : null} >
                                                                        <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                                                        <material.TableCell>{rowData.male}</material.TableCell>
                                                                        <material.TableCell>{rowData.female}</material.TableCell>
                                                                        <material.TableCell>{rowData.other}</material.TableCell>
                                                                        <material.TableCell>{rowData.total}</material.TableCell>
                                                                        <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                            <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        )}
                                                                        <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                                <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                                                            ) : (
                                                                                <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                                                            )}
                                                                        </material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.isAgeBasedDataPresent === true ? (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: false }) }}>Yes</material.Link>
                                                                            ) : null}
                                                                        </material.TableCell>
                                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                            <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                                                            )}
                                                                            </material.TableCell>
                                                                        )}
                                                                    </material.TableRow>
                                                                )) : (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )}
                                                            </material.TableBody>
                                                        </material.Table>
                                                    </material.TableContainer>
                                                </div>
                                            </div>
                                        </material.Paper>
                                    </div>
                                </material.TabPanel>
                                <material.TabPanel value="Differently Abled Employees & Workers History">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                    <div>
                                        <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <material.TableContainer>
                                                        <material.Table>
                                                            <material.TableHead>
                                                                <material.TableRow>
                                                                    <StyledTableCell>Category of Worker</StyledTableCell>
                                                                    <StyledTableCell>Male</StyledTableCell>
                                                                    <StyledTableCell>Female</StyledTableCell>
                                                                    <StyledTableCell>Other</StyledTableCell>
                                                                    <StyledTableCell>Total Derived</StyledTableCell>
                                                                    <StyledTableCell>From Date</StyledTableCell>
                                                                    <StyledTableCell>To Date</StyledTableCell>
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                    <StyledTableCell>Approved Status</StyledTableCell>
                                                                    <StyledTableCell>Age Based Data</StyledTableCell>
                                                                </material.TableRow>
                                                            </material.TableHead>
                                                            <material.TableBody>
                                                                {differentlyAbledEmployeesWorkersData?.length ? differentlyAbledEmployeesWorkersData?.map((rowData, ind) => (
                                                                    <material.TableRow key={ind} >
                                                                        <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                                                        <material.TableCell>{rowData.male}</material.TableCell>
                                                                        <material.TableCell>{rowData.female}</material.TableCell>
                                                                        <material.TableCell>{rowData.other}</material.TableCell>
                                                                        <material.TableCell>{rowData.total}</material.TableCell>
                                                                        <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                                APPROVED
                                                                            </span> : null}
                                                                        </material.TableCell>
                                                                        <material.TableCell>
                                                                            {rowData.isAgeBasedDataPresent === true ? (
                                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: true }) }}>Yes</material.Link>
                                                                            ) : null}
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )) : (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )}
                                                            </material.TableBody>
                                                        </material.Table>
                                                    </material.TableContainer>
                                                </div>
                                            </div>
                                        </material.Paper>
                                    </div>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddDifferentlyAbledEmployeesWorkers
                openAddDifferentlyAbledEmployeesWorkers={openAddDifferentlyAbledEmployeesWorkers}
                setOpenAddDifferentlyAbledEmployeesWorkers={setOpenAddDifferentlyAbledEmployeesWorkers}
                getDifferentlyAbledEmployeesWorkersData={getDifferentlyAbledEmployeesWorkersData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getDifferentlyAbledEmployeesWorkersData={getDifferentlyAbledEmployeesWorkersData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <EmployeeAgeBasedDataDialog
                openEmployeeAgeBasedDataTable={openEmployeeAgeBasedDataTable}
                setOpenEmployeeAgeBasedDataTable={setOpenEmployeeAgeBasedDataTable}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default DifferentlyAbledEmployeesAndWorkers;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddEmployeesWorkersWellBeing from '../../../sub-issues-dialogs/employee/AddEmployeesWorkersWellBeing';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveEmployeesWorkersWellBeingData, getEmployeesWorkersWellBeingData, sendForApprovalEmployeesWorkersWellBeingData } from '../../../services/sub-issue-services/employees-workers-well-being-service/EmployeesWorkers-WellBeingService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function EmployeesWorkersWellBeing(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [employeesWorkersWellBeingTabs, setEmployeesWorkersWellBeingTabs] = useState('Employees & Workers Well Being');
    const [employeesWorkersWellBeingTableData, setEmployeesWorkersWellBeingTableData] = useState([]);
    const [openEmployeesWorkersWellBeing, setOpenEmployeesWorkersWellBeing] = useState({ action: false, employeesWorkersWellBeingTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getAllEmployeesWorkersWellBeingData()
    }, [employeesWorkersWellBeingTabs]);

    const getFormDateToDate = (data) => {
        getAllEmployeesWorkersWellBeingData(data)
    };

    const getAllEmployeesWorkersWellBeingData = async (data) => {
        if (employeesWorkersWellBeingTabs === "Employees & Workers Well Being") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getEmployeesWorkersWellBeingData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.employeesWorkersWellBeingDataList);
                    setEmployeesWorkersWellBeingTableData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (employeesWorkersWellBeingTabs === "Employees & Workers Well Being History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getEmployeesWorkersWellBeingData(payloadData)
                .then((resp) => {
                    setEmployeesWorkersWellBeingTableData(resp.data.employeesWorkersWellBeingDataList)
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const addEmployeesWorkersWellBeing = () => {
        setOpenEmployeesWorkersWellBeing({ action: true, employeesWorkersWellBeingTableData: null });
    };

    const editEmployeesWorkersWellBeing = (tableData) => {
        setOpenEmployeesWorkersWellBeing({ action: true, employeesWorkersWellBeingTableData: tableData });
    };

    const clickCheckBox = (value) => {
        setOpenEmployeesWorkersWellBeing({ action: false, employeesWorkersWellBeingTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendApprovalEmployeesWorkersWellBeing = async () => {
        if (recordRefNos.length) {
            await sendForApprovalEmployeesWorkersWellBeingData(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getAllEmployeesWorkersWellBeingData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const approvedEmployeesWorkersWellBeing = async () => {
        // if (recordRefNos.length) {
        //     await approveEmployeesWorkersWellBeingData("APPROVED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
        //         .then((resp) => {
        //             recordRefNos = [];
        //             getAllEmployeesWorkersWellBeingData();
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "success",
        //                 "message": resp.data,
        //             })
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
        //             })
        //         })
        // };
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveEmployeesWorkersWellBeingData(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getAllEmployeesWorkersWellBeingData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const rejectEmployeesWorkersWellBeing = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordRefNos.length) {
            //     await approveEmployeesWorkersWellBeingData("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
            //         .then((resp) => {
            //             recordRefNos = [];
            //             getAllEmployeesWorkersWellBeingData();
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveEmployeesWorkersWellBeingData(obj)
            .then((resp) => {
                recordRefNos = [];
                getAllEmployeesWorkersWellBeingData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const handleTabChange = (event, newValue) => {
        setEmployeesWorkersWellBeingTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: "80%" }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <material.TabContext value={employeesWorkersWellBeingTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <material.Tab className="text-capitalize" label="Employees & Workers Well Being" value="Employees & Workers Well Being" />
                                    <material.Tab className="text-capitalize" label="Employees & Workers Well Being History" value="Employees & Workers Well Being History" />
                                    <material.Tab className="text-capitalize" label="Specific Disclosure" value="Specific Disclosure" />
                                    <material.Tab className="text-capitalize" label="Specific Disclosure History" value="Specific Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Employees & Workers Well Being">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-lg-6">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        <span className='float-end'>
                                            {employeesWorkersWellBeingTableData?.length ? (
                                                <>
                                                    <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedEmployeesWorkersWellBeing}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectEmployeesWorkersWellBeing}>Reject</material.Button>
                                                </>
                                            ) : null}
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalEmployeesWorkersWellBeing}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addEmployeesWorkersWellBeing}>Add Employee & Worker Well Being</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                                                <StyledTableCell>Gender</StyledTableCell>
                                                                <StyledTableCell>Health Insurance</StyledTableCell>
                                                                <StyledTableCell>Accident Insurance</StyledTableCell>
                                                                <StyledTableCell>Maternity Benefits</StyledTableCell>
                                                                <StyledTableCell>Paternity Benefits</StyledTableCell>
                                                                <StyledTableCell>Day Care Facilities</StyledTableCell>
                                                                <StyledTableCell>Life Insurance</StyledTableCell>
                                                                <StyledTableCell>Stock Ownership</StyledTableCell>
                                                                <StyledTableCell>Retirement Provision</StyledTableCell>
                                                                <StyledTableCell>Disability and Invalidity Coverage</StyledTableCell>
                                                                {/* <StyledTableCell>Total</StyledTableCell> */}
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {employeesWorkersWellBeingTableData?.length ? employeesWorkersWellBeingTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                        () => editEmployeesWorkersWellBeing(item) : null}>
                                                                    <material.TableCell>{item.categoryOfWorker}</material.TableCell>
                                                                    <material.TableCell>{item.gender}</material.TableCell>
                                                                    <material.TableCell>{item.healthInsurance}</material.TableCell>
                                                                    <material.TableCell>{item.accidentInsurance}</material.TableCell>
                                                                    <material.TableCell>{item.maternityBenefits}</material.TableCell>
                                                                    <material.TableCell>{item.paternityBenefits}</material.TableCell>
                                                                    <material.TableCell>{item.dayCareFacilities}</material.TableCell>
                                                                    <material.TableCell>{item.lifeInsurance}</material.TableCell>
                                                                    <material.TableCell>{item.stockOwnership}</material.TableCell>
                                                                    <material.TableCell>{item.retirementProvision}</material.TableCell>
                                                                    <material.TableCell>{item.disabilityAndInvalidityCoverage}</material.TableCell>
                                                                    {/* <material.TableCell>{item.total}</material.TableCell> */}
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </div>
                            </material.TabPanel>
                            <material.TabPanel value="Employees & Workers Well Being History">
                                <div className="row">
                                    <div className='col-lg-12 mb-3'>
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <material.TableContainer>
                                            <material.Table>
                                                <material.TableHead>
                                                    <material.TableRow>
                                                        <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                                        <StyledTableCell>Gender</StyledTableCell>
                                                        <StyledTableCell>Health Insurance</StyledTableCell>
                                                        <StyledTableCell>Accident Insurance</StyledTableCell>
                                                        <StyledTableCell>Maternity Benefits</StyledTableCell>
                                                        <StyledTableCell>Paternity Benefits</StyledTableCell>
                                                        <StyledTableCell>Day Care Facilities</StyledTableCell>
                                                        <StyledTableCell>Life Insurance</StyledTableCell>
                                                        <StyledTableCell>Stock Ownership</StyledTableCell>
                                                        <StyledTableCell>Retirement Provision</StyledTableCell>
                                                        <StyledTableCell>Disability and Invalidity Coverage</StyledTableCell>
                                                        {/* <StyledTableCell>Total</StyledTableCell> */}
                                                        <StyledTableCell>From Date</StyledTableCell>
                                                        <StyledTableCell>To Date</StyledTableCell>
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                        <StyledTableCell>Status</StyledTableCell>
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    {employeesWorkersWellBeingTableData?.length ? employeesWorkersWellBeingTableData.map((item, ind) => (
                                                        <material.TableRow key={ind}>
                                                            <material.TableCell>{item.categoryOfWorker}</material.TableCell>
                                                            <material.TableCell>{item.gender}</material.TableCell>
                                                            <material.TableCell>{item.healthInsurance}</material.TableCell>
                                                            <material.TableCell>{item.accidentInsurance}</material.TableCell>
                                                            <material.TableCell>{item.maternityBenefits}</material.TableCell>
                                                            <material.TableCell>{item.paternityBenefits}</material.TableCell>
                                                            <material.TableCell>{item.dayCareFacilities}</material.TableCell>
                                                            <material.TableCell>{item.lifeInsurance}</material.TableCell>
                                                            <material.TableCell>{item.stockOwnership}</material.TableCell>
                                                            <material.TableCell>{item.retirementProvision}</material.TableCell>
                                                            <material.TableCell>{item.disabilityAndInvalidityCoverage}</material.TableCell>
                                                            {/* <material.TableCell>{item.total}</material.TableCell> */}
                                                            <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                            <material.TableCell>
                                                                {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                    APPROVED
                                                                </span> : null}
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )) :
                                                        (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={12}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )
                                                    }
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </div>
                                </div>
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure" >
                                <SpecificDisclosure
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                            <material.TabPanel value="Specific Disclosure History" >
                                <SpecificDisclosureHistory
                                    treeViewData={treeViewData} />
                            </material.TabPanel>
                        </material.TabContext>
                    </div>
                </material.Box>
            </material.Box>
            <AddEmployeesWorkersWellBeing
                openEmployeesWorkersWellBeing={openEmployeesWorkersWellBeing}
                setOpenEmployeesWorkersWellBeing={setOpenEmployeesWorkersWellBeing}
                getAllEmployeesWorkersWellBeingData={getAllEmployeesWorkersWellBeingData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getAllEmployeesWorkersWellBeingData={getAllEmployeesWorkersWellBeingData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default EmployeesWorkersWellBeing;
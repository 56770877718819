import React, { useEffect, useState } from "react";
import { material } from "../../../library/Material";
import SwipeDrawer from "../../drawer/SwipeDrawer";
import { useLocation } from "react-router-dom";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddCoverageOfTraining from "../../../sub-issues-dialogs/subissue-coverage-of-training/AddCoverageOfTraining";
import { approveCoverageOfTraining, getAllCoverageOfTrainingData, sendForApprovalCoverageOfTraining } from "../../../services/sub-issue-services/CoverageOfTrainingService";
import { useSelector } from "react-redux";
import Snackbar from "../../../toaster-message/Snackbar";
import moment from "moment";
import ApplicableNotApplicableWarning from "../../../dialogs/ApplicableNotApplicableWarning";
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from "../../../shared/Filter";
import SpecificDisclosureHistory from "../../specific-disclosure/SpecificDisclosureHistory";
import SpecificDisclosure from "../../specific-disclosure/SpecificDisclosure";
import RejectionNote from "../../../dialogs/RejectionNote";
import DateFilter from "../../../shared/DateFilter";


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };
var recordRefNo = [];

function CoverageOfTraining(props) {
  const location = useLocation();
  const treeViewData = location?.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [value, setValue] = useState("Coverage of Training");
  const [openAddCoverageOfTraining, setOpenAddCoverageOfTraining] = useState({
    action: false,
    coverageOfTrainingData: null,
  });
  const [coverageOfTrainingAllData, setCoverageOfTrainingAllData] = useState([]);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [applicable, setApplicable] = useState(true);
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i];
  };

  useEffect(() => {
    getCoverageOfTrainingData()
  }, [value]);

  const getFormDateToDate = (data) => {
    getCoverageOfTrainingData(data)
  };

  const getCoverageOfTrainingData = async (data) => {
    if (value === "Coverage of Training") {
      let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
      let approveHistory = false;
      let payload = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
      }
      await getAllCoverageOfTrainingData(payload)
        .then((resp) => {
          setApplicable(resp.data.applicable)
          let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dtoList)
          setCoverageOfTrainingAllData(filterData);
          setApplicableRefNo(resp.data.applicableRefNo);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    } else if (value === "Coverage of Training History") {
      let forApproval = true;
      let approveHistory = true;
      let payload = {
        corporateRefNo: userDetails.corporateRefNo,
        facilityRefNo: userDetails.facilityRefNo,
        forApproval,
        approveHistory,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
      }
      await getAllCoverageOfTrainingData(payload)
        .then((resp) => {
          setApplicable(resp.data.applicable)
          setCoverageOfTrainingAllData(resp.data.dtoList)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    }
  };

  const handleCoverageOfTrainingAndHistoryTabs = (event, newValue) => {
    setValue(newValue);
  };

  const applicableOrNotCoverageOfTraining = () => {
    setOpenWarningDialog(true)
  };

  const handelAddCoverageOfTraining = () => {
    setOpenAddCoverageOfTraining({
      action: true,
      coverageOfTrainingData: null,
    });
  };

  const editCoverageOfTraining = (rowData) => {
    setOpenAddCoverageOfTraining({
      action: true,
      coverageOfTrainingData: rowData,
    });
  };

  const handleCheckedRecords = (ids) => {
    setOpenAddCoverageOfTraining({
      action: false,
      coverageOfTrainingData: null,
    });
    if (recordRefNo.includes(ids)) {
      // recordRefNo.pop(ids)
      recordRefNo = recordRefNo.filter(item => item !== ids);
    } else {
      recordRefNo.push(ids)
    }
  };
  
  const sendForApprovalRecords = async () => {
    if (recordRefNo.length) {
      await sendForApprovalCoverageOfTraining(recordRefNo)
        .then((resp) => {
          getCoverageOfTrainingData()
          recordRefNo = [];
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    };
  };

  const approveRecords = async () => {
    if (recordRefNo.length) {
      let object = {
        "rejectionNote": "",
        "corporateRefNo": userDetails.corporateRefNo,
        "facilityRefNo": userDetails.facilityRefNo,
        "subIssueId": treeViewData.id,
        "subIssueName": treeViewData.subIssue,
        "rejectedBy": userDetails.username,
        "status": "APPROVED",
        "recordRefNo": recordRefNo
      }
      await approveCoverageOfTraining(object)
        .then((resp) => {
          getCoverageOfTrainingData()
          recordRefNo = [];
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          };
        })
    };
  };

  const rejectRecords = async () => {
    if (recordRefNo.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    }
  };

  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };

  const getRejectionNoteData = async (noteData) => {
    let obj = {
      "rejectionNote": noteData.rejectionNote,
      "corporateRefNo": userDetails.corporateRefNo,
      "facilityRefNo": userDetails.facilityRefNo,
      "subIssueId": treeViewData.id,
      "subIssueName": treeViewData.subIssue,
      "rejectedBy": userDetails.username,
      "status": "REJECTED",
      "recordRefNo": recordRefNo
    }
    await approveCoverageOfTraining(obj)
      .then((resp) => {
        recordRefNo = [];
        getCoverageOfTrainingData();
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        };
      })
  };

  return (
    <div>
      <material.Box sx={{ display: "flex" }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7, width: 100 }}>
          <div className="row">
            <div className="col-6">
              <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
            </div>
            <div className="col-6">
              <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                <material.Stack direction="row" spacing={1} alignItems="center">
                  <material.Typography>Not applicable</material.Typography>
                  <material.Switch {...label} color="secondary" checked={applicable} onChange={applicableOrNotCoverageOfTraining} />
                  <material.Typography>Applicable</material.Typography>
                </material.Stack>
              </span>
            </div>
          </div>
          <div className="mt-4">
            <material.TabContext value={value}>
              <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <material.TabList
                  onChange={handleCoverageOfTrainingAndHistoryTabs}
                  aria-label="lab API tabs example"
                >
                  <material.Tab sx={{ textTransform: "none" }} label="Coverage of Training" value="Coverage of Training" />
                  <material.Tab sx={{ textTransform: "none" }} label="Coverage of Training History" value="Coverage of Training History" />
                  <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure" value="Specific Disclosure" />
                  <material.Tab sx={{ textTransform: "none" }} label="Specific Disclosure History" value="Specific Disclosure History" />
                </material.TabList>
              </material.Box>
              <material.TabPanel value="Coverage of Training">
                <div className="row mt-2 align-items-center">
                  <div className="col-lg-6">
                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    )}
                  </div>
                  <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                    <span className='float-end'>
                      {coverageOfTrainingAllData?.length ? (
                        <>
                          <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                          <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                        </>
                      ) : null}
                      <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalRecords}>Send for Approval</material.Button>
                      <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={handelAddCoverageOfTraining}>Add Coverage of Training</material.Button>
                    </span>
                  </div>
                </div>
                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                  <div className='row'>
                    <div className="col-12">
                      <material.TableContainer>
                        <material.Table>
                          <material.TableHead>
                            <material.TableRow>
                              <StyledTableCell>Category of Worker/Employee</StyledTableCell>
                              <StyledTableCell>Gender</StyledTableCell>
                              <StyledTableCell>Type of Training</StyledTableCell>
                              <StyledTableCell>Training Topics</StyledTableCell>
                              <StyledTableCell>Other Training Topics (Specify)</StyledTableCell>
                              <StyledTableCell>Number of Worker/Employee Received Training</StyledTableCell>
                              <StyledTableCell>Duration of Training (hr)</StyledTableCell>
                              <StyledTableCell>From Date</StyledTableCell>
                              <StyledTableCell>To Date</StyledTableCell>
                              {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                <StyledTableCell>Approval Date</StyledTableCell>
                              )}
                              <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                              {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                <StyledTableCell>Rejection Note</StyledTableCell>
                              )}
                            </material.TableRow>
                          </material.TableHead>
                          <material.TableBody>
                            {coverageOfTrainingAllData?.length ? coverageOfTrainingAllData.map((rowData, k) => (
                              <material.TableRow
                                key={k}
                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ? () => editCoverageOfTraining(rowData) : null}
                              >
                                <material.TableCell>{rowData.categoryOfWorkerEmployee}</material.TableCell>
                                <material.TableCell>{rowData.gender}</material.TableCell>
                                <material.TableCell>{rowData.typeOfTraining}</material.TableCell>
                                <material.TableCell>{rowData.trainingTopic}</material.TableCell>
                                <material.TableCell>{(rowData.otherSpecify === "" || rowData.otherSpecify === null) ? "N/A" : rowData.otherSpecify}</material.TableCell>
                                <material.TableCell>{rowData.numberOfWorkersOrEmployeesReceivingTraining}</material.TableCell>
                                <material.TableCell>{rowData.durationOfTraining}</material.TableCell>
                                <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                  <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                )}
                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                  {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                    <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                  ) : (
                                    <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                  )}
                                </material.TableCell>
                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                  <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                    <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                  )}
                                  </material.TableCell>
                                )}
                              </material.TableRow>
                            )) : (
                              <material.TableRow >
                                <material.TableCell colSpan={12}>
                                  <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                              </material.TableRow>
                            )}
                          </material.TableBody>
                        </material.Table>
                      </material.TableContainer>
                    </div>
                  </div>
                </material.Paper>
              </material.TabPanel>
              <material.TabPanel value="Coverage of Training History">
                <div className="row">
                  <div className="col-lg-8">
                    <DateFilter
                      getFormDateToDate={getFormDateToDate}
                    />
                  </div>
                </div>
                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                  <div className='row'>
                    <div className="col-12">
                      <material.TableContainer>
                        <material.Table>
                          <material.TableHead>
                            <material.TableRow>
                              <StyledTableCell>Category of Worker/Employee</StyledTableCell>
                              <StyledTableCell>Gender</StyledTableCell>
                              <StyledTableCell>Type of Training</StyledTableCell>
                              <StyledTableCell>Training Topics</StyledTableCell>
                              <StyledTableCell>Others Training Topics (Specify)</StyledTableCell>
                              <StyledTableCell>Number of Worker/Employee Received Training</StyledTableCell>
                              <StyledTableCell>Duration of Training (hr)</StyledTableCell>
                              <StyledTableCell>From Date</StyledTableCell>
                              <StyledTableCell>To Date</StyledTableCell>
                              <StyledTableCell>Approval Date</StyledTableCell>
                              <StyledTableCell>Status</StyledTableCell>
                            </material.TableRow>
                          </material.TableHead>
                          <material.TableBody>
                            {coverageOfTrainingAllData?.length ? coverageOfTrainingAllData.map((rowData, k) => (
                              <material.TableRow
                                key={k}
                              >
                                <material.TableCell>{rowData.categoryOfWorkerEmployee}</material.TableCell>
                                <material.TableCell>{rowData.gender}</material.TableCell>
                                <material.TableCell>{rowData.typeOfTraining}</material.TableCell>
                                <material.TableCell>{rowData.trainingTopic}</material.TableCell>
                                <material.TableCell>{(rowData.otherSpecify === "" || rowData.otherSpecify === null) ? "N/A" : rowData.otherSpecify}</material.TableCell>
                                <material.TableCell>{rowData.numberOfWorkersOrEmployeesReceivingTraining}</material.TableCell>
                                <material.TableCell>{rowData.durationOfTraining}</material.TableCell>
                                <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                <material.TableCell>
                                  {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                    APPROVED
                                  </span> : null}
                                </material.TableCell>
                              </material.TableRow>
                            )) : (
                              <material.TableRow >
                                <material.TableCell colSpan={10}>
                                  <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                              </material.TableRow>
                            )}
                          </material.TableBody>
                        </material.Table>
                      </material.TableContainer>
                    </div>
                  </div>
                </material.Paper>
              </material.TabPanel>
              <material.TabPanel value="Specific Disclosure">
                <SpecificDisclosure
                  treeViewData={treeViewData}
                />
              </material.TabPanel>
              <material.TabPanel value="Specific Disclosure History">
                <SpecificDisclosureHistory
                  treeViewData={treeViewData}
                />
              </material.TabPanel>
            </material.TabContext>
          </div>
        </material.Box>
      </material.Box>
      <AddCoverageOfTraining
        openAddCoverageOfTraining={openAddCoverageOfTraining}
        setOpenAddCoverageOfTraining={setOpenAddCoverageOfTraining}
        getCoverageOfTrainingData={getCoverageOfTrainingData}
        treeViewData={treeViewData}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <ApplicableNotApplicableWarning
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getCoverageOfTrainingData={getCoverageOfTrainingData}
        applicable={applicable}
        treeViewData={treeViewData}
        applicableRefNo={applicableRefNo}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </div>
  );
}

export default CoverageOfTraining;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateGhgScopeData } from '../../services/sub-issue-services/ghg-scope-common-services/GhgScopeCommonService';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import DatePeriod from '../../shared/DatePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddGhgScope2(props) {

    const { openEnergyScope2GhgEmission, setOpenEnergyScope2GhgEmission, getGhgScope2AllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, control, reset, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [activity, setActivity] = useState('');
    const [unit, setUnit] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openEnergyScope2GhgEmission.action && openEnergyScope2GhgEmission.energyScope2GhgEmissionData) {
            setSourceType(openEnergyScope2GhgEmission.energyScope2GhgEmissionData.typeOfSource);
            setActivity(openEnergyScope2GhgEmission.energyScope2GhgEmissionData.activity);
            setUnit(openEnergyScope2GhgEmission.energyScope2GhgEmissionData.unitCo2);
            reset(openEnergyScope2GhgEmission.energyScope2GhgEmissionData);
        }
    }, [openEnergyScope2GhgEmission.action === true]);
    const handleChange = (event) => {
        setActivity(event.target.value);
    };
    const handleUnitChange = (event) => {
        setUnit(event.target.value);
    };
    const handleSourceChange = (event) => {
        setSourceType(event.target.value);
    };
    const handleClose = () => {
        reset({ amount: "" });
        setActivity("");
        setUnit("");
        setSourceType("");
        setOpenEnergyScope2GhgEmission({ action: false, energyScope2GhgEmissionData: null })
    };
    const saveGhgScope2 = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "typeOfSource": data.typeOfSource,
                "activity": data.activity,
                "amount": parseFloat(data.amount),
                "unitCo2": data.unitCo2,
                "applicable": true,
                "refNo": "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateGhgScopeData(object, "GHG_SCOPE2")
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getGhgScope2AllData();
                    reset({ amount: "" });
                    setActivity("");
                    setUnit("");
                    setSourceType("");
                    setOpenEnergyScope2GhgEmission({ action: false, energyScope2GhgEmissionData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateGhgScope2 = async (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "typeOfSource": data.typeOfSource,
            "activity": data.activity,
            "amount": parseFloat(data.amount),
            "unitCo2": data.unitCo2,
            "applicable": true,
            "refNo": openEnergyScope2GhgEmission.energyScope2GhgEmissionData.refNo,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateGhgScopeData(object, "GHG_SCOPE2")
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getGhgScope2AllData();
                reset({ amount: "" });
                setActivity("");
                setUnit("");
                setSourceType("");
                setOpenEnergyScope2GhgEmission({ action: false, energyScope2GhgEmissionData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openEnergyScope2GhgEmission.energyScope2GhgEmissionData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getGhgScope2AllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ amount: "" });
                setActivity("");
                setUnit("");
                setSourceType("");
                setOpenEnergyScope2GhgEmission({ action: false, energyScope2GhgEmissionData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog
                open={openEnergyScope2GhgEmission.action} maxWidth="md" fullWidth >
                <material.DialogTitle id="alert-dialog-title" className='headingText'>
                    {openEnergyScope2GhgEmission.energyScope2GhgEmissionData ? "Edit GHG Scope 2" : "Add GHG Scope 2"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openEnergyScope2GhgEmission.energyScope2GhgEmissionData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row align-items-center'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>GHG Scope2 Data</material.Typography>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" fullWidth className='mt-3'>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfSource", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={sourceType}
                                        onChange={handleSourceChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"Location Based Scope 2 Emissions"}>Location Based Scope 2 Emissions</material.MenuItem>
                                        <material.MenuItem value={"Market Based Scope 2 Emissions"}>Market Based Scope 2 Emissions</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" className='mt-3' fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Activity</material.InputLabel>
                                    <material.Select
                                        {...register("activity", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={activity}
                                        onChange={handleChange}
                                        label="Activity type"
                                    >
                                        <material.MenuItem value={"Electricity"}>Electricity</material.MenuItem>
                                        <material.MenuItem value={"Heating"}>Heating</material.MenuItem>
                                        <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                        <material.MenuItem value={"Steam"}>Steam</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.TextField
                                    {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Amount"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.amount && errors.amount.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-md-6 py-2'>
                                <material.FormControl variant="standard" className='mt-3' fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Unit CO2-e</material.InputLabel>
                                    <material.Select
                                        {...register("unitCo2", { required: true, })}
                                        required
                                        fullWidth
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={unit}
                                        onChange={handleUnitChange}
                                        label="Unit type"
                                    >
                                        <material.MenuItem value={"kg"}>kg</material.MenuItem>
                                        <material.MenuItem value={"metric tons"}>metric tons</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openEnergyScope2GhgEmission.energyScope2GhgEmissionData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateGhgScope2)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openEnergyScope2GhgEmission.energyScope2GhgEmissionData.rejectionNote === null) || (openEnergyScope2GhgEmission.energyScope2GhgEmissionData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveGhgScope2)}
                            startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddGhgScope2;
import React, { useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';


const physicalPollutant = ["Color", "Electrical Conductivity", "Odour", "Ph Value", "Temperature", "Total Suspended Solids (TSS)", "Total Dissolved Solids (TDS)", "Oil & Grease", "Other Pollutant (Specify)"];
const chemicalPollutant = ["Biochemical Oxygen Demand (BOD)", "Boron", "Chemical Oxygen Demand (COD)", "Total Carbon", "Ammonical Nitrogen", "Total Kjeldahl Nitrogen", "Free Ammonia", "Nitrate (as NO3)", "Phosphate", "Other Pollutant (Specify)"];
const metalsPollutant = ["Arsenic", "Benzene", "Copper", "Cadmium", "Cyanide", "Iron", "Lead", "Magnesium", "Mercury", "Manganese", "Nickel", "Zinc", "Other Pollutant (Specify)"];
const aromaticsPollutant = ["Phenolic Compounds", "Chloroamines", "Polychlorinated Biphenyls", "Polynuclear Aromatic Hydrocarbons", "Alachlor", "Atrazine", "Beta HCH", "Butachlor", "Chlorpyriphos"];
const bacteriologicalPollutant = ["Coliform Bacteria", "Escherichiacoli"];


function AddWaterDischargeQualityReceptor(props) {

    const { openAddWaterDischargeQualityReceptor, setOpenAddWaterDischargeQualityReceptor, waterDischargeQualityReceptorAllData, dischargePointValue, dischargeDestinationValue } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [pollutantSuite, setPollutantSuite] = useState("");
    const [pollutantParameter, setPollutantParameter] = useState("");
    const [pollutantParameterValue, setPollutantParameterValue] = useState([]);
    const [receptorQualitySuite, setReceptorQualitySuite] = useState("");
    const [receptorQualityParameter, setReceptorQualityParameter] = useState("");
    const [receptorQualityParameterValue, setReceptorQualityParameterValue] = useState([]);

    if (dischargePointValue || dischargeDestinationValue) {
        setValue("qualityDischargePoint", dischargePointValue);
        setValue("dischargeDestinationValueData", dischargeDestinationValue)
    }

    const pollutantSuiteChange = (event) => {
        let pollutantSuiteChangeData = event.target.value;
        setValue("receptorQualityPollutantSuit", pollutantSuiteChangeData);
        setPollutantSuite(pollutantSuiteChangeData);
        if (pollutantSuiteChangeData === "Physical") {
            setPollutantParameterValue(physicalPollutant);
        } else if (pollutantSuiteChangeData === "Chemical") {
            setPollutantParameterValue(chemicalPollutant);
        } else if (pollutantSuiteChangeData === "Metals") {
            setPollutantParameterValue(metalsPollutant);
        } else if (pollutantSuiteChangeData === "Aromatics") {
            setPollutantParameterValue(aromaticsPollutant);
        } else if (pollutantSuiteChangeData === "Bacteriological") {
            setPollutantParameterValue(bacteriologicalPollutant);
        };
    };
    const pollutantParameterChange = (event) => {
        let pollutantParameterChangeValue = event.target.value
        setPollutantParameter(pollutantParameterChangeValue);
        if (pollutantParameterChangeValue === "Color") {
            setValue("qualityUnit", "Hazen")
        } else if (pollutantParameterChangeValue === "Electrical Conductivity") {
            setValue("qualityUnit", "mhos/cm")
        } else if (pollutantParameterChangeValue === "Odour") {
            setValue("qualityUnit", "ppm")
        } else if (pollutantParameterChangeValue === "Ph Value") {
            setValue("qualityUnit", "Ph unit")
        } else if (pollutantParameterChangeValue === "Temperature") {
            setValue("qualityUnit", "℃")
        } else if (pollutantParameterChangeValue === "Alachlor" || pollutantParameterChangeValue === "Atrazine" ||
            pollutantParameterChangeValue === "Beta HCH" || pollutantParameterChangeValue === "Butachlor" || pollutantParameterChangeValue === "Chlorpyriphos") {
            setValue("qualityUnit", "μg/l")
        } else if (pollutantParameterChangeValue === "Coliform bacteria" || pollutantParameterChangeValue === "Escherichiacoli") {
            setValue("qualityUnit", "MPN/100ml")
        } else if (pollutantParameterChangeValue === "Other Pollutant (Specify)") {
            setValue("qualityUnit", "")
        } else {
            setValue("qualityUnit", "mg/l")
        };
    };
    const receptorQualitySuiteChange = (event) => {
        let receptorQualitySuiteChangeData = event.target.value;
        setReceptorQualitySuite(receptorQualitySuiteChangeData);
        if (receptorQualitySuiteChangeData === "Physical") {
            setReceptorQualityParameterValue(physicalPollutant);
        } else if (receptorQualitySuiteChangeData === "Chemical") {
            setReceptorQualityParameterValue(chemicalPollutant);
        } else if (receptorQualitySuiteChangeData === "Metals") {
            setReceptorQualityParameterValue(metalsPollutant);
        } else if (receptorQualitySuiteChangeData === "Aromatics") {
            setReceptorQualityParameterValue(aromaticsPollutant);
        } else if (receptorQualitySuiteChangeData === "Bacteriological") {
            setReceptorQualityParameterValue(bacteriologicalPollutant);
        };
    };
    const receptorQualityParameterChange = (event) => {
        let receptorQualityParameterChangeData = event.target.value;
        setReceptorQualityParameter(receptorQualityParameterChangeData);
        if (receptorQualityParameterChangeData === "Color") {
            setValue("receptorQualityUnit", "Hazen")
        } else if (receptorQualityParameterChangeData === "Electrical Conductivity") {
            setValue("receptorQualityUnit", "mhos/cm")
        } else if (receptorQualityParameterChangeData === "Odour") {
            setValue("receptorQualityUnit", "ppm")
        } else if (receptorQualityParameterChangeData === "Ph Value") {
            setValue("receptorQualityUnit", "Ph unit")
        } else if (receptorQualityParameterChangeData === "Temperature") {
            setValue("receptorQualityUnit", "℃")
        } else if (receptorQualityParameterChangeData === "Alachlor" || receptorQualityParameterChangeData === "Atrazine" ||
            receptorQualityParameterChangeData === "Beta HCH" || receptorQualityParameterChangeData === "Butachlor" || receptorQualityParameterChangeData === "Chlorpyriphos") {
            setValue("receptorQualityUnit", "μg/l")
        } else if (receptorQualityParameterChangeData === "Coliform bacteria" || receptorQualityParameterChangeData === "Escherichiacoli") {
            setValue("receptorQualityUnit", "MPN/100ml")
        } else if (receptorQualityParameterChangeData === "Other Pollutant (Specify)") {
            setValue("receptorQualityUnit", "")
        } else {
            setValue("receptorQualityUnit", "mg/l")
        }
    };
    const handleClose = () => {
        reset({
            qualityDischargePointValue: "", qualityPollutantValue: "", qualityUnit: "", dischargeDestinationValueData: "", qualityDischargePoint: "",
            receptorDischargePointValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "",
            receptorQualityPollutantParameterOthersSpecify: "", qualityPollutantParameterOthersSpecify: "",
        });
        setReceptorQualitySuite("");
        setReceptorQualityParameter("");
        setPollutantParameter("");
        setPollutantSuite("");
        setOpenAddWaterDischargeQualityReceptor({ action: false, waterDischargeQualityReceptorAllData: null, })
    };
    const saveWaterDischargeQualityReceptor = (data) => {
        waterDischargeQualityReceptorAllData(data);
        reset({
            qualityDischargePointValue: "", qualityPollutantValue: "", qualityUnit: "", dischargeDestinationValueData: "", qualityDischargePoint: "",
            receptorDischargePointValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "",
            receptorQualityPollutantParameterOthersSpecify: "", qualityPollutantParameterOthersSpecify: "",
        });
        setReceptorQualitySuite("");
        setReceptorQualityParameter("");
        setPollutantParameter("");
        setPollutantSuite("");
        setOpenAddWaterDischargeQualityReceptor({ action: false, waterDischargeQualityReceptorAllData: null, })
    };

    return (
        <>
            <material.Dialog open={openAddWaterDischargeQualityReceptor.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title"  className='fw-bold headingText'>
                    Add Water Discharge Quality Receptor
                    {/* {openAddWaterDischargeQualityReceptor.waterDischargeQualityReceptorAllData ? "Edit Water Discharge Quality Receptor" : "Add Water Discharge Quality Receptor"} */}
                </material.DialogTitle>
                <material.DialogContent>
                    <material.DialogContentText id="alert-dialog-description">
                        <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'> <material.Typography><h5 className='fw-bold headingText'>Water Discharge Quality</h5></material.Typography></div>
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("qualityDischargePoint",)}
                                        // value={dischargePointValue}
                                        // required
                                        label="Discharge Point"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className='col-4 py-2'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                        <material.Select
                                            {...register("qualityPollutantSuit",)}
                                            // required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={pollutantSuite}
                                            onChange={pollutantSuiteChange}
                                            label="Pollutant suite"
                                        >
                                            <material.MenuItem value={"Physical"}>{"Physical"}</material.MenuItem>
                                            <material.MenuItem value={"Metals"}>{"Metals"}</material.MenuItem>
                                            <material.MenuItem value={"Chemical"}>{"Chemical"}</material.MenuItem>
                                            <material.MenuItem value={"Aromatics"}>{"Aromatics"}</material.MenuItem>
                                            <material.MenuItem value={"Bacteriological"}>{"Bacteriological"}</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4 py-2'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                        <material.Select
                                            {...register("qualityPollutantParameter",)}
                                            // required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={pollutantParameter}
                                            onChange={pollutantParameterChange}
                                            label="Pollutant Parameter"
                                        >
                                            {pollutantParameterValue.map((ele, ind) => (
                                                <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                {pollutantParameter === "Other Pollutant (Specify)" ? (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("otherSpecifyDischargePollutant", { required: true, maxLength: 20 })}
                                            required
                                            label="Other Pollutant Specify"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors?.otherSpecifyDischargePollutant?.type === "maxLength" && (
                                            <p className='text-danger'>Maximum length 20 character</p>
                                        )}
                                    </div>
                                ) : null}
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("qualityPollutantValue", { pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                        // required
                                        label="Pollutant Value"
                                        id="userName"
                                        variant="standard"
                                        type='number'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.qualityPollutantValue && errors.qualityPollutantValue.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                    )}
                                </div>
                                {pollutantParameter === "Other Pollutant (Specify)" ? (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("qualityUnit")}
                                            label="Unit"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                ) : (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("qualityUnit")}
                                            label="Unit"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                            InputProps={{ readOnly: true, }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                )}
                            </div>
                        </material.Card>
                        <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-1'> <material.Typography><h5 className='fw-bold headingText'>Water Discharge Receptor Quality</h5></material.Typography> </div>
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("dischargeDestinationValueData",)}
                                        // value={dischargeDestinationValue}
                                        // required
                                        label="Discharge Destination"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("monitoringLocationLat", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                        // required
                                        label="Monitoring Location (Latitude)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.monitoringLocationLat && errors.monitoringLocationLat.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                    )}
                                </div>
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("monitoringLocationLong", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                        // required
                                        label="Monitoring Location (Longitude)"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.monitoringLocationLong && errors.monitoringLocationLong.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                    )}
                                </div>
                                <div className='col-4 py-2'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                        <material.Select
                                            {...register("receptorQualityPollutantSuit",)}
                                            // required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={receptorQualitySuite}
                                            onChange={receptorQualitySuiteChange}
                                            label="Pollutant suite"
                                        >
                                            <material.MenuItem value={"Physical"}>{"Physical"}</material.MenuItem>
                                            <material.MenuItem value={"Metals"}>{"Metals"}</material.MenuItem>
                                            <material.MenuItem value={"Chemical"}>{"Chemical"}</material.MenuItem>
                                            <material.MenuItem value={"Aromatics"}>{"Aromatics"}</material.MenuItem>
                                            <material.MenuItem value={"Bacteriological"}>{"Bacteriological"}</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                <div className='col-4 py-2'>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                        <material.Select
                                            {...register("receptorQualityPollutantParameter",)}
                                            // required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={receptorQualityParameter}
                                            onChange={receptorQualityParameterChange}
                                            label="Pollutant Parameter"
                                        >
                                            {receptorQualityParameterValue.map((ele, ind) => (
                                                <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                                {receptorQualityParameter === "Other Pollutant (Specify)" ? (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("otherSpecifyReceptorPollutant", { required: true, maxLength: 20 })}
                                            required
                                            label="Other Pollutant Specify"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors?.otherSpecifyReceptorPollutant?.type === "maxLength" && (
                                            <p className='text-danger'>Maximum length 20 character</p>
                                        )}
                                    </div>
                                ) : null}
                                <div className='col-4 py-2'>
                                    <material.TextField
                                        {...register("receptorQualityPollutantValue", { pattern: /^[0-9]+(?:\.[0-9]{1,4})?$/ })}
                                        // required
                                        label="Pollutant Value"
                                        id="userName"
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.receptorQualityPollutantValue && errors.receptorQualityPollutantValue.type === "pattern" && (
                                        <p className="errorMsg text-danger">Allowed only number & also four digit after decimal.</p>
                                    )}
                                </div>
                                {receptorQualityParameter === "Other Pollutant (Specify)" ? (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("receptorQualityUnit")}
                                            label="Unit"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                ) : (
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("receptorQualityUnit")}
                                            label="Unit"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                            InputProps={{ readOnly: true, }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                )}
                            </div>
                        </material.Card>
                    </material.DialogContentText>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" onClick={handleSubmit(saveWaterDischargeQualityReceptor)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>

        </>
    )
}

export default AddWaterDischargeQualityReceptor
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getWaterConsumptionSupplierData = async (waterConsumptionSupplierData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/water/water-consumption-supplier/get-all-data`, { params: { corporateRefNo: waterConsumptionSupplierData.corporateRefNo, facilityRefNo: waterConsumptionSupplierData.facilityRefNo, forApproval: waterConsumptionSupplierData.forApproval, approveHistory: waterConsumptionSupplierData.approveHistory, } })
};

export const saveOrUpdateWaterConsumptionSupplierData = async (waterConsumptionSupplierData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/water/water-consumption-supplier/save-or-update`, waterConsumptionSupplierData)
};

export const sendForApprovalWaterConsumptionSupplierRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-consumption-supplier/send-for-approval`, RecordIds);
};

export const approveWaterConsumptionSupplierRecord = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-consumption-supplier/approve`, payloadData)
};


import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateSecurityPracticesData = async (SecurityPracticesData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/security-practices/save-or-update`, SecurityPracticesData)
};

export const getSecurityPracticesData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/security-practices/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalSecurityPractices = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/security-practices/send-for-approval`, recordRefNo)
};

export const approveSecurityPractices = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/security-practices/approve`, payloadData)
};

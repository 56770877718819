export const tableContent = [
    {
        facilityDetailsColumns: [
            { header: 'Facility Name', key: 'assetName', callFrom: "facilityDetails" },
            { header: 'Affiliation / Association With Corporate ', key: 'affiliation', callFrom: "facilityDetails" },
            { header: 'Shares With Corporate (%)', key: 'percentageOfShare', callFrom: "facilityDetails" },
            { header: 'Participation in Business Responsibility Initiatives', key: 'participatesInBusinessResponsibility', callFrom: "facilityDetails" },
        ]
    },
    {
        businessActivitiesColumns: [
            { header: 'Facility Name', key: 'assetName', callFrom: "businessActivities" },
            // { header: 'Description of Main Activity', key: "nicDto", callFrom: "businessActivities" },
            { header: 'Description of Main Activity', key: "primaryActivity", callFrom: "businessActivities" },
            { header: 'Description of Business Activity', key: 'activityDetails', callFrom: "businessActivities" },
            { header: '(%) of Total Turnover Contributed', key: 'totalTurnOverContributionPercentage', callFrom: "businessActivities" },
        ]
    },
    {
        productsOrServicesColumns: [
            { header: 'Product/Service', key: 'projectActivity', callFrom: "productsOrServices" },
            { header: 'NIC Code', key: 'nicCode', callFrom: "productsOrServices" },
            { header: 'SEBI Category', key: 'sebiCategory', callFrom: "productsOrServices" },
            { header: 'NSE Category', key: 'nseCategory', callFrom: "productsOrServices" },
            // { header: 'CDP Category', key: 'cdpCategory', callFrom: "productsOrServices" },
            { header: 'CPCB Category', key: 'cpcbCategory', callFrom: "productsOrServices" },
            { header: '(%) of total Turnover Contributed', key: 'turnOverContribution', callFrom: "productsOrServices" }
        ]
    },
    {
        numberOfFacilityPlantsAndOfficesColumns: [
            { header: 'Country Name', key: 'country' },
            { header: 'State/ Provinces', key: 'state' },
            { header: 'Number of Plants', key: 'numberOfPlant' },
            { header: 'Number of Offices', key: 'numberOfOffice' },
            { header: 'Total', key: 'total' },
        ]
    },
    {
        numberOfLocationsWherePlantsAndOfficesColumns: [
            { header: 'Location', key: 'data' },
            { header: 'Number of Plants', key: 'numberOfPlant' },
            { header: 'Number of Offices', key: 'numberOfOffice' },
            { header: 'Total', key: 'total' },
        ]
    },
    {
        esgMaterialColumns: [
            { header: "Issue", key: "issue" },
            { header: "Business Interest Score", key: "businessInterestScore" },
            { header: "Stakeholder Interest Score", key: "stakeHolderInterestScore" },
            { header: "Score", key: "maturityThreshold" },
        ]
    }
];

export const actions = [
    {
        label: 'Edit',
    }
];

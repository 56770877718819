import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getAllDiversityOfGovernanceBodiesData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/diversity-inclusion/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateDiversity = async (diversityOfGovernanceBodiesData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/diversity-inclusion/save-or-update`, diversityOfGovernanceBodiesData)
};

export const sendForApproved = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/diversity-inclusion/send-for-approval`, recordIds);
};

export const approveRecord = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/diversity-inclusion/approve`, payloadData)
};
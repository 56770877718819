import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateChildAndForcedLabourData } from '../../services/sub-issue-services/human-rights-services/ChildAndForcedLabourService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


const childLabourData = ["Young Workers Engaged in Facility", "Young Workers Exposed to Hazardous Work"];
const forcedLabourData = ["Slave Labour", "Bonded Labour"];

function AddChildAndForcedLabour(props) {

    const { openChildAndForcedLabour, setOpenChildAndForcedLabour, getChildAndForcedLabourAllData, treeViewData } = props;
    const { register, handleSubmit, reset, watch, control, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);

    const [typeOfOperation, setTypeOfOperation] = useState("");
    const [geographicLocation, setGeographicLocation] = useState("");
    const [humanRightRiskOn, setHumanRightRiskOn] = useState("");
    const [significantRiskOn, setSignificantRiskOn] = useState("");
    const [significantRiskOnData, setSignificantRiskOnData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openChildAndForcedLabour.action && openChildAndForcedLabour.childAndForcedLabourData) {
            setTypeOfOperation(openChildAndForcedLabour.childAndForcedLabourData.typeOfOperation)
            setGeographicLocation(openChildAndForcedLabour.childAndForcedLabourData.geographicLocationBasedRiskAssessed)
            setHumanRightRiskOn(openChildAndForcedLabour.childAndForcedLabourData.humanRightRisk)
            setSignificantRiskOn(openChildAndForcedLabour.childAndForcedLabourData.significantRisk)
            if (openChildAndForcedLabour.childAndForcedLabourData.humanRightRisk === "Child Labour") {
                setSignificantRiskOnData(childLabourData)
            } else {
                setSignificantRiskOnData(forcedLabourData)
            }
            reset(openChildAndForcedLabour.childAndForcedLabourData);
        };
    }, [openChildAndForcedLabour.action === true]);

    const changeTypeOfOperation = (event) => {
        setTypeOfOperation(event.target.value);
    };
    const changeGeographicLocation = (event) => {
        setGeographicLocation(event.target.value);
    };
    const changeHumanRightRiskOn = (event) => {
        let humanRightRiskOnData = event.target.value;
        setHumanRightRiskOn(humanRightRiskOnData);
        if (humanRightRiskOnData === "Child Labour") {
            setSignificantRiskOnData(childLabourData)
        } else {
            setSignificantRiskOnData(forcedLabourData)
        };
    };
    const changeSignificantRiskOn = (event) => {
        setSignificantRiskOn(event.target.value);
    };
    const handleClose = () => {
        reset({ totalNumberOfCase: "", measuresTakenToEffectiveAbolitionOfChildAndForceLabour: "" })
        setTypeOfOperation("");
        setGeographicLocation("");
        setHumanRightRiskOn("");
        setSignificantRiskOnData([]);
        setSignificantRiskOn("");
        setOpenChildAndForcedLabour({ action: false, childAndForcedLabourData: null });
    };

    const saveChildAndForcedLabour = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "typeOfOperation": data.typeOfOperation,
                "geographicLocationBasedRiskAssessed": data.geographicLocationBasedRiskAssessed,
                "humanRightRisk": data.humanRightRisk,
                "significantRisk": data.significantRisk,
                "totalNumberOfCase": data.totalNumberOfCase,
                "measuresTakenToEffectiveAbolitionOfChildAndForceLabour": data.measuresTakenToEffectiveAbolitionOfChildAndForceLabour,
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            saveOrUpdateChildAndForcedLabourData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getChildAndForcedLabourAllData();
                    reset({ totalNumberOfCase: "", measuresTakenToEffectiveAbolitionOfChildAndForceLabour: "" })
                    setTypeOfOperation("");
                    setGeographicLocation("");
                    setSignificantRiskOnData([]);
                    setHumanRightRiskOn("");
                    setSignificantRiskOn("");
                    setOpenChildAndForcedLabour({ action: false, childAndForcedLabourData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateChildAndForcedLabour = (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openChildAndForcedLabour.childAndForcedLabourData.refNo,
            "typeOfOperation": data.typeOfOperation,
            "geographicLocationBasedRiskAssessed": data.geographicLocationBasedRiskAssessed,
            "humanRightRisk": data.humanRightRisk,
            "significantRisk": data.significantRisk,
            "totalNumberOfCase": data.totalNumberOfCase,
            "measuresTakenToEffectiveAbolitionOfChildAndForceLabour": data.measuresTakenToEffectiveAbolitionOfChildAndForceLabour,
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate,
        }
        saveOrUpdateChildAndForcedLabourData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getChildAndForcedLabourAllData();
                reset({ totalNumberOfCase: "", measuresTakenToEffectiveAbolitionOfChildAndForceLabour: "" })
                setTypeOfOperation("");
                setGeographicLocation("");
                setHumanRightRiskOn("");
                setSignificantRiskOnData([]);
                setSignificantRiskOn("");
                setOpenChildAndForcedLabour({ action: false, childAndForcedLabourData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openChildAndForcedLabour.childAndForcedLabourData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getChildAndForcedLabourAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ totalNumberOfCase: "", measuresTakenToEffectiveAbolitionOfChildAndForceLabour: "" })
                setTypeOfOperation("");
                setGeographicLocation("");
                setHumanRightRiskOn("");
                setSignificantRiskOnData([]);
                setSignificantRiskOn("");
                setOpenChildAndForcedLabour({ action: false, childAndForcedLabourData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openChildAndForcedLabour.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold'>
                    {openChildAndForcedLabour.childAndForcedLabourData ? "Edit Child and Forced Labour" : "Add Child and Forced Labour"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openChildAndForcedLabour.childAndForcedLabourData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Type of Operation</material.InputLabel>
                                    <material.Select
                                        {...register("typeOfOperation", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={typeOfOperation}
                                        onChange={changeTypeOfOperation}
                                        label="Type Of Operation"
                                    >
                                        <material.MenuItem value={"Facility it Self"}>Facility it Self</material.MenuItem>
                                        <material.MenuItem value={"Supplier"}>Supplier</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Geographic Location Based Risk Assessed</material.InputLabel>
                                    <material.Select
                                        {...register("geographicLocationBasedRiskAssessed", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={geographicLocation}
                                        onChange={changeGeographicLocation}
                                        label="Geographic Location Based Risk Assessed"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Human Right Risk on</material.InputLabel>
                                    <material.Select
                                        {...register("humanRightRisk", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={humanRightRiskOn}
                                        onChange={changeHumanRightRiskOn}
                                        label="Human Right Risk On"
                                    >
                                        <material.MenuItem value={"Child Labour"}>Child Labour</material.MenuItem>
                                        <material.MenuItem value={"Forced Labour"}>Forced Labour</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Significant Risk on</material.InputLabel>
                                    <material.Select
                                        {...register("significantRisk", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={significantRiskOn}
                                        onChange={changeSignificantRiskOn}
                                        label="Significant Risk on"
                                    >
                                        {significantRiskOnData?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-6 my-2'>
                                <material.TextField
                                    {...register("totalNumberOfCase", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Case"
                                    id="female"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalNumberOfCase && errors.totalNumberOfCase.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6 my-2'>
                                <material.TextField
                                    {...register("measuresTakenToEffectiveAbolitionOfChildAndForceLabour", { required: true, })}
                                    required
                                    margin="dense"
                                    label="Measures Taken to Effective Abolition of Child  & Force Labour"
                                    id="female"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openChildAndForcedLabour.childAndForcedLabourData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none" }} className="me-2" onClick={handleSubmit(updateChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openChildAndForcedLabour.childAndForcedLabourData.rejectionNote === null) || (openChildAndForcedLabour.childAndForcedLabourData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveChildAndForcedLabour)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddChildAndForcedLabour;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { getGeneralDisclosuresHistory } from '../../services/GeneralDisclosureService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDisclosuresQuestion } from '../../services/GeneralDisclosureService';
import Snackbar from '../../toaster-message/Snackbar';

var usersRole;

function GeneralDisclosureHistory(props) {

    const { treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [generalDisclosureData, setGeneralDisclosureData] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [headingAnswerPresent, setHeadingAnswerPresent] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getGeneralDisclosuresHistoryData()
        // getQuestions()
    }, []);

    // const getQuestions = async () => {
    //     await getDisclosuresQuestion(treeViewData.issue, "GENERAL_DISCLOSURE")
    //         .then((resp) => {
    //             setQuestions(resp.data)
    //             resp.data.forEach(element => {
    //                 if (element.field_type === "RADIO") {
    //                     setQuestionsType(element.questionid)
    //                 }
    //             });
    //         })
    //         .catch((error) => {

    //         })
    // };

    const getFormDateToDate = (data) => {
        getGeneralDisclosuresHistoryData(data)
    }

    const getGeneralDisclosuresHistoryData = async (filterDate) => {
        let questionId;
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData.pillar,
            "issue": treeViewData.issue,
            "typeOfDisclosure": "GENERAL_DISCLOSURE",
            // fromDate: filterDate?.fromDate,
            // toDate: filterDate?.toDate,
        }
        await getDisclosuresQuestion(treeViewData.issue, "GENERAL_DISCLOSURE")
            .then((resp) => {
                setQuestions(resp.data)
                questionId = resp.data.filter((element) => element.field_type === "OPTIONAL_TEXT_BOX").map((res) => res.questionid)
                getGeneralDisclosuresHistory(obj)
                    .then((res) => {
                        setGeneralDisclosureData(res.data)
                        if (res.data.length) {
                            res.data.forEach(element => {
                                if (element.questionId === questionId[0]) {
                                    element?.answers?.forEach(element => {
                                        if (element.answer === null || element.answer === "") {
                                            setHeadingAnswerPresent("No");
                                        } else {
                                            setHeadingAnswerPresent("Yes");
                                        }
                                    })
                                }
                            });
                        }
                    })
                    .catch((error) => {

                    })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const getBorderBottom = (ind, ansLength) => {
        let border = (ind == (ansLength - 1)) ? 'none' : '1px solid #D0CCCC'
        return border;
    };

    return (
        <div className='row'>
            {/* <div className='mb-3 col-lg-8'>
                <DateFilter
                    getFormDateToDate={getFormDateToDate}
                />
            </div> */}
            <material.TableContainer>
                <material.Table>
                    <material.TableHead>
                        <material.TableRow>
                            <StyledTableCell align="center" sx={{ width: 300 }} >Question</StyledTableCell>
                            <StyledTableCell align="center" sx={{ width: 500, pl: 0 }} >Answer</StyledTableCell>
                            <StyledTableCell align="center" sx={{ width: 100, pl: 0 }}>Approval Date</StyledTableCell>
                            <StyledTableCell align="center" sx={{ width: 100, pl: 0 }}>Status</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {generalDisclosureData.length ?
                            questions.length ? questions.map((element, i) => (
                                element.field_type === "HEADING" ? (
                                    <material.TableRow key={i}>
                                        <material.TableCell colSpan={4}>{element.question} : </material.TableCell>
                                    </material.TableRow>
                                ) : element.field_type === "RADIO" ? (
                                    <material.TableRow style={{ borderBottom: '2px solid black' }} key={i}>
                                        <material.TableCell colSpan={4}>{element.question} : {headingAnswerPresent}</material.TableCell>
                                    </material.TableRow>
                                ) : element.field_type !== "HEADING" ? (
                                    <material.TableRow sx={{ width: 200 }} style={{ borderBottom: i === generalDisclosureData.find(ele => ele.questionId === element.questionid).length - 1 ? "none" : '2px solid black' }} key={i} >
                                        <material.TableCell align="left" component="th" scope="row" >{element.question}</material.TableCell>
                                        <material.TableCell align="left" style={{ padding: 0 }}>{generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.map((answer, a) =>
                                            <div className='answer-text' style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 200, overflowX:"scroll", padding: 7, borderBottom: getBorderBottom(a, generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.length) }} key={a} align={answer.answer === null || answer.answer === "" ? "center" : "left"}> {answer.answer === null || answer.answer === "" ? "N/A" : answer.answer}</div>)}</material.TableCell>
                                        <material.TableCell style={{ padding: 0 }}>{generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.map((approvalDate, d) =>
                                            <div style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 200, padding: 7, display: "flex", alignItems: "center", justifyContent: "center", borderBottom: getBorderBottom(d, generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.length), width: "10rem" }} key={d}>{approvalDate.answer === null ? "N/A" : (approvalDate.approvalDate ? moment(approvalDate.approvalDate).format("DD-MM-YYYY") : "")}</div>)}
                                        </material.TableCell>
                                        <material.TableCell style={{ padding: 0 }}>{generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.map((actiontype, c) =>
                                            <div style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 200, padding: 7, display: "flex", alignItems: "center", justifyContent: "center", borderBottom: getBorderBottom(c, generalDisclosureData.find(ele => ele.questionId === element.questionid).answers.length) }} key={c}>
                                                {actiontype.answer === null ? "N/A" :
                                                    actiontype.approvalStatus === "APPROVED" ? <span className="badge bg-success">
                                                        APPROVED
                                                    </span> : null}
                                            </div>)}
                                        </material.TableCell>
                                    </material.TableRow>
                                ) : null
                            )) : null
                            : (
                                <material.TableRow >
                                    <material.TableCell colSpan={10}>
                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                    </material.TableCell>
                                </material.TableRow>
                            )}
                    </material.TableBody>
                    {/* <material.TableBody>
                        {generalDisclosureData.length ? generalDisclosureData.map((row, i) =>
                            <material.TableRow style={{ borderBottom: i == generalDisclosureData.length - 1 ? "none" : '2px solid black' }} key={i} >
                                <material.TableCell align="left" component="th" scope="row" >
                                    {questions.length ? questions.find(q => q.questionid === row.questions).question : ""}
                                </material.TableCell>
                                {row.answers.length ? (
                                    <>
                                        <material.TableCell align="left" style={{ padding: 0 }}>{row.answers.map((answer, a) =>
                                            <div style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 100, padding: 7, borderBottom: getBorderBottom(a, row.answers.length), width: 750, wordWrap: "break-word" }} key={a}>{answer.answer}</div>)}</material.TableCell>
                                        <material.TableCell style={{ padding: 0 }}>{row.answers.map((approvalDate, d) =>
                                            <div style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 100, padding: 7, display: "flex", alignItems: "center", justifyContent: "center", borderBottom: getBorderBottom(d, row.answers.length) }} key={d}>{approvalDate.approvalDate ? moment(approvalDate.approvalDate).format("DD-MM-YYYY") : ""}</div>)}</material.TableCell>
                                        <material.TableCell style={{ padding: 0 }}>{row.answers.map((actiontype, c) =>
                                            <div style={{ borderRight: '1px solid #D0CCCC', borderLeft: '1px solid #D0CCCC', height: 100, padding: 7, display: "flex", alignItems: "center", justifyContent: "center", borderBottom: getBorderBottom(c, row.answers.length) }} key={c}>
                                                {actiontype.approvalStatus === "APPROVED" ? <span className="badge bg-success">
                                                    APPROVED
                                                </span> : null}
                                                {actiontype.approvalStatus === "REJECTED" ? <span className="badge bg-danger">
                                                    REJECTED
                                                </span> : null}
                                            </div>)}
                                        </material.TableCell>
                                    </>
                                ) : <material.TableCell align='center'>No Answer</material.TableCell>}
                            </material.TableRow>
                        ) : (
                            <material.TableRow >
                                <material.TableCell colSpan={10}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                </material.TableCell>
                            </material.TableRow>
                        )}
                    </material.TableBody> */}
                </material.Table>
            </material.TableContainer>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default GeneralDisclosureHistory;
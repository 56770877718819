import React, { useState } from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddSebi from './AddSebi';
import { useSelector } from 'react-redux';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import Snackbar from '../../toaster-message/Snackbar';
import { deleteSebi } from '../../services/materiality-services/MaterialityService';

function SebiMateriality(props) {

    const { getSiteBySiteRefNo, facilityData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSebi, setOpenSebi] = useState({ action: false, editData: null });
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const addSebiDialog = () => {
        setOpenSebi({ action: true, editData: null });
    };

    const editSebiData = (rowData) => {
        setOpenSebi({ action: true, editData: rowData });
    };

    const deleteConfirmation = (rowData) => {
        setOpenDeleteRecordConfirmationDialog(true);
        setDeleteRowData(rowData);
    };
    const deleteProductServiceData = (rowData) => {
        deleteSebi(userDetails.facilityRefNo, rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSiteBySiteRefNo();
                setOpenDeleteRecordConfirmationDialog(false);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <>
            <div className='row'>
                {userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? (
                    <div className='col-12'>
                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", float: 'inline-end' }} startIcon={<material.AddIcon />} onClick={addSebiDialog} >Add SEBI</material.Button>
                    </div>
                ) : null}
                <div className='col-12 mt-3'>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead>
                                <material.TableRow>
                                    <StyledTableCell>Code</StyledTableCell>
                                    <StyledTableCell>Sector</StyledTableCell>
                                    <StyledTableCell>Industry Code</StyledTableCell>
                                    <StyledTableCell>Industry</StyledTableCell>
                                    <StyledTableCell>Basic Industry Code</StyledTableCell>
                                    <StyledTableCell>Basic Industry</StyledTableCell>
                                    <StyledTableCell>Definition</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {facilityData?.sebiDtos?.length ? facilityData.sebiDtos.map((sebi, k) => (
                                    <material.TableRow key={k}
                                    // sx={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                    // onClick={userDetails.roles.includes("ROLE_FACILITY_EDITOR") ? () => editSebiData(sebi) : null}
                                    >
                                        <material.TableCell>{sebi.sectCode}</material.TableCell>
                                        <material.TableCell>{sebi.sector}</material.TableCell>
                                        <material.TableCell>{sebi.indCode}</material.TableCell>
                                        <material.TableCell>{sebi.industry}</material.TableCell>
                                        <material.TableCell>{sebi.basicIndCode}</material.TableCell>
                                        <material.TableCell>{sebi.basicIndustry}</material.TableCell>
                                        <material.TableCell className='text-break' size='small' style={{ width: "20rem" }}>{sebi.definition}</material.TableCell>
                                        <material.TableCell>
                                            <material.IconButton onClick={() => deleteConfirmation(sebi)} color='error'>
                                                <material.DeleteIcon />
                                            </material.IconButton>
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={8}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddSebi
                openSebi={openSebi}
                setOpenSebi={setOpenSebi}
                getSiteBySiteRefNo={getSiteBySiteRefNo}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                deleteProductServiceData={deleteProductServiceData}
                callFrom="SEBI"
                deleteRowData={deleteRowData}
            />
        </>
    )
}

export default SebiMateriality
import React,{useEffect, useState}from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../library/Material';
import { useSelector } from "react-redux";
import { getDisclosuresQuestion } from '../../services/GeneralDisclosureService';

function AddGovernanceBodyStructure(props) {
    const { register, handleSubmit, reset, watch, formState: { errors }, } = useForm();
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddGovernanceBodyStructure, setOpenAddGovernanceBodyStructure,treeViewData} = props;


    const saveGovernanceBodyStructure = () => {
        setOpenAddGovernanceBodyStructure(false);
    };

    const handleClose = () => {
        setOpenAddGovernanceBodyStructure(false);
    };

    return (
        <div>
            <material.Dialog open={openAddGovernanceBodyStructure} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{openAddGovernanceBodyStructure.orgElectricityData ? "Edit governance body & structure" : "Add governance body & structure"}</material.DialogTitle>
                <material.DialogContent>
                    
                         
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" onClick={handleSubmit(saveGovernanceBodyStructure)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
        </div>
    )
}

export default AddGovernanceBodyStructure;
import { rootApiUrl } from "../../environment/Environment";
import { axiosInstance } from "../../shared/AxiosConfig";

export const getMaterialReportData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/materials-used`, { params: { ...payload } })
};

export const getMaterialReclaimedData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/meterial-reclaimed`, { params: { ...payload } })
};
export const getMaterialRecycledData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/report/meterial-recycled`, { params: { ...payload } })
};
import React, { useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddRecycledInput from '../../sub-issues-dialogs/material/AddRecycledInput';
import moment from 'moment';
import ApplicableNotApplicableWarning from '../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../toaster-message/Snackbar';
import { useEffect } from 'react';
import { approveRecord, getAllMaterialUsedData, sendForApproval } from '../../services/sub-issue-services/MaterialUsedService';
import { useSelector } from 'react-redux';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../shared/Filter';
import RejectionNote from '../../dialogs/RejectionNote';
import DateFilter from '../../shared/DateFilter';


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };
var recordRefNos = [];

function RecycledInput(props) {
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const location = useLocation();
    const treeViewData = location?.state;
    const [recycledInputTabs, setRecycledInputTabs] = useState('Recycled Input');
    const [recycledInputData, setRecycledInputData] = useState([]);
    const [openAddRecycledInput, setOpenAddRecycledInput] = useState({ action: false, recycledInputData: null });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [applicable, setApplicable] = useState(true);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getRecycledInputData();
    }, [recycledInputTabs]);

    const getRecycledInputData = async (filterDate) => {
        if (recycledInputTabs === 'Recycled Input') {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "RECYCLED_INPUT"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate
            }
            await getAllMaterialUsedData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable);
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList);
                    setRecycledInputData(filterData);
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
        else if (recycledInputTabs === 'Recycled Input History') {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "RECYCLED_INPUT"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllMaterialUsedData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    setRecycledInputData(resp.data.dataDtoList)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const addRecycledInput = () => {
        setOpenAddRecycledInput({ action: true, recycledInputData: null });
    };

    const editRecycledInput = (rowData) => {
        setOpenAddRecycledInput({ action: true, recycledInputData: rowData });
    };

    const handleClickCheckbox = (ids) => {
        setOpenAddRecycledInput({ action: false, recycledInputData: null });
        if (recordRefNos.includes(ids)) {
            // recordRefNos.pop(ids);
            recordRefNos = recordRefNos.filter(item => item !== ids);
        } else {
            recordRefNos.push(ids);
        }
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const sendApproval = async () => {
        if (recordRefNos.length) {
            await sendForApproval(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getRecycledInputData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const approved = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos,
                typeOfRecord: "RECYCLED_INPUT",
            }
            await approveRecord(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getRecycledInputData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const rejectRecord = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos,
            typeOfRecord: "RECYCLED_INPUT",
        }
        await approveRecord(obj)
            .then((resp) => {
                recordRefNos = [];
                getRecycledInputData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleRecycledInputTabs = (event, newValue) => {
        setRecycledInputTabs(newValue);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getFormDateToDate = (data) => {
        getRecycledInputData(data);
    }

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary"
                                        checked={applicable} onChange={applicableNotApplicable}
                                    />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={recycledInputTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleRecycledInputTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Recycled Input" value="Recycled Input" />
                                    <material.Tab className='text-capitalize' label="Recycled Input History" value="Recycled Input History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Recycled Input">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-6">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <>
                                                {recycledInputData?.length ? (
                                                    <span className='float-end'>
                                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} disabled={!applicable || !recordRefNos.length} startIcon={<material.DoneIcon />} onClick={approved} >Approve</material.Button>
                                                        <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} onClick={rejectRecord} startIcon={<material.PriorityHighIcon />}>Reject</material.Button>
                                                    </span>
                                                ) : null}
                                            </>
                                        ) : (
                                            <span className='float-end'>
                                                <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.SendIcon />} disabled={!applicable} onClick={sendApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' sx={{ textTransform: "none" }} disabled={!applicable} startIcon={<material.AddIcon />} onClick={addRecycledInput} >Add  Recycled Input</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Recycled Material</StyledTableCell>
                                                            <StyledTableCell>Other Waste Specify</StyledTableCell>
                                                            <StyledTableCell>Category of Material</StyledTableCell>
                                                            <StyledTableCell>Amount</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                            )}
                                                            <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                <StyledTableCell>Rejection Note</StyledTableCell>
                                                            )}
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {recycledInputData?.length ? recycledInputData.map((item, i) => (
                                                            <material.TableRow key={i}

                                                                sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                    () => editRecycledInput(item) : null}
                                                            >
                                                                <material.TableCell>{item.material}</material.TableCell>
                                                                <material.TableCell>{(item.otherSpecify === null || item.otherSpecify === "") ? "N/A" : item.otherSpecify}</material.TableCell>
                                                                <material.TableCell>{item.typeOfMaterial}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                )}
                                                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => handleClickCheckbox(item.refNo)} color="success" />
                                                                    ) : (
                                                                        <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => handleClickCheckbox(item.refNo)} color="success" />
                                                                    )}
                                                                </material.TableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                        <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                    )}
                                                                    </material.TableCell>
                                                                )}
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={10}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Recycled Input History">
                                <div className="row">
                                    <div className="col-7">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer >
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell>Recycled Material</StyledTableCell>
                                                            <StyledTableCell>Other Waste Specify</StyledTableCell>
                                                            <StyledTableCell>Category of Material</StyledTableCell>
                                                            <StyledTableCell>Amount</StyledTableCell>
                                                            <StyledTableCell>Unit</StyledTableCell>
                                                            <StyledTableCell>From Date</StyledTableCell>
                                                            <StyledTableCell>To Date</StyledTableCell>
                                                            <StyledTableCell>Approval Date</StyledTableCell>
                                                            <StyledTableCell>Status</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {recycledInputData?.length ? recycledInputData.map((item, i) => (
                                                            <material.TableRow key={i} >
                                                                <material.TableCell>{item.material}</material.TableCell>
                                                                <material.TableCell>{(item.otherSpecify === null || item.otherSpecify === "") ? "N/A" : item.otherSpecify}</material.TableCell>
                                                                <material.TableCell>{item.typeOfMaterial}</material.TableCell>
                                                                <material.TableCell>{item.amount}</material.TableCell>
                                                                <material.TableCell>{item.unit}</material.TableCell>
                                                                <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>
                                                                    {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={9}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box >
            </material.Box >
            <AddRecycledInput
                openAddRecycledInput={openAddRecycledInput}
                setOpenAddRecycledInput={setOpenAddRecycledInput}
                getRecycledInputData={getRecycledInputData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getRecycledInputData={getRecycledInputData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default RecycledInput;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { useForm } from 'react-hook-form';
import { getAllCorporate } from '../../services/CorporateService';
import { getSitesByCorporateRefNo } from '../../services/SiteService';
import Snackbar from '../../toaster-message/Snackbar';
import { useSelector } from 'react-redux';
import { getPillarIssueSubIssueTreeList } from '../../services/GeneralDisclosureService';
import { saveFacilityIssueMapping, getEntityData } from '../../services/ManageEntityService';
import { useNavigate, useLocation } from 'react-router-dom';
import BarsLoader from '../../shared/loader/BarsLoader';

var corporateList;
var corporateRefNo = [];
var facilityList = [];
var facilityRefNo = [];
// var pillarIssueList = [];

function AddEntity(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [corporateName, setCorporateName] = useState([]);
    const [facilityName, setFacilityName] = useState([]);
    const [pillarIssueSubIssueData, setPillarIssueSubIssueData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [pillarIssueList, setPillarIssueList] = useState([]);
    const navigate = useNavigate();
    const [selectFacilityName, setSelectFacilityName] = useState('');
    const [selectCorporateName, setSelectCorporateName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const entityData = location.state?.entityData;

    useEffect(() => {
        if (userDetails.roles.includes("ROLE_CORPORATE_EDITOR")) {
            getFacilityByCorporateRefNo()
        } else if (userDetails.roles.includes("ROLE_ADMIN")) {
            getAllCorporateData();
        };
        pillarIssueSubIssueTreeList();
    }, []);

    const getAllCorporateData = async () => {
        await getAllCorporate()
            .then((resp) => {
                corporateList = resp.data;
                let activeCorporate = resp.data.filter((ele) => ele.active === true);
                let corporateName = activeCorporate?.map((ele) => ele.corporateName).map((element, i) => {
                    if (element !== null) {
                        return element
                    } else if (element === null) {
                        return element = "No Name" + i
                    }
                })
                setCorporateName(corporateName)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const getFacilityByCorporateRefNo = async (refNo) => {
        await getSitesByCorporateRefNo(userDetails.roles.includes("ROLE_ADMIN") ? refNo : userDetails.corporateRefNo)
            .then((resp) => {
                facilityList = resp.data;
                let sitesName = resp.data?.map((ele) => ele.assetName);
                setFacilityName(sitesName)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const pillarIssueSubIssueTreeList = async () => {
        await getPillarIssueSubIssueTreeList()
            .then((resp) => {
                let pillarIssue = resp.data;
                pillarIssue.forEach((pillar) => {
                    pillar.children.forEach((issue) => {
                        issue.allowed = true;
                    })
                })
                // setPillarIssueList(pillarIssue)
                setPillarIssueSubIssueData(resp.data)
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const selectCorporate = async (event) => {
        setSelectFacilityName("");
        setSelectCorporateName(event.target.value)
        // reset({})
        corporateRefNo = corporateList?.filter((ele) => ele.corporateName === event.target.value).map((res) => res.refNo)[0];
        getFacilityByCorporateRefNo(corporateRefNo);
    };

    const selectSite = (event) => {
        setPillarIssueSubIssueData([]);
        setSelectFacilityName(event.target.value)
        pillarIssueSubIssueTreeList();
        facilityRefNo = facilityList?.filter((ele) => ele.assetName == event.target.value).map((res) => res.refNo);
        // getAllEntityData(facilityRefNo);
    };

    const getAllEntityData = (facilityRefNo) => {
        setIsLoading(true);
        const payload = {
            corporateRefNo: userDetails.roles.includes("ROLE_ADMIN") ? corporateRefNo : userDetails.corporateRefNo,
            facilityRefNo: facilityRefNo
        }
        getEntityData(payload)
            .then((resp) => {
                let data = resp.data.facilityData.map((ele) => {
                    return {
                        ...ele,
                    }
                })
                if (data[0]?.data.length) {
                    const filterData = mergeData(pillarIssueList, resp.data.facilityData);
                    setPillarIssueSubIssueData(filterData);
                    setIsLoading(false);
                } else {
                    setPillarIssueSubIssueData(pillarIssueList);
                    setIsLoading(false);
                };
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    function mergeData(data1, data2) {
        data1.forEach((pillar) => {
            pillar.children.forEach(issue => {
                const filterData = data2[0]?.data.filter((ele) => {
                    if (ele.id == issue.id) {
                        return ele;
                    }
                })
                issue.allowed = filterData[0].allowed;
            })
        })
        return data1;
    };

    const goBack = () => {
        navigate("/manage-entity");
    };

    const save = (data) => {
        const transformedData = Object.keys(data).map(key => {
            return {
                topicId: parseInt(key),
                allowed: data[key]
            };
        });
        const payload = {
            corporateRefNo: userDetails.roles.includes("ROLE_ADMIN") ? entityData.corporateRefNo : userDetails.corporateRefNo,
            facilityRefNo: [entityData.facilityRefNo],
            data: transformedData
        }
        saveFacilityIssueMapping(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setPillarIssueSubIssueData([]);
                navigate("/manage-entity");
                reset();
                setSelectFacilityName("");
                setSelectCorporateName("");
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className="col-8">
                            <span>
                                {userDetails.roles.includes("ROLE_ADMIN") ?
                                    (<material.Typography variant="h5">Manage Entity for {entityData?.facilityName} under {entityData?.corporateName}</material.Typography>)
                                    :
                                    (<material.Typography variant="h5">Manage Entity for {entityData?.facilityName}</material.Typography>)
                                }
                            </span>
                        </div>
                        <div className="col-4">
                            <span className='float-end'>
                                <material.Button variant="contained" color='info' sx={{ textTransform: "none" }}
                                    onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                            </span>
                        </div>
                    </div>
                    <material.Paper elevation={3} sx={{ p: 3, mt: 2, pb: 7 }} className='column-chart'>
                        <form>
                            {/* <div className='row'>
                                {userDetails.roles.includes("ROLE_ADMIN") ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label">Corporate Name</material.InputLabel>
                                            <material.Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectCorporateName}
                                                onChange={selectCorporate}
                                            >
                                                {corporateName.map((ele, i) => (
                                                    <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                                ))}
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                ) : null}
                                <div className={userDetails.roles.includes("ROLE_ADMIN") ? "col-lg-6 col-md-6 col-sm-12" : "col-12"}>
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="demo-simple-select-standard-label">Facility Name</material.InputLabel>
                                        <material.Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectFacilityName}
                                            onChange={selectSite}
                                        >
                                            {facilityName.map((ele, i) => (
                                                <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </div>
                            </div> */}
                            {pillarIssueSubIssueData.length ? pillarIssueSubIssueData.map((pillar, i) => (
                                <material.Accordion sx={{ mt: 2 }} key={i}>
                                    <material.AccordionSummary
                                        expandIcon={<material.ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <material.Typography sx={{ flexGrow: 1, fontSize: 20, fontWeight: "bold" }}>{pillar.attributeName}</material.Typography>
                                    </material.AccordionSummary>
                                    <material.AccordionDetails>
                                        <div className='row mt-2'>
                                            {pillar.children.map((issue, k) => (
                                                <div className="col-lg-12 col-md-12 col-sm-12" key={k}>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <material.Typography>{issue.attributeName}</material.Typography>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <material.Stack direction="row" spacing={1} alignItems="center">
                                                                <material.FormGroup>
                                                                    <material.FormControlLabel labelPlacement="start"
                                                                        control={<material.Switch color="success" defaultChecked />}
                                                                        {...register(`${issue.id}`)}
                                                                    />
                                                                </material.FormGroup>
                                                            </material.Stack>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </material.AccordionDetails>
                                </material.Accordion>
                            )) : null}
                            <div className="col-12 mt-3">
                                <span className='float-end' hidden={!pillarIssueSubIssueData.length}>
                                    <material.Button variant="outlined" color='success' sx={{ textTransform: "none" }}
                                        onClick={handleSubmit(save)} startIcon={<material.DoneIcon />}>Save</material.Button>
                                </span>
                            </div>
                        </form>
                    </material.Paper>
                </material.Box>
            </material.Box>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div >
    );
}

export default AddEntity;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateWaterDischargeData } from '../../services/sub-issue-services/WaterDischargeService';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddWaterDischargeQualityReceptor from './AddWaterDischargeQualityReceptor';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


const physicalPollutant = ["Color", "Electrical Conductivity", "Odour", "pH Value", "Temperature", "Total Suspended Solids (TSS)", "Total Dissolved Solids (TDS)", "Oil & Grease", "Other Pollutant (Specify)"];
const chemicalPollutant = ["Biochemical Oxygen Demand (BOD)", "Boron", "Chemical Oxygen Demand (COD)", "Total Carbon", "Ammonical Nitrogen", "Total Kjeldahl Nitrogen", "Free Ammonia", "Nitrate (as NO3)", "Phosphate", "Other Pollutant (Specify)"];
const metalsPollutant = ["Arsenic", "Benzene", "Copper", "Cadmium", "Cyanide", "Iron", "Lead", "Magnesium", "Mercury", "Manganese", "Nickel", "Zinc", "Other Pollutant (Specify)"];
const aromaticsPollutant = ["Phenolic Compounds", "Chloroamines", "Polychlorinated Biphenyls", "Polynuclear Aromatic Hydrocarbons", "Alachlor", "Atrazine", "Beta HCH", "Butachlor", "Chlorpyriphos"];
const bacteriologicalPollutant = ["Coliform Bacteria", "Escherichiacoli"];

var waterDischargeQualityReceptorAllTableData = [];

function AddWaterDischarge(props) {

    const { openWaterDischarge, setOpenWaterDischarge, getWaterDischargeAllData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [wasteWaterReuse, setWasteWaterReuse] = useState("No");
    const [levelOfTreatment, setLevelOfTreatment] = useState("");
    const [category, setCategory] = useState("");
    const [purposeReuseRecycle, setPurposeReuseRecycle] = useState("");
    const [dischargeDestinationType, setDischargeDestinationType] = useState("");
    const [destinationCategory, setDestinationCategory] = useState("");
    const [pollutantSuite, setPollutantSuite] = useState("");
    const [pollutantParameter, setPollutantParameter] = useState("");
    const [pollutantParameterValue, setPollutantParameterValue] = useState([]);
    const [openAddWaterDischargeQualityReceptor, setOpenAddWaterDischargeQualityReceptor] = useState({ action: false, waterDischargeQualityReceptorAllData: null, })
    const [waterDischargeQualityReceptorTableData, setWaterDischargeQualityReceptorTableData] = useState([]);
    const [dischargePointValue, setDischargePointValue] = useState("");
    const [dischargeDestinationValue, setDischargeDestinationValue] = useState("");
    const [finalDischargeQuantityValue, setFinalDischargeQuantityValue] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [receptorQualitySuite, setReceptorQualitySuite] = useState("");
    const [receptorQualityParameter, setReceptorQualityParameter] = useState("");
    const [receptorQualityParameterValue, setReceptorQualityParameterValue] = useState([]);
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeDischargePoint = watch("dischargePoint");
    const onChangeDischargeDestination = watch("dischargeDestination");
    const onChangeFinalDischarge = watch("finalDischarge");

    useEffect(() => {
        let value1 = getValues("dischargePoint");
        setValue("destinationDischargePoint", value1);
        setValue("qualityDischargePoint", value1);
        setDischargePointValue(value1)
    }, [onChangeDischargePoint]);

    useEffect(() => {
        let finalDischargeValue = getValues("finalDischarge");
        if (finalDischargeValue === "0") {
            setFinalDischargeQuantityValue(true)
        } else {
            setFinalDischargeQuantityValue(false)
        }
    }, [onChangeFinalDischarge]);

    useEffect(() => {
        let value2 = getValues("dischargeDestination");
        setValue("receptorDischargeDestination", value2);
        setDischargeDestinationValue(value2)
    }, [onChangeDischargeDestination]);

    useEffect(() => {
        if (openWaterDischarge.action && openWaterDischarge.waterDischargeAllData) {
            setPollutantSuite(openWaterDischarge.waterDischargeAllData.qualityPollutantSuit);
            if (openWaterDischarge.waterDischargeAllData.qualityPollutantSuit === "Physical") {
                setPollutantParameterValue(physicalPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.qualityPollutantSuit === "Chemical") {
                setPollutantParameterValue(chemicalPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.qualityPollutantSuit === "Metals") {
                setPollutantParameterValue(metalsPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.qualityPollutantSuit === "Aromatics") {
                setPollutantParameterValue(aromaticsPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.qualityPollutantSuit === "Bacteriological") {
                setPollutantParameterValue(bacteriologicalPollutant);
            };
            setPollutantParameter(openWaterDischarge.waterDischargeAllData.qualityPollutantParameter);
            setPurposeReuseRecycle(openWaterDischarge.waterDischargeAllData.purposeOfReuseOrRecycle);
            setDischargeDestinationType(openWaterDischarge.waterDischargeAllData.dischargeDestinationType);
            setDestinationCategory(openWaterDischarge.waterDischargeAllData.destinationCategory);
            setCategory(openWaterDischarge.waterDischargeAllData.category);
            setLevelOfTreatment(openWaterDischarge.waterDischargeAllData.levelOfTreatment);
            setWasteWaterReuse(openWaterDischarge.waterDischargeAllData.doesThePlantReuseOrRecyclePartOfItsWastewater ? "Yes" : "No");
            setReceptorQualitySuite(openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit);
            if (openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit === "Physical") {
                setReceptorQualityParameterValue(physicalPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit === "Chemical") {
                setReceptorQualityParameterValue(chemicalPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit === "Metals") {
                setReceptorQualityParameterValue(metalsPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit === "Aromatics") {
                setReceptorQualityParameterValue(aromaticsPollutant);
            } else if (openWaterDischarge.waterDischargeAllData.receptorQualityPollutantSuit === "Bacteriological") {
                setReceptorQualityParameterValue(bacteriologicalPollutant);
            };
            setReceptorQualityParameter(openWaterDischarge.waterDischargeAllData.receptorQualityPollutantParameter);
            reset(openWaterDischarge.waterDischargeAllData);
        }
    }, [openWaterDischarge.action === true]);

    const handleClose = () => {
        reset({
            destinationDischargePoint: "", qualityDischargePoint: "", receptorDischargeDestination: "",
            dischargePoint: "", outputSource: "", quantityReusedOrRecycled: "", finalDischarge: "", dischargeLocationLat: "", dischargeLocationLong: "", dischargeDestination: "", receptorQualityPollutantSuit: "", qualityDischargePointValue: "",
            qualityPollutantValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "", receptorQualityPollutantParameter: "", receptorDischargePointValue: "", dischargePointValue: "",
        });
        setReceptorQualitySuite("");
        setReceptorQualityParameter("");
        setPollutantParameter("");
        setPollutantSuite("");
        setPurposeReuseRecycle("");
        setDischargeDestinationType("");
        setDestinationCategory("");
        setCategory("");
        setLevelOfTreatment("");
        setWaterDischargeQualityReceptorTableData([])
        waterDischargeQualityReceptorAllTableData = [];
        setOpenWaterDischarge({ action: false, waterDischargeAllData: null });
    };
    const saveWaterDischarge = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let allData;
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            if (waterDischargeQualityReceptorTableData.length) {
                allData = waterDischargeQualityReceptorTableData.map((ele) => {
                    let obj = {
                        ...data, ...ele,
                        "doesThePlantReuseOrRecyclePartOfItsWastewater": wasteWaterReuse === "Yes" ? true : false,
                        "refNo": "",
                        "corporateRefNo": userDetails.corporateRefNo,
                        "facilityRefNo": userDetails.facilityRefNo,
                        "applicable": true,
                        "rejectionNote": "",
                        toDate: payLoad?.toDate ? payLoad.toDate : data.toDate,
                        fromDate: payLoad?.fromDate ? payLoad.fromDate : data.fromDate
                    };
                    return obj;
                });
            } else {
                allData = [{
                    ...data,
                    "doesThePlantReuseOrRecyclePartOfItsWastewater": wasteWaterReuse === "Yes" ? true : false,
                    "refNo": "",
                    "corporateRefNo": userDetails.corporateRefNo,
                    "facilityRefNo": userDetails.facilityRefNo,
                    "applicable": true,
                    "rejectionNote": "",
                    toDate: payLoad?.toDate,
                    fromDate: payLoad?.fromDate
                }]
            };
            saveOrUpdateWaterDischargeData(allData)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getWaterDischargeAllData();
                    reset({
                        destinationDischargePoint: "", qualityDischargePoint: "", receptorDischargeDestination: "",
                        dischargePoint: "", outputSource: "", quantityReusedOrRecycled: "", finalDischarge: "", dischargeLocationLat: "", dischargeLocationLong: "", dischargeDestination: "", receptorQualityPollutantSuit: "", qualityDischargePointValue: "",
                        qualityPollutantValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "", receptorQualityPollutantParameter: "", receptorDischargePointValue: "", dischargePointValue: "",
                    })
                    setReceptorQualitySuite("");
                    setReceptorQualityParameter("");
                    setPollutantParameter("");
                    setPollutantSuite("");
                    setPurposeReuseRecycle("");
                    setDischargeDestinationType("");
                    setDestinationCategory("");
                    setCategory("");
                    setLevelOfTreatment("");
                    setWaterDischargeQualityReceptorTableData([]);
                    waterDischargeQualityReceptorAllTableData = [];
                    setOpenWaterDischarge({ action: false, waterDischargeAllData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };
    const wasteWaterRecycle = (event) => {
        setWasteWaterReuse(event.target.value);
    };
    const levelOfTreatmentChange = (event) => {
        setLevelOfTreatment(event.target.value);
    };
    const categoryChange = (event) => {
        setCategory(event.target.value);
    };
    const purposeReuseRecycleChange = (event) => {
        setPurposeReuseRecycle(event.target.value);
    };
    const dischargeDestinationTypeChange = (event) => {
        setDischargeDestinationType(event.target.value);
    };
    const destinationCategoryChange = (event) => {
        setDestinationCategory(event.target.value);
    };
    const pollutantSuiteChange = (event) => {
        let pollutantSuiteChangeData = event.target.value;
        setValue("receptorQualityPollutantSuit", pollutantSuiteChangeData);

        setPollutantSuite(pollutantSuiteChangeData);
        if (pollutantSuiteChangeData === "Physical") {
            setPollutantParameterValue(physicalPollutant);
        } else if (pollutantSuiteChangeData === "Chemical") {
            setPollutantParameterValue(chemicalPollutant);
        } else if (pollutantSuiteChangeData === "Metals") {
            setPollutantParameterValue(metalsPollutant);
        } else if (pollutantSuiteChangeData === "Aromatics") {
            setPollutantParameterValue(aromaticsPollutant);
        } else if (pollutantSuiteChangeData === "Bacteriological") {
            setPollutantParameterValue(bacteriologicalPollutant);
        };
    };
    const pollutantParameterChange = (event) => {
        let pollutantParameterChangeValue = event.target.value
        setPollutantParameter(pollutantParameterChangeValue);
        if (pollutantParameterChangeValue === "Color") {
            setValue("qualityUnit", "Hazen")
        } else if (pollutantParameterChangeValue === "Electrical Conductivity") {
            setValue("qualityUnit", "mhos/cm")
        } else if (pollutantParameterChangeValue === "Odour") {
            setValue("qualityUnit", "ppm")
        } else if (pollutantParameterChangeValue === "pH Value") {
            setValue("qualityUnit", "pH unit")
        } else if (pollutantParameterChangeValue === "Temperature") {
            setValue("qualityUnit", "℃")
        } else if (pollutantParameterChangeValue === "Alachlor" || pollutantParameterChangeValue === "Atrazine" ||
            pollutantParameterChangeValue === "Beta HCH" || pollutantParameterChangeValue === "Butachlor" || pollutantParameterChangeValue === "Chlorpyriphos") {
            setValue("qualityUnit", "μg/l")
        } else if (pollutantParameterChangeValue === "Coliform bacteria" || pollutantParameterChangeValue === "Escherichiacoli") {
            setValue("qualityUnit", "MPN/100ml")
        } else if (pollutantParameterChangeValue === "Other Pollutant (Specify)") {
            setValue("qualityUnit", "")
        } else {
            setValue("qualityUnit", "mg/l")
        };
    };

    const receptorQualitySuiteChange = (event) => {
        let receptorQualitySuiteChangeData = event.target.value;
        setReceptorQualitySuite(receptorQualitySuiteChangeData);
        if (receptorQualitySuiteChangeData === "Physical") {
            setReceptorQualityParameterValue(physicalPollutant);
        } else if (receptorQualitySuiteChangeData === "Chemical") {
            setReceptorQualityParameterValue(chemicalPollutant);
        } else if (receptorQualitySuiteChangeData === "Metals") {
            setReceptorQualityParameterValue(metalsPollutant);
        } else if (receptorQualitySuiteChangeData === "Aromatics") {
            setReceptorQualityParameterValue(aromaticsPollutant);
        } else if (receptorQualitySuiteChangeData === "Bacteriological") {
            setReceptorQualityParameterValue(bacteriologicalPollutant);
        };
    };
    const receptorQualityParameterChange = (event) => {
        let receptorQualityParameterChangeData = event.target.value;
        setReceptorQualityParameter(receptorQualityParameterChangeData);
        if (receptorQualityParameterChangeData === "Color") {
            setValue("receptorQualityUnit", "Hazen")
        } else if (receptorQualityParameterChangeData === "Electrical Conductivity") {
            setValue("receptorQualityUnit", "mhos/cm")
        } else if (receptorQualityParameterChangeData === "Odour") {
            setValue("receptorQualityUnit", "ppm")
        } else if (receptorQualityParameterChangeData === "pH Value") {
            setValue("receptorQualityUnit", "pH unit")
        } else if (receptorQualityParameterChangeData === "Temperature") {
            setValue("receptorQualityUnit", "℃")
        } else if (receptorQualityParameterChangeData === "Alachlor" || receptorQualityParameterChangeData === "Atrazine" ||
            receptorQualityParameterChangeData === "Beta HCH" || receptorQualityParameterChangeData === "Butachlor" || receptorQualityParameterChangeData === "Chlorpyriphos") {
            setValue("receptorQualityUnit", "μg/l")
        } else if (receptorQualityParameterChangeData === "Coliform bacteria" || receptorQualityParameterChangeData === "Escherichiacoli") {
            setValue("receptorQualityUnit", "MPN/100ml")
        } else if (receptorQualityParameterChangeData === "Other Pollutant (Specify)") {
            setValue("receptorQualityUnit", "")
        } else {
            setValue("receptorQualityUnit", "mg/l")
        };
    };

    const addWaterDischargeQualityReceptor = () => {
        setOpenAddWaterDischargeQualityReceptor({ action: true, waterDischargeQualityReceptorAllData: null, });
    };
    const waterDischargeQualityReceptorAllData = (allData) => {
        waterDischargeQualityReceptorAllTableData.push(allData)
        setWaterDischargeQualityReceptorTableData(waterDischargeQualityReceptorAllTableData)
    }
    const handleDeleteRow = (index) => {
        const updatedData = [...waterDischargeQualityReceptorTableData];
        updatedData.splice(index, 1);
        waterDischargeQualityReceptorAllTableData = updatedData;
        setWaterDischargeQualityReceptorTableData(updatedData)
    };

    const updateWaterDischarge = (fromData) => {
        saveOrUpdateWaterDischargeData([
            {
                ...fromData,
                otherSpecifyDischargePollutant: pollutantParameter === "Other Pollutant (Specify)" ? fromData.otherSpecifyDischargePollutant : "",
                otherSpecifyReceptorPollutant: receptorQualityParameter === "Other Pollutant (Specify)" ? fromData.otherSpecifyReceptorPollutant : "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo
            }
        ])
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getWaterDischargeAllData();
                reset({
                    destinationDischargePoint: "", qualityDischargePoint: "", receptorDischargeDestination: "",
                    dischargePoint: "", outputSource: "", quantityReusedOrRecycled: "", finalDischarge: "", dischargeLocationLat: "", dischargeLocationLong: "", dischargeDestination: "", receptorQualityPollutantSuit: "", qualityDischargePointValue: "",
                    qualityPollutantValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "", receptorQualityPollutantParameter: "", receptorDischargePointValue: "", dischargePointValue: "",
                })
                setReceptorQualitySuite("");
                setReceptorQualityParameter("");
                setPollutantParameter("");
                setPollutantSuite("");
                setPurposeReuseRecycle("");
                setDischargeDestinationType("");
                setDestinationCategory("");
                setCategory("");
                setLevelOfTreatment("");
                setOpenWaterDischarge({ action: false, waterDischargeAllData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openWaterDischarge.waterDischargeAllData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getWaterDischargeAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({
                    destinationDischargePoint: "", qualityDischargePoint: "", receptorDischargeDestination: "",
                    dischargePoint: "", outputSource: "", quantityReusedOrRecycled: "", finalDischarge: "", dischargeLocationLat: "", dischargeLocationLong: "", dischargeDestination: "", receptorQualityPollutantSuit: "", qualityDischargePointValue: "",
                    qualityPollutantValue: "", monitoringLocationLat: "", monitoringLocationLong: "", receptorQualityPollutantValue: "", receptorQualityUnit: "", receptorQualityPollutantParameter: "", receptorDischargePointValue: "", dischargePointValue: "",
                })
                setReceptorQualitySuite("");
                setReceptorQualityParameter("");
                setPollutantParameter("");
                setPollutantSuite("");
                setPurposeReuseRecycle("");
                setDischargeDestinationType("");
                setDestinationCategory("");
                setCategory("");
                setLevelOfTreatment("");
                setOpenWaterDischarge({ action: false, waterDischargeAllData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openWaterDischarge.action} maxWidth="lg" fullWidth >
                <material.DialogTitle id="alert-dialog-title"  className='fw-bold headingText'>
                    {openWaterDischarge.waterDischargeAllData ? "Edit Water Discharge" : "Add Water Discharge"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12 d-flex flex-row align-items-center'>
                            <div className='me-2 fw-bold text-dark'>Does the plant reuse or recycle part of its wastewater? :</div>
                            <material.FormControl>
                                <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={wasteWaterReuse}
                                    onChange={wasteWaterRecycle}
                                >
                                    <material.FormControlLabel value="Yes" control={<material.Radio color='secondary' />} label="Yes" />
                                    <material.FormControlLabel value="No" control={<material.Radio color='secondary' />} label="No" />
                                </material.RadioGroup>
                            </material.FormControl>
                        </div>
                    </div>
                    {openWaterDischarge.waterDischargeAllData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 1, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'><material.Typography><h5 className='fw-bold headingText'>Water Discharge Recycle or Reuse KLD</h5></material.Typography> </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("outputSource",)}
                                    // required
                                    label="Output Source"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Level of Treatment</material.InputLabel>
                                    <material.Select
                                        {...register("levelOfTreatment",)}
                                        // required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={levelOfTreatment}
                                        onChange={levelOfTreatmentChange}
                                        label="Level of treatment"
                                    >
                                        <material.MenuItem value={"No Treatment"}>No Treatment</material.MenuItem>
                                        <material.MenuItem value={"Primary Treatment"}>Primary Treatment</material.MenuItem>
                                        <material.MenuItem value={"Secondary Treatment"}>Secondary Treatment</material.MenuItem>
                                        <material.MenuItem value={"Tertiary Treatment"}>Tertiary Treatment</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {wasteWaterReuse === "Yes" ? (
                                <>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label" >Category</material.InputLabel>
                                            <material.Select
                                                {...register("category",)}
                                                // required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={category}
                                                onChange={categoryChange}
                                                label="Category"
                                            >
                                                <material.MenuItem value={"Reuse"}>Reuse</material.MenuItem>
                                                <material.MenuItem value={"Recycle"}>Recycle</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("quantityReusedOrRecycled",)}
                                            // required
                                            label="Quantity Reused or Recycled"
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label" >Purpose of Reuse or Recycle</material.InputLabel>
                                            <material.Select
                                                {...register("purposeOfReuseOrRecycle",)}
                                                // required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={purposeReuseRecycle}
                                                onChange={purposeReuseRecycleChange}
                                                label="Purpose of reuse or recycle"
                                            >
                                                <material.MenuItem value={"Process"}>Process</material.MenuItem>
                                                <material.MenuItem value={"Cooling"}>Cooling</material.MenuItem>
                                                <material.MenuItem value={"Washing"}>Washing</material.MenuItem>
                                                <material.MenuItem value={"Third party"}>Third Party</material.MenuItem>
                                                <material.MenuItem value={"Others"}>Others</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                </>
                            ) : null}
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("finalDischarge",)}
                                    // required
                                    label="Final Discharge Quantity"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("dischargePoint",)}
                                    // required
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'><material.Typography><h5 className='fw-bold headingText'>Water Discharge Effluent Destination</h5></material.Typography></div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("destinationDischargePoint",)}
                                    // required
                                    disabled={finalDischargeQuantityValue}
                                    label="Discharge Point"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("dischargeLocationLat", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    // required
                                    disabled={finalDischargeQuantityValue}
                                    label="Discharge Location (Latitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.dischargeLocationLat && errors.dischargeLocationLat.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("dischargeLocationLong", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    // required
                                    disabled={finalDischargeQuantityValue}
                                    label="Discharge Location (Longitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.dischargeLocationLong && errors.dischargeLocationLong.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Discharge Destination Type</material.InputLabel>
                                    <material.Select
                                        {...register("dischargeDestinationType",)}
                                        // required
                                        disabled={finalDischargeQuantityValue}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={dischargeDestinationType}
                                        onChange={dischargeDestinationTypeChange}
                                        label="Discharge destination type"
                                    >
                                        <material.MenuItem value={"Surface Water"}>Surface Water</material.MenuItem>
                                        <material.MenuItem value={"Ground Water"}>Ground Water</material.MenuItem>
                                        <material.MenuItem value={"Sea Water"}>Sea Water</material.MenuItem>
                                        <material.MenuItem value={"3rd Party"}>3rd Party</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-4 py-2'>
                                <material.TextField
                                    {...register("dischargeDestination",)}
                                    // required
                                    disabled={finalDischargeQuantityValue}
                                    label="Discharge Destination"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>

                            <div className='col-4 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Destination Category</material.InputLabel>
                                    <material.Select
                                        {...register("destinationCategory",)}
                                        // required
                                        disabled={finalDischargeQuantityValue}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={destinationCategory}
                                        onChange={destinationCategoryChange}
                                        label="Destination Category"
                                    >
                                        <material.MenuItem value={"Fresh Water (≤1,000 mg/L TDS)"}>{"Fresh Water (≤1,000 mg/L TDS)"}</material.MenuItem>
                                        <material.MenuItem value={"Other Water (>1,000 mg/L TDS)"}>{"Other Water (>1,000 mg/L TDS)"}</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                    {openWaterDischarge.waterDischargeAllData ? (
                        <>
                            <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                                <div className='row'>
                                    <div className='py-1'> <material.Typography><h5 className='fw-bold headingText'>Water Discharge Quality</h5></material.Typography></div>
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("qualityDischargePoint")}
                                            disabled={finalDischargeQuantityValue}
                                            label="Discharge Point"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                            <material.Select
                                                {...register("qualityPollutantSuit")}
                                                disabled={finalDischargeQuantityValue}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={pollutantSuite}
                                                onChange={pollutantSuiteChange}
                                                label="Pollutant suite"
                                            >
                                                <material.MenuItem value={"Physical"}>{"Physical"}</material.MenuItem>
                                                <material.MenuItem value={"Metals"}>{"Metals"}</material.MenuItem>
                                                <material.MenuItem value={"Chemical"}>{"Chemical"}</material.MenuItem>
                                                <material.MenuItem value={"Aromatics"}>{"Aromatics"}</material.MenuItem>
                                                <material.MenuItem value={"Bacteriological"}>{"Bacteriological"}</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                            <material.Select
                                                {...register("qualityPollutantParameter")}
                                                disabled={finalDischargeQuantityValue}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={pollutantParameter}
                                                onChange={pollutantParameterChange}
                                                label="Pollutant Parameter"
                                            >
                                                {pollutantParameterValue.map((ele, ind) => (
                                                    <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                                ))}
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    {pollutantParameter === "Other Pollutant (Specify)" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("otherSpecifyDischargePollutant")}
                                                disabled={finalDischargeQuantityValue}
                                                label="Other Pollutant Specify"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                    ) : null}
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("qualityPollutantValue", { pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                            label="Pollutant Value"
                                            disabled={finalDischargeQuantityValue}
                                            id="userName"
                                            variant="standard"
                                            type='number'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.qualityPollutantValue && errors.qualityPollutantValue.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                        )}
                                    </div>
                                    {pollutantParameter === "Other Pollutant (Specify)" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("qualityUnit")}
                                                label="Unit"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                    ) : (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("qualityUnit")}
                                                disabled={finalDischargeQuantityValue}
                                                label="Unit"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                                InputProps={{ readOnly: true, }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </material.Card>
                            <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                                <div className='row'>
                                    <div className='py-1'> <material.Typography><h5 className='fw-bold headingText'>Water Discharge Receptor Quality</h5></material.Typography> </div>
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("receptorDischargeDestination")}
                                            disabled={finalDischargeQuantityValue}
                                            label="Discharge Destination"
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("monitoringLocationLat", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                            label="Monitoring Location (Latitude)"
                                            disabled={finalDischargeQuantityValue}
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.monitoringLocationLat && errors.monitoringLocationLat.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also 4 digit after decimal.</p>
                                        )}
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("monitoringLocationLong", { pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                            label="Monitoring Location (Longitude)"
                                            disabled={finalDischargeQuantityValue}
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.monitoringLocationLong && errors.monitoringLocationLong.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                        )}
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label">Pollutant Suite</material.InputLabel>
                                            <material.Select
                                                {...register("receptorQualityPollutantSuit")}
                                                disabled={finalDischargeQuantityValue}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={receptorQualitySuite}
                                                onChange={receptorQualitySuiteChange}
                                                label="Pollutant suite"
                                            >
                                                <material.MenuItem value={"Physical"}>{"Physical"}</material.MenuItem>
                                                <material.MenuItem value={"Metals"}>{"Metals"}</material.MenuItem>
                                                <material.MenuItem value={"Chemical"}>{"Chemical"}</material.MenuItem>
                                                <material.MenuItem value={"Aromatics"}>{"Aromatics"}</material.MenuItem>
                                                <material.MenuItem value={"Bacteriological"}>{"Bacteriological"}</material.MenuItem>
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    <div className='col-4 py-2'>
                                        <material.FormControl variant="standard" fullWidth>
                                            <material.InputLabel id="demo-simple-select-standard-label">Pollutant Parameter</material.InputLabel>
                                            <material.Select
                                                {...register("receptorQualityPollutantParameter")}
                                                disabled={finalDischargeQuantityValue}
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={receptorQualityParameter}
                                                onChange={receptorQualityParameterChange}
                                                label="Pollutant Parameter"
                                            >
                                                {receptorQualityParameterValue.map((ele, ind) => (
                                                    <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                                ))}
                                            </material.Select>
                                        </material.FormControl>
                                    </div>
                                    {receptorQualityParameter === "Other Pollutant (Specify)" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("otherSpecifyReceptorPollutant")}
                                                disabled={finalDischargeQuantityValue}
                                                label="Other Pollutant Specify"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                    ) : null}
                                    <div className='col-4 py-2'>
                                        <material.TextField
                                            {...register("receptorQualityPollutantValue", { pattern: /^[0-9]+(?:\.[0-9]{1,4})?$/ })}
                                            label="Pollutant Value"
                                            disabled={finalDischargeQuantityValue}
                                            id="userName"
                                            variant="standard"
                                            type='text'
                                            size="small"
                                            fullWidth
                                        />
                                        {errors.receptorQualityPollutantValue && errors.receptorQualityPollutantValue.type === "pattern" && (
                                            <p className="errorMsg text-danger">Allowed only number & also four digit after decimal.</p>
                                        )}
                                    </div>
                                    {receptorQualityParameter === "Other Pollutant (Specify)" ? (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("receptorQualityUnit")}
                                                label="Unit"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                    ) : (
                                        <div className='col-4 py-2'>
                                            <material.TextField
                                                {...register("receptorQualityUnit")}
                                                disabled={finalDischargeQuantityValue}
                                                label="Unit"
                                                id="userName"
                                                variant="standard"
                                                type='text'
                                                size="small"
                                                fullWidth
                                                InputProps={{ readOnly: true, }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </material.Card>
                        </>
                    ) : (
                        <div className='row'>
                            <div className='col-12 mt-3 py-2'>
                                <material.Button variant="contained" className='float-end' color='success' sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addWaterDischargeQualityReceptor} disabled={finalDischargeQuantityValue}>Add Water Discharge Quality & Receptor</material.Button>
                            </div>
                            <material.Card className='col-12'>
                                <material.TableContainer>
                                    <material.Table>
                                        <material.TableHead>
                                            <material.TableRow>
                                                <StyledTableCell>Discharge Point</StyledTableCell>
                                                <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                <StyledTableCell>Pollutant Parameter Others(Specify)</StyledTableCell>
                                                <StyledTableCell>Pollutant Value</StyledTableCell>
                                                <StyledTableCell>Unit</StyledTableCell>
                                                <StyledTableCell>Discharge Destination</StyledTableCell>
                                                <StyledTableCell>Monitoring Location (Latitude)</StyledTableCell>
                                                <StyledTableCell>Monitoring Location (Longitude)</StyledTableCell>
                                                <StyledTableCell>Pollutant Suite</StyledTableCell>
                                                <StyledTableCell>Pollutant Parameter</StyledTableCell>
                                                <StyledTableCell>Receptor Quality Pollutant Parameter Other (Specify)</StyledTableCell>
                                                <StyledTableCell>Pollutant Value</StyledTableCell>
                                                <StyledTableCell>Unit</StyledTableCell>
                                                <StyledTableCell>Action</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {waterDischargeQualityReceptorTableData?.map((ele, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{ele.qualityDischargePoint}</material.TableCell>
                                                    <material.TableCell>{ele.qualityPollutantSuit}</material.TableCell>
                                                    <material.TableCell>{ele.qualityPollutantParameter}</material.TableCell>
                                                    <material.TableCell>{ele.otherSpecifyDischargePollutant}</material.TableCell>
                                                    <material.TableCell>{ele.qualityPollutantValue}</material.TableCell>
                                                    <material.TableCell>{ele.qualityUnit}</material.TableCell>
                                                    <material.TableCell>{ele.dischargeDestinationValueData}</material.TableCell>
                                                    <material.TableCell>{ele.monitoringLocationLat}</material.TableCell>
                                                    <material.TableCell>{ele.monitoringLocationLong}</material.TableCell>
                                                    <material.TableCell>{ele.receptorQualityPollutantSuit}</material.TableCell>
                                                    <material.TableCell>{ele.receptorQualityPollutantParameter}</material.TableCell>
                                                    <material.TableCell>{ele.otherSpecifyReceptorPollutant}</material.TableCell>
                                                    <material.TableCell>{ele.receptorQualityPollutantValue}</material.TableCell>
                                                    <material.TableCell>{ele.receptorQualityUnit}</material.TableCell>
                                                    <material.TableCell align='center'><material.DeleteIcon color='warning' onClick={() => handleDeleteRow(i)} /></material.TableCell>
                                                </material.TableRow>
                                            ))}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Card>
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openWaterDischarge.waterDischargeAllData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateWaterDischarge)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openWaterDischarge.waterDischargeAllData.rejectionNote === null) || (openWaterDischarge.waterDischargeAllData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveWaterDischarge)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddWaterDischargeQualityReceptor
                openAddWaterDischargeQualityReceptor={openAddWaterDischargeQualityReceptor}
                setOpenAddWaterDischargeQualityReceptor={setOpenAddWaterDischargeQualityReceptor}
                waterDischargeQualityReceptorAllData={waterDischargeQualityReceptorAllData}
                dischargePointValue={dischargePointValue}
                dischargeDestinationValue={dischargeDestinationValue}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddWaterDischarge;
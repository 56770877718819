import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const getSocialScreeningData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/social-screening/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const saveOrUpdateSocialScreeningData = async (SocialScreeningData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/social-screening/save-or-update`, SocialScreeningData)
};

export const sendForApprovalSocialScreeningData = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/social-screening/send-for-approval`, recordIds)
};

export const approveSocialScreeningData = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/social-screening/approve`, payloadData)
};










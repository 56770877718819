import axios from "axios";

let navigateCallback;

export const setNavigateCallback = (callback) => {
    navigateCallback = callback;
};


export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            setTimeout(() => {
                navigateCallback("/login")
            }, 3000)
        }
        return Promise.reject(error);
    }
);
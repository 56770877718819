import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateEmployeeAndWorker } from '../../services/sub-issue-services/EmployeeAndWorkerService';
import { checkCriteriaForYesTypeAnswer } from '../../shared/Shared';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddEmployeeWorkers(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, setValue, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const { openAddEmployeeWorkers, setOpenAddEmployeeWorkers, getEmployeeAndWorkerAllData, treeViewData } = props;
    const [categoryOfWorker, setCategoryOfWorker] = useState("");
    const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("No");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const onChangeMale = watch('male');
    const onChangeFemale = watch('female');
    const onChangeOther = watch('other');

    useEffect(() => {
        totalCalculation();
    }, [onChangeMale, onChangeFemale, onChangeOther]);

    const totalCalculation = () => {
        let val1 = getValues('male');
        let val2 = getValues('female');
        let val3 = getValues('other');
        if (val1 && val2 && val3) {
            let totalValue = Number(val1) + Number(val2) + Number(val3);
            setValue("total", totalValue);
        };
    };


    useEffect(() => {
        if (openAddEmployeeWorkers.action && openAddEmployeeWorkers.employeeWorkersData) {
            reset(openAddEmployeeWorkers.employeeWorkersData);
            setCategoryOfWorker(openAddEmployeeWorkers.employeeWorkersData ? openAddEmployeeWorkers.employeeWorkersData.categoryOfWorker : "");
            setIsEmployeeAgeBasedDataPresent(openAddEmployeeWorkers.employeeWorkersData.isAgeBasedDataPresent ? "Yes" : "No")
        }
    }, [openAddEmployeeWorkers.action === true]);

    const selectCategoryOfWorker = (event) => {
        setCategoryOfWorker(event.target.value)
    };

    const saveEmployeeWorkers = async (formData) => {
        let payLoad = generateFromDateAndToDate(formData);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "refNo": openAddEmployeeWorkers.employeeWorkersData ? openAddEmployeeWorkers.employeeWorkersData.refNo : "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "categoryOfWorker": formData.categoryOfWorker,
                "male": Number(formData.male),
                "female": Number(formData.female),
                "other": Number(formData.other),
                "total": Number(formData.total),
                "maleUnder30": Number(formData.maleUnder30),
                "male30To50": Number(formData.male30To50),
                "maleAbove50": Number(formData.maleAbove50),
                "femaleUnder30": Number(formData.femaleUnder30),
                "female30To50": Number(formData.female30To50),
                "femaleAbove50": Number(formData.femaleAbove50),
                "otherUnder30": Number(formData.otherUnder30),
                "other30To50": Number(formData.other30To50),
                "otherAbove50": Number(formData.otherAbove50),
                "vulnerable": Number(formData.vulnerable),
                "isAgeBasedDataPresent": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
                toDate: payLoad?.toDate ? payLoad.toDate : formData.toDate,
                fromDate: payLoad?.fromDate ? payLoad.fromDate : formData.fromDate,
            };
            if (isEmployeeAgeBasedDataPresent === "Yes") {
                if (checkCriteriaForYesTypeAnswer(formData)) {
                    if (Number(formData.total) >= Number(formData.vulnerable)) {
                        await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                            .then((resp) => {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "success",
                                    "message": resp.data,
                                })
                                getEmployeeAndWorkerAllData()
                                setCategoryOfWorker("")
                                reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "" })
                                setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
                            })
                            .catch((error) => {
                                if (error.response.status === 401) {
                                    setOpenSnackBar({
                                        "action": true,
                                        "type": "error",
                                        "message": "You are not authorized to perform this action. Redirected to login",
                                    })
                                } else {
                                    setOpenSnackBar({
                                        "action": true,
                                        "type": "error",
                                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                                    })
                                };
                            })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                        })
                    }
                }
                else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Sum of age based count not matching with total count",
                    })
                };
            } else if (isEmployeeAgeBasedDataPresent === "No") {
                if (Number(formData.total) >= Number(formData.vulnerable)) {
                    await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                        .then((resp) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "success",
                                "message": resp.data,
                            })
                            getEmployeeAndWorkerAllData()
                            setCategoryOfWorker("")
                            reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
                            setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": "You are not authorized to perform this action. Redirected to login",
                                })
                            } else {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                                })
                            };
                        })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            };
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEmployeeWorkers = async (formData) => {
        let payLoad = generateFromDateAndToDate(formData);
        let obj = {
            "refNo": openAddEmployeeWorkers.employeeWorkersData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "categoryOfWorker": formData.categoryOfWorker,
            "male": Number(formData.male),
            "female": Number(formData.female),
            "other": Number(formData.other),
            "total": Number(formData.total),
            "maleUnder30": Number(formData.maleUnder30),
            "male30To50": Number(formData.male30To50),
            "maleAbove50": Number(formData.maleAbove50),
            "femaleUnder30": Number(formData.femaleUnder30),
            "female30To50": Number(formData.female30To50),
            "femaleAbove50": Number(formData.femaleAbove50),
            "otherUnder30": Number(formData.otherUnder30),
            "other30To50": Number(formData.other30To50),
            "otherAbove50": Number(formData.otherAbove50),
            "vulnerable": Number(formData.vulnerable),
            "isAgeBasedDataPresent": isEmployeeAgeBasedDataPresent === "Yes" ? true : false,
            toDate: payLoad?.toDate ? payLoad.toDate : formData.toDate,
            fromDate: payLoad?.fromDate ? payLoad.fromDate : formData.fromDate,
        };
        if (isEmployeeAgeBasedDataPresent === "Yes") {
            if (checkCriteriaForYesTypeAnswer(formData)) {
                if (Number(formData.total) >= Number(formData.vulnerable)) {
                    await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                        .then((resp) => {
                            setOpenSnackBar({
                                "action": true,
                                "type": "success",
                                "message": resp.data,
                            })
                            getEmployeeAndWorkerAllData()
                            setCategoryOfWorker("")
                            reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "" })
                            setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": "You are not authorized to perform this action. Redirected to login",
                                })
                            } else {
                                setOpenSnackBar({
                                    "action": true,
                                    "type": "error",
                                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                                })
                            };
                        })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                    })
                }
            }
            else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Sum of age based count not matching with total count",
                })
            };
        } else if (isEmployeeAgeBasedDataPresent === "No") {
            if (Number(formData.total) >= Number(formData.vulnerable)) {
                await saveOrUpdateEmployeeAndWorker(obj, "EMPLOYEE_AND_WORKER")
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data,
                        })
                        getEmployeeAndWorkerAllData()
                        setCategoryOfWorker("")
                        reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
                        setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": "You are not authorized to perform this action. Redirected to login",
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                            })
                        };
                    })
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "The total number of Vulnerable count cannot be greater than the total number of Total Derived count",
                })
            }
        }
    };

    const handleClickClose = () => {
        setCategoryOfWorker("")
        reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
        setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
    };

    const handleChangeEmployeeAndWorkerData = (event) => {
        setIsEmployeeAgeBasedDataPresent(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddEmployeeWorkers.employeeWorkersData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getEmployeeAndWorkerAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setCategoryOfWorker("")
                reset({ male: "", female: "", other: "", total: "", maleUnder30: "", male30To50: "", maleAbove50: "", femaleUnder30: "", female30To50: "", femaleAbove50: "", vulnerable: "", otherUnder30: "", other30To50: "", otherAbove50: "" });
                setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddEmployeeWorkers.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddEmployeeWorkers.employeeWorkersData ? "Edit Employee & Worker" : "Add Employee & Worker"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='d-flex flex-row align-items-center'>
                        <span className='fw-bold me-2'>
                            Is employee age based data present ? :
                        </span>
                        <material.FormControl>
                            <material.RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={isEmployeeAgeBasedDataPresent} name="row-radio-buttons-group" onChange={handleChangeEmployeeAndWorkerData} >
                                <material.FormControlLabel value="Yes" label="Yes" control={<material.Radio color='secondary' />} />
                                <material.FormControlLabel value="No" label="No" control={<material.Radio color='secondary' />} />
                            </material.RadioGroup>
                        </material.FormControl>
                    </div>
                    <div className='my-3'>
                        {openAddEmployeeWorkers.employeeWorkersData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row mt-2'>
                            <div className='py-2'>
                                <material.Typography className='fw-bold headingText'>Employee & Worker data</material.Typography>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of Employee & Worker</material.InputLabel>
                                    <material.Select
                                        {...register("categoryOfWorker", { required: true, })}
                                        margin="dense"
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfWorker}
                                        onChange={selectCategoryOfWorker}
                                        label="Category of worker"
                                    >
                                        <material.MenuItem value={"Employee Permanent"}>Employee Permanent</material.MenuItem>
                                        <material.MenuItem value={"Employee other than Permanent"}>Employee other than Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker Permanent"}>Worker Permanent</material.MenuItem>
                                        <material.MenuItem value={"Worker other than Permanent"}>Worker other than Permanent</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("male", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Male"
                                    id="male"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.male && errors.male.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("female", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Female"
                                    id="female"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.female && errors.female.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("other", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Other"
                                    id="other"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.other && errors.other.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("total", { required: true, })}
                                    required
                                    margin="dense"
                                    label="Total"
                                    id="total"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("vulnerable", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Vulnerable"
                                    id="vulnerable"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                />
                                {errors.vulnerable && errors.vulnerable.type === "pattern" && (
                                    <p className="errorMsg text-danger">No decimal allowed</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                    {isEmployeeAgeBasedDataPresent === "Yes" ? (
                        <material.Card elevation={1} sx={{ p: 2, border: 1, mt: 2 }}>
                            <div className='row mt-2'>
                                <div className='py-2'>
                                    <material.Typography className='fw-bold headingText'>Employee & Workers age based data</material.Typography>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("maleUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Male Under 30"
                                        id="maleUnder30"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.maleUnder30 && errors.maleUnder30.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("male30To50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Male 30 to 50"
                                        id="male30To50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.male30To50 && errors.male30To50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("maleAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Male Above 50"
                                        id="maleAbove50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.maleAbove50 && errors.maleAbove50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("femaleUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Female Under 30"
                                        id="femaleUnder30"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.femaleUnder30 && errors.femaleUnder30.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("female30To50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Female 30 to 50"
                                        id="female30To50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.female30To50 && errors.female30To50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("femaleAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Female Above 50"
                                        id="femaleAbove50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.femaleAbove50 && errors.femaleAbove50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("otherUnder30", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Other Under 30"
                                        id="femaleUnder30"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.other30 && errors.other30.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("other30To50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Other 30 to 50"
                                        id="other30To50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.other30To50 && errors.other30To50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <material.TextField
                                        {...register("otherAbove50", { required: true, pattern: /^[0-9]+$/ })}
                                        required
                                        margin="dense"
                                        label="Other Above 50"
                                        id="otherAbove50"
                                        variant="standard"
                                        type="number"
                                        size="small"
                                        fullWidth
                                    />
                                    {errors.otherAbove50 && errors.otherAbove50.type === "pattern" && (
                                        <p className="errorMsg text-danger">No decimal allowed</p>
                                    )}
                                </div>
                            </div>
                        </material.Card>
                    ) : null}
                </material.DialogContent>
                <material.DialogActions className="m-3">
                    <material.Button onClick={handleClickClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddEmployeeWorkers.employeeWorkersData ? (
                        <>
                            <material.Button color="success" variant="outlined" onClick={handleSubmit(updateEmployeeWorkers)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddEmployeeWorkers.employeeWorkersData.rejectionNote === null) || (openAddEmployeeWorkers.employeeWorkersData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button color="success" variant="outlined" onClick={handleSubmit(saveEmployeeWorkers)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEmployeeWorkers
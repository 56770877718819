import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useLocation } from 'react-router-dom';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { createMultipleGeneralDisclosures, generalDisclosuresStatusChange, getDisclosuresQuestion, getGeneralDisclosuresByQuestion, sendDisclosuresForApprove } from '../../services/GeneralDisclosureService';
import { useSelector } from 'react-redux';
import GeneralDisclosureHistory from './GeneralDisclosureHistory';
import Snackbar from '../../toaster-message/Snackbar';
import RejectionNote from '../../dialogs/RejectionNote';
import DynamicQuestionForm from '../dynamic-question-form/DynamicQuestionForm';

var usersRole;

function GeneralDisclosure(props) {

    const [value, setValue] = React.useState('General Disclosure');
    const location = useLocation();
    const treeViewData = location.state;
    useEffect(() => {
        setValue("General Disclosure")
    }, [treeViewData.issue]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                <span><material.Typography variant="h5" className='headingText'>{treeViewData.issue}</material.Typography></span>
                <div className='mt-3'>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={value}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <material.Tab sx={{ textTransform: "none" }} label="General Disclosure" value="General Disclosure" />
                                    <material.Tab sx={{ textTransform: "none" }} label="General Disclosure History" value="General Disclosure History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="General Disclosure"><GeneralDisclosureTable treeViewData={treeViewData} /></material.TabPanel>
                            <material.TabPanel value="General Disclosure History"><GeneralDisclosureHistory treeViewData={treeViewData} /></material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </div>
            </material.Box>
        </material.Box>
    );
}

const GeneralDisclosureTable = (props) => {

    const { treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openAddDisclosure, setOpenAddDisclosure] = useState(false);
    const [openEditDisclosure, setOpenEditDisclosure] = useState({ action: false, data: null });
    const [generalDisclosureData, setGeneralDisclosureData] = useState([]);
    const [allowNewDisclosure, setAllowNewDisclosure] = useState(true);
    const [questionType, setQuestionsType] = useState("");
    const [hideButton, setHideButton] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [generalDisclosureQuestions, setGeneralDisclosureQuestions] = useState([]);
    const [generalDisclosureAns, setGeneralDisclosureAns] = useState([]);
    const [allQuestionsAnswers, setAllQuestionsAnswers] = useState(null);

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getGeneralDisclosures();
    }, [treeViewData.issue]);

    const getGeneralDisclosures = () => {
        setGeneralDisclosureQuestions([]);
        setAllQuestionsAnswers(null);
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData.pillar,
            "issue": treeViewData.issue,
            "typeOfDisclosure": "GENERAL_DISCLOSURE",
            sendForApproval: usersRole === "ROLE_FACILITY_APPROVER" ? true : false,
            isApprover: usersRole === "ROLE_FACILITY_APPROVER" ? true : false,
        }
        Promise.all([getDisclosuresQuestion(treeViewData.issue, "GENERAL_DISCLOSURE"), getGeneralDisclosuresByQuestion(obj)]).then((responses) => {

            //old code

            // Handle the responses for each API request
            // const allQuestions = responses[0].data;
            // let questionStructure = convertToNestedStructure(allQuestions);
            // setQuestions(questionStructure);
            // const allAnswers = responses[1].data;
            // if (allAnswers.length > 0) {
            //     setGeneralDisclosureAns(allAnswers)
            //     allAnswers[0].answers?.forEach(element => {
            //         if (element.approvalStatus === "APPROVED") {
            //             setAllowNewDisclosure(true);
            //         } else {
            //             setAllowNewDisclosure(false);
            //         }
            //         setHideButton(element.sentForApproval)
            //     });
            // } else {
            //     setGeneralDisclosureAns(allAnswers)
            //     setAllowNewDisclosure(true);
            // };

            // // Create a data structure to associate questions with answers using question ID
            // Create a map to store answers based on question IDs
            // const answersMap = new Map();
            // // Populate the answers map
            // allAnswers.forEach(answer => {
            //     const questionId = answer.questionId;
            //     answersMap.set(questionId, answer);
            // });
            // // Create a new array by adding the 'answer' property to each question
            // const questionsWithAnswers = allQuestions.map(question => {
            //     const questionId = question.questionid;
            //     const answer = answersMap.get(questionId);
            //     // Add an 'answer' property to the question object
            //     question.answer = answer ? answer : null;
            //     return question;
            // });
            // const questionnaireStructure = [];
            // questionsWithAnswers.forEach((question, index) => {
            //     const { questionid, parent_question_no } = question;
            //     const isChild = parent_question_no !== null;
            //     if (!isChild) {
            //         const childQuestions = questionsWithAnswers.filter((child) => child.parent_question_no === questionid);
            //         questionnaireStructure.push({
            //             ...question,
            //             child_questions: childQuestions.length > 0 ? childQuestions : null,
            //         });
            //         console.log(questionsWithAnswers)
            //     }
            // });

            //new code

            const allQuestions = responses[0].data;
            let questionStructure = convertToNestedStructure(allQuestions);
            setQuestions(questionStructure);
            const allAnswers = responses[1].data;
            if (allAnswers.length > 0) {
                setGeneralDisclosureAns(allAnswers)
                allAnswers[0].answers?.forEach(element => {
                    if (element.approvalStatus === "APPROVED") {
                        setAllowNewDisclosure(true);
                    } else {
                        setAllowNewDisclosure(false);
                    }
                    setHideButton(element.sentForApproval)
                });
            } else {
                setGeneralDisclosureAns(allAnswers)
                setAllowNewDisclosure(true);
            };

            // Create a data structure to associate questions with answers using question ID
            const answersMap = new Map();
            // Populate the answers map
            allAnswers.forEach(answer => {
                const questionId = answer.questionId;
                answersMap.set(questionId, answer);
            });

            // Add 'answer' property to each question
            const questionsWithAnswers = allQuestions.map(question => {
                const questionId = question.questionid;
                const answer = answersMap.get(questionId);
                question.answer = answer ? answer : null;
                return question;
            });

            // Recursive function to build the nested structure
            const buildNestedStructure = (questions, parentQuestionId = null) => {
                return questions
                    .filter(question => question.parent_question_no === parentQuestionId)
                    .map(question => ({
                        ...question,
                        child_questions: buildNestedStructure(questions, question.questionid)
                    }));
            };

            const questionnaireStructure = buildNestedStructure(questionsWithAnswers);

            setGeneralDisclosureQuestions(questionnaireStructure)
            setAllQuestionsAnswers(answersMap)
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const convertToNestedStructure = (data) => {
        const nestedStructure = [];
        const parentMap = {};
        data.forEach((item) => {
            const newItem = { ...item, child_questions: [] };
            if (item.parent_question_no === null) {
                nestedStructure.push(newItem);
            } else {
                const parentItem = parentMap[item.parent_question_no];
                if (parentItem) {
                    parentItem.child_questions.push(newItem);
                }
            }
            parentMap[item.questionid] = newItem;
        });
        return nestedStructure;
    };

    const approve = async (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData.pillar,
            "issue": treeViewData.issue,
            "typeOfDisclosure": "GENERAL_DISCLOSURE",
            status: "APPROVED",
        }
        await generalDisclosuresStatusChange(obj)
            .then((res) => {
                getGeneralDisclosures()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };
    const reject = async () => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    };
    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData.pillar,
            "issue": treeViewData.issue,
            "typeOfDisclosure": "GENERAL_DISCLOSURE",
            status: "REJECTED",
            rejectionNote: noteData.rejectionNote
        }
        await generalDisclosuresStatusChange(obj)
            .then((res) => {
                getGeneralDisclosures()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };
    const sendApproval = async () => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "pillar": treeViewData.pillar,
            "issue": treeViewData.issue,
            "typeOfDisclosure": "GENERAL_DISCLOSURE"
        }
        if (hideButton === false || hideButton === null) {
            await sendDisclosuresForApprove(obj)
                .then((resp) => {
                    getGeneralDisclosures()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                })
        }
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const save = async (data) => {
        let questionAnsList = Object.entries(data).map((ele) => {
            let answerData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                pillar: treeViewData.pillar,
                issue: treeViewData.issue,
                questionId: ele[0],
                "typeOfDisclosure": "GENERAL_DISCLOSURE",
                answer: ele[1],
                refNo: allQuestionsAnswers.get(ele[0])?.answers[0]?.refNo
            };
            return answerData;
        })
        await createMultipleGeneralDisclosures(questionAnsList)
            .then((resp) => {
                getGeneralDisclosures()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const cancel = () => {

    }

    return (
        <material.Card className='px-2'>
            <div className='row'>
                <div hidden={usersRole === "ROLE_FACILITY_EDITOR"} className='col-12'>
                    <span className='float-end my-2' hidden={usersRole === "ROLE_FACILITY_APPROVER"}>
                        <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                        <material.Button variant="contained" color='success' hidden={generalDisclosureAns.length == 0 || generalDisclosureAns[0]?.answers[0]?.rejectionNote == "" || generalDisclosureAns[0]?.answers[0]?.rejectionNote == null} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.VisibilityIcon />} onClick={() => viewRejectionNote(generalDisclosureAns[0]?.answers[0])}>View Rejection Note</material.Button>
                        {/* <material.Button disabled={!allowNewDisclosure} sx={{ textTransform: "none" }} variant="contained" color="success" onClick={addSpecificDisclosure} startIcon={<material.AddIcon />}>Add Specific Disclosure</material.Button> */}
                    </span>
                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? null : (
                        <span className='float-end my-2' hidden={allowNewDisclosure}>
                            <material.Button sx={{ textTransform: "none", mr: 1 }} variant="contained" color='success' startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                            <material.Button sx={{ textTransform: "none" }} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                        </span>
                    )}
                </div>
                <div className='col-12'>
                    {usersRole === "ROLE_FACILITY_ESG_DATA" || usersRole === "ROLE_FACILITY_EDITOR" ? (
                        generalDisclosureAns.length > 0 ?
                            <DynamicQuestionForm questions={generalDisclosureQuestions} save={save} cancel={cancel} /> :
                            <DynamicQuestionForm questions={questions} save={save} cancel={cancel} />)
                        : null
                    }
                    {usersRole === "ROLE_FACILITY_APPROVER" ? (
                        generalDisclosureAns.length > 0 ?
                            <DynamicQuestionForm questions={generalDisclosureQuestions} save={save} cancel={cancel} /> :
                            <div className='d-flex align-items-center justify-content-center text-danger fw-bold py-5 fs-4'>
                                No data found
                            </div>
                    )
                        : null
                    }
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <RejectionNote
                    openRejectionNoteDialog={openRejectionNoteDialog}
                    setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                    getRejectionNoteData={getRejectionNoteData}
                />
            </div>
        </material.Card>

    )
}

export default GeneralDisclosure;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateEHSApprovalStatusData } from '../../services/sub-issue-services/environmental-compliance-service/EHSApprovalStatus';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


const envClearancePermitsSelect = ["Environmental Clearance (EC)", "Consent to Establish (CTE)", "Consent to Operate (CTO)", "Hazardous Waste Authorization", "Biomedical Waste Authorization", "Factory License", "Fire NOC", "Shops and Establishment License", "PESO License", "Other (Specify)"];

function AddEHSApprovalStatus(props) {
    const { addOpenEHSApprovalStatus, setAddOpenEHSApprovalStatus, getAllEHSApprovalStatusData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [envClearancePermits, setEnvClearancePermits] = useState("");
    const [applicability, setApplicability] = useState("");
    const [complied, setComplied] = useState("");
    const [conductedByExternalAgency, setConductedByExternalAgency] = useState("");
    const [resultsDisclosedInPublicDomain, setResultsDisclosedInPublicDomain] = useState("");

    const [clearancePermitDate, setClearancePermitDate] = useState(dayjs(new Date()));
    const [validUpTo, setValidUpTo] = useState(dayjs(new Date()));
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (addOpenEHSApprovalStatus.action && addOpenEHSApprovalStatus.ehsApprovalStatusTableData) {
            setEnvClearancePermits(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.envClearancePermits);
            setApplicability(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.applicability);
            setValidUpTo(dayjs(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.validUpTo));
            setClearancePermitDate(dayjs(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.clearancePermitDate));
            setComplied(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.complied);
            setConductedByExternalAgency(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.conductedByExternalAgency);
            setResultsDisclosedInPublicDomain(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.resultsDisclosedInPublicDomain);
            reset(addOpenEHSApprovalStatus.ehsApprovalStatusTableData);
        } else {
            setClearancePermitDate(dayjs(new Date()));
            setValidUpTo(dayjs(new Date()));
        }
    }, [addOpenEHSApprovalStatus.action === true]);

    const saveEHSApprovalStatus = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "envClearancePermits": data.envClearancePermits,
                "applicability": data.applicability,
                "refOfClearancePermit": data.refOfClearancePermit,
                "clearancePermitDate": moment(clearancePermitDate.$d).format("YYYY-MM-DD"),
                "validUpTo": moment(validUpTo.$d).format("YYYY-MM-DD"),
                "complied": data.complied,
                otherSpecify: envClearancePermits === "Other(Specify)" ? data.otherSpecify : "",
                relevantWebLink: data.relevantWebLink,
                resultsDisclosedInPublicDomain: data.resultsDisclosedInPublicDomain,
                conductedByExternalAgency: data.conductedByExternalAgency,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateEHSApprovalStatusData(obj)
                .then((resp) => {
                    getAllEHSApprovalStatusData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ refOfClearancePermit: "", relevantWebLink: "" });
                    setEnvClearancePermits("");
                    setApplicability("");
                    setValidUpTo("");
                    setClearancePermitDate("");
                    setComplied("");
                    setConductedByExternalAgency("")
                    setResultsDisclosedInPublicDomain("")
                    setAddOpenEHSApprovalStatus({ action: false, ehsApprovalStatusTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateEHSApprovalStatus = async (data) => {
        let obj = {
            "refNo": addOpenEHSApprovalStatus.ehsApprovalStatusTableData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "envClearancePermits": data.envClearancePermits,
            "applicability": data.applicability,
            "refOfClearancePermit": data.refOfClearancePermit,
            "clearancePermitDate": moment(clearancePermitDate.$d).format("YYYY-MM-DD"),
            "validUpTo": moment(validUpTo.$d).format("YYYY-MM-DD"),
            "complied": data.complied,
            otherSpecify: envClearancePermits === "Other(Specify)" ? data.otherSpecify : "",
            relevantWebLink: data.relevantWebLink,
            resultsDisclosedInPublicDomain: data.resultsDisclosedInPublicDomain,
            conductedByExternalAgency: data.conductedByExternalAgency,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateEHSApprovalStatusData(obj)
            .then((resp) => {
                getAllEHSApprovalStatusData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ refOfClearancePermit: "", relevantWebLink: "" });
                setEnvClearancePermits("");
                setApplicability("");
                setValidUpTo("");
                setClearancePermitDate("");
                setComplied("");
                setConductedByExternalAgency("");
                setResultsDisclosedInPublicDomain("")
                setAddOpenEHSApprovalStatus({ action: false, ehsApprovalStatusTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ refOfClearancePermit: "", relevantWebLink: "" });
        setEnvClearancePermits("");
        setApplicability("");
        setValidUpTo("");
        setClearancePermitDate("");
        setComplied("");
        setConductedByExternalAgency("");
        setResultsDisclosedInPublicDomain("");
        setAddOpenEHSApprovalStatus({ action: false, ehsApprovalStatusTableData: null });
    };

    const selectEnvClearancePermits = (event) => {
        setEnvClearancePermits(event.target.value);
    };

    const selectApplicability = (event) => {
        setApplicability(event.target.value);
    };

    const selectComplied = (event) => {
        setComplied(event.target.value);
    };

    const changeConductedByExternalAgency = (event) => {
        setConductedByExternalAgency(event.target.value);
    };
    const changeResultsDisclosedInPublicDomain = (event) => {
        setResultsDisclosedInPublicDomain(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenEHSApprovalStatus.ehsApprovalStatusTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllEHSApprovalStatusData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ refOfClearancePermit: "", relevantWebLink: "" });
                setEnvClearancePermits("");
                setApplicability("");
                setValidUpTo("");
                setClearancePermitDate("");
                setComplied("");
                setConductedByExternalAgency("");
                setResultsDisclosedInPublicDomain("")
                setAddOpenEHSApprovalStatus({ action: false, ehsApprovalStatusTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenEHSApprovalStatus.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{addOpenEHSApprovalStatus.ehsApprovalStatusTableData ? "Edit EHS Approval Status" : "Add EHS Approval Status"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenEHSApprovalStatus.ehsApprovalStatusTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>EHS Approval Status</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Env. Clearance & Permits</material.InputLabel>
                                    <material.Select
                                        {...register("envClearancePermits", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={envClearancePermits}
                                        onChange={selectEnvClearancePermits}
                                        fullWidth
                                        label="Material"
                                    >
                                        {envClearancePermitsSelect?.map((ele, ind) => (
                                            <material.MenuItem key={ind} value={ele}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {envClearancePermits === "Other (Specify)" ? (
                                <div className='col-lg-4 py-2'>
                                    <material.TextField
                                        {...register("otherSpecify", { required: true, maxLength: 20 })}
                                        required
                                        label="Other Env. Clearance & Permits Specify"
                                        id="userName"
                                        sx={{ marginTop: "10px" }}
                                        variant="standard"
                                        type='text'
                                        size="small"
                                        fullWidth
                                    />
                                    {errors?.otherSpecify?.type === "maxLength" && (
                                        <p className='text-danger'>Maximum length 20 character</p>
                                    )}
                                </div>
                            ) : null}
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Applicability</material.InputLabel>
                                    <material.Select
                                        {...register("applicability", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={applicability}
                                        onChange={selectApplicability}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("refOfClearancePermit", { required: true })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Ref .of Clearance & Permit"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.refOfClearancePermit && errors.refOfClearancePermit.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        sx={{ width: "100%" }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        label="Clearance & Permit Date"
                                        format='DD-MM-YYYY'
                                        value={clearancePermitDate}
                                        onChange={(newValue) => {
                                            setClearancePermitDate(newValue)
                                        }}
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        sx={{ width: "100%" }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        label="Valid up to"
                                        format='DD-MM-YYYY'
                                        value={validUpTo}
                                        onChange={(newValue) => {
                                            setValidUpTo(newValue)
                                        }}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Complied</material.InputLabel>
                                    <material.Select
                                        {...register("complied", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={complied}
                                        onChange={selectComplied}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>

                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Conducted by External Agency</material.InputLabel>
                                    <material.Select
                                        {...register("conductedByExternalAgency", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={conductedByExternalAgency}
                                        onChange={changeConductedByExternalAgency}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Results Disclosed in Public Domain</material.InputLabel>
                                    <material.Select
                                        {...register("resultsDisclosedInPublicDomain", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={resultsDisclosedInPublicDomain}
                                        onChange={changeResultsDisclosedInPublicDomain}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Yes"}>Yes</material.MenuItem>
                                        <material.MenuItem value={"No"}>No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("relevantWebLink", { required: true })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Relevant Web Link"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenEHSApprovalStatus.ehsApprovalStatusTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateEHSApprovalStatus)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenEHSApprovalStatus.ehsApprovalStatusTableData.rejectionNote === null) || (addOpenEHSApprovalStatus.ehsApprovalStatusTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveEHSApprovalStatus)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddEHSApprovalStatus;
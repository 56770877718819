import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AddWasteGeneration from '../../../sub-issues-dialogs/add-waste/AddWasteGeneration';
import { approveWasteGenerationDataRecord, getAllWasteGenerationData, sendForApprovalWasteGenerationDataRecord } from '../../../services/sub-issue-services/WasteGenerationService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';



const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var usersRole;
var recordRefNos = [];

function WasteGeneration(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const [wasteGeneration, setWasteGeneration] = useState('Waste Generation');
    const [allWasteGenerationData, setAllWasteGenerationData] = useState([]);
    const [openAddWasteGeneration, setOpenAddWasteGeneration] = useState({ action: false, wasteGenerationData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [dataSets, setDataSets] = useState(null);
    const [pieChartDataSets, setPieChartDataSets] = useState(null);

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        getWasteGenerationData()
    }, [wasteGeneration]);

    const getFormDateToDate = (data) => {
        getWasteGenerationData(data)
    };

    const prepareDataForColumnChart = (value, chartType) => {
        let wasteCategory = {}
        if (chartType === "column-chart") {
            for (const x of value) {
                wasteCategory[x.wasteCategory] = parseFloat(wasteCategory[x.wasteCategory] == undefined ? 0 : wasteCategory[x.wasteCategory]) + parseFloat(x.amount)
            };
            return wasteCategory;
        } else {
            for (const x of value) {
                wasteCategory[x.type] = parseFloat(wasteCategory[x.type] == undefined ? 0 : wasteCategory[x.type]) + parseFloat(x.amount)
            };
            let sum = (Object.values(wasteCategory).reduce((a, b) => { return a + b }))
            let obj2 = {}
            Object.keys(wasteCategory).forEach(x => {
                obj2[x] = parseFloat((wasteCategory[x] / sum) * 100).toFixed(2)
            })
            return obj2
        }
    };

    const getWasteGenerationData = async (filterDate) => {
        if (wasteGeneration === "Waste Generation") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllWasteGenerationData(object)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dtoList)
                    setAllWasteGenerationData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (wasteGeneration === "Waste Generation History") {
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllWasteGenerationData(object)
                .then((resp) => {
                    console.log(prepareDataForColumnChart(resp.data.dtoList.filter((ele) => ele.type === "Non Hazardous Waste"), "column-chart"))
                    setAllWasteGenerationData(resp.data.dtoList)
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (wasteGeneration === "Graph") {
            let forApproval = true;
            let approveHistory = true;
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllWasteGenerationData(object)
                .then(async (resp) => {
                    console.log(prepareDataForColumnChart(resp.data.dtoList.filter((ele) => ele.type === "Non Hazardous Waste"), "column-chart"))
                    // let data = {
                    //     labels: ['Construction and demolition waste', 'Municipal solid waste', 'Packaging waste (other than plastic)', 'Plastic waste', 'Other Non-hazardous waste', 'Oily substances', 'Non-oily', 'Other Hazardous waste', 'Battery waste', 'Radioactive waste', 'Bio-medical waste', 'E-waste'],
                    //     datasets: [
                    //         {
                    //             label: 'Hazardous Waste',
                    //             data: prepareDataForColumnChart(resp.data.dtoList.filter((ele) => ele.type === "Hazardous Waste"), "column-chart"),
                    //             backgroundColor: 'Orange',
                    //         },
                    //         {
                    //             label: 'Non Hazardous Waste',
                    //             data: prepareDataForColumnChart(resp.data.dtoList.filter((ele) => ele.type === "Non Hazardous Waste"), "column-chart"),
                    //             backgroundColor: 'blue',
                    //         },
                    //         {
                    //             label: 'Other Waste',
                    //             data: prepareDataForColumnChart(resp.data.dtoList.filter((ele) => ele.type === "Other Waste"), "column-chart"),
                    //             backgroundColor: 'green',
                    //         },
                    //     ],
                    // };
                    // let values = prepareDataForColumnChart(resp.data.dtoList)
                    // let randomColorArray = []
                    // Object.keys(values).forEach(x => {
                    //     randomColorArray.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                    // })
                    // let pieChartData = {
                    //     labels: Object.keys(values),
                    //     datasets: [{
                    //         data: Object.values(values),
                    //         backgroundColor: randomColorArray
                    //     }]
                    // };
                    // setDataSets(data);
                    // setPieChartDataSets(pieChartData);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const addWasteGeneration = () => {
        setOpenAddWasteGeneration({ action: true, wasteGenerationData: null });
    };

    const editWasteGeneration = (rowData) => {
        setOpenAddWasteGeneration({ action: true, wasteGenerationData: rowData });
    };

    const clicWasteGenerationCheckBox = (value) => {
        setOpenAddWasteGeneration({ action: false, wasteGenerationData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value);
        }
    };

    const sendApprovalWasteGeneration = async () => {
        if (recordRefNos.length) {
            await sendForApprovalWasteGenerationDataRecord(recordRefNos)
                .then((resp) => {
                    getWasteGenerationData();
                    recordRefNos = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const approveWasteGeneration = async () => {
        // if (recordIds.length) {
        //     await approveWasteGenerationDataRecord("APPROVED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordIds)
        //         .then((resp) => {
        //             getWasteGenerationData();
        //             recordIds = [];
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "success",
        //                 "message": resp.data,
        //             })
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
        //             })
        //         })
        // }
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveWasteGenerationDataRecord(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getWasteGenerationData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const rejectWasteGeneration = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordRefNos.length) {
            //     await approveWasteGenerationDataRecord("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
            //         .then((resp) => {
            //             getWasteGenerationData();
            //             recordRefNos = [];
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        }
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveWasteGenerationDataRecord(obj)
            .then((resp) => {
                recordRefNos = [];
                getWasteGenerationData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const wasteGenerationTab = (event, newValue) => {
        setWasteGeneration(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className='float-end'>
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable}
                                            color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={wasteGeneration}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={wasteGenerationTab} aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Waste Generation" value="Waste Generation" />
                                        <material.Tab className='text-capitalize' label="Waste Generation History" value="Waste Generation History" />
                                        {/* <material.Tab className='text-capitalize' label="Graph" value="Graph" /> */}
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Waste Generation">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-7">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-5 my-2" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                                <>
                                                    {allWasteGenerationData?.length ? (
                                                        <span className='float-end'>
                                                            <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} disabled={!applicable || !recordRefNos.length} startIcon={<material.DoneIcon />} onClick={approveWasteGeneration} >Approve</material.Button>
                                                            <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} disabled={!applicable || !recordRefNos.length} onClick={rejectWasteGeneration} startIcon={<material.PriorityHighIcon />}>Reject</material.Button>
                                                        </span>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <span className='float-end'>
                                                    <material.Button variant="contained" color='success' sx={{ textTransform: "none", mr: 2 }} startIcon={<material.SendIcon />} disabled={!applicable} onClick={sendApprovalWasteGeneration}>Send for Approval</material.Button>
                                                    <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} disabled={!applicable} startIcon={<material.AddIcon />} onClick={addWasteGeneration} >Add Waste Generation</material.Button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Waste Category</StyledTableCell>
                                                                <StyledTableCell>Others Specify</StyledTableCell>
                                                                <StyledTableCell>Waste Type</StyledTableCell>
                                                                <StyledTableCell>Activity</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {allWasteGenerationData?.length ? allWasteGenerationData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ? () => editWasteGeneration(item) : null}>
                                                                    <material.TableCell>{item.wasteCategory}</material.TableCell>
                                                                    <material.TableCell>{(item.otherSpecify === "" || item.otherSpecify === null) ? "N/A" : item.otherSpecify}</material.TableCell>
                                                                    <material.TableCell>{item.type}</material.TableCell>
                                                                    <material.TableCell>{item.activity}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clicWasteGenerationCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clicWasteGenerationCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={11}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Waste Generation History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Waste Category</StyledTableCell>
                                                                <StyledTableCell>Others Specify</StyledTableCell>
                                                                <StyledTableCell>Waste Type</StyledTableCell>
                                                                <StyledTableCell>Activity</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {allWasteGenerationData?.length ? allWasteGenerationData.map((item, ind) => (
                                                                <material.TableRow key={ind} >
                                                                    <material.TableCell>{item.wasteCategory}</material.TableCell>
                                                                    <material.TableCell>{(item.otherSpecify === "" || item.otherSpecify === null) ? "N/A" : item.otherSpecify}</material.TableCell>
                                                                    <material.TableCell>{item.type}</material.TableCell>
                                                                    <material.TableCell>{item.activity}</material.TableCell>
                                                                    <material.TableCell>{item.amount}</material.TableCell>
                                                                    <material.TableCell>{item.unit}</material.TableCell>
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={11}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                {/* <material.TabPanel value="Graph">
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2, width: "70%" }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                {dataSets === null ? null : (
                                                    <Bar options={options} data={dataSets} />
                                                )}
                                            </div>
                                        </div>
                                    </material.Paper>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2, width: "60%" }}>
                                        <div className="row">
                                            <div className="col-12">
                                                {pieChartDataSets === null ? null : (
                                                    <Pie data={pieChartDataSets} />
                                                )}
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel> */}
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddWasteGeneration
                openAddWasteGeneration={openAddWasteGeneration}
                setOpenAddWasteGeneration={setOpenAddWasteGeneration}
                getWasteGenerationData={getWasteGenerationData}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getWasteGenerationData={getWasteGenerationData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default WasteGeneration;
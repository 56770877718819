import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import SwipeDrawer from '../drawer/SwipeDrawer';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useLocation } from 'react-router-dom';
import AddMaterialDialog from '../../sub-issues-dialogs/AddMaterialDialog';
import { useSelector, useDispatch } from 'react-redux';
import { approveRecord, getAllMaterialUsedData, sendForApproval } from '../../services/sub-issue-services/MaterialUsedService';
import moment from 'moment';
import ApplicableNotApplicableWarning from '../../dialogs/ApplicableNotApplicableWarning';
import Snackbar from '../../toaster-message/Snackbar';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../shared/Filter';
import RejectionNote from '../../dialogs/RejectionNote';
import DateFilter from '../../shared/DateFilter';
import { datePeriodAction } from '../../store/slice/DatePeriodSlice';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };
var usersRole;
var recordRefNos = [];
const initialState = {
    isLoggedIn: false,
    datePeriod: {}
};

function MaterialUsed(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const datePeriodDetails = useSelector((state) => state.DatePeriodSlice.datePeriod);
    const location = useLocation();
    const treeViewData = location?.state;
    const dispatch = useDispatch()
    const [openAddMaterial, setOpenAddMaterial] = useState({ action: false, materialData: null });
    const [materialData, setMaterialData] = useState([]);
    const [value, setValue] = React.useState('Material Used');
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    // useEffect(() => {
    //     dispatch(datePeriodAction(initialState))
    // }, []);

    useEffect(() => {
        getMaterialUsedData()
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getFormDateToDate = (data) => {
        getMaterialUsedData(data)
    };

    const getMaterialUsedData = async (filterDate) => {
        if (value === 'Material Used') {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "MATERIAL_USED"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllMaterialUsedData(payload)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataDtoList)
                    setMaterialData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (value === 'Material Used History') {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "MATERIAL_USED"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: filterDate?.fromDate,
                toDate: filterDate?.toDate,
            }
            await getAllMaterialUsedData(payload)
                .then((resp) => {
                    setMaterialData(resp.data.dataDtoList)
                    setApplicable(resp.data.applicable)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };
    const addMaterial = () => {
        setOpenAddMaterial({ action: true, materialData: null })
    };
    const editMaterial = (rowData) => {
        setOpenAddMaterial({ action: true, materialData: rowData })
    };
    const handleClickCheckbox = (value) => {
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value)
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
        setOpenAddMaterial({ action: false, materialData: null });
    };
    const sendApproval = async () => {
        if (recordRefNos.length) {
            await sendForApproval(recordRefNos)
                .then((res) => {
                    recordRefNos = [];
                    getMaterialUsedData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": res.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };
    const approved = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos,
                typeOfRecord: "MATERIAL_USED"
            }
            await approveRecord(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getMaterialUsedData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };
    const rejectRecord = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos,
            typeOfRecord: "MATERIAL_USED"
        }
        await approveRecord(obj)
            .then((resp) => {
                recordRefNos = [];
                getMaterialUsedData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span><material.Typography variant="h5" className="headingText">{treeViewData?.subIssue}</material.Typography></span></div>
                        <div className='col-6'>
                            <span className='float-end' hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={value}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <material.Tab className='text-capitalize' label="Material Used" value="Material Used" />
                                        <material.Tab className='text-capitalize' label="Material Used History" value="Material Used History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Material Used">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-6">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {materialData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approved}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecord}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addMaterial}>Add Materials</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Material</StyledTableCell>
                                                                <StyledTableCell>Type of Material</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {materialData?.length ? materialData.map((rowData, i) => (
                                                                <material.TableRow key={i}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ? () => editMaterial(rowData) : null}
                                                                >
                                                                    <material.TableCell>{rowData.material}</material.TableCell>
                                                                    <material.TableCell>{rowData.typeOfMaterial}</material.TableCell>
                                                                    <material.TableCell>{rowData.amount}</material.TableCell>
                                                                    <material.TableCell>{rowData.unit}</material.TableCell>
                                                                    <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleClickCheckbox(rowData.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleClickCheckbox(rowData.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={10}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Material Used History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer >
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow >
                                                                <StyledTableCell>Material</StyledTableCell>
                                                                <StyledTableCell>Type of Material</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Unit</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {materialData?.length ? materialData.map((rowData, i) => (
                                                                <material.TableRow key={i}>
                                                                    <material.TableCell>{rowData.material}</material.TableCell>
                                                                    <material.TableCell>{rowData.typeOfMaterial}</material.TableCell>
                                                                    <material.TableCell>{rowData.amount}</material.TableCell>
                                                                    <material.TableCell>{rowData.unit}</material.TableCell>
                                                                    <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={10}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddMaterialDialog
                getMaterialUsedData={getMaterialUsedData}
                openAddMaterial={openAddMaterial}
                setOpenAddMaterial={setOpenAddMaterial}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getMaterialUsedData={getMaterialUsedData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
}

export default MaterialUsed;
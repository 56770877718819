import React from 'react';
import GaugeChart from 'react-gauge-chart';

function SpeedometerChart(props) {

    const { chartData, fullScreen } = props;

    return (
        <div style={{ display: "flex", flexDirection: "column",justifyContent:"center", alignItems: "center",left:0,right:100 }}>
            <span style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "15px" }}>Renewable Material Use</span>
            <div style={{ width: "100%", maxWidth: "400px" }}>
                <GaugeChart id="full-dial-gauge"
                    colors={["#7ade91", '#3fa156', '#1b5929']}  // Colors of the segments
                    arcWidth={0.2}  // Thickness of the arc
                    percent={Number(chartData)}
                    formatTextValue={(value) => {
                        const percentage = (value * 1).toFixed(0);
                        let label = 'Low';
                        if (percentage >= 50) label = 'Medium';
                        if (percentage >= 75) label = 'High';
                        return `${percentage}% - ${label}`;
                    }}
                    textColor={"black"}  // Text color
                    style={{ height: "330px", width: "100%", paddingTop: "40px" }}
                    animate={true}
                    animationDuration={3000}

                />
            </div>
        </div>
    );
}

export default SpeedometerChart;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateParentalLeaveData } from '../../services/sub-issue-services/parental-leave-service/ParentalLeaveService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddParentalLeave(props) {
    const { openParentalLeave, setOpenParentalLeave, getAllParentalLeave, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [gender, setGender] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openParentalLeave.action && openParentalLeave.parentalLeaveTableData) {
            setGender(openParentalLeave.parentalLeaveTableData.gender);
            reset(openParentalLeave.parentalLeaveTableData);
        }
    }, [openParentalLeave.action === true]);

    const saveParentalLeave = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "gender": data.gender,
                "noOfEmployeeEntitledToParentalLeave": Number(data.noOfEmployeeEntitledToParentalLeave),
                "noOfEmployeeTookParentalLeave": Number(data.noOfEmployeeTookParentalLeave),
                "noOfEmployeeReturnedWorkAfterParentalLeave": Number(data.noOfEmployeeReturnedWorkAfterParentalLeave),
                "noOfEmployeeRetainedTwelveMonthAfterParentalLeave": Number(data.noOfEmployeeRetainedTwelveMonthAfterParentalLeave),
                "retentionRates": parseFloat(data.retentionRates),
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            await saveOrUpdateParentalLeaveData(obj)
                .then((resp) => {
                    getAllParentalLeave()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ noOfEmployeeEntitledToParentalLeave: "", noOfEmployeeTookParentalLeave: "", noOfEmployeeReturnedWorkAfterParentalLeave: "", noOfEmployeeRetainedTwelveMonthAfterParentalLeave: "", retentionRates: "", });
                    setGender("");
                    setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateParentalLeave = async (data) => {
        let obj = {
            "refNo": openParentalLeave.parentalLeaveTableData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "gender": data.gender,
            "noOfEmployeeEntitledToParentalLeave": Number(data.noOfEmployeeEntitledToParentalLeave),
            "noOfEmployeeTookParentalLeave": Number(data.noOfEmployeeTookParentalLeave),
            "noOfEmployeeReturnedWorkAfterParentalLeave": Number(data.noOfEmployeeReturnedWorkAfterParentalLeave),
            "noOfEmployeeRetainedTwelveMonthAfterParentalLeave": Number(data.noOfEmployeeRetainedTwelveMonthAfterParentalLeave),
            "retentionRates": parseFloat(data.retentionRates),
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate,
        }
        await saveOrUpdateParentalLeaveData(obj)
            .then((resp) => {
                getAllParentalLeave()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ noOfEmployeeEntitledToParentalLeave: "", noOfEmployeeTookParentalLeave: "", noOfEmployeeReturnedWorkAfterParentalLeave: "", noOfEmployeeRetainedTwelveMonthAfterParentalLeave: "", retentionRates: "", });
                setGender("");
                setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ noOfEmployeeEntitledToParentalLeave: "", noOfEmployeeTookParentalLeave: "", noOfEmployeeReturnedWorkAfterParentalLeave: "", noOfEmployeeRetainedTwelveMonthAfterParentalLeave: "", retentionRates: "", });
        setGender("");
        setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
    };

    const changeGender = (event) => {
        setGender(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openParentalLeave.parentalLeaveTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllParentalLeave()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ noOfEmployeeEntitledToParentalLeave: "", noOfEmployeeTookParentalLeave: "", noOfEmployeeReturnedWorkAfterParentalLeave: "", noOfEmployeeRetainedTwelveMonthAfterParentalLeave: "", retentionRates: "", });
                setGender("");
                setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <>
            <material.Dialog open={openParentalLeave.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openParentalLeave.parentalLeaveTableData ? "Edit Parental Leave" : "Add Parental Leave"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {openParentalLeave.parentalLeaveTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-2 col-4'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        onChange={changeGender}
                                        label="Gender"
                                    >
                                        <material.MenuItem value={"Male"}>Male</material.MenuItem>
                                        <material.MenuItem value={"Female"}>Female</material.MenuItem>
                                        <material.MenuItem value={"Other"}>Other</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("noOfEmployeeEntitledToParentalLeave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Entitled to Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfEmployeeEntitledToParentalLeave && errors.noOfEmployeeEntitledToParentalLeave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("noOfEmployeeTookParentalLeave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Took Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfEmployeeTookParentalLeave && errors.noOfEmployeeTookParentalLeave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("noOfEmployeeReturnedWorkAfterParentalLeave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Returned Work After Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfEmployeeReturnedWorkAfterParentalLeave && errors.noOfEmployeeReturnedWorkAfterParentalLeave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("noOfEmployeeRetainedTwelveMonthAfterParentalLeave", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    label="Number of Employee Retained 12 Month After Parental Leave"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.noOfEmployeeRetainedTwelveMonthAfterParentalLeave && errors.noOfEmployeeRetainedTwelveMonthAfterParentalLeave.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            {/* <div className='py-2 col-4'>
                                <material.TextField
                                    {...register("retentionRates", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Retention Rates"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.retentionRates && errors.retentionRates.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div> */}
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openParentalLeave.parentalLeaveTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(updateParentalLeave)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openParentalLeave.parentalLeaveTableData.rejectionNote === null) || (openParentalLeave.parentalLeaveTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" className="me-2" sx={{ textTransform: "none" }} onClick={handleSubmit(saveParentalLeave)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </>
    )
}

export default AddParentalLeave;
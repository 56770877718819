import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateRetirementBenefitData = async (RetirementBenefitData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/retirement-benefit/save-or-update`, RetirementBenefitData)
};

export const getRetirementBenefitData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/retirement-benefit/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalRetirementBenefit = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/retirement-benefit/send-for-approval`, recordRefNo)
};

export const approveRetirementBenefit = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/retirement-benefit/approve`, recordRefNo)
};
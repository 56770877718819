import React from 'react';
import { Chart } from "react-google-charts";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);


const options = {
    responsive: true,
    plugins: {
        tooltip: {
            enabled: true,
        }
    },
    maintainAspectRatio: false
};

function HalfDonutChart(props) {

    const { chartData, fullScreen } = props;
    const recycleReuseValue = (Number(chartData?.corporateTotalRecycle / chartData?.corporateTotalWithdrawal) * 100).toFixed(2);
    const recycleReuseMax = 100;
    const recycleReuseData = {
        datasets: [
            {
                data: [recycleReuseValue, recycleReuseMax - recycleReuseValue],
                backgroundColor: ['#FF6384', '#EEEEEE'],
                borderWidth: 1,
                circumference: 270,
                rotation: 225,
                cutout: '80%'
            }
        ],
        labels: chartData.corporateTotalWithdrawal? ['Water Recycle'] : ['No data found'],
    };

    const waterDischargeValue = (Number(chartData?.corporateTotalDischarge / chartData?.corporateTotalWithdrawal) * 100).toFixed(2);
    const waterDischargeMax = 100;
    const waterDischargeData = {
        datasets: [
            {
                data: [waterDischargeValue, waterDischargeMax - waterDischargeValue],
                backgroundColor: ['#ffbf80', '#EEEEEE'],
                borderWidth: 1,
                circumference: 270,
                rotation: 225,
                cutout: '80%',
            }
        ],
        labels: chartData.corporateTotalWithdrawal? ['Water Discharge'] : ['No data found'],
    };

    const waterConsumptionValue = Number(100 - waterDischargeValue);
    const waterConsumptionMax = 100;
    const waterConsumptionData = {
        datasets: [
            {
                data: [waterConsumptionValue, waterConsumptionMax - waterConsumptionValue],
                backgroundColor: ['#e1f0c1', '#EEEEEE'],
                borderWidth: 1,
                circumference: 270,
                rotation: 225,
                cutout: '80%',
            }
        ],
        labels: chartData.corporateTotalWithdrawal ? ['Water Consumption'] : ['No data found'],
    };


    return (
        <div className='row' style={{ height: fullScreen ? "500px" : "" }}>
            <span style={{ display: "flex", justifyContent: "center", fontName: "arial", fontSize: 18, fontWeight: "bold" }}>Water Consumption, Water Discharge & Water Recycle/Reuse</span>
            <div className="col-lg-4">
                <Doughnut
                    data={waterConsumptionData}
                    options={options}
                    style={{ width: "50%" }}
                />
                <span style={{ display: "flex", justifyContent: "center", fontName: "arial", fontSize: 18, fontWeight: "bold" }}>Water Consumption</span>
            </div>
            <div className="col-lg-4">
                <Doughnut
                    data={waterDischargeData}
                    options={options}
                    style={{ width: "50%" }}
                />
                <span style={{ display: "flex", justifyContent: "center", fontName: "arial", fontSize: 18, fontWeight: "bold" }}>Water Discharge</span>
            </div>
            <div className="col-lg-4">
                <Doughnut
                    data={recycleReuseData}
                    options={options}
                    style={{ width: "50%" }}
                />
                <span style={{ display: "flex", justifyContent: "center", fontName: "arial", fontSize: 18, fontWeight: "bold" }}>Water Recycle</span>
            </div>
        </div>
    );
}

export default HalfDonutChart;


import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../component/login/Login';
import UserManagemant from '../component/user-management/UserManagemant';
import CorporateList from '../component/corporate-list/CorporateList';
import SiteList from '../component/site/SiteList';
import CorporateDetails from '../component/corporate-deails/CorporateDetails';
import Ranking from '../component/ranking/Ranking';
import GeneralDisclosure from '../component/general-disclosure/GeneralDisclosure';
import MaterialUsed from '../component/sub-issue/MaterialUsed';
import RecycledInput from '../component/sub-issue/RecycledInput';
import ReclaimedProducts from '../component/sub-issue/ReclaimedProducts';
import RAndDAndCAPEX from '../component/sub-issue/RAndDAndCAPEX';
import LifecycleAssessment from '../component/sub-issue/LifecycleAssessment';
import EnergyConsumptionWithinOrg from '../component/sub-issue/energy/EnergyConsumptionWithinOrg';
import EnergyConsumptionOutsideOrg from '../component/sub-issue/energy/EnergyConsumptionOutsideOrg';
import EnergyEfficiencyProduction from '../component/sub-issue/energy/EnergyEfficiencyProduction';
import EnergyEfficiencyProductsServices from '../component/sub-issue/energy/EnergyEfficiencyProductsServices';
import WaterWithdrawal from '../component/sub-issue/water-wastewater/WaterWithdrawal';
import WaterDischarge from '../component/sub-issue/water-wastewater/WaterDischarge';
import WaterConsumption from '../component/sub-issue/water-wastewater/WaterConsumption';
import AirEmissionsInteractions from '../component/sub-issue/air/AirEmissionsInteractions';
import AirEmissionsManagement from '../component/sub-issue/air/AirEmissionsManagement';
import GhgScope1 from '../component/sub-issue/climate-change/GhgScope1';
import GhgScope2 from '../component/sub-issue/climate-change/GhgScope2';
import GhgScope3 from '../component/sub-issue/climate-change/GhgScope3';
import CoverageOfTraining from '../component/sub-issue/training/CoverageOfTraining';
import PerformanceApprisal from '../component/sub-issue/training/PerformanceApprisal';
import DiversityOfGovernanceBodies from '../component/sub-issue/diversity-inclusion/DiversityOfGovernanceBodies';
import GrievancesRedressalSystem from '../component/sub-issue/grievances/GrievancesRedressalSystem';
import Health from '../component/sub-issue/health-and-safety/Health';
import Injuries from '../component/sub-issue/health-and-safety/Injuries';
import EmployeeAndWorkers from '../component/sub-issue/employee/EmployeeAndWorkers';
import DifferentlyAbledEmployeesAndWorkers from '../component/sub-issue/employee/DifferentlyAbledEmployeesAndWorkers';
import NewHiringAndTurnover from '../component/sub-issue/employee/NewHiringAndTurnover';
import WasteGeneration from '../component/sub-issue/waste/WasteGeneration';
import WasteRecovery from '../component/sub-issue/waste/WasteRecovery';
import WasteDisposal from '../component/sub-issue/waste/WasteDisposal';
import WasteImpacts from '../component/sub-issue/waste/WasteImpacts';
import WasteManagement from '../component/sub-issue/waste/WasteManagement';
import GovernanceBodyStructure from '../component/sub-issue/governance-structure/GovernanceBodyStructure';
import BiodiversityValueOfSite from '../component/sub-issue/biodiversity/BiodiversityValueOfSite';
import SensitiveSpeciesAffected from '../component/sub-issue/biodiversity/SensitiveSpeciesAffected';
import HabitatsProtectedOrRestored from '../component/sub-issue/biodiversity/HabitatsProtectedOrRestored';
import BiodiversityImpacts from '../component/sub-issue/biodiversity/BiodiversityImpacts';
import BiodiversityCommitments from '../component/sub-issue/biodiversity/BiodiversityCommitments';
import EnvironmentalCriteria from '../component/sub-issue/supplier-chain-environment/EnvironmentalCriteria';
import AdverseImpactAction from '../component/sub-issue/supplier-chain-environment/AdverseImpactAction';
import EHSApprovalStatus from '../component/sub-issue/environmental-compliance/EHSApprovalStatus';
// import FinesAndPenalties from '../component/sub-issue/environmental-compliance/FinesAndPenalties';
// import ViolationsNotices from '../component/sub-issue/environmental-compliance/ViolationsNotices';
import EmployeesWorkersWellBeing from '../component/sub-issue/employee/EmployeesWorkersWellBeing';
import ParentalLeave from '../component/sub-issue/employee/ParentalLeave';
import RetirementBenefit from '../component/sub-issue/employee/RetirementBenefit';
import DiscriminationIncidents from '../component/sub-issue/non-discrimination/DiscriminationIncidents';
import FreedomOfAssociation from '../component/sub-issue/freedom-of-association/FreedomOfAssociation';
import SecurityPractices from '../component/sub-issue/human-rights/SecurityPractices';
import SocialScreening from '../component/sub-issue/supplier-chain-social/SocialScreening';
import SafetyMeasures from '../component/sub-issue/supplier-chain-social/SafetyMeasures';
import ChildAndForcedLabour from '../component/sub-issue/human-rights/ChildAndForcedLabour';
import IndigenousPeoplesRights from '../component/sub-issue/indigenous_peoples/IndigenousPeoplesRights';
import NoticesAndFines from '../component/sub-issue/non-compliance/NoticesAndFines';
import ViolationAndExceedance from '../component/sub-issue/non-compliance/ViolationAndExceedance';
import AdverseImpactsAndAction from '../component/sub-issue/supplier-chain-social/AdverseImpactsAndAction';
import NoMatch from './NoMatch';
import AirEmissionAreaSource from '../component/sub-issue/air/AirEmissionAreaSource';
import AirEmissionsPointSource from '../component/sub-issue/air/AirEmissionsPointSource';
import Dashboard from '../component/dashboard/Dashboard';


import Material from '../component/dashboard/materials/Material';
import ManageEntity from '../component/manage-entity/ManageEntity';
import AddEntity from '../component/manage-entity/AddEntity';
import EditEntity from '../component/manage-entity/EditEntity';


function Routing(props) {
    return (
        <div>
            <Routes>
                <Route path='' element={<Navigate to="/login" />} />
                <Route path='/login' element={<Login />} />
                <Route path='/user-management' element={<UserManagemant />} />
                <Route path='/corporate-list' element={<CorporateList />} />
                <Route path='/site-list' element={<SiteList />} />
                <Route path='/corporate' element={<CorporateDetails />} />
                <Route path='/facility' element={<CorporateDetails />} />
                <Route path='/ranking' element={<Ranking />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/manage-entity' element={<ManageEntity />} />
                <Route path='/manage-entity/add-entity' element={<AddEntity />} />
                <Route path='/manage-entity/edit-entity' element={<EditEntity />} />

                {/* 1st phase */}

                <Route path='/Environment/Materials' element={<GeneralDisclosure />} />
                <Route path='/Environment/Materials/Materials_Used' element={<MaterialUsed />} />
                <Route path='/Environment/Materials/Recycled_Input' element={<RecycledInput />} />
                <Route path='/Environment/Materials/Reclaimed_Products' element={<ReclaimedProducts />} />
                <Route path='/Environment/Energy' element={<GeneralDisclosure />} />
                <Route path='/Environment/Energy/Energy_Consumption_Within_Organization' element={<EnergyConsumptionWithinOrg />} />
                <Route path='/Environment/Energy/Energy_Consumption_Outside_Organization' element={<EnergyConsumptionOutsideOrg />} />
                <Route path='/Environment/Water_&_Wastewater' element={<GeneralDisclosure />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Withdrawal' element={<WaterWithdrawal />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Discharge' element={<WaterDischarge />} />
                <Route path='/Environment/Water_&_Wastewater/Water_Consumption' element={<WaterConsumption />} />
                <Route path='/Environment/Climate_Change' element={<GeneralDisclosure />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_1' element={<GhgScope1 />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_2' element={<GhgScope2 />} />
                <Route path='/Environment/Air' element={<GeneralDisclosure />} />
                <Route path='/Environment/Air/Air_Emissions_-_Point_Source' element={<AirEmissionsPointSource />} />
                <Route path='/Environment/Air/Air_Emissions_-_Area_Source' element={<AirEmissionAreaSource />} />
                <Route path='/Environment/Waste' element={<GeneralDisclosure />} />
                <Route path='/Environment/Waste/Waste_Generation' element={<WasteGeneration />} />
                <Route path='/Environment/Waste/Waste_Recovery' element={<WasteRecovery />} />
                <Route path='/Environment/Waste/Waste_Disposal' element={<WasteDisposal />} />
                <Route path='/Social/Training' element={<GeneralDisclosure />} />
                <Route path='/Social/Training/Coverage_of_Training' element={<CoverageOfTraining />} />
                <Route path='/Social/Diversity_&_Inclusion' element={<GeneralDisclosure />} />
                <Route path='/Social/Diversity_&_Inclusion/Diversity_of_Governance_Bodies' element={<DiversityOfGovernanceBodies />} />
                {/* <Route path='/Social/Diversity_&_Inclusion/Remuneration' element={<Remuneration />} /> */}
                <Route path='/Social/Occupational_Health_&_Safety' element={<GeneralDisclosure />} />
                <Route path='/Social/Occupational_Health_&_Safety/Work_Related_Ill_Health' element={<Health />} />
                <Route path='/Social/Occupational_Health_&_Safety/Work_Related_Injuries' element={<Injuries />} />
                <Route path='/Social/Employee' element={<GeneralDisclosure />} />
                <Route path='/Social/Employee/Employee_&_Workers' element={<EmployeeAndWorkers />} />
                <Route path='/Social/Employee/Differently_Abled_Employees_&_Workers' element={<DifferentlyAbledEmployeesAndWorkers />} />
                <Route path='/Social/Employee/New_Hiring_&_Turnover' element={<NewHiringAndTurnover />} />
                <Route path='/Governance/Governance_Structure' element={<GeneralDisclosure />} />
                <Route path='/Governance/Governance_Structure/Governance_Body_&_Structure' element={<GovernanceBodyStructure />} />
                <Route path='/Governance/Grievances' element={<GeneralDisclosure />} />
                <Route path='/Governance/Grievances/Grievances_Redressal_System' element={<GrievancesRedressalSystem />} />
                <Route path='/Governance/Non-compliance' element={<GeneralDisclosure />} />

                {/* 2nd phase  */}

                <Route path='/Environment/Materials/R&D_and_CAPEX' element={<RAndDAndCAPEX />} />
                <Route path='/Environment/Materials/Lifecycle_Assessment' element={<LifecycleAssessment />} />
                <Route path='/Social/Employee/Employees_&_Workers_Well-Being' element={<EmployeesWorkersWellBeing />} />
                <Route path='/Social/Employee/Parental_Leave' element={<ParentalLeave />} />
                <Route path='/Social/Employee/Retirement_Benefit' element={<RetirementBenefit />} />
                <Route path='/Social/Non-Discrimination' element={<GeneralDisclosure />} />
                <Route path='/Social/Non-Discrimination/Discrimination_Incidents' element={<DiscriminationIncidents />} />
                <Route path='/Social/Freedom_of_Association' element={<GeneralDisclosure />} />
                <Route path='/Social/Freedom_of_Association/Freedom_of_Association' element={<FreedomOfAssociation />} />
                <Route path='/Social/Human_Rights' element={<GeneralDisclosure />} />
                <Route path='/Social/Human_Rights/Security_Practices' element={<SecurityPractices />} />
                <Route path='/Social/Human_Rights/Child_&_Forced_Labour' element={<ChildAndForcedLabour />} />
                <Route path='/Social/Supplier_Chain-Social' element={<GeneralDisclosure />} />
                <Route path='/Social/Supplier_Chain-Social/Social_Screening' element={<SocialScreening />} />
                <Route path='/Social/Supplier_Chain-Social/Safety_Measures' element={<SafetyMeasures />} />
                <Route path='/Social/Supplier_Chain-Social/Adverse_Impacts_&_Action' element={<AdverseImpactsAndAction />} />
                <Route path='/Social/Indigenous_Peoples' element={<GeneralDisclosure />} />
                <Route path='/Social/Indigenous_Peoples/Indigenous_Peoples_Rights' element={<IndigenousPeoplesRights />} />
                <Route path='/Environment/Energy/Energy_Efficiency_-_Production' element={<EnergyEfficiencyProduction />} />
                <Route path='/Environment/Energy/Energy_Efficiency_-_Products_&_Services' element={<EnergyEfficiencyProductsServices />} />
                <Route path='/Environment/Climate_Change/GHG_Scope_3' element={<GhgScope3 />} />
                <Route path='/Environment/Air/Air_Emissions_&_Interactions' element={<AirEmissionsInteractions />} />
                <Route path='/Environment/Air/Air_Emissions_Management' element={<AirEmissionsManagement />} />
                <Route path='/Environment/Waste/Waste_Impacts' element={<WasteImpacts />} />
                <Route path='/Environment/Waste/Waste_Management' element={<WasteManagement />} />
                {/* <Route path='/Environment/Hazardous_Materials' element={<GeneralDisclosure} /> /> */}
                <Route path='/Environment/Biodiversity' element={<GeneralDisclosure />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Value_of_Site' element={<BiodiversityValueOfSite />} />
                <Route path='/Environment/Biodiversity/Sensitive_Species_Affected' element={<SensitiveSpeciesAffected />} />
                <Route path='/Environment/Biodiversity/Habitats_Protected_or_Restored' element={<HabitatsProtectedOrRestored />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Service_Impacted' element={<BiodiversityImpacts />} />
                <Route path='/Environment/Biodiversity/Biodiversity_Commitments' element={<BiodiversityCommitments />} />
                <Route path='/Environment/Environmental_Compliance' element={<GeneralDisclosure />} />
                <Route path='/Environment/Environmental_Compliance/EHS_Approval_Status' element={<EHSApprovalStatus />} />
                {/* <Route path='/Environment/Environmental_Compliance/Fines_and_Penalties' element={<FinesAndPenalties />} />
                <Route path='/Environment/Environmental_Compliance/Violations_&_Notices' element={<ViolationsNotices />} /> */}
                <Route path='/Environment/Supplier_Chain-Environment' element={<GeneralDisclosure />} />
                <Route path='/Environment/Supplier_Chain-Environment/Environmental_Criteria' element={<EnvironmentalCriteria />} />
                <Route path='/Environment/Supplier_Chain-Environment/Adverse_Impacts_&_Actions' element={<AdverseImpactAction />} />
                <Route path='/Social/Training/Performance_Appraisal' element={<PerformanceApprisal />} />
                <Route path='/Governance/Non-Compliance/Notices_&_Fines' element={<NoticesAndFines />} />
                <Route path='/Governance/Non-Compliance/Violation_&_Exceedance' element={<ViolationAndExceedance />} />

                {/* 2nd phase  */}


                {/* dashboard routing */}

                <Route path='/issue-dashboard/material' element={<Material />} />


                <Route path='*' element={<NoMatch />} />
            </Routes>
        </div>
    );
}

export default Routing;
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { generateFromDateAndToDate, dataPeriod, quarterData, halfYearPeriod, months } from './TimePeriod';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { material } from '../library/Material';
import { useDispatch } from 'react-redux';
import { datePeriodAction } from '../store/slice/DatePeriodSlice';
import dayjs from 'dayjs';
import moment from 'moment';
import Snackbar from '../toaster-message/Snackbar';


function DateFilter(props) {

    const { getFormDateToDate } = props;
    const dispatch = useDispatch()
    const { register, handleSubmit, control, reset, resetField, getValues, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [periodType, setPeriodType] = React.useState('');
    const [selectedMonth, setSelectedMonth] = React.useState('');
    const [quarterType, setQuarterType] = React.useState('');
    const [halfYearType, setHalfYearType] = React.useState('');
    const [selectedYear, setSelectedYear] = React.useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const onchangePeriodType = (event) => {
        setPeriodType(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    };

    const handleQuarterChange = (event) => {
        setQuarterType(event.target.value);
    };

    const onchangeHalfYearlyType = (event) => {
        setHalfYearType(event.target.value);
    };

    const changeLabel = () => {
        if (periodType === 'Monthly') {
            return 'Month';
        } else if (periodType === 'Quarterly') {
            return 'Quarter';
        } else if (periodType === 'Half Yearly') {
            return 'One half';
        }
    };
    const changeSourceArray = () => {
        if (periodType === 'Monthly') {
            return months;
        } else if (periodType === 'Quarterly') {
            return quarterData;
        } else if (periodType === 'Half Yearly') {
            return halfYearPeriod;
        } else {
            return [];
        }
    };

    const changeSelectionChangeFunction = (event) => {
        if (periodType === 'Monthly') {
            handleMonthChange(event);
        } else if (periodType === 'Quarterly') {
            handleQuarterChange(event);
        } else if (periodType === 'Half Yearly') {
            onchangeHalfYearlyType(event);
        }
    };
    const changeValueState = () => {
        if (periodType === 'Monthly') {
            return selectedMonth;
        } else if (periodType === 'Quarterly') {
            return quarterType;
        } else if (periodType === 'Half Yearly') {
            return halfYearType;
        }
    };

    const applyFilter = (data) => {
        let value = generateFromDateAndToDate(data);
        // dispatch(datePeriodAction({ ...value, action: true }))
        let newDate = moment().format("YYYY-MM-DD")
        if ((newDate > value.fromDate) && (newDate > value.toDate)) {
            getFormDateToDate(value)
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't filter future records",
            })
        }
    };

    const refreshData = () => {
        setPeriodType("")
        setSelectedMonth("")
        setQuarterType("")
        setHalfYearType("")
        setSelectedYear("")
        getFormDateToDate()
    };

    return (
        <div>
            <div className="row">
                <div className='col-lg-3'>
                    <material.FormControl variant="standard" fullWidth >
                        <material.InputLabel id="demo-simple-select-standard-label">Data Period</material.InputLabel>
                        <material.Select
                            {...register('periodType', { required: true, })}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={periodType}
                            onChange={onchangePeriodType}
                            label="Data Period"
                        >
                            {
                                dataPeriod.map((period, pInd) => (
                                    <material.MenuItem value={period} key={pInd}>
                                        {period}
                                    </material.MenuItem>
                                )
                                )
                            }
                        </material.Select>
                    </material.FormControl>
                </div>
                {(periodType == '') || (periodType == 'Yearly') ? null : (
                    <div className='col-lg-3'>
                        <material.FormControl variant="standard" fullWidth >
                            <material.InputLabel id="demo-simple-select-standard-label">{changeLabel()}</material.InputLabel>
                            <material.Select
                                {...register(periodType === 'Monthly' ? 'selectedMonth' : periodType === 'Quarterly' ? 'selectedQuarter' : periodType === 'Half Yearly' ? 'halfYearType' : null, { required: true, })}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Month"
                                value={changeValueState()}
                                onChange={changeSelectionChangeFunction}
                            >
                                {
                                    changeSourceArray().map((item, ind) => (
                                        <material.MenuItem value={item.value} key={ind}>
                                            {item.name}
                                        </material.MenuItem>
                                    )
                                    )
                                }
                            </material.Select>
                        </material.FormControl>
                    </div>
                )}
                <div className='col-lg-3'>
                    {periodType !== '' ? (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="selectedYear"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            openTo="year"
                                            views={['year']}
                                            label="Year"
                                            minDate={dayjs(new Date("01-01-1700"))}
                                            format='YYYY'
                                            value={selectedYear}
                                            onChange={(newValue) => {
                                                setSelectedYear(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                    {...field}
                                                />
                                            )}
                                            slotProps={{ textField: { variant: 'standard', color: 'success' } }}
                                            // sx={{ width: '80%' }}
                                            disableFuture
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </>
                    ) : null}
                </div>
                <div className="col-lg-3 mt-3">
                    {periodType !== '' ? (
                        <span>
                            <material.IconButton title='Filter Record' color='success' disabled={!isValid} onClick={handleSubmit(applyFilter)}>
                                <material.FilterListIcon />
                            </material.IconButton>
                            <material.IconButton title='Refresh' color='success' onClick={refreshData}>
                                <material.RefreshIcon />
                            </material.IconButton>
                            {/* <material.Button variant="contained" color='success' sx={{ textTransform: "none", ml: 2, mt: 1.5 }} disabled={!isValid} onClick={handleSubmit(applyFilter)}>Apply</material.Button> */}
                        </span>
                    ) : null}
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default DateFilter;
import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";


export const getPillarIssueSubIssueTreeList = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-pillar-issue-sub-issue-tree-list`)
};

export const getGeneralDisclosuresByQuestion = async (questions) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/get-latest-general-disclosure-by-pillar-issue`, questions, { params: { sendForApproval: questions.sendForApproval, isApprover: questions.isApprover } })
};

export const createMultipleGeneralDisclosures = async (questions) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/create-multiple`, questions)
};

export const updateGeneralDisclosures = async (questions) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/create`, questions)
};

export const getGeneralDisclosuresHistory = async (questions) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/get-general-disclosure-history-by-pillar-issue`, questions)
};

export const generalDisclosuresStatusChange = async (payload) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/approve-disclosure`, payload, { params: { status: payload.status, rejectionNote: payload.rejectionNote } })
};

export const sendDisclosuresForApprove = async (questions) => {
    return await axiosInstance.post(`${rootApiUrl}/api/general-disclosures/send-disclosure-for-approval`, questions)
};

export const getDisclosuresQuestion = async (issue, disclosureType) => {
    return await axiosInstance.get(`${rootApiUrl}/api/master/get-question`, { params: { issue: issue, disclosureType: disclosureType } })
};
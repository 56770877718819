import React from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';

function SafetyMeasures(props) {
    const location = useLocation();
    const treeViewData = location.state;

    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                <span><material.Typography variant="h5">{treeViewData.subIssue}</material.Typography></span>
            </material.Box>
        </material.Box>
    );
}

export default SafetyMeasures;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import { useSelector } from "react-redux";
import AddDiversityOfGovernanceBodies from '../../../sub-issues-dialogs/diversity-of-governance/AddDiversityOfGovernanceBodies';
import { getAllDiversityOfGovernanceBodiesData } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveRecord } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import { sendForApproved } from '../../../services/sub-issue-services/DiversityOfGovernanceBodiesService';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment/moment';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import EmployeeAgeBasedDataDialog from '../../../sub-issues-dialogs/employee/EmployeeAgeBasedDataDialog';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';

var usersRole;
var recordRefNo = []
const label = { inputProps: { "aria-label": "Color switch demo" } };

function DiversityOfGovernanceBodies(props) {
  const location = useLocation();
  const treeViewData = location?.state;
  const userDetails = useSelector((state) => state.LoginSlice.data);
  const [selectTab, setSelectTab] = useState('Diversity of Governance Bodies');
  const [diversityOfGovernanceBodiesData, setDiversityOfGovernanceBodiesData] = useState([]);
  const [openAddDiversity, setOpenAddDiversity] = useState({ action: false, diversityOfGovernanceData: null });
  const [applicable, setApplicable] = useState(true);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
  const [openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable] = useState({ action: false, employeesAgeBasedData: null, collForm: "DIVERSITY_OF_GOVERNANCE_BODIES" });
  const [applicableRefNo, setApplicableRefNo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  for (let i = 0; i < userDetails?.roles?.length; i++) {
    usersRole = userDetails.roles[i]
  };

  useEffect(() => {
    getDiversityOfGovernanceBodiesData();
  }, [selectTab]);

  const getFormDateToDate = (data) => {
    getDiversityOfGovernanceBodiesData(data)
  };

  const getDiversityOfGovernanceBodiesData = async (data) => {
    if (selectTab === 'Diversity of Governance Bodies') {
      getDiversityOfGovernanceData(data);
    } else if (selectTab === 'Diversity of Governance Bodies History') {
      getDiversityOfGovernanceBodiesHistory(data);
    }
  };

  const getDiversityOfGovernanceData = (data) => {
    let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
    let approveHistory = false;
    let payload = {
      corporateRefNo: userDetails.corporateRefNo,
      facilityRefNo: userDetails.facilityRefNo,
      forApproval,
      approveHistory,
      fromDate: data?.fromDate,
      toDate: data?.toDate,
    }
    getAllDiversityOfGovernanceBodiesData(payload)
      .then((resp) => {
        let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dataList)
        setDiversityOfGovernanceBodiesData(filterData)
        setApplicable(resp.data.applicable);
        setApplicableRefNo(resp.data.applicableRefNo);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        }
      })
  };
  const getDiversityOfGovernanceBodiesHistory = (data) => {
    let forApproval = true;
    let approveHistory = true;
    let payload = {
      corporateRefNo: userDetails.corporateRefNo,
      facilityRefNo: userDetails.facilityRefNo,
      forApproval,
      approveHistory,
      fromDate: data?.fromDate,
      toDate: data?.toDate,
    }
    getAllDiversityOfGovernanceBodiesData(payload)
      .then((resp) => {
        setDiversityOfGovernanceBodiesData(resp.data.dataList);
        setApplicable(resp.data.applicable);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        }
      })
  };

  const addDiversity = () => {
    setOpenAddDiversity({ action: true, diversityOfGovernanceData: null });
    setOpenEmployeeAgeBasedDataTable({ action: false, employeesAgeBasedData: null, collForm: "DIVERSITY_OF_GOVERNANCE_BODIES" })
  };

  const editDiversity = (rowData) => {
    setOpenAddDiversity({ action: true, diversityOfGovernanceData: rowData });
  };

  const handleCheckedRecords = (value) => {
    setOpenAddDiversity({ action: false, diversityOfGovernanceData: null });
    if (recordRefNo.includes(value)) {
      // recordRefNo.pop(value);
      recordRefNo = recordRefNo.filter(item => item !== value);
    }
    else {
      recordRefNo.push(value);
    }
  };

  const sendApprovalDiversity = async () => {
    if (recordRefNo.length)
      await sendForApproved(recordRefNo)
        .then((resp) => {
          recordRefNo = [];
          getDiversityOfGovernanceData();
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          }
        })
  };

  const approvedDiversity = async () => {
    if (recordRefNo.length) {
      let object = {
        "rejectionNote": "",
        "corporateRefNo": userDetails.corporateRefNo,
        "facilityRefNo": userDetails.facilityRefNo,
        "subIssueId": treeViewData.id,
        "subIssueName": treeViewData.subIssue,
        "rejectedBy": userDetails.username,
        "status": "APPROVED",
        "recordRefNo": recordRefNo
      }
      await approveRecord(object)
        .then((resp) => {
          recordRefNo = []
          getDiversityOfGovernanceData()
          setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": "You are not authorized to perform this action. Redirected to login",
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            })
          }
        })
    };
  };

  const rejectRecordDiversity = async () => {
    if (recordRefNo.length) {
      setOpenRejectionNoteDialog({ action: true, rejectionData: null });
    }
  };
  const viewRejectionNote = (rejectData) => {
    setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
  };
  const getRejectionNoteData = async (noteData) => {
    let obj = {
      "rejectionNote": noteData.rejectionNote,
      "corporateRefNo": userDetails.corporateRefNo,
      "facilityRefNo": userDetails.facilityRefNo,
      "subIssueId": treeViewData.id,
      "subIssueName": treeViewData.subIssue,
      "rejectedBy": userDetails.username,
      "status": "REJECTED",
      "recordRefNo": recordRefNo
    }
    await approveRecord(obj)
      .then((resp) => {
        recordRefNo = [];
        getDiversityOfGovernanceData();
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": resp.data,
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": "You are not authorized to perform this action. Redirected to login",
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
          })
        }
      })
  };

  const handleChangeApplicableNotApplicable = () => {
    setOpenWarningDialog(true)
  };

  const handleChangeDiversityTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  const employeeAgeBasedData = (rowData) => {
    setOpenEmployeeAgeBasedDataTable({ action: true, employeesAgeBasedData: rowData, collForm: "DIFFERENTLY_ABLED_EMPLOYEE_WORKERS" });
    setOpenAddDiversity({ action: false, diversityOfGovernanceData: null });
  };


  return (
    <div>
      <material.Box sx={{ display: 'flex' }}>
        <SwipeDrawer />
        <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
          <div className='row'>
            <div className='col-6'>
              <span><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
            </div>
            <div className="col-6">
              <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                <material.Stack direction="row" spacing={1} alignItems="center">
                  <material.Typography>Not applicable</material.Typography>
                  <material.Switch {...label} checked={applicable} color="secondary" onChange={handleChangeApplicableNotApplicable} />
                  <material.Typography>Applicable</material.Typography>
                </material.Stack>
              </span>
            </div>
          </div>
          <div>
            <material.Box sx={{ width: '100%', typography: 'body1' }}>
              <material.TabContext value={selectTab}>
                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <material.TabList onChange={handleChangeDiversityTab} aria-label="lab API tabs example">
                    <material.Tab sx={{ textTransform: "none" }} label="Diversity of Governance Bodies" value="Diversity of Governance Bodies" />
                    <material.Tab sx={{ textTransform: "none" }} label="Diversity of Governance Bodies History" value="Diversity of Governance Bodies History" />
                  </material.TabList>
                </material.Box>
                <material.TabPanel value="Diversity of Governance Bodies">
                  <div className="row mt-2 align-items-center">
                    <div className="col-lg-6">
                      {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                        <DateFilter
                          getFormDateToDate={getFormDateToDate}
                        />
                      )}
                    </div>
                    <div className="col-lg-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                      <span className='float-end'>
                        {diversityOfGovernanceBodiesData?.length ? (
                          <>
                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedDiversity}>Approve</material.Button>
                            <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecordDiversity}>Reject</material.Button>
                          </>
                        ) : null}
                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalDiversity}>Send for Approval</material.Button>
                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addDiversity}>Add Diversity of Governance Bodies</material.Button>
                      </span>
                    </div>
                  </div>
                  <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                    <div className="row mt-2">
                      <div className="col-12">
                        <material.TableContainer>
                          <material.Table>
                            <material.TableHead>
                              <material.TableRow>
                                <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                <StyledTableCell>Male</StyledTableCell>
                                <StyledTableCell>Female</StyledTableCell>
                                <StyledTableCell>Other</StyledTableCell>
                                <StyledTableCell>Total Derived</StyledTableCell>
                                <StyledTableCell>Vulnerable</StyledTableCell>
                                <StyledTableCell>From Date</StyledTableCell>
                                <StyledTableCell>To Date</StyledTableCell>
                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                  <StyledTableCell>Approval Date</StyledTableCell>
                                )}
                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                <StyledTableCell>Age Based Data</StyledTableCell>
                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                  <StyledTableCell>Rejection Note</StyledTableCell>
                                )}
                              </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                              {diversityOfGovernanceBodiesData?.length ? diversityOfGovernanceBodiesData.map((rowData, ind) => (
                                <material.TableRow key={ind}
                                  sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                  onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ? () => editDiversity(rowData) : null}
                                >
                                  <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                  <material.TableCell>{rowData.male}</material.TableCell>
                                  <material.TableCell>{rowData.female}</material.TableCell>
                                  <material.TableCell>{rowData.other}</material.TableCell>
                                  <material.TableCell>{rowData.total}</material.TableCell>
                                  <material.TableCell>{rowData.vulnerable}</material.TableCell>
                                  <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                    <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  )}
                                  <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                    {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                      <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                    ) : (
                                      <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckedRecords(rowData.refNo)} color="success" />
                                    )}
                                  </material.TableCell>
                                  <material.TableCell>
                                    {rowData.isAgeBasedDataPresent === true ? (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: false }) }}>Yes</material.Link>
                                    ) : null}
                                  </material.TableCell>
                                  {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                    <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                    )}
                                    </material.TableCell>
                                  )}
                                </material.TableRow>
                              )) : (
                                <material.TableRow >
                                  <material.TableCell colSpan={12}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                  </material.TableCell>
                                </material.TableRow>
                              )}
                            </material.TableBody>
                          </material.Table>
                        </material.TableContainer>
                      </div>
                    </div>
                  </material.Paper>
                </material.TabPanel>
                <material.TabPanel value="Diversity of Governance Bodies History">
                  <div className="row">
                    <div className="col-lg-8">
                      <DateFilter
                        getFormDateToDate={getFormDateToDate}
                      />
                    </div>
                  </div>
                  <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                    <div className='row'>
                      <div className="col-12">
                        <material.TableContainer>
                          <material.Table>
                            <material.TableHead>
                              <material.TableRow>
                                <StyledTableCell>Category of Employee & Worker</StyledTableCell>
                                <StyledTableCell>Male</StyledTableCell>
                                <StyledTableCell>Female</StyledTableCell>
                                <StyledTableCell>Other</StyledTableCell>
                                <StyledTableCell>Total Derived</StyledTableCell>
                                <StyledTableCell>Vulnerable</StyledTableCell>
                                <StyledTableCell>From Date</StyledTableCell>
                                <StyledTableCell>To Date</StyledTableCell>
                                <StyledTableCell>Approval Date</StyledTableCell>
                                <StyledTableCell>Approved Status</StyledTableCell>
                                <StyledTableCell>Age Based Data</StyledTableCell>
                              </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                              {diversityOfGovernanceBodiesData?.length ? diversityOfGovernanceBodiesData.map((rowData, ind) => (
                                <material.TableRow key={ind}>
                                  <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                  <material.TableCell>{rowData.male}</material.TableCell>
                                  <material.TableCell>{rowData.female}</material.TableCell>
                                  <material.TableCell>{rowData.other}</material.TableCell>
                                  <material.TableCell>{rowData.total}</material.TableCell>
                                  <material.TableCell>{rowData.vulnerable}</material.TableCell>
                                  <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                  <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : null}</material.TableCell>
                                  <material.TableCell>
                                    {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                      APPROVED
                                    </span> : null}
                                  </material.TableCell>
                                  <material.TableCell>
                                    {rowData.isAgeBasedDataPresent === true ? (
                                      <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: true }) }}>Yes</material.Link>
                                    ) : null}
                                  </material.TableCell>
                                </material.TableRow>
                              )) : (
                                <material.TableRow >
                                  <material.TableCell colSpan={12}>
                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                  </material.TableCell>
                                </material.TableRow>
                              )}
                            </material.TableBody>
                          </material.Table>
                        </material.TableContainer>
                      </div>
                    </div>
                  </material.Paper>
                </material.TabPanel>
              </material.TabContext>
            </material.Box>
          </div>
        </material.Box>
      </material.Box>
      <AddDiversityOfGovernanceBodies
        openAddDiversity={openAddDiversity}
        setOpenAddDiversity={setOpenAddDiversity}
        getDiversityOfGovernanceData={getDiversityOfGovernanceData}
        treeViewData={treeViewData}
      />
      <ApplicableNotApplicableWarning
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getDiversityOfGovernanceData={getDiversityOfGovernanceData}
        applicable={applicable}
        treeViewData={treeViewData}
        applicableRefNo={applicableRefNo}
      />
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <EmployeeAgeBasedDataDialog
        openEmployeeAgeBasedDataTable={openEmployeeAgeBasedDataTable}
        setOpenEmployeeAgeBasedDataTable={setOpenEmployeeAgeBasedDataTable}
      />
      <RejectionNote
        openRejectionNoteDialog={openRejectionNoteDialog}
        setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
        getRejectionNoteData={getRejectionNoteData}
      />
    </div>
  );
}

export default DiversityOfGovernanceBodies;
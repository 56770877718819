import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";

export const saveOrUpdateGhgScopeData = async (GhgScopeData, typeOfRecord) => {
    return await axiosInstance.post(`${rootApiUrl}/api/ghg-scope-common/save-or-update`, GhgScopeData, { params: { typeOfRecord: typeOfRecord } })
};

export const getGhgScopeData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/ghg-scope-common/get-all`, { params: payload })
};

export const sendForApprovalGhgScope = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/ghg-scope-common/send-for-approval`, recordRefNo)
};

export const approveGhgScope = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/ghg-scope-common/approve`, payload)
};
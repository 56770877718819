import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import moment from 'moment';
import AddParentalLeave from '../../../sub-issues-dialogs/employee/AddParentalLeave';
import Snackbar from '../../../toaster-message/Snackbar';
import { approveParentalLeaveData, getParentalLeaveData, sendForApprovalParentalLeaveData } from '../../../services/sub-issue-services/parental-leave-service/ParentalLeaveService';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNos = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function ParentalLeave(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };
    const [parentalLeaveTabs, setParentalLeaveTabs] = useState('Parental Leave');
    const [parentalLeaveTableData, setParentalLeaveTableData] = useState([]);
    const [openParentalLeave, setOpenParentalLeave] = useState({ action: false, parentalLeaveTableData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getAllParentalLeave()
    }, [parentalLeaveTabs]);

    const getFormDateToDate = (data) => {
        getAllParentalLeave(data)
    };

    const getAllParentalLeave = async (data) => {
        if (parentalLeaveTabs === "Parental Leave") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getParentalLeaveData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.parentalLeaveDataList);
                    setParentalLeaveTableData(filterData)
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (parentalLeaveTabs === "Parental Leave History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getParentalLeaveData(payloadData)
                .then((resp) => {
                    setParentalLeaveTableData(resp.data.parentalLeaveDataList)
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const addParentalLeave = () => {
        setOpenParentalLeave({ action: true, parentalLeaveTableData: null });
    };

    const editParentalLeave = (rowData) => {
        setOpenParentalLeave({ action: true, parentalLeaveTableData: rowData });
    };

    const clickCheckBox = (value) => {
        setOpenParentalLeave({ action: false, parentalLeaveTableData: null });
        if (recordRefNos.includes(value)) {
            // recordRefNos.pop(value);
            recordRefNos = recordRefNos.filter(item => item !== value);
        } else {
            recordRefNos.push(value)
        };
    };

    const sendApprovalParentalLeave = async () => {
        if (recordRefNos.length) {
            await sendForApprovalParentalLeaveData(recordRefNos)
                .then((resp) => {
                    recordRefNos = [];
                    getAllParentalLeave();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const approvedParentalLeave = async () => {
        if (recordRefNos.length) {
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNos
            }
            await approveParentalLeaveData(obj)
                .then((resp) => {
                    recordRefNos = [];
                    getAllParentalLeave();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                })
            // if (recordRefNos.length) {
            //     await approveParentalLeaveData("APPROVED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
            //         .then((resp) => {
            //             recordRefNos = [];
            //             getAllParentalLeave();
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        };
    };

    const rejectParentalLeave = async () => {
        if (recordRefNos.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordRefNos.length) {
            //     await approveParentalLeaveData("REJECTED", userDetails.corporateRefNo, userDetails.facilityRefNo, recordRefNos)
            //         .then((resp) => {
            //             recordRefNos = [];
            //             getAllParentalLeave();
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNos
        }
        await approveParentalLeaveData(obj)
            .then((resp) => {
                recordRefNos = [];
                getAllParentalLeave();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                })
            })
    };

    const handleTabChange = (event, newValue) => {
        setParentalLeaveTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <span className="col-6"><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        <div className="col-6">
                            {usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR" ? null : (
                                <span className="float-end">
                                    <material.Stack direction="row" spacing={1} alignItems="center">
                                        <material.Typography>Not applicable</material.Typography>
                                        <material.Switch {...label} onChange={applicableNotApplicable} checked={applicable} color="secondary" />
                                        <material.Typography>Applicable</material.Typography>
                                    </material.Stack>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="mt-3">
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={parentalLeaveTabs}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Parental Leave" value="Parental Leave" />
                                        <material.Tab className="text-capitalize" label="Parental Leave History" value="Parental Leave History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Parental Leave">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-7">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {parentalLeaveTableData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNos.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approvedParentalLeave}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNos.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectParentalLeave}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendApprovalParentalLeave}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addParentalLeave}>Add Parental Leave</material.Button>
                                            </span>
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Gender</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Entitled to Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Took Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Returned Work After Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Retained 12 Month After Parental Leave</StyledTableCell>
                                                                {/* <StyledTableCell>Retention Rates</StyledTableCell> */}
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {parentalLeaveTableData?.length ? parentalLeaveTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                                        () => editParentalLeave(item) : null}>
                                                                    <material.TableCell>{item.gender}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeEntitledToParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeTookParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeReturnedWorkAfterParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeRetainedTwelveMonthAfterParentalLeave}</material.TableCell>
                                                                    {/* <material.TableCell>{item.retentionRates}</material.TableCell> */}
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Parental Leave History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Gender</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Entitled to Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Took Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Returned Work After  Parental Leave</StyledTableCell>
                                                                <StyledTableCell>Number of Employee Retained 12 Month After  Parental Leave</StyledTableCell>
                                                                {/* <StyledTableCell>Retention Rates</StyledTableCell> */}
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {parentalLeaveTableData?.length ? parentalLeaveTableData.map((item, ind) => (
                                                                <material.TableRow key={ind}>
                                                                    <material.TableCell>{item.gender}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeEntitledToParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeTookParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeReturnedWorkAfterParentalLeave}</material.TableCell>
                                                                    <material.TableCell>{item.noOfEmployeeRetainedTwelveMonthAfterParentalLeave}</material.TableCell>
                                                                    {/* <material.TableCell>{item.retentionRates}</material.TableCell> */}
                                                                    <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) :
                                                                (
                                                                    <material.TableRow >
                                                                        <material.TableCell colSpan={12}>
                                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                        </material.TableCell>
                                                                    </material.TableRow>
                                                                )
                                                            }
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddParentalLeave
                openParentalLeave={openParentalLeave}
                setOpenParentalLeave={setOpenParentalLeave}
                getAllParentalLeave={getAllParentalLeave}
                treeViewData={treeViewData}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getAllParentalLeave={getAllParentalLeave}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default ParentalLeave;
import { rootApiUrl } from "../../../environment/Environment";
import { axiosInstance } from "../../../shared/AxiosConfig";


export const saveOrUpdateEnergyOutsideData = async (energyOutsideData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/energy-consumption-outside/save-or-update`, energyOutsideData)
};

export const getAllEnergyOutsideData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/energy-consumption-outside/get-all`, { params: payload })
};

export const sendForApprovalEnergyOutside = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/energy-consumption-outside/send-for-approval`, recordRefNos)
};

export const approveEnergyOutside = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/energy-consumption-outside/approve`, payload)
};
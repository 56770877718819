import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";

export const saveOrUpdateCoverageOfTraining = async (trainingData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/coverage-of-training/save-or-update`, trainingData)
};

export const getAllCoverageOfTrainingData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/coverage-of-training/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalCoverageOfTraining = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/coverage-of-training/send-for-approval`, recordIds)
};

export const approveCoverageOfTraining = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/coverage-of-training/approve`, payloadData)
};
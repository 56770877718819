import React from 'react';
import { material } from '../../library/Material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useSelector } from 'react-redux';
import moment from 'moment';


var usersRole;

function EmployeeAgeBasedDataDialog(props) {

    const { openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const handleClose = () => {
        setOpenEmployeeAgeBasedDataTable({ action: false, employeeHiringData: null, collForm: "NEW_HIRING" })
    };

    return (
        <>
            <div>
                <material.Dialog open={openEmployeeAgeBasedDataTable.action} PaperProps={{
                    sx: {maxWidth:"60%"}
                }} >
                    <material.DialogTitle id="alert-dialog-title">
                        <div className='row'>
                            <div className='col-10'><h6 className='fw-bold headingText'>Employee Age Based Data</h6></div>
                            <div className='col-2'>
                                <material.Tooltip title="Cancel">
                                    <material.IconButton className=' float-end' onClick={handleClose}>
                                        <material.CloseIcon color='error' />
                                    </material.IconButton>
                                </material.Tooltip>
                            </div>
                        </div>
                    </material.DialogTitle>
                    <material.DialogContent>
                        <material.DialogContentText id="alert-dialog-description">
                            <div className="row">
                                {openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.history === false ? (
                                    <div className="col-12">
                                        <material.TableContainer>
                                            <material.Table>
                                                <material.TableHead>
                                                    <material.TableRow>
                                                        <StyledTableCell>{"Male Under 30"} </StyledTableCell>
                                                        <StyledTableCell>{"Male 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Male Above 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Female Under 30"}</StyledTableCell>
                                                        <StyledTableCell>{"Female 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Female Above 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Other Under 30"}</StyledTableCell>
                                                        <StyledTableCell>{"Other 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Other Above 50"}</StyledTableCell>
                                                        <StyledTableCell>{usersRole === "ROLE_FACILITY_APPROVER" ? null : "Approval Date"}</StyledTableCell>
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    <material.TableRow>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.maleUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.male30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.maleAbove50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.femaleUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.female30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.femaleAbove50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.otherUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.other30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.otherAbove50}</material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.approvedDate ? moment(openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </div>
                                ) : openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.history === true ? (
                                    <div className="col-12">
                                        <material.TableContainer>
                                            <material.Table>
                                                <material.TableHead>
                                                    <material.TableRow>
                                                        <StyledTableCell>{"Male Under 30"} </StyledTableCell>
                                                        <StyledTableCell>{"Male 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Male Above 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Female Under 30"}</StyledTableCell>
                                                        <StyledTableCell>{"Female 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Female Above 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Other Under 30"}</StyledTableCell>
                                                        <StyledTableCell>{"Other 30 to 50"}</StyledTableCell>
                                                        <StyledTableCell>{"Other Above 50"}</StyledTableCell>
                                                        <StyledTableCell>Approval Date</StyledTableCell>
                                                        <StyledTableCell>Status</StyledTableCell>
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    <material.TableRow>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.maleUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.male30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.maleAbove50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.femaleUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.female30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.femaleAbove50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.otherUnder30}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.other30To50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.otherAbove50}</material.TableCell>
                                                        <material.TableCell>{openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.approvedDate ? moment(openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>
                                                            {openEmployeeAgeBasedDataTable?.employeesAgeBasedData?.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                APPROVED
                                                            </span> : null}
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </div>
                                ) : null}
                            </div>
                        </material.DialogContentText>
                    </material.DialogContent>
                </material.Dialog>
            </div>
        </>
    )
}

export default EmployeeAgeBasedDataDialog;
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { material } from '../../library/Material';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateHabitatsProtectedOrRestoredData } from '../../services/sub-issue-services/biodiversity-services/HabitatsProtectedOrRestoredService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';

function AddHabitatsProtectedOrRestored(props) {

    const { openHabitatsProtectedRestored, setOpenHabitatsProtectedRestored, getAllHabitatsProtectedData, treeViewData } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, watch, getValues, setValue, formState: { errors }, } = useForm({ mode: "onTouched" });

    const [habitatProtectedOrRestored, setHabitatProtectedOrRestored] = useState("");
    const [protectOrRestoreHabitatBy, setProtectOrRestoreHabitatBy] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openHabitatsProtectedRestored.action && openHabitatsProtectedRestored.HabitatsProtectedRestoredData) {
            setHabitatProtectedOrRestored(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.habitatProtected);
            setProtectOrRestoreHabitatBy(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.protectHabitatBy);
            reset(openHabitatsProtectedRestored.HabitatsProtectedRestoredData)
        }
    }, [openHabitatsProtectedRestored.action === true])

    const handleClose = () => {
        reset({ sizeOfProtected: "", statusOfProtectedArea: "" })
        setHabitatProtectedOrRestored("");
        setProtectOrRestoreHabitatBy("");
        setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
    };
    const changeHabitatProtectedOrRestored = (event) => {
        setHabitatProtectedOrRestored(event.target.value)
    };
    const changeProtectOrRestoreHabitatBy = (event) => {
        setProtectOrRestoreHabitatBy(event.target.value)
    };
    const saveHabitatsProtectedOrRestored = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "applicable": true,
                "habitatProtected": data.habitatProtected,
                "sizeOfProtected": parseFloat(data.sizeOfProtected),
                "protectHabitatBy": data.protectHabitatBy,
                "statusOfProtectedArea": data.statusOfProtectedArea,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateHabitatsProtectedOrRestoredData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllHabitatsProtectedData();
                    reset({ sizeOfProtected: "", statusOfProtectedArea: "" })
                    setHabitatProtectedOrRestored("");
                    setProtectOrRestoreHabitatBy("");
                    setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateHabitatsProtectedOrRestored = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": openHabitatsProtectedRestored.HabitatsProtectedRestoredData.refNo,
            "applicable": true,
            "habitatProtected": data.habitatProtected,
            "sizeOfProtected": parseFloat(data.sizeOfProtected),
            "protectHabitatBy": data.protectHabitatBy,
            "statusOfProtectedArea": data.statusOfProtectedArea,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateHabitatsProtectedOrRestoredData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllHabitatsProtectedData();
                reset({ sizeOfProtected: "", statusOfProtectedArea: "" })
                setHabitatProtectedOrRestored("");
                setProtectOrRestoreHabitatBy("");
                setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openHabitatsProtectedRestored.HabitatsProtectedRestoredData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllHabitatsProtectedData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ sizeOfProtected: "", statusOfProtectedArea: "" })
                setHabitatProtectedOrRestored("");
                setProtectOrRestoreHabitatBy("");
                setOpenHabitatsProtectedRestored({ action: false, HabitatsProtectedRestoredData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={openHabitatsProtectedRestored.action} maxWidth="md"  fullWidth>
                <material.DialogTitle className='fw-bold headingText'>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? "Edit Habitats Protected or Restored" : "Add Habitats Protected or Restored"}
                </material.DialogTitle>
                <material.DialogContent>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Habitats Protected or Restored</material.Typography>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Habitat Protected or Restored</material.InputLabel>
                                    <material.Select
                                        {...register("habitatProtected", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={habitatProtectedOrRestored}
                                        onChange={changeHabitatProtectedOrRestored}
                                        label="Habitat Protected or Restored"
                                    >
                                        <material.MenuItem value={"Natural Terrestrial"}>Natural Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Modified Terrestrial"}>Modified Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Natural Aquatic"}>Natural Aquatic</material.MenuItem>
                                        <material.MenuItem value={"Modified Aquatic"}>Modified Aquatic</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.TextField
                                    {...register("sizeOfProtected", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Size of Protected or Restored  Area (ha)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.sizeOfProtected && errors.sizeOfProtected.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" >Protect or Restore Habitat by</material.InputLabel>
                                    <material.Select
                                        {...register("protectHabitatBy", { required: true, })}
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={protectOrRestoreHabitatBy}
                                        onChange={changeProtectOrRestoreHabitatBy}
                                        label="Protect or Restore Habitat by"
                                    >
                                        <material.MenuItem value={"Owner"}>Owner</material.MenuItem>
                                        <material.MenuItem value={"Govt. Dept."}>Govt. Dept.</material.MenuItem>
                                        <material.MenuItem value={"Partnerships with 3rd Parties"}>Partnerships with 3rd Parties</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-2'>
                                <material.TextField
                                    {...register("statusOfProtectedArea", { required: true })}
                                    required
                                    label="Status of Protected or Restored Area"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {/* {errors.amount && errors.amount.type === "pattern" && (
                                <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                            )} */}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openHabitatsProtectedRestored.HabitatsProtectedRestoredData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateHabitatsProtectedOrRestored)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openHabitatsProtectedRestored.HabitatsProtectedRestoredData.rejectionNote === null) || (openHabitatsProtectedRestored.HabitatsProtectedRestoredData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveHabitatsProtectedOrRestored)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddHabitatsProtectedOrRestored;
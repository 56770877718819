import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateSecurityPracticesData } from '../../services/sub-issue-services/human-rights-services/SecurityPracticesService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';


function AddSecurityPractices(props) {
    const { addOpenSecurityPractices, setAddOpenSecurityPractices, getSecurityPracticesAllData, treeViewData } = props;
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const [buttonDisable, setButtonDisable] = useState(false);

    const onChangeTotalNumberOfSecurityPersonnelFacility = watch('totalNumberOfSecurityPersonnelFacility');
    const onChangeReceivedTrainingOnHumanRightsFacility = watch('receivedTrainingOnHumanRightsFacility');
    const onChangeSupplyChainAssessedThirdParty = watch('totalNumberOfSecurityPersonnelThirdParty');
    const onChangeReceivedTrainingOnHumanRightsThirdParty = watch('receivedTrainingOnHumanRightsThirdParty');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNumberOfSecurityPersonnelFacility, onChangeReceivedTrainingOnHumanRightsFacility]);

    useEffect(() => {
        totalSecurityThirdPartyValue();
    }, [onChangeReceivedTrainingOnHumanRightsThirdParty, onChangeSupplyChainAssessedThirdParty]);

    const totalCalculation = () => {
        let totalNumberOfSecurityPersonnelFacilityValue = getValues('totalNumberOfSecurityPersonnelFacility');
        let receivedTrainingOnHumanRightsFacilityValue = getValues('receivedTrainingOnHumanRightsFacility');

        if (Number(receivedTrainingOnHumanRightsFacilityValue) > Number(totalNumberOfSecurityPersonnelFacilityValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel(facility) value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    const totalSecurityThirdPartyValue = () => {
        let supplyChainAssessedThirdPartyValue = getValues('totalNumberOfSecurityPersonnelThirdParty');
        let receivedTrainingOnHumanRightsThirdPartyValue = getValues('receivedTrainingOnHumanRightsThirdParty');

        if (Number(receivedTrainingOnHumanRightsThirdPartyValue) > Number(supplyChainAssessedThirdPartyValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Nos. of security personnel received training on human rights facility value not greater than total number of security personnel (third party) value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenSecurityPractices.action && addOpenSecurityPractices.securityPracticesTableData) {
            reset(addOpenSecurityPractices.securityPracticesTableData);
        }
    }, [addOpenSecurityPractices.action === true])

    const saveAddSecurityPractices = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "totalNumberOfSecurityPersonnelFacility": Number(data.totalNumberOfSecurityPersonnelFacility),
                "receivedTrainingOnHumanRightsFacility": Number(data.receivedTrainingOnHumanRightsFacility),
                "totalNumberOfSecurityPersonnelThirdParty": Number(data.totalNumberOfSecurityPersonnelThirdParty),
                "receivedTrainingOnHumanRightsThirdParty": Number(data.receivedTrainingOnHumanRightsThirdParty),
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
            }
            saveOrUpdateSecurityPracticesData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getSecurityPracticesAllData()
                    reset({ totalNumberOfSecurityPersonnelFacility: "", receivedTrainingOnHumanRightsFacility: "", totalNumberOfSecurityPersonnelThirdParty: "", receivedTrainingOnHumanRightsThirdParty: "", })
                    setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAddSecurityPractices = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": addOpenSecurityPractices.securityPracticesTableData.refNo,
            "totalNumberOfSecurityPersonnelFacility": Number(data.totalNumberOfSecurityPersonnelFacility),
            "receivedTrainingOnHumanRightsFacility": Number(data.receivedTrainingOnHumanRightsFacility),
            "totalNumberOfSecurityPersonnelThirdParty": Number(data.totalNumberOfSecurityPersonnelThirdParty),
            "receivedTrainingOnHumanRightsThirdParty": Number(data.receivedTrainingOnHumanRightsThirdParty),
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate,
        }
        saveOrUpdateSecurityPracticesData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getSecurityPracticesAllData()
                reset({ totalNumberOfSecurityPersonnelFacility: "", receivedTrainingOnHumanRightsFacility: "", totalNumberOfSecurityPersonnelThirdParty: "", receivedTrainingOnHumanRightsThirdParty: "", })
                setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ totalNumberOfSecurityPersonnelFacility: "", receivedTrainingOnHumanRightsFacility: "", totalNumberOfSecurityPersonnelThirdParty: "", receivedTrainingOnHumanRightsThirdParty: "", })
        setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenSecurityPractices.securityPracticesTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getSecurityPracticesAllData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ totalNumberOfSecurityPersonnelFacility: "", receivedTrainingOnHumanRightsFacility: "", totalNumberOfSecurityPersonnelThirdParty: "", receivedTrainingOnHumanRightsThirdParty: "", })
                setAddOpenSecurityPractices({ action: false, securityPracticesTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenSecurityPractices.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenSecurityPractices.securityPracticesTableData ? "Edit Security Practices" : "Add Security Practices"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='my-3'>
                        {addOpenSecurityPractices.securityPracticesTableData ? null : (
                            <DatePeriod
                                register={register}
                                control={control}
                            />
                        )}
                    </div>
                    <material.Card elevation={1} sx={{ p: 2, border: 1 }}>
                        <div className='row'>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("totalNumberOfSecurityPersonnelFacility", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Total Number of Security Personnel (Facility)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalNumberOfSecurityPersonnelFacility?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("receivedTrainingOnHumanRightsFacility", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Number of Security Personnel Received Training on Human Rights Facility"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.receivedTrainingOnHumanRightsFacility?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("totalNumberOfSecurityPersonnelThirdParty", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Total Number of Security Personnel (3rd Party)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalNumberOfSecurityPersonnelThirdParty?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-6'>
                                <material.TextField
                                    {...register("receivedTrainingOnHumanRightsThirdParty", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    className='mt-3'
                                    margin="dense"
                                    label="Number of Security Personnel Received Training on Human Rights (3rd Party)"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.receivedTrainingOnHumanRightsThirdParty?.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" onClick={handleClose} sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenSecurityPractices.securityPracticesTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" disabled={buttonDisable} sx={{ textTransform: "none" }} className="me-2" onClick={handleSubmit(updateAddSecurityPractices)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenSecurityPractices.securityPracticesTableData.rejectionNote === null) || (addOpenSecurityPractices.securityPracticesTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" disabled={buttonDisable} sx={{ textTransform: "none" }} className="me-2" onClick={handleSubmit(saveAddSecurityPractices)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div >
    )
}

export default AddSecurityPractices; 
import React, { useState } from 'react';
import { material } from '../../library/Material';
import { NavLink } from 'react-router-dom';
import "./SideBar.css";
import { useSelector, useDispatch } from 'react-redux';
import { sidebarAction, sidebarEsgDataAction } from '../../store/slice/SidebarSlice';
import SidebarTreeView from './SidebarTreeView';

function SideBar(props) {

    const { openDrawer } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const nestedMenuData = useSelector((state) => state.SidebarSlice.data);
    const dispatch = useDispatch();
    // const [openAdministration, setOpenAdministration] = useState(false);
    // const [openEsgData, setOpenEsgData] = useState(false);
    // const [openIssueDashboard, setOpenIssueDashboard]=useState(false);

    const administration = (menuName) => {
        // setOpenAdministration(!openAdministration)
        dispatch(sidebarAction(menuName))
    };
    const esgData = (menuName) => {
        // setOpenEsgData(!openEsgData)
        dispatch(sidebarEsgDataAction(menuName))
    };

    const issueDashboard = (menuName) => {
        // setOpenIssueDashboard(!openIssueDashboard);
        dispatch(sidebarAction(menuName))
    };

    return (
        <div className='side-nav' >
            {userDetails?.roles?.map((role, i) => (
                <div key={i}>
                    {role === "ROLE_ADMIN" ? (
                        <>
                            <material.List>
                                <material.ListItemButton onClick={() => administration("Administration")}>
                                    <material.ListItemIcon>
                                        <material.GroupIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="Administration" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "Administration" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "Administration"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/user-management'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Users<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/corporate-list'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Corporates<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/manage-entity'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Manage Entity<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                        </>
                    ) : role === "ROLE_CORPORATE_EDITOR" ? (
                        <>
                            <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/dashboard'>
                                    <material.DashboardIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Dashboard<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            <material.List>
                                <material.ListItemButton onClick={() => issueDashboard("Issue Dashboard")}>
                                    <material.ListItemIcon>
                                        <material.SpaceDashboardIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="Issue Dashboard" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "Issue Dashboard" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "Issue Dashboard"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/material'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Material<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/energy'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Energy<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/water_and_wastewater'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Water & Wastewater<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/climate_change'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Climate Change<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/air'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Air<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/waste'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Waste<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                            <div>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/corporate'>
                                    <material.CorporateFareIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Corporate<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            <material.List>
                                <material.ListItemButton onClick={() => administration("Administration")}>
                                    <material.ListItemIcon>
                                        <material.GroupIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="Administration" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "Administration" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "Administration"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/user-management'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Users<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/site-list'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Facility<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/manage-entity'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Manage Entity<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                        </>
                        // ) : role === "ROLE_FACILITY_EDITOR" ? (
                        //     <>
                        //         <div className='mt-3'>
                        //             <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/facility'>
                        //                 <material.ApartmentIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Facility<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                        //             </NavLink>
                        //         </div>
                        //     </>
                    ) : role === "ROLE_FACILITY_APPROVER" ? (
                        <>
                            <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/facility'>
                                    <material.ApartmentIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Facility<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            <material.List>
                                <material.ListItemButton onClick={() => esgData("ESG Data")}>
                                    <material.ListItemIcon>
                                        <material.DataUsageIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="ESG Data" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "ESG Data" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "ESG Data"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <material.ListItemButton sx={{ pl: 3, '&:hover': { background: 'none' } }}>
                                            {openDrawer === true ? (<SidebarTreeView />) : null}
                                        </material.ListItemButton>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                        </>
                    ) : role === "ROLE_FACILITY_ESG_DATA" || role === "ROLE_FACILITY_EDITOR" ? (
                        <>
                            <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/dashboard'>
                                    <material.DashboardIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Dashboard<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            <material.List>
                                <material.ListItemButton onClick={() => issueDashboard("Issue Dashboard")}>
                                    <material.ListItemIcon>
                                        <material.SpaceDashboardIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="Issue Dashboard" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "Issue Dashboard" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "Issue Dashboard"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/material'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Material<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/energy'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Energy<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/water_and_wastewater'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Water & Wastewater<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/climate_change'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Climate Change<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/air'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Air<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                        <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/issue-dashboard/waste'>
                                            <material.ListItemButton sx={{ pl: 6 }}>
                                                {openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Waste<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                            </material.ListItemButton>
                                        </NavLink>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                            <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/facility'>
                                    <material.ApartmentIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Facility<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/ranking'>
                                    <material.ShowChartIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Ranking<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div>
                            {/* <div className='mt-3'>
                                <NavLink className={(navData) => (navData.isActive ? 'active-text' : 'inactive-text')} to='/dashboard'>
                                    <material.DashboardIcon sx={{ fontSize: 25, ml: 2 }} />{openDrawer === true ? (<span className='ms-2' style={{ fontSize: "14px", fontWeight: "bold" }}>Dashboard<span className='display-icon'><material.ArrowRightIcon /></span></span>) : null}
                                </NavLink>
                            </div> */}
                            <material.List>
                                <material.ListItemButton onClick={() => esgData("ESG Data")}>
                                    <material.ListItemIcon>
                                        <material.DataUsageIcon sx={{ fontSize: 25 }} />
                                    </material.ListItemIcon>
                                    {openDrawer === true ? <material.ListItemText primary="ESG Data" sx={{ ml: -2 }} /> : null}
                                    {nestedMenuData === "ESG Data" ? <material.ArrowDropUpIcon /> : <material.ArrowDropDownIcon />}
                                </material.ListItemButton>
                                <material.Collapse in={nestedMenuData === "ESG Data"} timeout="auto" unmountOnExit>
                                    <material.List component="div" disablePadding>
                                        <material.ListItemButton sx={{ pl: 3 }}>
                                            {openDrawer === true ? (<SidebarTreeView />) : null}
                                        </material.ListItemButton>
                                    </material.List>
                                </material.Collapse>
                            </material.List>
                        </>
                    ) : null}
                </div>
            ))}
        </div>
    );
}

export default SideBar;
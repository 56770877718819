import React, { useState, useEffect } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { saveOrUpdateWaterConsumptionSupplierData } from '../services/sub-issue-services/WaterConsumptionSupplierService';
import Snackbar from '../toaster-message/Snackbar';



function AddWaterConsumption(props) {
    const { openAddWaterConsumption, setOpenAddWaterConsumption, waterConsumptionSupplierData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, formState: { errors }, } = useForm();
    const [selectWithdrawalOrDischarge, setSelectWithdrawalOrDischarge] = useState('');
    const [selectSource, setSelectSource] = useState('');
    const [selectUnit, setSelectUnit] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });


    useEffect(() => {
        if (openAddWaterConsumption.action && openAddWaterConsumption.waterConsumptionData) {
            reset(openAddWaterConsumption.waterConsumptionData)
            setSelectWithdrawalOrDischarge(openAddWaterConsumption.waterConsumptionData.type)
            setSelectSource(openAddWaterConsumption.waterConsumptionData.source)
            setSelectUnit(openAddWaterConsumption.waterConsumptionData.unit)
        }
    }, [openAddWaterConsumption.action === true]);

    const saveWaterConsumption = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "type": data.type,
            "source": data.source,
            "amount": data.amount,
            "unit": data.unit,
            "applicable": true,
            "refNo": openAddWaterConsumption.waterConsumptionData ? openAddWaterConsumption.waterConsumptionData.refNo : ""
        }
        saveOrUpdateWaterConsumptionSupplierData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                waterConsumptionSupplierData();
                reset({ amount: "" });
                setSelectWithdrawalOrDischarge("");
                setSelectSource("");
                setSelectUnit("");
                setOpenAddWaterConsumption({ action: false, waterConsumptionData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ amount: "" });
        setSelectWithdrawalOrDischarge("");
        setSelectSource("");
        setSelectUnit("")
        setOpenAddWaterConsumption({ action: false, waterConsumptionData: null })
    };

    const changeWithdrawalOrDischarge = (event) => {
        setSelectWithdrawalOrDischarge(event.target.value)
    };
    const changeSource = (event) => {
        setSelectSource(event.target.value)
    };
    const changeUnit = (event) => {
        setSelectUnit(event.target.value)
    };

    return (
        <div>
            <material.Dialog open={openAddWaterConsumption.action} maxWidth="sm" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddWaterConsumption.waterConsumptionData ? "Edit Water Consumption" : "Add Water Consumption"}</material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <material.Box>
                            <material.FormControl
                                required
                                fullWidth
                                variant="standard"
                            >
                                <material.InputLabel id="demo-simple-select-standard" variant="standard">Withdrawal or Discharge</material.InputLabel>
                                <material.Select
                                    {...register("type", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // inputProps={openAddWaterConsumption.waterConsumptionData ? { readOnly: true } : null}
                                    value={selectWithdrawalOrDischarge}
                                    onChange={changeWithdrawalOrDischarge}
                                >
                                    <material.MenuItem value="Water withdrawal">Water withdrawal</material.MenuItem>
                                    <material.MenuItem value="Water Discharge">Water Discharge</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </material.Box>
                    </div>
                    <div className="mt-2">
                        <material.Box>
                            <material.FormControl required fullWidth variant="standard">
                                <material.InputLabel id="demo-simple-select-standard" variant="standard">Source</material.InputLabel>
                                <material.Select
                                    {...register("source", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // inputProps={openAddWaterConsumption.waterConsumptionData ? { readOnly: true } : null}
                                    value={selectSource}
                                    onChange={changeSource}

                                >
                                    <material.MenuItem value="Total Water withdrawal From water Stress Area">Total Water withdrawal From water Stress Area</material.MenuItem>
                                    <material.MenuItem value="Total Water Withdrawal From Water Stress Area">Total Water Withdrawal From Water Stress Area</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </material.Box>
                    </div>
                    <div className="mt-2">
                        <material.TextField
                            // {...register("amount", { required: true })}
                            fullWidth
                            required
                            variant="standard"
                            id="demo-simple-select-standard"
                            label="Amount"
                            type="number"
                            {...register("amount", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                        />
                        {errors.amount && errors.amount.type === "pattern" && (
                            <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                        )}
                    </div>
                    <div className="mt-2">
                        <material.Box>
                            <material.FormControl required fullWidth variant="standard">
                                <material.InputLabel id="demo-simple-select-standard" variant="standard">Unit</material.InputLabel>
                                <material.Select
                                    {...register("unit", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectUnit}
                                    onChange={changeUnit}
                                >
                                    <material.MenuItem value="KL">KL</material.MenuItem>
                                    <material.MenuItem value="ML">ML</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </material.Box>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="outlined" color="error" onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="outlined" color="success" className="me-2" onClick={handleSubmit(saveWaterConsumption)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddWaterConsumption;


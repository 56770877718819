import React, { useState } from 'react';
import { material } from '../library/Material';
import { StyledTableCell } from './TableHeaderStyle';
import GenOperation from '../dialogs/GenOperation';
import RankingDialog from '../dialogs/RankingDialog';
import { useSelector } from 'react-redux';

var usersRole;

function Table(props) {
    const { columns, fields, callFrom, getCorporateWithFacilities, actions, getScoringData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const [openDialog, setOpenDialog] = useState({ action: false, data: "" });
    const [openRankingDialog, setOpenRankingDialog] = useState({ action: false, data: "" });
    const editGenOperation = (data) => {
        if (callFrom === "RANKING") {
            setOpenRankingDialog({ action: true, data: data })
        } else {
            setOpenDialog({ action: true, data: data })
        }
    }

    return (
        <div>
            <material.TableContainer >
                <material.Table>
                    <material.TableHead>
                        <material.TableRow >
                            {columns.map((column, cInd) =>
                                <StyledTableCell size='small' key={cInd}>{column.header}</StyledTableCell>
                            )}
                            {actions?.length ? (
                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>Action</StyledTableCell>
                            ) : (<StyledTableCell></StyledTableCell>)}
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {fields.length ? fields.map((item, rowIndex) => (
                            <material.TableRow key={rowIndex}>
                                {columns.map((column, colIndex) => (

                                    <material.TableCell key={colIndex} className='text-break' size='small' style={{ width: "25rem" }}>
                                        {item[column.key]}</material.TableCell>
                                ))}
                                <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                    {fields.length ? actions?.map((action, actindx) => (
                                        <span key={actindx}>
                                            {action.label === "Edit" ? (
                                                <material.IconButton title='Edit' aria-label="create" size="large" onClick={() => editGenOperation(item)}>
                                                    <material.CreateIcon color='primary' />
                                                </material.IconButton>
                                            ) : null}
                                        </span>
                                    )) : null}
                                </material.TableCell>
                            </material.TableRow>
                        )) : null}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
            <GenOperation
                callFrom={callFrom}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                getCorporateWithFacilities={getCorporateWithFacilities}
            />
            <RankingDialog
                openRankingDialog={openRankingDialog}
                setOpenRankingDialog={setOpenRankingDialog}
                getScoringData={getScoringData}
            />
        </div>
    );
}

export default Table;
import { createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const initialState = {
    isLoggedIn: false,
    data: {}
};


const logoutSlice = createSlice({
    name: "logout",
    initialState,
    reducers: {
        logoutAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: false,
                data: action.payload,
            };
            sessionStorage.removeItem("main-root");
            localStorage.removeItem("main-root");
            return loginAuthState
        }
    },
});

export const { logoutAction } = logoutSlice.actions;
export default logoutSlice.reducer;
import React, { useEffect, useState } from 'react';
import { material } from '../library/Material';
import { useForm } from 'react-hook-form';
import { getAllCorporate } from '../services/CorporateService';
import { addUsers } from '../services/UsermanagementService';
import { getSitesByCorporateRefNo } from '../services/SiteService';
import { useSelector } from 'react-redux';
import Snackbar from '../toaster-message/Snackbar';

var corporateData;
var corporateRefNo = [];
var siteData = [];
var refNo = [];
var usersRole;

function AddUser(props) {
    const { openAddUser, setOpenAddUser, getUserList } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [role, setRole] = useState("");
    const [userType, setUserType] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState("password");
    const [corporateName, setCorporateName] = useState([]);
    const [siteName, setSiteName] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };

    useEffect(() => {
        if (usersRole === "ROLE_CORPORATE_EDITOR" && openAddUser.action === true) {
            getSiteDataByCorporateRefNo()
        } else if (usersRole === "ROLE_ADMIN" && openAddUser.action === true) {
            getAllCorporateData();
            if (openAddUser.callFrom === "corporateList") {
                getSiteDataByCorporateRefNo();
            }
        };
    }, [openAddUser.action === true]);

    const getAllCorporateData = async () => {
        await getAllCorporate()
            .then((resp) => {
                corporateData = resp.data;
                let activeCorporate = resp.data.filter((ele) => ele.active === true);
                let corporateName = activeCorporate?.map((ele) => ele.corporateName).map((element, i) => {
                    if (element !== null) {
                        return element
                    } else if (element === null) {
                        return element = "No Name" + i
                    }
                })
                setCorporateName(corporateName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const getSiteDataByCorporateRefNo = async () => {
        await getSitesByCorporateRefNo(usersRole === "ROLE_ADMIN" ? openAddUser.corporateData.refNo : userDetails.corporateRefNo)
            .then((resp) => {
                siteData = resp.data;
                let sitesName = resp.data?.map((ele) => ele.assetName);
                setSiteName(sitesName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const selectCorporate = async (e, value) => {
        corporateRefNo = corporateData?.filter((ele) => ele.corporateName === value).map((res) => res.refNo);
    };

    const selectSite = (e, value) => {
        refNo = siteData?.filter((ele) => ele.assetName === value).map((res) => res.refNo);
    };

    const selectRole = (event) => {
        setRole(event.target.value)
        // userRole.push(event.target.value)
    };

    const saveUser = async (formData) => {
        let obj;
        if (openAddUser.callFrom === "userManagement") {
            obj = {
                name: formData.name,
                username: formData.userName,
                email: formData.email,
                password: formData.password,
                role: [formData.role],
                corporateRefNo: usersRole === "ROLE_ADMIN" ? corporateRefNo[0] : userDetails.corporateRefNo,
                siteRefNo: refNo?.length ? refNo[0] : "",
                active: true
            }
        } else if (openAddUser.callFrom === "corporateList") {
            obj = {
                name: formData.name,
                username: formData.userName,
                email: formData.email,
                password: formData.password,
                role: [formData.role],
                corporateRefNo: openAddUser.corporateData.refNo,
                siteRefNo: refNo?.length ? refNo[0] : "",
                active: true
            }
        } else if (openAddUser.callFrom === "siteList") {
            obj = {
                name: formData.name,
                username: formData.userName,
                email: formData.email,
                password: formData.password,
                role: [formData.role],
                corporateRefNo: userDetails.corporateRefNo,
                siteRefNo: openAddUser.siteData.refNo,
                active: true
            }
        };
        await addUsers(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.message,
                })
                if (openAddUser.callFrom === "userManagement") {
                    getUserList()
                }
                reset({ 'userName': "", "name": "", 'email': "", 'role': "", 'password': "", 'corporateName': "" })
                setOpenAddUser({ "action": false, corporateData: "", callFrom: "" })
                setRole("");
                setUserType("");
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message":error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ 'userName': "", "name": "", 'email': "", 'role': "", 'password': "", 'corporateName': "" })
        setOpenAddUser({ "action": false, corporateData: "", callFrom: "" })
        setRole("");
        setUserType("")
    };

    function showPassword() {
        setIsVisible(!isVisible)
        setType("text")
    };

    function hidePassword() {
        setIsVisible(!isVisible)
        setType("password")
    };

    const selectUserType = (event) => {
        setUserType(event.target.value)
        // console.log()
    };


    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth open={openAddUser.action}>
                {openAddUser.callFrom === "siteList" ? (
                    <material.DialogTitle className='fw-bold headingText'>Add user for {openAddUser.siteData.assetName}</material.DialogTitle>
                ) : openAddUser.callFrom === "corporateList" ? (
                    <material.DialogTitle className='fw-bold headingText'>Add user for {openAddUser.corporateData.corporateName}</material.DialogTitle>
                ) : (
                    <material.DialogTitle className='fw-bold headingText'>{usersRole === "ROLE_ADMIN" ? "Add user" : `Add user for : ${userDetails.corporateName}`}</material.DialogTitle>
                )}
                <material.DialogContent>
                    <form>
                        <div>
                            <material.TextField
                                {...register("name", { required: true, })}
                                error={errors.userName?.type === "required"}
                                margin="dense"
                                required
                                label="Name"
                                id="userName"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.name?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div>
                            <material.TextField
                                {...register("userName", { required: true, })}
                                error={errors.userName?.type === "required"}
                                margin="dense"
                                required
                                label="User Name"
                                id="userName"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.userName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div>
                            <material.TextField
                                {...register("email", { required: true, })}
                                error={errors.email?.type === "required"}
                                margin="dense"
                                required
                                label="Email"
                                id="email"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div>
                            <material.TextField
                                {...register("password", { required: true })}
                                error={errors.password?.type === "required"}
                                margin="dense"
                                required
                                label="Password"
                                id="password"
                                variant="standard"
                                type={type}
                                size="small"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <material.InputAdornment position="end">
                                            {isVisible === false ? (<material.VisibilityIcon onClick={showPassword} sx={{ cursor: "pointer" }} />) : (<material.VisibilityOffIcon onClick={hidePassword} sx={{ cursor: "pointer" }} />)}
                                        </material.InputAdornment>
                                    ),
                                }}
                            />
                            <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                        </div>
                        {usersRole === "ROLE_ADMIN" ? (
                            <div>
                                {openAddUser.callFrom === "siteList" || openAddUser.callFrom === "corporateList" ? null : (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel id="userType">User Type</material.InputLabel>
                                        <material.Select
                                            {...register("userType")}
                                            error={errors.userType?.type === "required"}
                                            margin="dense"
                                            id="userType"
                                            value={userType}
                                            onChange={selectUserType}
                                        >
                                            <material.MenuItem value="ADMIN">ADMIN</material.MenuItem>
                                            <material.MenuItem value="LENDER">LENDER</material.MenuItem>
                                            <material.MenuItem value="CORPORATE">CORPORATE</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.userType?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                )}
                            </div>
                        ) : null}
                        {usersRole === "ROLE_CORPORATE_EDITOR" || (usersRole === "ROLE_ADMIN" && openAddUser.callFrom === "corporateList") ? (
                            <>
                                {openAddUser.callFrom === "siteList" || openAddUser.callFrom === "corporateList" ? null : (
                                    <div>
                                        <material.Autocomplete
                                            margin="dense"
                                            id="sitesName"
                                            onChange={selectSite}
                                            options={siteName}
                                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Facility Name"
                                                fullWidth
                                                required
                                                {...register("sitesName", { required: true })}
                                                error={errors.sitesName?.type === "required"}
                                            />}
                                        />
                                        <p className='form-text text-danger'>{errors.sitesName?.type === "required" && 'This field is required'}</p>
                                    </div>
                                )}
                            </>
                        ) : usersRole === "ROLE_ADMIN" ? (
                            <>
                                {openAddUser.callFrom === "corporateList" || userType === "ADMIN" || userType === "LENDER" ? null : (
                                    <div>
                                        <material.Autocomplete
                                            margin="dense"
                                            id="treatment"
                                            onChange={selectCorporate}
                                            options={corporateName}
                                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Corporate Name"
                                                fullWidth
                                                required
                                                {...register("corporateName", { required: true })}
                                                error={errors.corporateName?.type === "required"}
                                            />}
                                        />
                                        <p className='form-text text-danger'>{errors.corporateName?.type === "required" && 'This field is required'}</p>
                                    </div>
                                )}
                            </>
                        ) : null}
                        {usersRole === "ROLE_CORPORATE_EDITOR" ? (
                            <div>
                                {openAddUser.callFrom === "siteList" ? (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel required id="role">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true })}
                                            error={errors.role?.type === "required"}
                                            margin="dense"
                                            id="role"
                                            value={role}
                                            onChange={selectRole}
                                        >
                                            <material.MenuItem value="ROLE_FACILITY_EDITOR">FACILITY EDITOR</material.MenuItem>
                                            <material.MenuItem value="ROLE_FACILITY_APPROVER">FACILITY APPROVER</material.MenuItem>
                                            <material.MenuItem value="ROLE_FACILITY_ESG_DATA">FACILITY ESG DATA</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                ) : (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel required id="role">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true })}
                                            error={errors.role?.type === "required"}
                                            margin="dense"
                                            id="role"
                                            value={role}
                                            onChange={selectRole}
                                        >
                                            <material.MenuItem value="ROLE_FACILITY_EDITOR">FACILITY EDITOR</material.MenuItem>
                                            <material.MenuItem value="ROLE_FACILITY_APPROVER">FACILITY APPROVER</material.MenuItem>
                                            <material.MenuItem value="ROLE_FACILITY_ESG_DATA">FACILITY ESG DATA</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                )}
                            </div>
                        ) : usersRole === "ROLE_ADMIN" ? (
                            <div>
                                {userType === "ADMIN" ? (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel required id="role">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true })}
                                            error={errors.role?.type === "required"}
                                            margin="dense"
                                            id="role"
                                            value={role}
                                            onChange={selectRole}
                                        >
                                            <material.MenuItem value="ROLE_ADMIN">ADMIN</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                ) : userType === "LENDER" ? (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel required id="role">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true })}
                                            error={errors.role?.type === "required"}
                                            margin="dense"
                                            id="role"
                                            value={role}
                                            onChange={selectRole}
                                        >
                                            <material.MenuItem value="ROLE_LENDER">LENDER</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                ) : (
                                    <material.FormControl variant="standard" fullWidth>
                                        <material.InputLabel required id="role">Role</material.InputLabel>
                                        <material.Select
                                            {...register("role", { required: true })}
                                            error={errors.role?.type === "required"}
                                            margin="dense"
                                            id="role"
                                            value={role}
                                            onChange={selectRole}
                                        >
                                            <material.MenuItem value="ROLE_CORPORATE_EDITOR">CORPORATE EDITOR</material.MenuItem>
                                            <material.MenuItem value="ROLE_CORPORATE_APPROVER">CORPORATE APPROVER</material.MenuItem>
                                        </material.Select>
                                        <p className='form-text text-danger'>{errors.role?.type === "required" && 'This field is required'}</p>
                                    </material.FormControl>
                                )}
                            </div>
                        ) : null}
                    </form>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button color='success' variant="outlined" onClick={handleSubmit(saveUser)} sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div >
    );
}

export default AddUser;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateSocialScreeningData } from '../../services/sub-issue-services/social-screening-service/SocialScreeningService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddSocialScreening(props) {
    const { addOpenSocialScreening, setAddOpenSocialScreening, getAllSocialScreeningData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, watch, getValues, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const onChangeTotalNoOfSuppliers = watch('totalNoOfSuppliers');
    const onChangeSuppliersScreenedUsingSocialCriteria = watch('suppliersScreenedUsingSocialCriteria');
    const onChangeSuppliersAssessedForSocialImpact = watch('suppliersAssessedForSocialImpact');
    const onChangeSuppliersHavingSignificantNegativeImpact = watch('suppliersHavingSignificantNegativeImpact');
    const onChangeCorrectiveMeasureGivenToSupplier = watch('correctiveMeasureGivenToSupplier');
    const onChangeRelationshipTerminatedForNegativeSocialImpact = watch('relationshipTerminatedForNegativeSocialImpact');

    useEffect(() => {
        totalCalculation();
    }, [onChangeTotalNoOfSuppliers, onChangeSuppliersScreenedUsingSocialCriteria, onChangeSuppliersAssessedForSocialImpact, onChangeSuppliersHavingSignificantNegativeImpact, onChangeCorrectiveMeasureGivenToSupplier, onChangeRelationshipTerminatedForNegativeSocialImpact]);

    const totalCalculation = () => {
        let totalNoOfSuppliersValue = getValues('totalNoOfSuppliers');
        let suppliersScreenedUsingSocialCriteriaValue = getValues('suppliersScreenedUsingSocialCriteria');
        let suppliersAssessedForSocialImpactValue = getValues('suppliersAssessedForSocialImpact');
        let suppliersHavingSignificantNegativeImpactValue = getValues('suppliersHavingSignificantNegativeImpact');
        let correctiveMeasureGivenToSupplierValue = getValues('correctiveMeasureGivenToSupplier');
        let relationshipTerminatedForNegativeSocialImpactValue = getValues('relationshipTerminatedForNegativeSocialImpact');

        if (Number(suppliersScreenedUsingSocialCriteriaValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers screened using social Criteria value not greater than total no. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersAssessedForSocialImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers assessed for social impact value not greater than total no. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(suppliersHavingSignificantNegativeImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Suppliers having significant negative impact value not greater than total no. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(correctiveMeasureGivenToSupplierValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Corrective measure given to supplier value not greater than total no. of suppliers value",
            })
            setButtonDisable(true)
        } else if (Number(relationshipTerminatedForNegativeSocialImpactValue) > Number(totalNoOfSuppliersValue)) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Relationship terminated for negative social impact value not greater than total no. of suppliers value",
            })
            setButtonDisable(true)
        } else {
            setButtonDisable(false)
        }
    };

    useEffect(() => {
        if (addOpenSocialScreening.action && addOpenSocialScreening.socialScreeningTableData) {
            reset(addOpenSocialScreening.socialScreeningTableData);
        }
    }, [addOpenSocialScreening.action === true]);

    const saveSocialScreening = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": "",
                "totalNoOfSuppliers": Number(data.totalNoOfSuppliers),
                "suppliersScreenedUsingSocialCriteria": Number(data.suppliersScreenedUsingSocialCriteria),
                "suppliersAssessedForSocialImpact": Number(data.suppliersAssessedForSocialImpact),
                "suppliersHavingSignificantNegativeImpact": Number(data.suppliersHavingSignificantNegativeImpact),
                "correctiveMeasureGivenToSupplier": Number(data.correctiveMeasureGivenToSupplier),
                "relationshipTerminatedForNegativeSocialImpact": Number(data.relationshipTerminatedForNegativeSocialImpact),
                "applicable": true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateSocialScreeningData(object)
                .then((resp) => {
                    getAllSocialScreeningData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ totalNoOfSuppliers: "", suppliersScreenedUsingSocialCriteria: "", suppliersAssessedForSocialImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeSocialImpact: "", })
                    setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateSocialScreening = async (data) => {
        let object = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            "refNo": addOpenSocialScreening.socialScreeningTableData.refNo,
            "totalNoOfSuppliers": Number(data.totalNoOfSuppliers),
            "suppliersScreenedUsingSocialCriteria": Number(data.suppliersScreenedUsingSocialCriteria),
            "suppliersAssessedForSocialImpact": Number(data.suppliersAssessedForSocialImpact),
            "suppliersHavingSignificantNegativeImpact": Number(data.suppliersHavingSignificantNegativeImpact),
            "correctiveMeasureGivenToSupplier": Number(data.correctiveMeasureGivenToSupplier),
            "relationshipTerminatedForNegativeSocialImpact": Number(data.relationshipTerminatedForNegativeSocialImpact),
            "applicable": true,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateSocialScreeningData(object)
            .then((resp) => {
                getAllSocialScreeningData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ totalNoOfSuppliers: "", suppliersScreenedUsingSocialCriteria: "", suppliersAssessedForSocialImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeSocialImpact: "", })
                setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ totalNoOfSuppliers: "", suppliersScreenedUsingSocialCriteria: "", suppliersAssessedForSocialImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeSocialImpact: "", })
        setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenSocialScreening.socialScreeningTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllSocialScreeningData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ totalNoOfSuppliers: "", suppliersScreenedUsingSocialCriteria: "", suppliersAssessedForSocialImpact: "", suppliersHavingSignificantNegativeImpact: "", correctiveMeasureGivenToSupplier: "", relationshipTerminatedForNegativeSocialImpact: "", })
                setAddOpenSocialScreening({ action: false, socialScreeningTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenSocialScreening.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold headingText'>{addOpenSocialScreening.socialScreeningTableData ? "Edit Social Screening" : "Add Social Screening"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenSocialScreening.socialScreeningTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Social Screening</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("totalNoOfSuppliers", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Total Number of Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.totalNoOfSuppliers && errors.totalNoOfSuppliers.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersScreenedUsingSocialCriteria", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Screened Using Social Criteria"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersScreenedUsingSocialCriteria && errors.suppliersScreenedUsingSocialCriteria.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersAssessedForSocialImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Assessed for Social Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersAssessedForSocialImpact && errors.suppliersAssessedForSocialImpact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("suppliersHavingSignificantNegativeImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Supplier Having Significant Negative Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.suppliersHavingSignificantNegativeImpact && errors.suppliersHavingSignificantNegativeImpact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("correctiveMeasureGivenToSupplier", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    margin="dense"
                                    label="Corrective Measure Given to Supplier"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.correctiveMeasureGivenToSupplier && errors.correctiveMeasureGivenToSupplier.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("relationshipTerminatedForNegativeSocialImpact", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Relationship Terminated for Negative Social Impact"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.relationshipTerminatedForNegativeSocialImpact && errors.relationshipTerminatedForNegativeSocialImpact.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none" }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenSocialScreening.socialScreeningTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none" }} className="me-2" disabled={buttonDisable} onClick={handleSubmit(updateSocialScreening)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenSocialScreening.socialScreeningTableData.rejectionNote === null) || (addOpenSocialScreening.socialScreeningTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" sx={{ textTransform: "none" }} color="success" className="me-2" disabled={buttonDisable} onClick={handleSubmit(saveSocialScreening)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddSocialScreening;
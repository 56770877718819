import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: ""
};

const treeViewPillarSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        treeViewPillarAction: (state = initialState, action) => {
            const treeViewState = {
                data: action.payload,
            };
            return treeViewState;
        },
    },
});

export const { treeViewPillarAction } = treeViewPillarSlice.actions;
export default treeViewPillarSlice.reducer;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import Snackbar from '../../../toaster-message/Snackbar';
import moment from 'moment';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import AddSecurityPractices from '../../../sub-issues-dialogs/security-practices/AddSecurityPractices';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import { approveSecurityPractices, getSecurityPracticesData, sendForApprovalSecurityPractices } from '../../../services/sub-issue-services/human-rights-services/SecurityPracticesService';
import { useSelector } from 'react-redux';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';


var recordRefNo = [];
var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };

function SecurityPractices(props) {
    const location = useLocation();
    const treeViewData = location?.state;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i];
    };

    const [securityPracticesTabs, setSecurityPracticesTabs] = useState('Security Practices');
    const [securityPracticesTableData, setSecurityPracticesTableData] = useState([]);
    const [addOpenSecurityPractices, setAddOpenSecurityPractices] = useState({ action: false, securityPracticesTableData: null });
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getSecurityPracticesAllData()
    }, [securityPracticesTabs]);

    const getFormDateToDate = (data) => {
        getSecurityPracticesAllData(data)
    };

    const getSecurityPracticesAllData = async (data) => {
        if (securityPracticesTabs === "Security Practices") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getSecurityPracticesData(payloadData)
                .then((resp) => {
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.securityPracticesDataDtoList);
                    setSecurityPracticesTableData(filterData);
                    setApplicable(resp.data.applicable);
                    setApplicableRefNo(resp.data.applicableRefNo)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else if (securityPracticesTabs === "Security Practices History") {
            let forApproval = true;
            let approveHistory = true;
            let payloadData = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getSecurityPracticesData(payloadData)
                .then((resp) => {
                    setSecurityPracticesTableData(resp.data.securityPracticesDataDtoList);
                    setApplicable(resp.data.applicable);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        };
    };

    const addSecurityPractices = () => {
        setAddOpenSecurityPractices({ action: true, securityPracticesTableData: null });
    };
    const editSecurityPractices = (rowData) => {
        setAddOpenSecurityPractices({ action: true, securityPracticesTableData: rowData });
    };

    const sendForApproval = async () => {
        if (recordRefNo.length) {
            await sendForApprovalSecurityPractices(recordRefNo)
                .then((resp) => {
                    recordRefNo = [];
                    getSecurityPracticesAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const handleChangeTabs = (event, newValue) => {
        setSecurityPracticesTabs(newValue);
    };
    const applicableNotApplicable = () => {
        setOpenWarningDialog(true);
    };

    const approve = async () => {
        if (recordRefNo.length) {
            let object = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo
            }
            await approveSecurityPractices(object)
                .then((resp) => {
                    recordRefNo = [];
                    getSecurityPracticesAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        }
    };

    const reject = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
        };
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    const getRejectionNoteData = async (noteData) => {
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo
        }
        await approveSecurityPractices(obj)
            .then((resp) => {
                recordRefNo = [];
                getSecurityPracticesAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const clickCheckBox = (value) => {
        setAddOpenSecurityPractices({ action: false, HabitatsProtectedRestoredData: null });
        if (recordRefNo.includes(value)) {
            // recordRefNo.pop(value);
            recordRefNo = recordRefNo.filter(item => item !== value);
        }
        else {
            recordRefNo.push(value)
        }
    };

    return (
        <>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className="row">
                        <div className="col-6">
                            <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className="col-6">
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary" checked={applicable} onChange={applicableNotApplicable} />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <material.Box sx={{ width: '100%', typography: 'body1' }}>
                        <material.TabContext value={securityPracticesTabs}>
                            <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                    <material.Tab className='text-capitalize' label="Security Practices" value="Security Practices" />
                                    <material.Tab className='text-capitalize' label="Security Practices History" value="Security Practices History" />
                                </material.TabList>
                            </material.Box>
                            <material.TabPanel value="Security Practices">
                                {/* <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? (
                                            <span className="float-end">
                                                {securityPracticesTableData.length ? (
                                                    <>
                                                        <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} disabled={!applicable} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                            </span>
                                        ) : (
                                            <span className="float-end">
                                                <material.Button variant="contained" color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                                <material.Button variant="contained" color="success" disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSecurityPractices}>Add Security Practices</material.Button>
                                            </span>
                                        )}
                                    </div>
                                </div> */}
                                <div className="row mt-2 align-items-center">
                                    <div className="col-lg-7">
                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        )}
                                    </div>
                                    <div className="col-lg-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                        <span className='float-end'>
                                            {securityPracticesTableData?.length ? (
                                                <>
                                                    <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approve}>Approve</material.Button>
                                                    <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={reject}>Reject</material.Button>
                                                </>
                                            ) : null}
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApproval}>Send for Approval</material.Button>
                                            <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSecurityPractices}>Add Freedom Association</material.Button>
                                        </span>
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (Inside)</StyledTableCell>
                                                    <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (3rd Party)</StyledTableCell>
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>From Date</StyledTableCell>
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>To Date</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>Approval Date</StyledTableCell>
                                                    )}
                                                    <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }} hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                        <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>Rejection Note</StyledTableCell>
                                                    )}
                                                </material.TableRow>
                                                <material.TableRow>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                    <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {securityPracticesTableData?.length ? securityPracticesTableData.map((item, ind) => (
                                                    <material.TableRow key={ind}
                                                        sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                        onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (item.sendForApproval === null || item.sendForApproval === false) && applicable === true) ?
                                                            () => editSecurityPractices(item) : null}>
                                                        <material.TableCell align='center'>{item.totalNumberOfSecurityPersonnelFacility}</material.TableCell>
                                                        <material.TableCell align='center'>{item.receivedTrainingOnHumanRightsFacility}</material.TableCell>
                                                        <material.TableCell align='center'>{item.totalNumberOfSecurityPersonnelThirdParty}</material.TableCell>
                                                        <material.TableCell align='center'>{item.receivedTrainingOnHumanRightsThirdParty}</material.TableCell>
                                                        <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell align='center'>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                        )}
                                                        <material.TableCell align='center' hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                            {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                <material.Checkbox {...label} disabled={!applicable || item.sendForApproval} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            ) : (
                                                                <material.Checkbox {...label} disabled={!applicable || item.approvedStatus === "APPROVED"} onChange={() => clickCheckBox(item.refNo)} color="success" />
                                                            )}
                                                        </material.TableCell>
                                                        {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                            <material.TableCell align='center'>{(item.rejectionNote === null) || (item.rejectionNote === "") ? "No" : (
                                                                <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(item) }}>Yes</material.Link>
                                                            )}
                                                            </material.TableCell>
                                                        )}
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={12}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </material.TabPanel>
                            <material.TabPanel value="Security Practices History">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <DateFilter
                                            getFormDateToDate={getFormDateToDate}
                                        />
                                    </div>
                                </div>
                                <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                    <div className='row'>
                                        <div className="col-12">
                                            <material.TableContainer>
                                                <material.Table>
                                                    <material.TableHead>
                                                        <material.TableRow >
                                                            <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (Inside)</StyledTableCell>
                                                            <StyledTableCell colSpan={2} align='center' sx={{ border: 1 }}>Security Personnel (3rd Party)</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>From Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} align='center' sx={{ border: 1 }}>To Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} sx={{ border: 1 }}>Approval Date</StyledTableCell>
                                                            <StyledTableCell rowSpan={2} sx={{ border: 1 }}>Status</StyledTableCell>
                                                        </material.TableRow>
                                                        <material.TableRow>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Total Number</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ border: 1 }}>Number Received Training on Human Rights</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {securityPracticesTableData?.length ? securityPracticesTableData.map((item, ind) => (
                                                            <material.TableRow key={ind}>
                                                                <material.TableCell align='center'>{item.totalNumberOfSecurityPersonnelFacility}</material.TableCell>
                                                                <material.TableCell align='center'>{item.receivedTrainingOnHumanRightsFacility}</material.TableCell>
                                                                <material.TableCell align='center'>{item.totalNumberOfSecurityPersonnelThirdParty}</material.TableCell>
                                                                <material.TableCell align='center'>{item.receivedTrainingOnHumanRightsThirdParty}</material.TableCell>
                                                                <material.TableCell>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell align='center'>{item.approvedDate ? moment(item.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                <material.TableCell align='center'>
                                                                    {item.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                        APPROVED
                                                                    </span> : null}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) :
                                                            (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )
                                                        }
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </div>
                                    </div>
                                </material.Paper>
                            </material.TabPanel>
                        </material.TabContext>
                    </material.Box>
                </material.Box>
            </material.Box>
            <AddSecurityPractices
                addOpenSecurityPractices={addOpenSecurityPractices}
                setAddOpenSecurityPractices={setAddOpenSecurityPractices}
                getSecurityPracticesAllData={getSecurityPracticesAllData}
                treeViewData={treeViewData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getSecurityPracticesAllData={getSecurityPracticesAllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </>
    );
}

export default SecurityPractices;
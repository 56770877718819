import axios from "axios";
import { rootApiUrl } from "../environment/Environment";
import { axiosInstance } from "../shared/AxiosConfig";

export const addUsers = async (userData) => {
    return await axios.post(`${rootApiUrl}/api/auth/signup`, userData)
};

export const getUsersByAdmin = async () => {
    return await axiosInstance.get(`${rootApiUrl}/api/user/get-user-admin`)
};

export const getUsersByCorporate = async (corporateRefNo, siteRefNo) => {
    return await axiosInstance.get(`${rootApiUrl}/api/user/get-user-corporate`, { params: { corporateRefNo: corporateRefNo, facilityRefNo: siteRefNo } })
};

export const generateOtpByUserName = async (userName) => {
    return await axios.post(`${rootApiUrl}/api/otp/generate-otp-user-name`, null, { params: { userName: userName } })
};

export const verifyOtpByUserName = async (userName, otp) => {
    return await axios.post(`${rootApiUrl}/api/otp/validate-otp`, null, { params: { userName: userName, otp: otp } })
};

export const saveNewPasswordByAdmin = async (resetPasswordData) => {
    return await axios.post(`${rootApiUrl}/api/user/save-new-password-admin`, resetPasswordData)
};

export const saveNewPassword = async (resetPasswordData) => {
    return await axios.post(`${rootApiUrl}/api/user/save-new-password`, resetPasswordData)
};
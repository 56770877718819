import React, { useState, useEffect } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { StyledTableCell } from '../../../src/shared/TableHeaderStyle';
import AddAreaSourcePollutantDetails from './AddAreaSourcePollutantDetails';
import DatePeriod from '../../shared/DatePeriod';
import { saveOrUpdateAirEmissionAreaSourceData } from '../../services/sub-issue-services/air-service/AirEmissionAreaSourceService';
import { useSelector } from 'react-redux';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import Snackbar from '../../toaster-message/Snackbar';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';

var pollutantDetailsArray = [];

function AddAirEmissionAreaSource(props) {

    const { openAreaSourceDialog, setOpenAreaSourceDialog, getAllAreaSourceData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, watch, control, setValue, getValues, formState: { errors }, } = useForm({ mode: "onTouched" });
    const [openPollutantDetailsDialog, setOpenPollutantDetailsDialog] = useState({ action: false, pollutantData: null });
    const [sourceId, setSourceId] = useState("");
    const [pollutantDetailsData, setPollutantDetailsData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    const changeSourceId = watch("sourceIds");
    useEffect(() => {
        let value = getValues("sourceIds")
        setSourceId(value)
    }, [changeSourceId]);

    useEffect(() => {
        if (openAreaSourceDialog.action && openAreaSourceDialog.areaSourceData) {
            reset(openAreaSourceDialog.areaSourceData)
        }
    }, [openAreaSourceDialog.action === true]);

    const getPollutantDetails = (pollutantData) => {
        pollutantDetailsArray.push(pollutantData);
        setPollutantDetailsData(pollutantDetailsArray);
    };

    const saveAreaSourceData = (formData) => {
        let payLoad = generateFromDateAndToDate(formData);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                refNo: "",
                applicable: true,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate,
                ...formData,
                airEmissionsAreaSourcePollutantDtos: pollutantDetailsData
            }
            saveOrUpdateAirEmissionAreaSourceData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getAllAreaSourceData()
                    reset({ sourceId: "", areaCoordinateEasting: "", areaCoordinateNorthing: "", storageArea: "" });
                    setSourceId("");
                    pollutantDetailsArray = [];
                    setPollutantDetailsData([]);
                    setOpenAreaSourceDialog({ action: false, areaSourceData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        }
    };

    const updateAreaSourceData = (formData) => {
        let obj = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            applicable: true,
            toDate: formData.toDate,
            fromDate: formData.fromDate,
            ...formData,
        }
        saveOrUpdateAirEmissionAreaSourceData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getAllAreaSourceData()
                reset({ sourceId: "", areaCoordinateEasting: "", areaCoordinateNorthing: "", storageArea: "" });
                setSourceId("");
                setOpenAreaSourceDialog({ action: false, areaSourceData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        setOpenAreaSourceDialog({ action: false, areaSourceData: null });
        reset({ sourceId: "", areaCoordinateEasting: "", areaCoordinateNorthing: "", storageArea: "" });
        setSourceId("");
        pollutantDetailsArray = [];
        setPollutantDetailsData([]);
    };

    const addPollutantDetails = () => {
        setOpenPollutantDetailsDialog({ action: true, pollutantData: null })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAreaSourceDialog.areaSourceData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllAreaSourceData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ sourceId: "", areaCoordinateEasting: "", areaCoordinateNorthing: "", storageArea: "" });
                setSourceId("");
                setOpenAreaSourceDialog({ action: false, areaSourceData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAreaSourceDialog.action} maxWidth="lg" fullWidth>
                <material.DialogTitle className='fw-bold'>{openAreaSourceDialog.areaSourceData ? "Edit Air Emission Area Source Data" : "Add Air Emission Area Source Data"}</material.DialogTitle>
                <material.DialogContent>
                    {openAreaSourceDialog.areaSourceData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Source Details (Area Source)</material.Typography>
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("sourceIds", { required: true, })}
                                    required
                                    label="Source Id"
                                    id="Source id"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("coordinateEasting", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Source Coordinate (Latitude)"
                                    id="areaCoordinateEasting"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                                {errors.areaCoordinateEasting && errors.areaCoordinateEasting.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("coordinateNorthing", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    label="Source Coordinate (Longitude)"
                                    id="areaCoordinateNorthing"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                                {errors.areaCoordinateNorthing && errors.areaCoordinateNorthing.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-4 col-lg-4 col-md-6'>
                                <material.TextField
                                    {...register("storageArea", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    label="Storage Area (m2)"
                                    id="Storage area (m2)"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.storageArea && errors.storageArea.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                        </div>
                    </material.Card>
                    {openAreaSourceDialog.areaSourceData ? null : (
                        <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                            <div className='row'>
                                <div className='py-1 col-6'>
                                    <material.Typography className='fw-bold'>Pollutant Details (Area Source)</material.Typography>
                                </div>
                                <div className='py-2 col-6'>
                                    <span className='float-end'>
                                        <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addPollutantDetails}>Add Pollutant Details</material.Button>
                                    </span>
                                </div>
                                <div className="col-12">
                                    <material.TableContainer >
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow >
                                                    <StyledTableCell>Source Id</StyledTableCell>
                                                    <StyledTableCell>Pollutant</StyledTableCell>
                                                    <StyledTableCell>Other Pollutant (Specify)</StyledTableCell>
                                                    <StyledTableCell>Emission Factor (gm/sec/unit area)</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {pollutantDetailsData?.map((item, ind) => (
                                                    <material.TableRow key={ind}>
                                                        <material.TableCell>{item.sourceIds}</material.TableCell>
                                                        <material.TableCell>{item.pollutant}</material.TableCell>
                                                        <material.TableCell>{(item.otherSpecifyPollutant === "" || item.otherSpecifyPollutant === null) ? "N/A" : item.otherSpecifyPollutant}</material.TableCell>
                                                        <material.TableCell>{item.areaEmissionFactor}</material.TableCell>
                                                    </material.TableRow>
                                                ))}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                </div>
                            </div>
                        </material.Card>
                    )}
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAreaSourceDialog.areaSourceData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateAreaSourceData)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAreaSourceDialog.areaSourceData.rejectionNote === null) || (openAreaSourceDialog.areaSourceData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAreaSourceData)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <AddAreaSourcePollutantDetails
                openPollutantDetailsDialog={openPollutantDetailsDialog}
                setOpenPollutantDetailsDialog={setOpenPollutantDetailsDialog}
                sourceId={sourceId}
                getPollutantDetails={getPollutantDetails}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    );
}

export default AddAirEmissionAreaSource;
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getAllDifferentlyAbledEmployeesWorkersData = async (differentlyAbledEmployeesWorkersData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employee-and-worker-number/get-all`, { params: { corporateRefNo: differentlyAbledEmployeesWorkersData.corporateRefNo, facilityRefNo: differentlyAbledEmployeesWorkersData.facilityRefNo, typeOfRecord: differentlyAbledEmployeesWorkersData.typeOfRecord, forApproval: differentlyAbledEmployeesWorkersData.forApproval, approveHistory: differentlyAbledEmployeesWorkersData.approveHistory, fromDate: differentlyAbledEmployeesWorkersData.fromDate, toDate: differentlyAbledEmployeesWorkersData.toDate } })
};

export const saveOrUpdateDifferentlyAbledEmployeesWorkersData = async (differentlyAbledEmployeesWorkersData, typeOfRecord) => {
    return await axiosInstance.post(`${rootApiUrl}/api/employee-and-worker-number/save-or-update`, differentlyAbledEmployeesWorkersData, { params: { typeOfRecord: typeOfRecord } })
};

export const sendForApprovalDifferentlyAbledEmployeesWorkersRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/send-for-approval`, RecordIds);
};

export const approveDifferentlyAbledEmployeesWorkersRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/approve`, recordId)
};





import React, { useState } from 'react';
import { Chart } from "react-google-charts";


function LineChart(props) {

    const { fullScreen, lineChartData, options } = props;
    // const [chartData, setChartData] = useState(data);
    const chartData = lineChartData && lineChartData.length > 1 ? lineChartData : [['Category', 'Value'], ['No Data Found', 0]];
    return (
        <div>
            <Chart
                chartType="LineChart"
                width="100%"
                height={fullScreen ? "500px" : "400px"}
                data={chartData}
                options={options}
            />
        </div>
    );
}

export default LineChart;
import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateBiodiversityValueOfSiteData } from '../../services/sub-issue-services/biodiversity-services/BiodiversityValueOfSiteService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';

const protectedArea = ["National Park", "Wildlife Sanctuary", "Marine Protected Area", "Community Reserve", "Conservation Reserves",];
const siteOfConservationImportance = ["Tiger Reserve", "Elephant Reserve", "Biosphere Reserve", "Ramsar Site", "Natural World Heritage Site", "Important Coastal & Marine Biodiversity Area", "Important Bird Area (IBA)", "Key Biodiversity Area (KBA)", "Biodiversity Heritage Sites", "Alliance for Zero Extinction Area (AZE)",];


function AddBiodiversityValueOfSite(props) {
    const { addOpenBiodiversityValueOfSite, setAddOpenBiodiversityValueOfSite, getBiodiversityValueOfSiteTableData, treeViewData } = props;

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [landOwnership, setLandOwnership] = useState("");
    const [sensitiveBiodiversityArea, setSensitiveBiodiversityArea] = useState("")
    const [protectedAreaSiteOfConservationImportance, setProtectedAreaSiteOfConservationImportance] = useState([]);
    const [categoryOfPAConservationImportanceSite, setCategoryOfPAConservationImportanceSite] = useState("");
    const [positionInRelation, setPositionInRelation] = useState("");
    const [ecosystemCharacterization, setEcosystemCharacterization] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (addOpenBiodiversityValueOfSite.action && addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData) {
            setLandOwnership(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.landOwnership);
            setSensitiveBiodiversityArea(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.sensitiveBiodiversityArea);
            setCategoryOfPAConservationImportanceSite(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.categoryOfConservationImportanceSite);
            setPositionInRelation(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.positionInRelation);
            setEcosystemCharacterization(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.ecosystemCharacterization);
            if (addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.sensitiveBiodiversityArea === 'Protected Area') {
                setProtectedAreaSiteOfConservationImportance(protectedArea);
            } else {
                setProtectedAreaSiteOfConservationImportance(siteOfConservationImportance);
            }
            reset(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData);
        };
    }, [addOpenBiodiversityValueOfSite.action === true]);

    const saveBiodiversityValueOfSite = (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "refNo": "",
                "applicable": true,
                "landOwnership": data.landOwnership,
                "sensitiveBiodiversityArea": data.sensitiveBiodiversityArea,
                "categoryOfConservationImportanceSite": data.categoryOfConservationImportanceSite,
                "positionInRelation": data.positionInRelation,
                "ecosystemCharacterization": data.ecosystemCharacterization,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            saveOrUpdateBiodiversityValueOfSiteData(object)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getBiodiversityValueOfSiteTableData();
                    setLandOwnership("");
                    setSensitiveBiodiversityArea("");
                    setCategoryOfPAConservationImportanceSite("");
                    setProtectedAreaSiteOfConservationImportance([]);
                    setPositionInRelation("");
                    setEcosystemCharacterization("");
                    setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateBiodiversityValueOfSite = (data) => {
        let object = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "refNo": addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.refNo,
            "applicable": true,
            "landOwnership": data.landOwnership,
            "sensitiveBiodiversityArea": data.sensitiveBiodiversityArea,
            "categoryOfConservationImportanceSite": data.categoryOfConservationImportanceSite,
            "positionInRelation": data.positionInRelation,
            "ecosystemCharacterization": data.ecosystemCharacterization,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        saveOrUpdateBiodiversityValueOfSiteData(object)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getBiodiversityValueOfSiteTableData();
                setLandOwnership("");
                setSensitiveBiodiversityArea("");
                setCategoryOfPAConservationImportanceSite("");
                setProtectedAreaSiteOfConservationImportance([]);
                setPositionInRelation("");
                setEcosystemCharacterization("");
                setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        setLandOwnership("");
        setSensitiveBiodiversityArea("");
        setCategoryOfPAConservationImportanceSite("");
        setProtectedAreaSiteOfConservationImportance([]);
        setPositionInRelation("");
        setEcosystemCharacterization("");
        setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
    };

    const selectLandOwnership = (event) => {
        setLandOwnership(event.target.value);
    };

    const selectSensitiveBiodiversityArea = (event) => {
        let selectedSensitiveBiodiversityArea = event.target.value;
        setSensitiveBiodiversityArea(selectedSensitiveBiodiversityArea)
        if (selectedSensitiveBiodiversityArea === 'Protected Area') {
            setProtectedAreaSiteOfConservationImportance(protectedArea)
        } else {
            setProtectedAreaSiteOfConservationImportance(siteOfConservationImportance)
        }
    };

    const selectCategoryOfPAConservationImportanceSite = (event) => {
        setCategoryOfPAConservationImportanceSite(event.target.value);
    };

    const selectPositionInRelation = (event) => {
        setPositionInRelation(event.target.value);
    };

    const selectEcosystemCharacterization = (event) => {
        setEcosystemCharacterization(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getBiodiversityValueOfSiteTableData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                setLandOwnership("");
                setSensitiveBiodiversityArea("");
                setCategoryOfPAConservationImportanceSite("");
                setProtectedAreaSiteOfConservationImportance([]);
                setPositionInRelation("");
                setEcosystemCharacterization("");
                setAddOpenBiodiversityValueOfSite({ action: false, biodiversityValueOfSiteTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenBiodiversityValueOfSite.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? "Edit Biodiversity Value of Site" : "Add Biodiversity Value of Site"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Biodiversity Value of Site</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Land Ownership</material.InputLabel>
                                    <material.Select
                                        {...register("landOwnership", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={landOwnership}
                                        onChange={selectLandOwnership}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Owned"}>Owned</material.MenuItem>
                                        <material.MenuItem value={"Leased"}>Leased</material.MenuItem>
                                        <material.MenuItem value={"Managed by the Organization"}>Managed by the Organization</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Sensitive Biodiversity Area</material.InputLabel>
                                    <material.Select
                                        {...register("sensitiveBiodiversityArea", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={sensitiveBiodiversityArea}
                                        onChange={selectSensitiveBiodiversityArea}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Protected Area"}>Protected Area</material.MenuItem>
                                        <material.MenuItem value={"Site of Conservation Importance"}>Site of Conservation Importance</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Category of PA / Conservation Importance Site</material.InputLabel>
                                    <material.Select
                                        {...register("categoryOfConservationImportanceSite", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={categoryOfPAConservationImportanceSite}
                                        onChange={selectCategoryOfPAConservationImportanceSite}
                                        fullWidth
                                        label="Material"
                                    >
                                        {protectedAreaSiteOfConservationImportance.map((ele, i) => (
                                            <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Position in Relation</material.InputLabel>
                                    <material.Select
                                        {...register("positionInRelation", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={positionInRelation}
                                        onChange={selectPositionInRelation}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Containing Portions"}>Containing Portions</material.MenuItem>
                                        <material.MenuItem value={"1 km"}>1 km</material.MenuItem>
                                        <material.MenuItem value={"1-2 km"}>1-2 km</material.MenuItem>
                                        <material.MenuItem value={"2-5 km"}>2-5 km</material.MenuItem>
                                        <material.MenuItem value={"More than 10 km"}>More than 10 km</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>Ecosystem Characterization</material.InputLabel>
                                    <material.Select
                                        {...register("ecosystemCharacterization", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={ecosystemCharacterization}
                                        onChange={selectEcosystemCharacterization}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Terrestrial"}>Terrestrial</material.MenuItem>
                                        <material.MenuItem value={"Freshwater or"}>Fresh Water</material.MenuItem>
                                        <material.MenuItem value={"Marine"}>Marine</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateBiodiversityValueOfSite)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.rejectionNote === null) || (addOpenBiodiversityValueOfSite.biodiversityValueOfSiteTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveBiodiversityValueOfSite)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddBiodiversityValueOfSite;
import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";

export const saveOrUpdateFreedomOfAssociationData = async (FreedomOfAssociationData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/freedom-of-association/save-or-update`, FreedomOfAssociationData)
};

export const getFreedomOfAssociationData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/freedom-of-association/get-all`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory, fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalFreedomOfAssociation = async (recordRefNo) => {
    return await axiosInstance.put(`${rootApiUrl}/api/freedom-of-association/send-for-approval`, recordRefNo)
};

export const approveFreedomOfAssociation = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/freedom-of-association/approve`, payload)
};

export const getLatestEmployeeData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employee-and-worker-number/get-latest-employee-data`, { params: { corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, typeOfRecord: payload.typeOfRecord } })
}
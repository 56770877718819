import React from 'react';
import { Chart } from "react-google-charts";


function ComboChart(props) {

    const { fullScreen, chartData, options } = props;

    const chartDataSource = chartData && chartData.length > 1 ? chartData : [['Category', 'Value'], ['No Data Found', 0]];

    return (
        <div>
            <Chart
                chartType="ComboChart"
                width="100%" 
                height={fullScreen ? "500px" : "400px"}
                data={chartDataSource}
                options={options}
            />
        </div>
    );
}

export default ComboChart;
import React, { useEffect, useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddEmployeeWorkers from '../../../sub-issues-dialogs/employee/AddEmployeeWorkers';
import { useSelector } from 'react-redux';
import { approveEmployeesAndWorkersRecords, getEmployeeAndWorkerData, sendForApprovalEmployeesAndWorkersRecords } from '../../../services/sub-issue-services/EmployeeAndWorkerService';
import { getDataFilterByCurrentApprovedDataAndNullApprovedDate } from '../../../shared/Filter';
import moment from 'moment';
import Snackbar from '../../../toaster-message/Snackbar';
import ApplicableNotApplicableWarning from '../../../dialogs/ApplicableNotApplicableWarning';
import EmployeeAgeBasedDataDialog from '../../../sub-issues-dialogs/employee/EmployeeAgeBasedDataDialog';
import RejectionNote from '../../../dialogs/RejectionNote';
import DateFilter from '../../../shared/DateFilter';

const label = { inputProps: { "aria-label": "Color switch demo" } };
var usersRole;
var recordRefNo = [];

function EmployeeAndWorkers(props) {
    const location = useLocation();
    const treeViewData = location.state;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    for (let i = 0; i < userDetails?.roles?.length; i++) {
        usersRole = userDetails.roles[i]
    };
    const [employeeWorkersTabs, setEmployeeWorkersTabs] = React.useState('Employee & Workers');
    const [openAddEmployeeWorkers, setOpenAddEmployeeWorkers] = useState({ action: false, employeeWorkersData: null });
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rejectionData: null });
    const [isEmployeeAgeBasedDataPresent, setIsEmployeeAgeBasedDataPresent] = useState("");
    const [accordionIsDisable, setAccordionIsDisable] = useState(null);
    const [applicable, setApplicable] = useState(true);
    const [applicableRefNo, setApplicableRefNo] = useState(null);
    const [employeesAndWorkersData, setEmployeesAndWorkersData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openEmployeeAgeBasedDataTable, setOpenEmployeeAgeBasedDataTable] = useState({ action: false, employeesAgeBasedData: null, collForm: "EMPLOYEE_WORKERS" });
   

    useEffect(() => {
        getEmployeeAndWorkerAllData()
    }, [employeeWorkersTabs]);

    const getFormDateToDate = (data) => {
        getEmployeeAndWorkerAllData(data)
    };

    const getEmployeeAndWorkerAllData = async (data) => {
        if (employeeWorkersTabs === "Employee & Workers") {
            let forApproval = usersRole === "ROLE_FACILITY_APPROVER" ? true : null;
            let approveHistory = false;
            let typeOfRecord = "EMPLOYEE_AND_WORKER"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getEmployeeAndWorkerData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    let filterData = getDataFilterByCurrentApprovedDataAndNullApprovedDate(resp.data.dtoList)
                    setEmployeesAndWorkersData(filterData);
                    setApplicableRefNo(resp.data.applicableRefNo);
                    if (resp.data.dtoList.length) {
                        resp.data?.dtoList.forEach(ele => {
                            setAccordionIsDisable(ele.isAgeBasedDataPresent)
                            setIsEmployeeAgeBasedDataPresent(ele.isAgeBasedDataPresent ? "Yes" : "No")
                        })
                    } else {
                        setIsEmployeeAgeBasedDataPresent("No")
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        } else if (employeeWorkersTabs === "Employee & Workers History") {
            let forApproval = true;
            let approveHistory = true;
            let typeOfRecord = "EMPLOYEE_AND_WORKER"
            let payload = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                forApproval,
                approveHistory,
                typeOfRecord,
                fromDate: data?.fromDate,
                toDate: data?.toDate,
            }
            await getEmployeeAndWorkerData(payload)
                .then((resp) => {
                    setApplicable(resp.data.applicable)
                    setEmployeesAndWorkersData(resp.data.dtoList)
                    if (resp.data.dtoList.length) {
                        resp.data?.dtoList.forEach(ele => {
                            setAccordionIsDisable(ele.isAgeBasedDataPresent)
                            setIsEmployeeAgeBasedDataPresent(ele.isAgeBasedDataPresent ? "Yes" : "No")
                        })
                    } else {
                        setIsEmployeeAgeBasedDataPresent("No")
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        }
    };

    const handleChangeApplicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    const changeEmployeeWorkersTabs = (event, newValue) => {
        setEmployeeWorkersTabs(newValue);
    };

    const addEmployeeWorkers = () => {
        setOpenAddEmployeeWorkers({ action: true, employeeWorkersData: null });
    };

    const editEmployeeWorkers = (rowData) => {
        setOpenAddEmployeeWorkers({ action: true, employeeWorkersData: rowData });
    };
    const handleCheckedRecord = (ids) => {
        setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
        if (recordRefNo.includes(ids)) {
            // recordRefNo.pop(ids);
            recordRefNo = recordRefNo.filter(item => item !== ids);
        } else {
            recordRefNo.push(ids);
        }
    };
    
    const sendForApprovalRecords = async () => {
        if (recordRefNo.length) {
            await sendForApprovalEmployeesAndWorkersRecords(recordRefNo)
                .then((resp) => {
                    getEmployeeAndWorkerAllData()
                    recordRefNo = [];
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const approveRecords = async () => {
        // if (recordIds.length) {
        //     let typeOfRecord = "EMPLOYEE_AND_WORKER";
        //     let status = "APPROVED"
        //     let payload = {
        //         corporateRefNo: userDetails.corporateRefNo,
        //         facilityRefNo: userDetails.facilityRefNo,
        //         typeOfRecord,
        //         status
        //     }
        //     await approveEmployeesAndWorkersRecords(payload, recordIds)
        //         .then((resp) => {
        //             getEmployeeAndWorkerAllData()
        //             recordIds = [];
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "success",
        //                 "message": resp.data,
        //             })
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
        //             })
        //         })
        // };
        if (recordRefNo.length) {
            let typeOfRecord = "EMPLOYEE_AND_WORKER";
            let obj = {
                "rejectionNote": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "subIssueId": treeViewData.id,
                "subIssueName": treeViewData.subIssue,
                "rejectedBy": userDetails.username,
                "status": "APPROVED",
                "recordRefNo": recordRefNo,
                typeOfRecord
            }
            await approveEmployeesAndWorkersRecords(obj)
                .then((resp) => {
                    recordRefNo = [];
                    getEmployeeAndWorkerAllData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    }
                })
        };
    };

    const rejectRecords = async () => {
        if (recordRefNo.length) {
            setOpenRejectionNoteDialog({ action: true, rejectionData: null });
            // if (recordRefNo.length) {
            //     let typeOfRecord = "EMPLOYEE_AND_WORKER";
            //     let status = "REJECTED"
            //     let payload = {
            //         corporateRefNo: userDetails.corporateRefNo,
            //         facilityRefNo: userDetails.facilityRefNo,
            //         typeOfRecord,
            //         status
            //     }
            //     await approveEmployeesAndWorkersRecords(payload, recordRefNo)
            //         .then((resp) => {
            //             getEmployeeAndWorkerAllData()
            //             recordRefNo = [];
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "success",
            //                 "message": resp.data,
            //             })
            //         })
            //         .catch((error) => {
            //             setOpenSnackBar({
            //                 "action": true,
            //                 "type": "error",
            //                 "message": error.response?.data?.message ? error.response.data.message : error.response.data,
            //             })
            //         })
        };
    };

    const getRejectionNoteData = async (noteData) => {
        let typeOfRecord = "EMPLOYEE_AND_WORKER";
        let obj = {
            "rejectionNote": noteData.rejectionNote,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "subIssueId": treeViewData.id,
            "subIssueName": treeViewData.subIssue,
            "rejectedBy": userDetails.username,
            "status": "REJECTED",
            "recordRefNo": recordRefNo,
            typeOfRecord
        }
        await approveEmployeesAndWorkersRecords(obj)
            .then((resp) => {
                recordRefNo = [];
                getEmployeeAndWorkerAllData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    const employeeAgeBasedData = (rowData) => {
        setOpenEmployeeAgeBasedDataTable({ action: true, employeesAgeBasedData: rowData, collForm: "EMPLOYEE_WORKERS" });
        setOpenAddEmployeeWorkers({ action: false, employeeWorkersData: null });
    };

    const viewRejectionNote = (rejectData) => {
        setOpenRejectionNoteDialog({ action: true, rejectionData: rejectData });
    };

    return (
        <div>
            <material.Box sx={{ display: 'flex' }}>
                <SwipeDrawer />
                <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                    <div className='row'>
                        <div className='col-6'>
                            <span className='cpl-6'><material.Typography variant="h5" className='headingText'>{treeViewData?.subIssue}</material.Typography></span>
                        </div>
                        <div className='col-6'>
                            <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                                <material.Stack direction="row" spacing={1} alignItems="center">
                                    <material.Typography>Not applicable</material.Typography>
                                    <material.Switch {...label} color="secondary" checked={applicable} onChange={handleChangeApplicableNotApplicable} />
                                    <material.Typography>Applicable</material.Typography>
                                </material.Stack>
                            </span>
                        </div>
                    </div>
                    <div>
                        <material.Box sx={{ width: '100%', typography: 'body1' }}>
                            <material.TabContext value={employeeWorkersTabs}>
                                <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <material.TabList onChange={changeEmployeeWorkersTabs} aria-label="lab API tabs example">
                                        <material.Tab className="text-capitalize" label="Employee & Workers" value="Employee & Workers" />
                                        <material.Tab className="text-capitalize" label="Employee & Workers History" value="Employee & Workers History" />
                                    </material.TabList>
                                </material.Box>
                                <material.TabPanel value="Employee & Workers">
                                    <div className="row mt-2 align-items-center">
                                        <div className="col-lg-7">
                                            {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                <DateFilter
                                                    getFormDateToDate={getFormDateToDate}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-5" hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                            <span className='float-end'>
                                                {employeesAndWorkersData?.length ? (
                                                    <>
                                                        <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} color='success' disabled={!applicable || !recordRefNo.length} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />} onClick={approveRecords}>Approve</material.Button>
                                                        <material.Button sx={{ textTransform: "none" }} hidden={usersRole === "ROLE_FACILITY_ESG_DATA"} disabled={!applicable || !recordRefNo.length} variant="contained" color='error' startIcon={<material.PriorityHighIcon />} onClick={rejectRecords}>Reject</material.Button>
                                                    </>
                                                ) : null}
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none", mr: 1 }} startIcon={<material.SendIcon />} onClick={sendForApprovalRecords}>Send for Approval</material.Button>
                                                <material.Button variant="contained" hidden={usersRole === "ROLE_FACILITY_APPROVER"} color='success' disabled={!applicable} sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addEmployeeWorkers}>Add Employee And Worker</material.Button>
                                            </span>
                                        </div>
                                    </div>

                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Category of Worker</StyledTableCell>
                                                                <StyledTableCell>Male</StyledTableCell>
                                                                <StyledTableCell>Female</StyledTableCell>
                                                                <StyledTableCell>Other</StyledTableCell>
                                                                <StyledTableCell>Total Derived</StyledTableCell>
                                                                <StyledTableCell>Vulnerable</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Approval Date</StyledTableCell>
                                                                )}
                                                                <StyledTableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>{usersRole === "ROLE_FACILITY_APPROVER" ? "Approve" : "Send for Approval"}</StyledTableCell>
                                                                <StyledTableCell>Age Based Data</StyledTableCell>
                                                                {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                    <StyledTableCell>Rejection Note</StyledTableCell>
                                                                )}
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {employeesAndWorkersData?.length ? employeesAndWorkersData.map((rowData, i) => (
                                                                <material.TableRow key={i}
                                                                    sx={usersRole === "ROLE_FACILITY_ESG_DATA" && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true ? { '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } } : null}
                                                                    onClick={((usersRole === "ROLE_FACILITY_ESG_DATA") && (rowData.sendForApproval === null || rowData.sendForApproval === false) && applicable === true) ?
                                                                        () => editEmployeeWorkers(rowData) : null}>
                                                                    <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                                                    <material.TableCell>{rowData.male}</material.TableCell>
                                                                    <material.TableCell>{rowData.female}</material.TableCell>
                                                                    <material.TableCell>{rowData.other}</material.TableCell>
                                                                    <material.TableCell>{rowData.total}</material.TableCell>
                                                                    <material.TableCell>{rowData.vulnerable}</material.TableCell>
                                                                    <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    )}
                                                                    <material.TableCell hidden={usersRole === "ROLE_FACILITY_EDITOR"}>
                                                                        {usersRole === "ROLE_FACILITY_ESG_DATA" ? (
                                                                            <material.Checkbox {...label} disabled={!applicable || rowData.sendForApproval} onChange={() => handleCheckedRecord(rowData.refNo)} color="success" />
                                                                        ) : (
                                                                            <material.Checkbox {...label} disabled={!applicable || rowData.approvedStatus === "APPROVED"} onChange={() => handleCheckedRecord(rowData.refNo)} color="success" />
                                                                        )}
                                                                    </material.TableCell>
                                                                    <material.TableCell>
                                                                        {rowData.isAgeBasedDataPresent === true ? (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: false }) }}>Yes</material.Link>
                                                                        ) : null}
                                                                    </material.TableCell>
                                                                    {usersRole === "ROLE_FACILITY_APPROVER" ? null : (
                                                                        <material.TableCell>{(rowData.rejectionNote === null) || (rowData.rejectionNote === "") ? "No" : (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); viewRejectionNote(rowData) }}>Yes</material.Link>
                                                                        )}
                                                                        </material.TableCell>
                                                                    )}
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                                <material.TabPanel value="Employee & Workers History">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <DateFilter
                                                getFormDateToDate={getFormDateToDate}
                                            />
                                        </div>
                                    </div>
                                    <material.Paper elevation={3} sx={{ p: 4, mt: 2 }}>
                                        <div className='row'>
                                            <div className="col-12">
                                                <material.TableContainer>
                                                    <material.Table>
                                                        <material.TableHead>
                                                            <material.TableRow>
                                                                <StyledTableCell>Category of Worker</StyledTableCell>
                                                                <StyledTableCell>Male</StyledTableCell>
                                                                <StyledTableCell>Female</StyledTableCell>
                                                                <StyledTableCell>Other</StyledTableCell>
                                                                <StyledTableCell>Total Derived</StyledTableCell>
                                                                <StyledTableCell>Vulnerable</StyledTableCell>
                                                                <StyledTableCell>From Date</StyledTableCell>
                                                                <StyledTableCell>To Date</StyledTableCell>
                                                                <StyledTableCell>Approval Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                                <StyledTableCell>Age Based Data</StyledTableCell>
                                                            </material.TableRow>
                                                        </material.TableHead>
                                                        <material.TableBody>
                                                            {employeesAndWorkersData?.length ? employeesAndWorkersData.map((rowData, i) => (
                                                                <material.TableRow key={i}>
                                                                    <material.TableCell>{rowData.categoryOfWorker}</material.TableCell>
                                                                    <material.TableCell>{rowData.male}</material.TableCell>
                                                                    <material.TableCell>{rowData.female}</material.TableCell>
                                                                    <material.TableCell>{rowData.other}</material.TableCell>
                                                                    <material.TableCell>{rowData.total}</material.TableCell>
                                                                    <material.TableCell>{rowData.vulnerable}</material.TableCell>
                                                                    <material.TableCell>{rowData.fromDate ? moment(rowData.fromDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.toDate ? moment(rowData.toDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>{rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : ""}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {rowData.approvedStatus === "APPROVED" ? <span className="badge bg-success">
                                                                            APPROVED
                                                                        </span> : null}
                                                                    </material.TableCell>
                                                                    <material.TableCell>
                                                                        {rowData.isAgeBasedDataPresent === true ? (
                                                                            <material.Link component="button" variant="body2" onClick={(e) => { e.stopPropagation(); employeeAgeBasedData({ ...rowData, history: true }) }}>Yes</material.Link>
                                                                        ) : null}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )) : (
                                                                <material.TableRow >
                                                                    <material.TableCell colSpan={12}>
                                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            )}
                                                        </material.TableBody>
                                                    </material.Table>
                                                </material.TableContainer>
                                            </div>
                                        </div>
                                    </material.Paper>
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.Box>
            </material.Box>
            <AddEmployeeWorkers
                openAddEmployeeWorkers={openAddEmployeeWorkers}
                setOpenAddEmployeeWorkers={setOpenAddEmployeeWorkers}
                getEmployeeAndWorkerAllData={getEmployeeAndWorkerAllData}
                treeViewData={treeViewData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ApplicableNotApplicableWarning
                openWarningDialog={openWarningDialog}
                setOpenWarningDialog={setOpenWarningDialog}
                getEmployeeAndWorkerAllData={getEmployeeAndWorkerAllData}
                applicable={applicable}
                treeViewData={treeViewData}
                applicableRefNo={applicableRefNo}
            />
            <EmployeeAgeBasedDataDialog
                openEmployeeAgeBasedDataTable={openEmployeeAgeBasedDataTable}
                setOpenEmployeeAgeBasedDataTable={setOpenEmployeeAgeBasedDataTable}
            />
            <RejectionNote
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getRejectionNoteData={getRejectionNoteData}
            />
        </div>
    );
};

export default EmployeeAndWorkers;
import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getAllWasteGenerationData = async (wasteGenerationData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/waste-generation/get-all`, { params: { corporateRefNo: wasteGenerationData.corporateRefNo, facilityRefNo: wasteGenerationData.facilityRefNo, forApproval: wasteGenerationData.forApproval, approveHistory: wasteGenerationData.approveHistory,fromDate: wasteGenerationData.fromDate, toDate: wasteGenerationData.toDate } })
};

export const saveOrUpdateWasteGenerationData = async (saveWasteGenerationData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/waste-generation/save-or-update`, saveWasteGenerationData)
};

export const sendForApprovalWasteGenerationDataRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-generation/send-for-approval`, RecordIds);
};

export const approveWasteGenerationDataRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-generation/approve`, recordId)
};

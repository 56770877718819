import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    data: false
};

const drawerSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        drawerAction: (state = initialState, action) => {
            const loginAuthState = {
                isLoggedIn: true,
                data: action.payload,
            };
            return loginAuthState;
        }
    },
});

export const { drawerAction } = drawerSlice.actions;
export default drawerSlice.reducer;
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../library/Material';
import { saveOrUpdateEnergyData } from '../services/sub-issue-services/energy/EnergyConsumptionWithinOrgService';
import { useSelector } from "react-redux";
import Snackbar from '../toaster-message/Snackbar';
import DatePeriod from '../shared/DatePeriod';
import { generateFromDateAndToDate } from '../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../services/sub-issue-services/DeleteRecordService';


const sourceData = ["Renewable Electricity (Self Produced)", "Renewable Electricity (Purchased)",];
const nonSourceData = ["Non-renewable Electricity (Self Produced)", "Non-renewable Energy (Purchased)"];
const purposeOfConsumptionData = [
    "Electricity", "Heating", "Cooling", "Steam"
];

const energyPurpose = [
    "Electricity Consumption",
    "Heating Consumption",
    "Cooling Consumption",
    "Steam Consumption"
];

function AddEnergy(props) {

    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { openAddOrgEnergy, setOpenAddOrgEnergy, getEnergyData, treeViewData } = props;
    const { register, control, handleSubmit, reset, watch, formState: { errors }, } = useForm();
    const [selectTypeOfSource, setSelectTypeOfSource] = useState("");
    const [selectUnit, setSelectUnit] = useState("");
    const [selectSource, setSelectSource] = useState("");
    const [selectPurposeOfConsumption, setSelectPurposeOfConsumption] = useState("");
    const [source, setSource] = useState([]);
    const [selectFuelTypeOfSource, setSelectFuelTypeOfSource] = useState("");
    const [selectFuelUnit, setSelectFuelUnit] = useState("");
    const [selectFuelType, setSelectFuelType] = useState("");
    const [selectSoldUnit, setSelectSoldUnit] = useState("");
    const [selectPurpose, setSelectPurpose] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (openAddOrgEnergy.action && openAddOrgEnergy.orgElectricityData) {
            setSelectTypeOfSource(openAddOrgEnergy.orgElectricityData.electricityTypeOfSource);
            setSelectSoldUnit(openAddOrgEnergy.orgElectricityData.electricitySoldUnit);
            setSelectPurpose(openAddOrgEnergy.orgElectricityData?.electricitySoldPurpose ? openAddOrgEnergy.orgElectricityData?.electricitySoldPurpose : "");
            setSelectFuelUnit(openAddOrgEnergy.orgElectricityData.fuelUnit);
            setSelectFuelType(openAddOrgEnergy.orgElectricityData.fuelType);
            setSelectFuelTypeOfSource(openAddOrgEnergy.orgElectricityData.fuelTypeOfSource);
            setSelectUnit(openAddOrgEnergy.orgElectricityData.electricityUnit);
            setSelectSource(openAddOrgEnergy.orgElectricityData.electricitySource);
            setSelectPurposeOfConsumption(openAddOrgEnergy.orgElectricityData.electricityPurposeOfConsumption);
            if (openAddOrgEnergy.orgElectricityData.electricityTypeOfSource === 'Renewable') {
                setSource(sourceData);
            } else if (openAddOrgEnergy.orgElectricityData.electricityTypeOfSource === 'Non-renewable') {
                setSource(nonSourceData);
            };
            reset(openAddOrgEnergy.orgElectricityData);
        }
    }, [openAddOrgEnergy.action === true]);

    const handleChangeTypeOfSource = (event) => {
        let selectedTypeOfSource = event.target.value;
        setSelectTypeOfSource(selectedTypeOfSource)
        if (selectedTypeOfSource === 'Renewable') {
            setSource(sourceData)
        } else {
            setSource(nonSourceData)
        }
    };
    const handleChangeSource = (event) => {
        setSelectSource(event.target.value);
    };
    const handleChangeUnit = (event) => {
        setSelectUnit(event.target.value);
    };
    const handleChangePurposeOfConsumption = (event) => {
        setSelectPurposeOfConsumption(event.target.value);
    };
    const handleChangeFuelTypeOfSource = (event) => {
        setSelectFuelTypeOfSource(event.target.value);
    };
    const handleChangeFuelType = (event) => {
        setSelectFuelType(event.target.value);
    };
    const handleChangeFuelUnit = (event) => {
        setSelectFuelUnit(event.target.value);
    };
    const handleChangeSoldUnit = (event) => {
        setSelectSoldUnit(event.target.value);
    };
    const handleChangePurpose = (event) => {
        setSelectPurpose(event.target.value);
    };
    const saveOrganizationElectricity = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let obj = {
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "electricityTypeOfSource": data.electricityTypeOfSource,
                "electricitySource": data.electricitySource,
                "electricityAmount": data.electricityAmount,
                "electricityUnit": data.electricityUnit,
                "electricityPurposeOfConsumption": data.electricityPurposeOfConsumption,
                "fuelTypeOfSource": data.fuelTypeOfSource,
                "fuelType": data.fuelType,
                "fuelAmount": data.fuelAmount,
                "fuelUnit": data.fuelUnit,
                "electricitySoldPurpose": data.electricitySoldPurpose,
                "electricitySoldAmount": data.electricitySoldAmount,
                "electricitySoldUnit": data.electricitySoldUnit,
                "applicable": true,
                "refNo": "",
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateEnergyData(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    getEnergyData()
                    reset({ electricityAmount: "", fuelAmount: "", electricitySoldAmount: "" });
                    setSelectTypeOfSource("");
                    setSelectSoldUnit("");
                    setSelectPurpose("");
                    setSelectFuelUnit("");
                    setSelectFuelType("");
                    setSelectFuelTypeOfSource("");
                    setSelectUnit("");
                    setSelectSource("")
                    setSelectPurposeOfConsumption("")
                    setOpenAddOrgEnergy({ action: false, orgElectricityData: null })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateOrganizationElectricity = async (data) => {
        let obj = {
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "electricityTypeOfSource": data.electricityTypeOfSource,
            "electricitySource": data.electricitySource,
            "electricityAmount": data.electricityAmount,
            "electricityUnit": data.electricityUnit,
            "electricityPurposeOfConsumption": data.electricityPurposeOfConsumption,
            "fuelTypeOfSource": data.fuelTypeOfSource,
            "fuelType": data.fuelType,
            "fuelAmount": data.fuelAmount,
            "fuelUnit": data.fuelUnit,
            "electricitySoldPurpose": data.electricitySoldPurpose,
            "electricitySoldAmount": data.electricitySoldAmount,
            "electricitySoldUnit": data.electricitySoldUnit,
            "applicable": true,
            "refNo": openAddOrgEnergy.orgElectricityData.refNo,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateEnergyData(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                getEnergyData()
                reset({ electricityAmount: "", fuelAmount: "", electricitySoldAmount: "" });
                setSelectTypeOfSource("");
                setSelectSoldUnit("");
                setSelectPurpose("");
                setSelectFuelUnit("");
                setSelectFuelType("");
                setSelectFuelTypeOfSource("");
                setSelectUnit("");
                setSelectSource("")
                setSelectPurposeOfConsumption("")
                setOpenAddOrgEnergy({ action: false, orgElectricityData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ electricityAmount: "", fuelAmount: "", electricitySoldAmount: "" });
        setSelectTypeOfSource("");
        setSelectSoldUnit("");
        setSelectPurpose("");
        setSelectFuelUnit("");
        setSelectFuelType("");
        setSelectFuelTypeOfSource("");
        setSelectUnit("");
        setSelectSource("")
        setSelectPurposeOfConsumption("")
        setOpenAddOrgEnergy({ action: false, orgElectricityData: null })
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: openAddOrgEnergy.orgElectricityData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getEnergyData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ electricityAmount: "", fuelAmount: "", electricitySoldAmount: "" });
                setSelectTypeOfSource("");
                setSelectSoldUnit("");
                setSelectPurpose("");
                setSelectFuelUnit("");
                setSelectFuelType("");
                setSelectFuelTypeOfSource("");
                setSelectUnit("");
                setSelectSource("")
                setSelectPurposeOfConsumption("")
                setOpenAddOrgEnergy({ action: false, orgElectricityData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddOrgEnergy.action} maxWidth="lg" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{openAddOrgEnergy.orgElectricityData ? "Edit Energy Consumption Within Organization" : "Add Energy Consumption Within Organization"}</material.DialogTitle>
                <material.DialogContent>
                    {openAddOrgEnergy.orgElectricityData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Electricity</material.Typography>
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.FormControl required={selectTypeOfSource !== "" ? true : false}
                                    fullWidth variant="standard">
                                    <material.InputLabel id="demo-simple-select-standard" variant="standard">Electricity Type of Source</material.InputLabel>
                                    <material.Select
                                        {...register("electricityTypeOfSource")}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        margin="dense"
                                        value={selectTypeOfSource}
                                        onChange={handleChangeTypeOfSource}
                                    >
                                        <material.MenuItem value={"Renewable"}>Renewable</material.MenuItem>
                                        <material.MenuItem value={"Non-renewable"}>Non-renewable</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectTypeOfSource !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Electricity Source</material.InputLabel>
                                        <material.Select
                                            {...register("electricitySource", { required: selectTypeOfSource === "" ? false : true })}
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectSource}
                                            onChange={handleChangeSource}
                                            disabled={selectTypeOfSource === "" ? true : false}
                                        >
                                            {source.map((ele, i) => (
                                                <material.MenuItem value={ele} key={i}>{ele}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.TextField
                                    fullWidth
                                    required={selectTypeOfSource !== "" ? true : false}
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Electricity Amount"
                                    type="number"
                                    {...register("electricityAmount", { required: selectTypeOfSource === "" ? false : true })}
                                    disabled={selectTypeOfSource === "" ? true : false}
                                />
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectTypeOfSource !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Electricity Unit</material.InputLabel>
                                        <material.Select
                                            {...register("electricityUnit", { required: selectTypeOfSource === "" ? false : true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectUnit}
                                            onChange={handleChangeUnit}
                                            disabled={selectTypeOfSource === "" ? true : false}
                                        >
                                            <material.MenuItem value="MJ">MJ</material.MenuItem>
                                            <material.MenuItem value="GJ">GJ</material.MenuItem>
                                            <material.MenuItem value="TJ">TJ</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectTypeOfSource !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Electricity Purpose of Consumption</material.InputLabel>
                                        <material.Select
                                            {...register("electricityPurposeOfConsumption", { required: selectTypeOfSource === "" ? false : true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectPurposeOfConsumption}
                                            onChange={handleChangePurposeOfConsumption}
                                            disabled={selectTypeOfSource === "" ? true : false}
                                        >
                                            {purposeOfConsumptionData?.map((data, i) => (
                                                <material.MenuItem key={i} value={data}>{data}</material.MenuItem>
                                            ))}
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                        </div>
                    </material.Card>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-2'>
                                <material.Typography className='fw-bold'>Fuel</material.Typography>
                            </div>
                            <div className='col-3 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectFuelTypeOfSource !== "" ? true : false} fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Fuel Type of Source</material.InputLabel>
                                        <material.Select
                                            {...register("fuelTypeOfSource")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectFuelTypeOfSource}
                                            onChange={handleChangeFuelTypeOfSource}
                                        >
                                            <material.MenuItem value="Renewable">Renewable</material.MenuItem>
                                            <material.MenuItem value="Non-renewable">Non-renewable</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-3 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectFuelTypeOfSource !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Fuel Type</material.InputLabel>
                                        <material.Select
                                            {...register("fuelType", { required: selectFuelTypeOfSource === "" ? false : true })}
                                            margin="dense"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectFuelType}
                                            onChange={handleChangeFuelType}
                                            disabled={selectFuelTypeOfSource === "" ? true : false}
                                        >
                                            <material.MenuItem value="Bio Fuels">Bio Fuels</material.MenuItem>
                                            <material.MenuItem value="Hydrogen Fuel">Hydrogen Fuel</material.MenuItem>
                                            <material.MenuItem value="Fully Synthetic Fuel">Fully Synthetic Fuel</material.MenuItem>
                                            <material.MenuItem value="Other Renewable Fuels">Other Renewable Fuels</material.MenuItem>
                                            <material.MenuItem value="Heavy Liquid Fuel">Heavy Liquid Fuel</material.MenuItem>
                                            <material.MenuItem value="Natural Gas (NG)">Natural Gas (NG)</material.MenuItem>
                                            <material.MenuItem value="Liquefied Petroleum Gas (LPG)">Liquefied Petroleum Gas (LPG)</material.MenuItem>
                                            <material.MenuItem value="Vehicular Fuel">Vehicular Fuel</material.MenuItem>
                                            <material.MenuItem value="Coal">Coal</material.MenuItem>
                                            <material.MenuItem value="Other Non-renewable Fuels">Other Non-renewable Fuels</material.MenuItem>
                                            <material.MenuItem value="Light Liquid Fuel">Light Liquid Fuel</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                            <div className='col-3 py-2'>
                                <material.TextField
                                    fullWidth
                                    required={selectFuelTypeOfSource !== "" ? true : false}
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Fuel Amount"
                                    type="number"
                                    {...register("fuelAmount", { required: selectFuelTypeOfSource === "" ? false : true })}
                                    disabled={selectFuelTypeOfSource === "" ? true : false}
                                />
                            </div>
                            <div className='col-3 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectFuelTypeOfSource !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Fuel Unit</material.InputLabel>
                                        <material.Select
                                            {...register("fuelUnit", { required: selectFuelTypeOfSource === "" ? false : true })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectFuelUnit}
                                            onChange={handleChangeFuelUnit}
                                            disabled={selectFuelTypeOfSource === "" ? true : false}
                                        >
                                            <material.MenuItem value="MJ">MJ</material.MenuItem>
                                            <material.MenuItem value="GJ">GJ</material.MenuItem>
                                            <material.MenuItem value="TJ">TJ</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                        </div>
                    </material.Card>
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Sold</material.Typography>
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectPurpose !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Energy Sold Purpose</material.InputLabel>
                                        <material.Select
                                            {...register("electricitySoldPurpose")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectPurpose}
                                            onChange={handleChangePurpose}
                                        >
                                            <material.MenuItem value="Electricity Consumption">Electricity Consumption</material.MenuItem>
                                            <material.MenuItem value="Heating Consumption">Heating Consumption</material.MenuItem>
                                            <material.MenuItem value="Cooling Consumption">Cooling Consumption</material.MenuItem>
                                            <material.MenuItem value="Steam Consumption">Steam Consumption</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                                {/* {openAddOrgEnergy.orgElectricityData ? (
                                    <material.TextField
                                        fullWidth
                                        variant="standard"
                                        id="demo-simple-select-standard"
                                        label="Energy Sold Purpose"
                                        type="text"
                                        InputProps={{ readOnly: true }}
                                        {...register("electricitySoldPurpose")}
                                    />
                                ) : (
                                    <material.Autocomplete
                                        id="area"
                                        fullWidth
                                        onChange={handleChangePurpose}
                                        options={energyPurpose}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Energy Sold Purpose"
                                            {...register("electricitySoldPurpose")}
                                        />}
                                    />
                                )} */}
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.TextField
                                    disabled={selectPurpose === "" ? true : false}
                                    required={selectPurpose !== "" ? true : false}
                                    fullWidth
                                    variant="standard"
                                    id="demo-simple-select-standard"
                                    label="Energy Sold Amount"
                                    type="number"
                                    {...register("electricitySoldAmount", { required: selectPurpose !== "" ? true : false })}
                                />
                            </div>
                            <div className='col-lg-4 col-md-6 py-2'>
                                <material.Box>
                                    <material.FormControl required={selectPurpose !== "" ? true : false}
                                        fullWidth variant="standard">
                                        <material.InputLabel id="demo-simple-select-standard" variant="standard">Energy Sold Unit</material.InputLabel>
                                        <material.Select
                                            {...register("electricitySoldUnit", { required: selectPurpose !== "" ? true : false })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            margin="dense"
                                            value={selectSoldUnit}
                                            disabled={selectPurpose === "" ? true : false}
                                            onChange={handleChangeSoldUnit}
                                        >
                                            <material.MenuItem value="MJ">MJ</material.MenuItem>
                                            <material.MenuItem value="GJ">GJ</material.MenuItem>
                                            <material.MenuItem value="TJ">TJ</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                </material.Box>
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {openAddOrgEnergy.orgElectricityData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateOrganizationElectricity)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(openAddOrgEnergy.orgElectricityData.rejectionNote === null) || (openAddOrgEnergy.orgElectricityData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveOrganizationElectricity)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    );
}

export default AddEnergy;
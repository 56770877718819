import React from 'react';
import { Chart } from "react-google-charts";


function DonutChart(props) {

    const { fullScreen, chartData, options } = props;

    const chartDataSource = chartData && chartData.length > 1 ? chartData : [['Category', 'Value'], ['No Data Found', 1]];

    return (
        <div>
            <Chart
                chartType="PieChart"
                width="100%"
                height={fullScreen ? "500px" : "400px"}
                data={chartDataSource}
                options={options}
            />
        </div>
    );
}

export default DonutChart;
import { axiosInstance } from "../../../shared/AxiosConfig";
import { rootApiUrl } from "../../../environment/Environment";


export const saveOrUpdateEnergyData = async (energyData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/energy/save-or-update`, energyData)
};

export const getAllEnergyData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/energy/get-all`, { params:payload })
};

export const sendForApprovalEnergy = async (recordRefNos) => {
    return await axiosInstance.put(`${rootApiUrl}/api/energy/send-for-approval`, recordRefNos)
};

export const approveRecordEnergy = async (payload) => {
    return await axiosInstance.put(`${rootApiUrl}/api/energy/approve`, payload)
};
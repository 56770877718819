import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";

export const getWasteRecoveryData = async (wasteRecoveryData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/waste-recovery/get-all`, { params: { corporateRefNo: wasteRecoveryData.corporateRefNo, facilityRefNo: wasteRecoveryData.facilityRefNo, forApproval: wasteRecoveryData.forApproval, approveHistory: wasteRecoveryData.approveHistory,fromDate: wasteRecoveryData.fromDate, toDate: wasteRecoveryData.toDate } })
};

export const saveOrUpdateWasteRecoveryData = async (saveWasteRecoveryData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/waste-recovery/save-or-update`, saveWasteRecoveryData)
};

export const sendForApprovalWasteGenerationDataRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-recovery/send-for-approval`, RecordIds);
};

export const approveWasteRecoveryDataRecord = async (recordId) => {
    return await axiosInstance.put(`${rootApiUrl}/api/waste-recovery/approve`, recordId)
};

import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useForm } from 'react-hook-form';
import { saveOrUpdateSensitiveSpeciesAffectedData } from '../../services/sub-issue-services/biodiversity-services/SensitiveSpeciesAffectedService';
import { useSelector } from 'react-redux';
import Snackbar from '../../toaster-message/Snackbar';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddsensitiveSpeciesAffected(props) {
    const { addOpenAddOpenSensitiveSpeciesAffected, setAddOpenSensitiveSpeciesAffected, getAllSensitiveSpeciesAffectedData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [iucnRedListSpeciesCategory, setIucnRedListSpeciesCategory] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);


    useEffect(() => {
        if (addOpenAddOpenSensitiveSpeciesAffected.action && addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData) {
            setIucnRedListSpeciesCategory(addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.iucnRedListSpeciesCategory);
            reset(addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData);
        }
    }, [addOpenAddOpenSensitiveSpeciesAffected.action === true]);

    const saveSensitiveSpeciesAffected = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                corporateRefNo: userDetails.corporateRefNo,
                facilityRefNo: userDetails.facilityRefNo,
                "refNo": "",
                "applicable": true,
                "iucnRedListSpeciesCategory": data.iucnRedListSpeciesCategory,
                "number": Number(data.number),
                "name": data.name,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateSensitiveSpeciesAffectedData(object)
                .then((resp) => {
                    getAllSensitiveSpeciesAffectedData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ number: "", name: "", });
                    setIucnRedListSpeciesCategory("");
                    setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateSensitiveSpeciesAffected = async (data) => {
        let object = {
            corporateRefNo: userDetails.corporateRefNo,
            facilityRefNo: userDetails.facilityRefNo,
            "refNo": addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.refNo,
            "applicable": true,
            "iucnRedListSpeciesCategory": data.iucnRedListSpeciesCategory,
            "number": Number(data.number),
            "name": data.name,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateSensitiveSpeciesAffectedData(object)
            .then((resp) => {
                getAllSensitiveSpeciesAffectedData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ number: "", name: "", });
                setIucnRedListSpeciesCategory("");
                setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    const handleClose = () => {
        reset({ number: "", name: "", });
        setIucnRedListSpeciesCategory("");
        setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
    };

    const selectIucnRedListSpeciesCategory = (event) => {
        setIucnRedListSpeciesCategory(event.target.value);
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllSensitiveSpeciesAffectedData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ number: "", name: "", });
                setIucnRedListSpeciesCategory("");
                setAddOpenSensitiveSpeciesAffected({ action: false, sensitiveSpeciesAffectedTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenAddOpenSensitiveSpeciesAffected.action} maxWidth="md" fullWidth >
                <material.DialogTitle className='fw-bold headingText'>{addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? "Edit Sensitive Species Affected" : "Add Sensitive Species Affected"}</material.DialogTitle>
                <material.DialogContent>
                    {addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Sensitive Species Affected</material.Typography>
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.FormControl variant="standard" margin="dense" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label" required>IUCN Red List Species Category</material.InputLabel>
                                    <material.Select
                                        {...register("iucnRedListSpeciesCategory", { required: true, })}
                                        required
                                        margin="dense"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={iucnRedListSpeciesCategory}
                                        onChange={selectIucnRedListSpeciesCategory}
                                        fullWidth
                                        label="Material"
                                    >
                                        <material.MenuItem value={"Critically Endangered"}>Critically Endangered</material.MenuItem>
                                        <material.MenuItem value={"Endangered Species"}>Endangered Species</material.MenuItem>
                                        <material.MenuItem value={"Near Threatened Species"}>Near Threatened Species</material.MenuItem>
                                        <material.MenuItem value={"Vulnerable Species"}>Vulnerable Species</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.TextField
                                    {...register("number", { required: true, pattern: /^[0-9]+$/ })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Number"
                                    id="userName"
                                    variant="standard"
                                    type='number'
                                    size="small"
                                    fullWidth
                                />
                                {errors.number && errors.number.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number</p>
                                )}
                            </div>
                            <div className='col-lg-6 py-1'>
                                <material.TextField
                                    {...register("name", { required: true, })}
                                    required
                                    // className='mt-3'
                                    margin="dense"
                                    label="Name"
                                    id="userName"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateSensitiveSpeciesAffected)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.rejectionNote === null) || (addOpenAddOpenSensitiveSpeciesAffected.sensitiveSpeciesAffectedTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveSensitiveSpeciesAffected)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
             <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddsensitiveSpeciesAffected;

import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const getAllWaterWithdrawalData = async (payloadData) => {
    return await axiosInstance.get(`${rootApiUrl}/api/water/water-withdrawal/get-all`, { params: payloadData })
};

export const saveWaterWithdrawalData = async (waterWithdrawalData) => {
    return await axiosInstance.post(`${rootApiUrl}/api/water/water-withdrawal/save-or-update`, waterWithdrawalData)
};
export const sendForApprovalWaterWithdrawalRecord = async (RecordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-withdrawal/send-for-approval`, RecordIds);
};

export const approveWaterWithdrawalRecord = async (payloadData) => {
    return await axiosInstance.put(`${rootApiUrl}/api/water/water-withdrawal/approve`, payloadData)
};
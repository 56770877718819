import { axiosInstance } from "../../shared/AxiosConfig";
import { rootApiUrl } from "../../environment/Environment";


export const saveOrUpdateEmployeeAndWorker = async (payload, typeOfRecord) => {
    return await axiosInstance.post(`${rootApiUrl}/api/employee-and-worker-number/save-or-update`, payload, { params: { typeOfRecord: typeOfRecord } })
};

export const getEmployeeAndWorkerData = async (payload) => {
    return await axiosInstance.get(`${rootApiUrl}/api/employee-and-worker-number/get-all`, { params: { typeOfRecord: payload.typeOfRecord, corporateRefNo: payload.corporateRefNo, facilityRefNo: payload.facilityRefNo, forApproval: payload.forApproval, approveHistory: payload.approveHistory,fromDate: payload.fromDate, toDate: payload.toDate } })
};

export const sendForApprovalEmployeesAndWorkersRecords = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/send-for-approval`, recordIds)
};

export const approveEmployeesAndWorkersRecords = async (recordIds) => {
    return await axiosInstance.put(`${rootApiUrl}/api/employee-and-worker-number/approve`, recordIds)
};
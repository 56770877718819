import React, { useEffect, useState } from 'react';
import { material } from '../../library/Material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toaster-message/Snackbar';
import { saveOrUpdateAirEmissionsInteractionsData } from '../../services/sub-issue-services/air-service/AirEmissionsInteractionsService';
import DatePeriod from '../../shared/DatePeriod';
import { generateFromDateAndToDate } from '../../shared/TimePeriod';
import moment from 'moment';
import DeleteRecordConfirmationDialog from '../../dialogs/DeleteRecordConfirmationDialog';
import { deleteRecord } from '../../services/sub-issue-services/DeleteRecordService';


function AddAirEmissionsInteractions(props) {
    const { addOpenAirEmissionsInteractions, setAddOpenAirEmissionsInteractions, getAllAirEmissionsInteractionsData, treeViewData } = props;
    const userDetails = useSelector((state) => state.LoginSlice.data);
    const { register, handleSubmit, reset, control, resetField, formState: { errors, isValid } } = useForm({ mode: "onTouched" });
    const [monitoringLocationIds, setMonitoringLocationIds] = useState("");
    const [unit, setUnit] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openDeleteRecordConfirmationDialog, setOpenDeleteRecordConfirmationDialog] = useState(false);

    useEffect(() => {
        if (addOpenAirEmissionsInteractions.action && addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData) {
            setMonitoringLocationIds(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.monitoringLocationIds);
            setUnit(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.unit)
            reset(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData);
        }
    }, [addOpenAirEmissionsInteractions.action === true]);


    const saveAirEmissionsInteractions = async (data) => {
        let payLoad = generateFromDateAndToDate(data);
        let newDate = moment().format("YYYY-MM-DD");
        if ((newDate > payLoad.fromDate) && (newDate > payLoad.toDate)) {
            let object = {
                "refNo": "",
                "corporateRefNo": userDetails.corporateRefNo,
                "facilityRefNo": userDetails.facilityRefNo,
                "applicable": true,
                "monitoringLocationIds": data.monitoringLocationIds,
                "monitoringLocationEasting": data.monitoringLocationEasting,
                "monitoringLocationNorthing": data.monitoringLocationNorthing,
                "measuredValue": Number(data.measuredValue),
                "nationalStandard": Number(data.nationalStandard),
                "unit": data.unit,
                toDate: payLoad?.toDate,
                fromDate: payLoad?.fromDate
            }
            await saveOrUpdateAirEmissionsInteractionsData(object)
                .then((resp) => {
                    getAllAirEmissionsInteractionsData()
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                    reset({ monitoringLocationEasting: "", monitoringLocationNorthing: "", measuredValue: "", nationalStandard: "", unit: "", });
                    setMonitoringLocationIds("");
                    setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "You are not authorized to perform this action. Redirected to login",
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                        })
                    };
                })
        } else {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "You can't add future records",
            })
        };
    };

    const updateAirEmissionsInteractions = async (data) => {
        let object = {
            "refNo": addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.refNo,
            "corporateRefNo": userDetails.corporateRefNo,
            "facilityRefNo": userDetails.facilityRefNo,
            "applicable": true,
            "monitoringLocationIds": data.monitoringLocationIds,
            "monitoringLocationEasting": data.monitoringLocationEasting,
            "monitoringLocationNorthing": data.monitoringLocationNorthing,
            "measuredValue": Number(data.measuredValue),
            "nationalStandard": Number(data.nationalStandard),
            "unit": data.unit,
            toDate: data.toDate,
            fromDate: data.fromDate
        }
        await saveOrUpdateAirEmissionsInteractionsData(object)
            .then((resp) => {
                getAllAirEmissionsInteractionsData()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                reset({ monitoringLocationEasting: "", monitoringLocationNorthing: "", measuredValue: "", nationalStandard: "", unit: "", });
                setMonitoringLocationIds("");
                setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    }

    const handleClose = () => {
        reset({ monitoringLocationEasting: "", monitoringLocationNorthing: "", measuredValue: "", nationalStandard: "", unit: "", });
        setMonitoringLocationIds("");
        setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
    };

    const handleMonitoringLocationIdChange = (event) => {
        setMonitoringLocationIds(event.target.value);
    };

    const handleChangeUnit = (event) => {
        setUnit(event.target.value)
    };

    const deleteConfirmation = () => {
        setOpenDeleteRecordConfirmationDialog(true);
    };

    const deleteRecordByRefNo = async () => {
        let payload = {
            refNo: addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.refNo,
            subissueName: treeViewData.subIssue,
        }
        await deleteRecord(payload)
            .then((resp) => {
                getAllAirEmissionsInteractionsData();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data,
                })
                setOpenDeleteRecordConfirmationDialog(false);
                reset({ monitoringLocationEasting: "", monitoringLocationNorthing: "", measuredValue: "", nationalStandard: "", unit: "", });
                setMonitoringLocationIds("");
                setAddOpenAirEmissionsInteractions({ action: false, airEmissionsInteractionsTableData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "You are not authorized to perform this action. Redirected to login",
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response?.data?.message ? error.response.data.message : error.response.data,
                    })
                };
            })
    };

    return (
        <div>
            <material.Dialog open={addOpenAirEmissionsInteractions.action} maxWidth="md" fullWidth>
                <material.DialogTitle id="alert-dialog-title" className='fw-bold headingText'>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? "Edit Air Emissions & Interactions" : "Add Air Emissions & Interactions"}
                </material.DialogTitle>
                <material.DialogContent>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? null : (
                        <DatePeriod
                            register={register}
                            control={control}
                        />
                    )}
                    <material.Card elevation={1} sx={{ p: 2, mt: 2, border: 1 }}>
                        <div className='row'>
                            <div className='py-1'>
                                <material.Typography className='fw-bold'>Air Emissions & Interactions</material.Typography>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.FormControl required variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label">Monitoring Location ID</material.InputLabel>
                                    <material.Select
                                        {...register("monitoringLocationIds", { required: true, })}
                                        require
                                        labelId="demo-simple-select-standard-label"
                                        className='pt-1'
                                        id="demo-simple-select-standard"
                                        value={monitoringLocationIds}
                                        onChange={handleMonitoringLocationIdChange}
                                        label="Type of source"
                                    >
                                        <material.MenuItem value={"AAQ 1"}>AAQ 1</material.MenuItem>
                                        <material.MenuItem value={"AAQ 2"}>AAQ 2</material.MenuItem>
                                        <material.MenuItem value={"AAQ 3"}>AAQ 3</material.MenuItem>
                                        <material.MenuItem value={"AAQ 4"}>AAQ 4</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("monitoringLocationEasting", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monitoring Location (Latitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monitoringLocationEasting && errors.monitoringLocationEasting.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("monitoringLocationNorthing", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,6})?$/ })}
                                    required
                                    margin="dense"
                                    label="Monitoring Location (Longitude)"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.monitoringLocationNorthing && errors.monitoringLocationNorthing.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also 6 digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("measuredValue", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="Measured Value"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.measuredValue && errors.measuredValue.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("nationalStandard", { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/ })}
                                    required
                                    margin="dense"
                                    label="National Standard"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                                {errors.nationalStandard && errors.nationalStandard.type === "pattern" && (
                                    <p className="errorMsg text-danger">Allowed only number & also two digit after decimal.</p>
                                )}
                            </div>
                            <div className='col-lg-4 py-2'>
                                <material.TextField
                                    {...register("unit", { required: true, })}
                                    required
                                    margin="dense"
                                    label="Unit"
                                    id="userName"
                                    variant="standard"
                                    type='text'
                                    size="small"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </material.Card>
                </material.DialogContent>
                <material.DialogActions sx={{ pb: 2, paddingX: 2 }}>
                    <material.Button variant="outlined" color="error" sx={{ textTransform: "none", mr: 1 }} onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData ? (
                        <>
                            <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(updateAirEmissionsInteractions)} startIcon={<material.DoneIcon />}>Update</material.Button>
                            {(addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.rejectionNote === null) || (addOpenAirEmissionsInteractions.airEmissionsInteractionsTableData.rejectionNote === "") ? (
                                <material.Button variant="outlined" color='error' sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />} onClick={deleteConfirmation}>Delete Record</material.Button>
                            ) : null}
                        </>
                    ) : (
                        <material.Button variant="outlined" color="success" sx={{ textTransform: "none", mr: 1 }} onClick={handleSubmit(saveAirEmissionsInteractions)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
             <DeleteRecordConfirmationDialog
                openDeleteRecordConfirmationDialog={openDeleteRecordConfirmationDialog}
                setOpenDeleteRecordConfirmationDialog={setOpenDeleteRecordConfirmationDialog}
                treeViewData={treeViewData}
                deleteRecordByRefNo={deleteRecordByRefNo}
            />
        </div>
    )
}

export default AddAirEmissionsInteractions;
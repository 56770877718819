import React, { useState } from 'react';
import { material } from '../../../library/Material';
import SwipeDrawer from '../../drawer/SwipeDrawer';
import { useLocation } from 'react-router-dom';
import SpecificDisclosure from '../../specific-disclosure/SpecificDisclosure';
import SpecificDisclosureHistory from '../../specific-disclosure/SpecificDisclosureHistory';


var usersRole;
const label = { inputProps: { "aria-label": "Color switch demo" } };


function BiodiversityCommitments(props) {
    const location = useLocation();
    const treeViewData = location?.state;
    const [biodiversityCommitmentsTabs, setBiodiversityCommitmentsTabs] = React.useState('Specific Disclosure');
    const [applicable, setApplicable] = useState(true);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const handleChangeTabs = (event, newValue) => {
        setBiodiversityCommitmentsTabs(newValue);
    };

    const applicableNotApplicable = () => {
        setOpenWarningDialog(true)
    };

    return (
        <material.Box sx={{ display: 'flex' }}>
            <SwipeDrawer />
            <material.Box component="main" sx={{ flexGrow: 1, p: 3, mt: 7 }}>
                <div className="row">
                    <div className="col-12">
                        <span><material.Typography variant="h5" className='text-capitalize'>{treeViewData?.subIssue}</material.Typography></span>
                    </div>
                    {/* <div className="col-6">
                        <span className="float-end" hidden={usersRole === "ROLE_FACILITY_APPROVER" || usersRole === "ROLE_FACILITY_EDITOR"}>
                            <material.Stack direction="row" spacing={1} alignItems="center">
                                <material.Typography>Not applicable</material.Typography>
                                <material.Switch {...label} color="secondary"
                                    checked={applicable} onChange={applicableNotApplicable}
                                />
                                <material.Typography>Applicable</material.Typography>
                            </material.Stack>
                        </span>
                    </div> */}
                </div>
                <material.Box sx={{ width: '100%', typography: 'body1' }}>
                    <material.TabContext value={biodiversityCommitmentsTabs}>
                        <material.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <material.TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                                <material.Tab className='text-capitalize' label="Specific Disclosure" value="Specific Disclosure" />
                                <material.Tab className='text-capitalize' label="Specific Disclosure History" value="Specific Disclosure History" />
                            </material.TabList>
                        </material.Box>
                        <material.TabPanel value="Specific Disclosure">
                            <SpecificDisclosure
                                treeViewData={treeViewData} />
                        </material.TabPanel>
                        <material.TabPanel value="Specific Disclosure History">
                            <SpecificDisclosureHistory
                                treeViewData={treeViewData} />
                        </material.TabPanel>
                    </material.TabContext>
                </material.Box>
            </material.Box>
        </material.Box>
    );
}

export default BiodiversityCommitments;